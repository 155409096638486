import { NgModule, APP_INITIALIZER } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule, Router } from '@angular/router';
import { ScenarioHealthComponent } from './scenario-health/scenario-health.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { MsalGuard } from '@azure/msal-angular';
import { HomeComponent } from './home/home.component';
import { PowerBIModule } from '../power-bi/power-bi.module';
import { AuthRoleGuard } from '../auth-role-guard';
import { CrewHealthComponent } from './crew-health/crew-health.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { MonitoringDashboardComponent } from './monitoring-dashboard/monitoring-dashboard.component';
import { ScenarioHealthV2Component } from './scenario-health-v2/scenario-health-v2.component';
import { DetailsUpdateComponent } from './details-update/details-update.component';
import { DetailsDisplayComponent } from './details-display/details-display.component';
import { ScenarioHealthService } from './service/scenario-health.service';
import { APP_CONFIG } from '../common/constants';
import { AppConfig } from '../model/app-config.model';
import { FeatureFlagGuard } from '../feature-flag-guard';

// routes for score card.
const scoreCardRoutes: Routes = [
  {
    path: 'score-card', component: HomeComponent,
    canActivate: [MsalGuard, FeatureFlagGuard],
    data: { featureFlag: 'score-card' }
  },
  {
    path: 'score-card/scenario-health', component: ScenarioHealthComponent,
    canActivate: [AuthRoleGuard, FeatureFlagGuard],
    data: { featureFlag: 'score-card/scenario-health' }
  },
  {
    path: 'score-card/crew-health', component: CrewHealthComponent,
    canActivate: [AuthRoleGuard, FeatureFlagGuard],
    data: { featureFlag: 'score-card/crew-health' }
  },
  {
    path: 'score-card/monitoring-dashboard', component: MonitoringDashboardComponent,
    canActivate: [AuthRoleGuard, FeatureFlagGuard],
    data: { featureFlag: 'score-card/monitoring-dashboard' }
  }
];

const loadConfigRoutes = (appConfig: AppConfig, router: Router) => () => {
  const enabledScoreCards = appConfig.scoreCards.filter(config => config.enable);
  const newScoreCardRoutes = enabledScoreCards.map(s => ({
    path: s.route,
    component: ScenarioHealthV2Component,
    canActivate: [AuthRoleGuard, FeatureFlagGuard],
    data: { featureFlag: s.route }
  }));

  router.resetConfig([...newScoreCardRoutes, ...router.config]);
};

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule.forChild(scoreCardRoutes),
    NgbDropdownModule,
    PowerBIModule,
  ],
  declarations: [
    ScenarioHealthComponent,
    HomeComponent,
    CrewHealthComponent,
    DashboardComponent,
    MonitoringDashboardComponent,
    ScenarioHealthV2Component,
    DetailsUpdateComponent,
    DetailsDisplayComponent,
  ],
  providers: [
    ScenarioHealthService,
    { provide: APP_INITIALIZER, useFactory: loadConfigRoutes, multi: true, deps: [APP_CONFIG, Router] },
  ]
})
export class ScoreCardModule { }

import { Component, OnInit, Input, EventEmitter, Output, SimpleChanges, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, Validators, ValidatorFn, AbstractControl } from '@angular/forms';
import { GraphService } from '../service/graph.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgbModalErrorComponent } from '../../modal/ngb-modal-error.component';
import { ProcessMiningRequest, Process } from '../model/process.model';
import { NgbModalSuccessComponent } from '../../modal/ngb-modal-success.component';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { constants } from '../common/constants';
import { MasterDataService } from '../service/master-data.service';
import { Subscription } from 'rxjs';
import { GraphMap } from '../common/graph/graph-map';
import { DisplayGraphService } from '../service/display-graph.service';
import { QueryOptionsService } from '../service/query-options.service';
import { mergeMap, filter } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { DataSource } from '../model/data-source.model';

@Component({
  selector: 'app-display-query-option',
  templateUrl: './query-option.component.html',
  styleUrls: ['./query-option.component.css', '../../../style/common-styles.scss']
})
export class QueryOptionComponent implements OnInit, OnDestroy {

  queryOptionForm = new FormGroup({
    dataSource: new FormControl('', [Validators.required]),
    query: new FormControl('', [Validators.required]),
    startEvents: new FormControl(''),
    endEvents: new FormControl(''),
    pruneThreshold: new FormControl(0, [Validators.required, this.validatePruneThreshold()]),
    includeActiveCases: new FormControl(false),
    skipSort: new FormControl(false),
  }, this.validateQueryOptions());

  processMiningRequest: ProcessMiningRequest;

  isQueryLoaded: boolean;
  isLoading: boolean;
  isUserAuthorized: boolean;

  // holds list of data sources
  dataSourceList: Array<DataSource>;

  teamGroupId: string;
  processId: string;
  isExecuteDoneSuccess: boolean;
  isSaveDoneSuccess: boolean;
  isImportedDataSource: boolean;

  private graphSubscription: Subscription;

  // all subscriptions.
  private allSubscriptions: Subscription;

  constructor(
    private graphService: GraphService,
    private modalService: NgbModal,
    private appInsightsService: ApplicationInsights,
    private masterDataService: MasterDataService,
    private displayGraphService: DisplayGraphService,
    private queryOptionsService: QueryOptionsService,
  ) {
    this.isExecuteDoneSuccess = false;
    this.isSaveDoneSuccess = false;
    this.isQueryLoaded = false;
    this.isLoading = false;
    this.isImportedDataSource = false;
    this.dataSourceList = new Array<DataSource>();

    this.queryOptionForm.get('dataSource').setValue(constants.SelectDataSource);

    this.allSubscriptions = new Subscription();
  }

  ngOnInit() {
    this.processId = this.queryOptionsService.getProcessId();
    this.teamGroupId = this.queryOptionsService.getOwnerGroupId();
    // Call all the value change events
    this.queryFormValueChange();
    this.dataSourceValueChange();

    // get the user authorization.
    this.isUserAuthorized = false;

    // loading master data.
    // 1. checking user authorization.
    // 2. loading process
    // 3. loading data sources.
    this.isQueryLoaded = false;
    this.isExecuteDoneSuccess = false;
    const masterDataSub = this.queryOptionsService.checkUserAuthorization().pipe(
      // if user is not authorized, do not load data source or process.
      filter((isUserAuthorized) => {
        this.isUserAuthorized = isUserAuthorized;
        return isUserAuthorized;
      }),
      mergeMap(() => {
        return this.masterDataService.getCommonAndSpecificGroupDataSources(this.teamGroupId);
      }),
      mergeMap((dataSources: DataSource[]) => {
        // process data source value.
        this.dataSourceList = dataSources;
        return this.graphService.getProcessById(this.processId);
      })
    ).subscribe(
      (process: Process) => {
        // mapping process.
        this.updateQueryOptionForm(process);

        this.isQueryLoaded = true;
        this.isExecuteDoneSuccess = true;
        this.isSaveDoneSuccess = true;
      },
      (error) => {
        const modal = this.modalService.open(NgbModalErrorComponent);
        modal.componentInstance.message = "Error in fetching master data for query options.";
        console.error('Error in fetching master data for query options.', error);
        this.appInsightsService.trackTrace({ message: 'query-options.component: error in fetching master data for query options.'});
        this.appInsightsService.trackException(error);
        this.isLoading = false;
      }
    );

    this.allSubscriptions.add(masterDataSub);
  }

  ngOnDestroy() {
    this.allSubscriptions.unsubscribe();
  }

  saveQueryOption(): void {
    this.isExecuteDoneSuccess = false;
    this.isSaveDoneSuccess = false;
    this.isLoading = true;

    // Create process mining request object.
    this.getLatestProcessMiningObject();

    // Call save method
    const graphSaveSubscription = this.graphService.saveProcessMiningAttributes(this.processId, this.processMiningRequest).subscribe(
      (response) => {
        if (response.status === 200) {
          const successModal = this.modalService.open(NgbModalSuccessComponent);
          successModal.componentInstance.message = "Successfully Saved Process Discovery attributes. Please view results in Discovered Process Map tab.";
        } else {
          const modal = this.modalService.open(NgbModalErrorComponent);
          modal.componentInstance.message = "Error in saving Process Discovery attributes. Please try again.";
        }
        this.isLoading = false;
        this.isSaveDoneSuccess = true;
      },
      (error) => {
        const modal = this.modalService.open(NgbModalErrorComponent);
        modal.componentInstance.message = "Error in saving Process Discovery attributes. Please try again.";
        console.error('error occured in saving process mining attributes.', error);
        this.appInsightsService.trackTrace({ message: 'query-options.component: error in saving process mining attributes'});
        this.appInsightsService.trackException(error);
        this.isLoading = false;
      }
    );

    this.allSubscriptions.add(graphSaveSubscription);
  }

  executeClick(): void {
    this.isExecuteDoneSuccess = false;
    this.isLoading = true;
    // Create process mining request object.
    this.getLatestProcessMiningObject();

    this.appInsightsService.startTrackEvent('Query Options: recompute process map');
    this.graphSubscription = this.graphService.getProcessMiningGraph(this.processId, this.processMiningRequest).subscribe(
      (graphMap: GraphMap) => {
        this.displayGraphService.setProcessMap(graphMap);
        this.displayGraphService.publishGraphChange();

        this.isLoading = false;
        this.isExecuteDoneSuccess = true;

        this.appInsightsService.stopTrackEvent('Query Options: recompute process map');
      },
      (error: HttpErrorResponse) => {
        const modal = this.modalService.open(NgbModalErrorComponent);
        modal.componentInstance.message = error.error;
        console.error('Error in computing process map with new attributes.', error);
        this.appInsightsService.stopTrackEvent('Query Options: recompute process map');
        this.appInsightsService.trackTrace({ message: 'query-options.component: error in computing process map with new attributes.'});
        this.appInsightsService.trackException(error);
        this.isLoading = false;
      }
    );
  }

  onboardPowerBIClick(): void {
    this.isLoading = true;
    this.graphService.onboardPowerBI(this.processId).subscribe(
      (response) => {
        if (response.status === 200) {
          const successModal = this.modalService.open(NgbModalSuccessComponent);
          successModal.componentInstance.message = "PowerBI onboarding is in progress. Please refresh page after 5 mins";
        }
        this.isLoading = false;
      },
      (error: HttpErrorResponse) => {
        const modal = this.modalService.open(NgbModalErrorComponent);
        if (error.status === 400) {
          modal.componentInstance.message = error.error;
        } else {
          modal.componentInstance.message = "Error onboarding Power BI. Please try again.";
        }
        console.error('Error occured onboarding Power BI.', error);
        this.appInsightsService.trackTrace({ message: 'query-options.component: error in onboarding Power BI'});
        this.appInsightsService.trackException(error);
        this.isLoading = false;
      }
    );
  }

  updateQueryOptionForm(process: Process) {
    const datasource = this.dataSourceList.find(x => x.id === process.dataSourceId);
    this.queryOptionForm.get('dataSource').setValue(datasource.id.toString());
    if (!datasource.typeDetails.isImported) {
      this.queryOptionForm.get('query').setValue(process.query);
    }
    this.queryOptionForm.get('startEvents').setValue(process.startEvents);
    this.queryOptionForm.get('endEvents').setValue(process.endEvents);
    this.queryOptionForm.get('pruneThreshold').setValue(process.pruneThreshold);
    this.queryOptionForm.get('includeActiveCases').setValue(process.includeActiveCases);
    this.queryOptionForm.get('skipSort').setValue(process.skipSort);
  }

  getLatestProcessMiningObject(): void {
    this.processMiningRequest = new ProcessMiningRequest();
    this.processMiningRequest.query = this.queryOptionForm.get('query').value;
    if (this.queryOptionForm.get('dataSource').value === constants.SelectDataSource) {
      const errorModal = this.modalService.open(NgbModalErrorComponent);
      errorModal.componentInstance.message = "Please select a data source to execute/save query";
      return;
    } else {
      this.processMiningRequest.dataSourceId = parseInt(this.queryOptionForm.get('dataSource').value);
    }

    const startEvents = (this.queryOptionForm.get('startEvents').value as string);
    const endEvents = (this.queryOptionForm.get('endEvents').value as string);
    let startEventsArray: string[];
    let endEventsArray: string[];

    if (startEvents != null && startEvents.length > 0) {
      startEventsArray = startEvents.split(",");
      startEventsArray = startEventsArray.map(s => s.trim());
    } else {
      startEventsArray = null;
    }
    this.processMiningRequest.startEvents = startEventsArray;

    if (endEvents != null && endEvents.length > 0) {
      endEventsArray = endEvents.split(",");
      endEventsArray = endEventsArray.map(s => s.trim());
    } else {
      endEventsArray = null;
    }
    this.processMiningRequest.endEvents = endEventsArray;

    this.processMiningRequest.pruneThreshold = this.queryOptionForm.get('pruneThreshold').value;
    this.processMiningRequest.includeActiveCases = this.queryOptionForm.get('includeActiveCases').value;
    this.processMiningRequest.skipSort = this.queryOptionForm.get('skipSort').value;
  }

  queryFormValueChange(): void {
    this.queryOptionForm.valueChanges.subscribe(() => {
      this.isExecuteDoneSuccess = false;
      this.isSaveDoneSuccess = false;
    });
  }

  dataSourceValueChange(): void {
    const dataSourceSubscription = this.queryOptionForm.get('dataSource').valueChanges.subscribe((val: string) => {
      const dataSource = this.dataSourceList.find(x => x.id.toString() === val);
      this.queryOptionForm.get('query').setValue("");
      this.queryOptionForm.get('startEvents').setValue("");
      this.queryOptionForm.get('endEvents').setValue("");
      this.queryOptionForm.get('pruneThreshold').setValue(0);
      this.queryOptionForm.get('includeActiveCases').setValue(false);
      this.queryOptionForm.get('skipSort').setValue(false);

      if (dataSource.typeDetails.isImported) {
        this.queryOptionForm.get('query').disable();
        this.isImportedDataSource = true;
      } else {
        this.queryOptionForm.get('query').enable();
        this.isImportedDataSource = false;
      }
    });
    this.allSubscriptions.add(dataSourceSubscription);
  }

  setFormControlDisability(isExecuteProgress: boolean) {
    if (isExecuteProgress) {
      this.queryOptionForm.disable({ emitEvent: false });
    } else {
      this.queryOptionForm.enable({ emitEvent: false });
    }
  }

  cancelClick() {
    this.appInsightsService.stopTrackEvent('Query Options: recompute process map');
    this.graphSubscription.unsubscribe();
    this.isLoading = false;
  }

  // Validations
  validatePruneThreshold(): ValidatorFn {
    return (pruneThreshold: AbstractControl): { [key: string]: any } | null => {
      const value = pruneThreshold.value as number;
      if (value >= 0 && value <= 1) {
        return null;
      } else {
        return {
          isPruneThresholdInvalid: true,
          pruneThresholdInvalidMessage: "Enter Prune Threshold between 0 and 1."
        };
      }
    };
  }

  validateQueryOptions(): ValidatorFn {
    return (queryOptions: AbstractControl): { [key: string]: any } | null => {
      const includeActiveCases = queryOptions.get('includeActiveCases').value as boolean;
      const endEvents = queryOptions.get('endEvents').value as string;
      if (!includeActiveCases) {
        return null;
      } else if (includeActiveCases && (endEvents === '' || endEvents === null)) {
        return {
          isIncludeActiveCasesInvalid: true,
          includeActiveCasesInvalidMessage: "End Activities must be specified if Include Active Cases is enabled"
        };
      }
    };
  }
}

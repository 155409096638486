import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-ngb-success-modal',
  template: `
    <div class="modal-header" style="background-color:rgb(60,130,61);color:white;">
      <div class="col-xs-1 text-center"> <i class="fa fa-check fa-2x"></i> </div>
      <h4 class="modal-title" style="margin-left:10px">Success</h4>
      <button type="button" class="close" aria-label="Close" (click)="activeModal.close('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <p [innerHTML]="message"></p>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-dark" ngbAutofocus  (click)="activeModal.close('Ok click')">Ok</button>
    </div>
  `
})
export class NgbModalSuccessComponent {
  @Input() message: string;

  constructor(public activeModal: NgbActiveModal) {
  }
}

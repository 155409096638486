import { TreeviewItem } from "ngx-treeview";

export class TreeConfig {
  title: string;
  hasFilter: boolean;
  hasCollapseExpand: boolean;
  maxHeight: number;
  hasAllCheckBox = false;
  decoupleChildFromParent = false;
  hasDivider = true;
}

export class TreeNode {
  id: string;
  displayName: string;
  type: string;
  children?: TreeNode[];
  parent?: TreeNode;
  level: number;
  hasChildren: boolean;
  isSelected: boolean;
  isCollapsed: boolean;
}

export class TreeviewItemValue {
  id: string;
  hasChildren: boolean;
  parent?: TreeviewItem;
  isSelected: boolean;
  type: string;
}

export class TreeLevelData {
  id: string;
  name: string;
  type: string;
}

export class TreeSelection {
  selectionHierarchy: TreeLevelData[];
}

<div [formGroup]="formGroup" class="d-flex flex-column">
  <div class="d-flex flex-row input-group input-group-sm">
    <div class="input-group-prepend">
      <label class="input-group-text" style="width: 11rem;">{{ instanceFilter.displayColumnName }}</label>
    </div>
    <div [ngSwitch]="instanceFilter.columnType">
      <!-- time input. -->
      <div *ngSwitchCase="'time'">
        <input type="text" class="form-control required" style="max-width: 13rem;" formControlName="input" attr.aria-label="{{ instanceFilter.displayColumnName }} Value"
          [owlDateTimeTrigger]="timeType" [owlDateTime]="timeType">
        <owl-date-time [pickerType]="'timer'" #timeType></owl-date-time>
      </div>
      <!-- date input. -->
      <div *ngSwitchCase="'date'">
        <input type="text" class="form-control required" style="max-width: 13rem;" formControlName="input" attr.aria-label="{{ instanceFilter.displayColumnName }} Value"
          [owlDateTimeTrigger]="dt3" [owlDateTime]="dt3">
        <owl-date-time [pickerType]="'calendar'" #dt3></owl-date-time>
      </div>
      <!-- date time input. -->
      <div *ngSwitchCase="'datetime'">
        <input type="text" class="form-control required" formControlName="input" [owlDateTimeTrigger]="datetimeType" attr.aria-label="{{ instanceFilter.displayColumnName }} Value"
          [owlDateTime]="datetimeType">
        <owl-date-time #datetimeType></owl-date-time>
      </div>

      <!-- default case. -->
      <div *ngSwitchDefault>
        <input type="text" class="form-control required" style="max-width: 13rem;" formControlName="input" attr.aria-label="{{ instanceFilter.displayColumnName }} Value">
      </div>
    </div>
  </div>
  <div class="d-flex flex-row mb-2">
    <small *ngIf="instanceFilter.columnType === 'int' && (formGroup.touched || formGroup.dirty) && formGroup.invalid"
      class="text-danger">Input field should be a number.</small>
    <small *ngIf="instanceFilter.columnType === 'real' && (formGroup.touched || formGroup.dirty) && formGroup.invalid"
      class="text-danger">Input field should be a decimal number.</small>
    <small *ngIf="instanceFilter.columnType === 'string' && (formGroup.touched || formGroup.dirty) && formGroup.invalid"
      class="text-danger">Input field should be a string.</small>
    <small
      *ngIf="instanceFilter.columnType === 'date' && (formGroup.touched || formGroup.dirty) && formGroup?.errors?.isDateInvalid"
      class="text-danger">{{ formGroup.errors.dateInvalidMessage }}</small>
    <small
      *ngIf="instanceFilter.columnType === 'time' && (formGroup.touched || formGroup.dirty) && formGroup?.errors?.isTimeInvalid"
      class="text-danger">{{ formGroup.errors.timeInvalidMessage }}</small>
    <small
      *ngIf="instanceFilter.columnType === 'time' && (formGroup.touched || formGroup.dirty) && formGroup?.errors?.isdatetimeInvalid"
      class="text-danger">{{ formGroup.errors.datetimeInvalidMessage }}</small>
  </div>
</div>

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { AddDashboardComponent } from './add-dashboard/add-dashboard.component';
import { BrowseCatalogComponent } from './browse-catalog/browse-catalog.component';
import { UpdateDashboardComponent } from './update-dashboard/update-dashboard.component';
import { AuthRoleGuard } from '../auth-role-guard';
import { FormsModule } from '@angular/forms';
import { OwnerValidatorDirective } from './common/owner-validator.directive';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { ServiceTreeComponent, ServiceTreeModalComponent } from './service-tree/service-tree.component';
import { FeatureFlagGuard } from '../feature-flag-guard';

// routes for monitoring solution.
const monitoringSolutionRoutes: Routes = [
  {
    path: 'monitoring-solution/add-dashboard',
    component: AddDashboardComponent,
    canActivate: [AuthRoleGuard, FeatureFlagGuard],
    data: { featureFlag: 'monitoring-solution/add-dashboard'}
  },
  {
    path: 'monitoring-solution/browse-catalog',
    component: BrowseCatalogComponent,
    canActivate: [AuthRoleGuard, FeatureFlagGuard],
    data: { featureFlag: 'monitoring-solution/browse-catalog'}
  },
  {
    path: 'monitoring-solution/update-dashboard/:dashboard-id',
    component: UpdateDashboardComponent,
    canActivate: [AuthRoleGuard, FeatureFlagGuard],
    data: { featureFlag: 'monitoring-solution/update-dashboard'}
  },
];

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        NgbNavModule,
        NgbModalModule,
        RouterModule.forChild(monitoringSolutionRoutes),
    ],
    declarations: [
        AddDashboardComponent,
        BrowseCatalogComponent,
        UpdateDashboardComponent,
        OwnerValidatorDirective,
        ServiceTreeComponent,
        ServiceTreeModalComponent,
    ]
})
export class MonitoringSolutionModule { }

import { AfterViewInit, Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { APP_CONFIG } from '../../common/constants';
import { AppConfig } from '../../model/app-config.model';
import { ReportInput } from '../../power-bi/model/power-bi.model';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements AfterViewInit {
  @ViewChild('titleDiv') titleDiv: ElementRef<HTMLElement>;
  powerBIConfig: ReportInput;
  constructor(@Inject(APP_CONFIG) appConfig: AppConfig) {
    this.powerBIConfig = appConfig.bamPowerBIReport;
  }

  ngAfterViewInit() {
    this.titleDiv.nativeElement.focus();
  }
}

// This file stores all the constants in the application.
export const constants = {
  // dashboard id's
  ScenarioHealthDashboard: 1,
  LiveSiteDashboard: 2,
  CrewDashboard: 3,
  
  // dashboard names
  ScenarioScoreCardName : "Scenario Health Score Card",
  CrewScoreCardName : "Crew Health Score Card",
};

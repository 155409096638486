<div class="container-fluid pt-3">
  <div class="jumbotron bg-transparent pb-1">
    <h1 class="display-5" #titleDiv tabindex="-1">Score Card</h1>
    <hr class="my-4" />
    <p class="lead">
      The Scenario Health Score Card helps the organization track the weekly
      health score for the critical scenarios. The dashboard provides a way for
      process owners to input the health status for their respective areas which
      the platform uses to compute metrics for the assigned period.
    </p>
    <p class="lead">
      For Onboarding & Access, please visit:
      <a class="link-text" href="https://aka.ms/m360wiki" target="_blank">
        https://aka.ms/m360wiki
        <span class="fa fa-link" aria-hidden="true"></span>
      </a>
    </p>
    <p class="lead">
      Power BI Report for Critical Scenarios Health Trend can be accessed here:
      <a class="link-text" href="{{ reportUrl }}" target="_blank" rel="noreferrer noopener">
        Scenario Health Trend Report
        <span class="fa fa-link" aria-hidden="true"></span>
      </a>
    </p>
  </div>
</div>
<app-power-bi-input [reportInput]="powerBIConfig"></app-power-bi-input>

<div class="modal-header">
  <ng-container *ngIf="!isUpdate">
    <h4 class="modal-title">Add New Data Source</h4>
  </ng-container>
  <ng-container *ngIf="isUpdate">
    <h4 class="modal-title">Update Data Source</h4>
  </ng-container>
  <button type="button" class="close" aria-label="Close" (click)="cancel()" [disabled]="isLoading">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <div *ngIf="isDataSourceLoading || isDataSourceTypeLoading" class="ml-auto">
    <span role="progressbar" class="info-text fa fa-spinner fa-spin fa-3x" aria-label="Loading"></span>
  </div>
  <ng-container *ngIf="!isDataSourceLoading && !isDataSourceTypeLoading">
    <form [formGroup]="dataSourceForm">
      <div class="input-group input-group-sm mb-2">
        <div class="input-group-prepend">
          <label class="input-group-text" id="dataSourceNameLabel" style="width: 9rem;">
            Data Source Name
            <span class="danger-text ml-1" aria-label="Required Field">*</span>
          </label>
        </div>
        <input type="text" formControlName="dataSourceName" class="form-control required" aria-label="Data Source Name"
          aria-describedby="dataSourceNameLabel">
      </div>
      <div class="input-group input-group-sm mb-2">
        <div class="input-group-prepend">
          <label class="input-group-text" id="dataSourceDescription" style="width: 9rem;">
            Description
          </label>
        </div>
        <textarea type="text" formControlName="description" class="form-control" aria-label="Data Source Description"
          aria-describedby="dataSourceDescription">
          </textarea>
      </div>
      <div class="input-group input-group-sm mb-2">
        <div class="input-group-prepend">
          <label class="input-group-text" id="dataSourceTypeLabel" style="width: 9rem;">
            Type
            <span class="danger-text ml-1" aria-label="Required Field">*</span>
          </label>
        </div>
        <select class="custom-select-sm form-control required" id="dataSourceType" style="width: 20rem;"
          aria-label="Data Source Type" formControlName="type">
          <option *ngFor="let source of dataSourceTypes" [ngValue]="source.type">{{ source.type }}</option>
        </select>
      </div>
      <div formGroupName="connectionString">
        <div *ngFor="let p of getFormGroupKeys(dataSourceForm.get('connectionString'))">
          <div class="input-group input-group-sm mb-2">
            <div class="input-group-prepend">
              <label class="input-group-text" id="dataSource{{p}}Label" style="width: 9rem;">
                {{p}}
                <span class="danger-text ml-1" aria-label="Required Field">*</span>
              </label>
            </div>
            <input type="text" formControlName="{{p}}" class="form-control required" aria-label="Data Source {{p}}"
              aria-describedby="dataSource{{p}}Label">
          </div>
        </div>
      </div>
      <div *ngIf="isImportedDataSource" class="input-group input-group-sm mb-2">
        <div class="input-group-prepend">
          <label class="input-group-text" id="dataSourceFileLabel" style="width: 9rem;">
            Choose New File
            <span class="danger-text ml-1" *ngIf="!isUpdate" aria-label="Required Field">*</span>
          </label>
        </div>
        <input formControlName="file" type="file" id="file" name="file" accept=".csv" class=" required"
          aria-label="Data Source File" aria-describedby="dataSourceFileLabel"
          (change)="onFileSelection($event.target.files)">
      </div>
    </form>
  </ng-container>


  <ng-container *ngIf="(isNewFileSelected || dataSourceForm.get('connectionString').dirty) && isUpdate">
    <small class="info-text"> Data source will be modified. Please visit dependent processes in Process Discovery to
      refresh </small>
    <br />
  </ng-container>
  <ng-container *ngIf="showError">
    <small class="danger-text"> {{ errorMessage }} </small>
  </ng-container>
</div>

<div class="modal-footer">
  <ng-container *ngIf="isLoading && isImportedDataSource">
    <small class="info-text"> File upload is in progress. Please wait. </small>
  </ng-container>
  <div *ngIf="isLoading" class="ml-2">
    <span role="progressbar" class="info-text fa fa-spinner fa-spin fa-2x" aria-label="Loading"></span>
  </div>
  <ng-container *ngIf="!isUpdate">
    <button type="button" class="btn primary-button" aria-label="Save" (click)="saveDataSource()"
      [disabled]="isLoading || !dataSourceForm.valid">Save</button>
  </ng-container>
  <ng-container *ngIf="isUpdate">
    <button type="button" class="btn primary-button" aria-label="Update" (click)="updateDataSource()"
      [disabled]="isLoading || !dataSourceForm.valid">Update</button>
  </ng-container>
  <button type="button" class="btn secondary-button" aria-label="Cancel" (click)="cancel()"
    [disabled]="isLoading">Cancel</button>
</div>

<div *ngIf="displayTable" appDivDimChange (divDimChange)="redrawTable()">
    <ngx-datatable #ngxDatatable class="bootstrap table-striped" [columns]="columns" [rows]="filteredRows"
        [columnMode]="'flex'" rowHeight="auto" headerHeight="undefined" [scrollbarV]="false" [reorderable]="false">
        <ng-container *ngFor="let column of columns">
            <ngx-datatable-column #test *ngIf="!customColumns.includes(column.name)" [name]="column.name" [prop]="column.prop"
                [resizeable]="false" sortable="true" [flexGrow]="column.flexGrow" [draggable]="false"
                [cellClass]="getCellClass(column.name)" [headerClass]="getCellClass(column.name)">              
            </ngx-datatable-column>
            <ng-container *ngIf="column.prop.startsWith('wrapText')">
                <ngx-datatable-column [name]="column.name" [prop]="column.prop"
                [resizeable]="false" sortable="true" [flexGrow]="column.flexGrow" [draggable]="false"
                [cellClass]="getCellClass(column.name)" [headerClass]="getCellClass(column.name)">
                    <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                        <span [attr.aria-label]="value" [title]="value"> {{ (value.length>100)? (value | slice:0:80)+'...':(value) }} </span>
                    </ng-template>
                </ngx-datatable-column>
            </ng-container>
            <ng-container *ngIf="column.prop.startsWith('edit')">
                <ngx-datatable-column *ngIf="tableOptions.addEditColumn" [flexGrow]="column.flexGrow" [name]="column.name" [resizeable]="false"
                    [prop]="column.prop" [sortable]="false" [cellClass]="'text-center'" [headerClass]="'text-center'" [draggable]="false">
                    <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                        <button class="btn btn-sm p-0" type="button" aria-label="Edit" (click)="editRow(value, column.name)">
                            <span class="fa fa-edit" style="color: #0F0FFF;"></span>
                        </button>
                    </ng-template>
                </ngx-datatable-column>
            </ng-container>
            <ng-container *ngIf="column.prop.startsWith('navigate')">
                <ngx-datatable-column *ngIf="tableOptions.addNavColumn" [flexGrow]="column.flexGrow" [name]="column.name" [resizeable]="false"
                    [prop]="column.prop" [sortable]="true" [draggable]="false"
                    [cellClass]="getCellClass(column.name)" [headerClass]="getCellClass(column.name)">
                    <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                        <a tabindex="0" style="cursor: pointer; color: #0F0FFF" [attr.aria-label]="navValue" [title]="value" (click)="navigateRow(value, column.name)">{{value}}</a>
                    </ng-template>
                </ngx-datatable-column>
            </ng-container>
            <ng-container *ngIf="column.name === 'Status'">
                <ngx-datatable-column [name]="column.name" [prop]="column.prop" [sortable]="false" [draggable]="false" [resizeable]="false"
                    [cellClass]="getStatusClass" [headerClass]="getCellClass(column.name)" [flexGrow]="column.flexGrow">
                    <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                        <span *ngIf="isStatusSpinner(value)" class="fa fa-spinner fa-spin" [attr.aria-label]="statusValue"> </span>
                    </ng-template>
                </ngx-datatable-column>
            </ng-container>
            <ng-container *ngIf="column.name === 'Status Message'">
                <ngx-datatable-column [name]="column.name" [prop]="column.prop" [sortable]="false" [draggable]="false"
                [cellClass]="'text-center'" [headerClass]="'text-center'" [flexGrow]="column.flexGrow">
                    <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                        <button class="btn btn-sm p-0" type="button" aria-label="Status Message" (click)="emitError(value, column.name)">
                            <span class="fa fa-ellipsis-h" style="color: #0F0FFF;" [attr.aria-label]="statusMsgValue"></span>
                        </button>
                    </ng-template>
                </ngx-datatable-column>
            </ng-container>
        </ng-container>
    </ngx-datatable>
</div>

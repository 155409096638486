import { Component, OnInit, OnDestroy, AfterViewInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { GraphService } from '../service/graph.service';
import { Graph } from '../common/graph/graph';
import { Subscription, Subject } from 'rxjs';
import { RuleFormService } from '../service/rule-form.service';
import { MasterDataService } from '../service/master-data.service';
import { InstanceFilters } from '../model/process.model';
import { GraphInstance } from '../common/graph/graph-instance';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgbModalErrorComponent } from '../../modal/ngb-modal-error.component';
import { HttpErrorResponse } from '@angular/common/http';
import { takeUntil } from 'rxjs/operators';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { FormComponentTypes } from '../common/constants';
import { PageName, PropertyTag } from '../model/page-configuration.model';
import { PropertyTaggingService } from '../service/property-tagging.service';
import { ProcessProperty } from '../model/graph.model';

@Component({
  selector: 'app-process-flow',
  templateUrl: './process-flow.component.html',
  styleUrls: ['./process-flow.component.css', '../../../style/common-styles.scss'],
  providers: [
    GraphService,
    RuleFormService,
    PropertyTaggingService
  ]
})
export class ProcessFlowComponent implements OnInit, OnDestroy {
  // display variables.
  isBusinessProcessCollapsed: boolean;
  isProcessSelected: boolean;

  // form options.
  requiredControls: UntypedFormGroup;

  // selected process id.
  processId: string;
  requiredControlsLabel: Array<InstanceFilters>;

  // form control for data refresh switch.
  refreshDataControl = new UntypedFormControl();

  // prevent the graph from loading before data is loaded.
  isRuleLoading: boolean;
  graphSubscription$: Subscription;

  // function to clear graph.
  clearGraph: () => void;
  unSubscribeRefreshData: Subject<boolean>;

  // current page name.
  readonly currentPageName = PageName.ProcessFlow;

  // accesibility variables.
  graphDescription: string;
  currentPathDescription: string;

  // common error message variable.
  displayError: boolean;
  errorMessage: string;

  constructor(
    private graphService: GraphService,
    private formService: RuleFormService,
    private masterDataService: MasterDataService,
    private modalService: NgbModal,
    private appInsightsService: ApplicationInsights,
    private propertyTaggingService: PropertyTaggingService
  ) {
    // view toggling.
    this.isBusinessProcessCollapsed = false;
    this.isRuleLoading = false;
    this.isProcessSelected = false;

    // required controls dynamic form.
    this.requiredControls = this.formService.getRuleForm();
    this.requiredControlsLabel = new Array<InstanceFilters>();

    this.clearGraph = null;

    this.graphDescription = '';
    this.currentPathDescription = '';

    this.displayError = false;
    this.errorMessage = '';
  }

  ngOnInit() {
    this.appInsightsService.trackTrace({ message: 'Process Flow Started'});
  }

  ngOnDestroy(): void {
    this.appInsightsService.trackTrace({message: 'Process Flow Complete'});
    if (this.graphSubscription$) {
      this.graphSubscription$.unsubscribe();
    }
    if (this.unSubscribeRefreshData) {
      this.unSubscribeRefreshData.next(true);
      this.unSubscribeRefreshData.complete();
    }
    
  }

  processResults(): void {
    this.appInsightsService.trackTrace({ message: `Process flow results called with process id: ${this.processId}`});
    // clear earlier results.
    if (this.clearGraph !== null) {
      this.clearGraph();
    }
    this.graphDescription = '';
    this.currentPathDescription = '';
    this.displayError = false;
    this.propertyTaggingService.setCurrentPagename(this.currentPageName);
    this.refreshDataControl.setValue(false);
    this.propertyTaggingService.disableComponent();
    this.propertyTaggingService.clearInput();

    // TODO: figure out a generalized way to get value from form service.
    const processInstanceFilters = this.processInstanceFilters(this.formService.convertToRuleset());
    this.isRuleLoading = true;
    this.appInsightsService.startTrackEvent('Process Flow Graph Load Time');
    this.graphSubscription$ = this.graphService.getProcessInstanceGraph(this.processId, processInstanceFilters).subscribe(
      (processInstanceGraph: GraphInstance) => {
        this.appInsightsService.trackTrace({message: `Process Flow graph computed for ${this.processId}`});
        const processFlowDivElement = document.getElementById('processFlowGraph');
        processInstanceGraph.setHTMLElement(processFlowDivElement);
        processInstanceGraph.initializeGraph();
        processInstanceGraph.redrawGraph();
        this.graphDescription = processInstanceGraph.getGraphDescription();
        this.appInsightsService.stopTrackEvent('Process Flow Graph Load Time');
        this.refreshDataControl.setValue(false);

        this.unSubscribeRefreshData = new Subject<boolean>();
        this.registerAnimationControls(processInstanceGraph);
        this.registerGraphClickEvents(processInstanceGraph);
        this.clearGraph = () => {
          this.unSubscribeRefreshData.next(false);
          this.unSubscribeRefreshData.complete();
          processInstanceGraph.disablePathAnimation();
          processInstanceGraph.clearGraph();
        };
        this.isRuleLoading = false;
        },
      (error: HttpErrorResponse) => {
        this.appInsightsService.stopTrackEvent('Process Flow Graph Load Time');
        console.log('error occured while fetching process instance graph', error);
        this.appInsightsService.trackException({ exception: error});
        const errorModal = this.modalService.open(NgbModalErrorComponent);
        errorModal.componentInstance.message = error.error;
        this.isRuleLoading = false;
      }
    );
  }

  processInstanceFilters(formValues: Array<any>): InstanceFilters[] {
    const processInstanceFilters = this.masterDataService.getProcessInstanceFilters(this.processId);
    for (let formIndex = 0; formIndex < formValues.length; formIndex++) {
      processInstanceFilters[formIndex].columnValue = formValues[formIndex]['input'];
    }
    return processInstanceFilters;
  }

  cancelExecution(): void {
    this.appInsightsService.stopTrackEvent('Process Flow Graph Load Time');
    this.appInsightsService.trackEvent({ name: 'Process Flow Graph Execution Cancelled'});
    this.graphSubscription$.unsubscribe();
    this.isRuleLoading = false;
  }

  registerAnimationControls(processGraph: GraphInstance) {
    this.refreshDataControl.valueChanges.pipe(
      takeUntil(this.unSubscribeRefreshData)
    ).subscribe(toggleValue => {
      if (toggleValue) {
        this.appInsightsService.startTrackEvent('Animation Enabled');
        this.currentPathDescription = processGraph.getCurrentPathDescription();
        processGraph.enablePathAnimation();
      } else {
        processGraph.disablePathAnimation();
        this.currentPathDescription = '';
        this.appInsightsService.stopTrackEvent('Animation Enabled');
      }
    });    
  }

  registerGraphClickEvents(processGraph: Graph) {
    processGraph.registerNodeClick().subscribe(nodeId => {
      this.propertyTaggingService.createPageComponentInput(this.currentPageName);
      for (const property of processGraph.getGraphProperties()) {
        this.propertyTaggingService.addProperty(this.currentPageName, property);
      }
      const nodeProperty = processGraph.getNodeProperties(+nodeId);
      for (const property of nodeProperty) {
        this.propertyTaggingService.addProperty(this.currentPageName, property);
      }
      this.displayCSOFlowGraph(nodeId, processGraph);

      // add metrics property for demo.
      for (const property of this.getMetrics(nodeId)) {
        this.propertyTaggingService.addProperty(this.currentPageName, property);
      }
      this.propertyTaggingService.setComponentInput();
      this.propertyTaggingService.enableComponent();
    });

    // commented out as we are not using edge click events.
    // processGraph.registerEdgeClick().subscribe(edgeId => {
    //   this.isSidePanelCollapsed = false;
    //   this.graphDisplayProperty = processGraph.getGraphProperties();
    //   this.itemDisplayProperty = processGraph.getEdgeProperties(+edgeId);
    // });
  }

  displayCSOFlowGraph(nodeID: string, processGraph: Graph) {
    const csoProperty = new ProcessProperty(
      'CSO Flow Graph',
      processGraph.getNodeEventName(+nodeID),
      [PropertyTag.CSOGraph]
    );
    this.propertyTaggingService.addProperty(this.currentPageName, csoProperty);
  }

  // toggle methods for views.
  toggleBusinessProcessView(): void {
    this.isBusinessProcessCollapsed = !this.isBusinessProcessCollapsed;
  }

  processIdEventHandler(businessProcessId: string): void {
    // get process instance filters from master data service.
    // temporary fix. need to streamline change detection.
    setTimeout(() => {
      const instanceFilters = this.masterDataService.getProcessInstanceFilters(businessProcessId);
      if (instanceFilters != null && instanceFilters.length === 0) {
        this.displayError = true;
        this.errorMessage = `Process is not onboarded to process flow.
      Please contact M360 team using the contact us page to onboard.`;
      } else {
        this.displayError = false;
        this.addRequiredControls(instanceFilters);
        this.processId = businessProcessId;
        this.isProcessSelected = true;
      }
    }, 1);
  }

  addRequiredControls(processInstanceFilters: Array<InstanceFilters>): void {
    // logging instance filters to AI.
    const instanceFiltersDisplay: any = processInstanceFilters.map(x => {
      const mappedX = {
        displayColumnName: x.displayColumnName,
        columnName: x.columnName,
        dataType: x.columnType,
      };
      return mappedX;
    });
    this.appInsightsService.trackTrace({ message: `Instance Filters for process ${this.processId} are ${JSON.stringify(instanceFiltersDisplay)}`});
    this.requiredControlsLabel = new Array<InstanceFilters>();
    this.formService.clearForm();
    this.requiredControls = this.formService.getRuleForm();
    let conditionSetIndex = 0;
    for (const instanceFilter of processInstanceFilters) {
      const formType: FormComponentTypes = this.getFormType(instanceFilter.columnType);
      this.formService.addConditionSet(conditionSetIndex, formType);
      this.requiredControlsLabel.push(instanceFilter);
      conditionSetIndex += 1;
    }
  }

  removeRequiredControls(remove: boolean) {
    if (remove) {
      this.isProcessSelected = false;
    } else {
      this.isProcessSelected = true;
    }
  }

  getFormType(columnType: string): FormComponentTypes {
    if (columnType === 'int') {
      return FormComponentTypes.LabelledIntInput;
    } else if (columnType === 'real') {
      return FormComponentTypes.LabelledRealInput;
    } else if (columnType === 'string') {
      return FormComponentTypes.LabelledStringInput;
    } else if (columnType === 'date') {
      return FormComponentTypes.LabelledDateInput;
    } else if (columnType === 'time') {
      return FormComponentTypes.LabelledTimeInput;
    } else if (columnType === 'datetime') {
      return FormComponentTypes.LabelledDatetimeInput;
    } else {
      this.appInsightsService.trackTrace({ message: 'unsupported column type received in getFormType'});
    }
  }

  getMetrics(nodeId: string): Array<ProcessProperty> {
    return [
      {
        name: 'Metric Name',
        value: 'Metric Value',
        propertyTags: [PropertyTag.MetricsTable]
      },
      {
        name: 'QoS (24h)',
        value: '',
        propertyTags: [PropertyTag.MetricsTable]
      },
      {
        name: 'Reliability (24h)',
        value: '',
        propertyTags: [PropertyTag.MetricsTable]
      },
      {
        name: 'Number of transaction (24h)',
        value: '',
        propertyTags: [PropertyTag.MetricsTable]
      },
      {
        name: 'Failed Transaction (24h)',
        value: '',
        propertyTags: [PropertyTag.MetricsTable]
      }
    ];
  }
}

import { Graph } from './graph';
import {
  GraphModel, GraphLabelMap,
  ProcessPath, ProcessNode, ProcessEdge, ProcessProperty, DistributionPath
} from '../../model/graph.model';
import { timer, Subscription } from 'rxjs';
import 'moment-duration-format';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { NodeColor } from '../constants';

import * as d3_color from 'd3-color';
import * as d3_interpolation from 'd3-interpolate';
import { TimeUtility } from '@microsoft/network-graph';

/**
 * GraphMap created for visjs.
 */
export class GraphMap extends Graph {
  // graph map variables.
  protected paths: Array<ProcessPath>;
  protected createdOn: Date;

  private graphModelMaps: Map<string, GraphModel>;
  private graphTraces: Map<string, Array<string>>;
  private graphPathProperties: Map<string, Array<ProcessProperty>>;

  private animateNetworkSubscription$: Subscription;

  private graphDescription: string;
  private pathDescriptions: Map<string, string>;

  constructor(appInsightsService: ApplicationInsights) {
    super(appInsightsService);
    this.paths = new Array<ProcessPath>();

    this.graphModelMaps = new Map<string, GraphModel>();
    this.graphTraces = new Map<string, Array<string>>();
    this.graphPathProperties = new Map<string, Array<ProcessProperty>>();

    this.pathDescriptions = new Map<string, string>();
  }

  setGraphMapCreatedDate(createdDate: Date) {
    this.createdOn = createdDate;
  }

  getGraphMapCreatedDate() {
    return this.createdOn;
  }

  setGraphPaths(paths: Array<ProcessPath>) {
    this.paths = paths;
    this.setGraphTrace(this.paths);
    this.setGraphPathPropertiesMap(this.paths);
  }

  public getGraphPathById(pathId: string): ProcessPath {
    const path = this.paths.find(x => x.id === pathId);
    if (path === undefined) {
      throw new Error(`Path with id ${pathId} not found.`);
    } else {
      return path;
    }
  }

  setGraphPathPropertiesMap(paths: Array<ProcessPath>) {
    for (let pathIndex = 0; pathIndex < paths.length; pathIndex++) {
      this.graphPathProperties.set(paths[pathIndex].id, paths[pathIndex].properties);
      // generate alt text distributions for paths.
      this.pathDescriptions.set(paths[pathIndex].id,
        this.generateGraphPathDescription(paths[pathIndex].nodeIdPath));
    }
  }

  getGraphPathPropertiesByPathId(pathId: string): Array<ProcessProperty> {
    if (this.graphPathProperties.has(pathId)) {
      return this.graphPathProperties.get(pathId);
    } else {
      this.appInsightsService.trackTrace({ message: `no properties for frequency value ${pathId}`});
      return null;
    }
  }

  generateGraphModels() {
    // generate updated node labels.
    const defaultNodeMap = new Array<GraphLabelMap>();
    const countTimeNodeMap = new Array<GraphLabelMap>();
    // const countNodeMap = new Array<GraphLabelMap>();
    const alertNodeMap = new Array<GraphLabelMap>();

    this.nodes.forEach((node: ProcessNode) => {
      const isNodeStartOrEnd = node.event === 'Start' || node.event === 'End' || node.event === 'Active';
      const nodeProperties = this.getNodeProperties(node.id);

      // adding default map.
      defaultNodeMap.push({ id: node.id, label: node.displayName });

      // adding time and count graph model.
      let nodeCountTimeLabel = node.displayName + '\n';
      if (!isNodeStartOrEnd) {
        const nodeTimeProperty = nodeProperties.find(p => p.name === 'Time');
        if (nodeTimeProperty != null) {
          nodeCountTimeLabel += 'Event Duration: (' + nodeTimeProperty.value + ' mins) \n';
        }

        const nodeCountProperty = nodeProperties.find(p => p.name === 'Count');
        if (nodeCountProperty != null) {
          nodeCountTimeLabel += 'Event Count: ' + nodeCountProperty.value;
        }
      }
      countTimeNodeMap.push({ id: node.id, label: nodeCountTimeLabel });

      // adding alert model.
      const alertCountProperty = nodeProperties.find(p => p.name === 'AlertCount');
      const totalTransactionCountProperty = nodeProperties.find(p => p.name === 'TotalTransactionCount');
      let nodeAlertLabel = node.displayName + '\n';
      if (alertCountProperty != null) {
        nodeAlertLabel += `Number of Violations: ${alertCountProperty.value} \n`;
      }
      if (totalTransactionCountProperty != null) {
        nodeAlertLabel += `Total Transactions: ${totalTransactionCountProperty.value}`;
      }
      alertNodeMap.push({ id: node.id, label: nodeAlertLabel });
    });

    // generate updated edge labels.
    const defaultEdgeMap = new Array<GraphLabelMap>();
    const countTimeEdgeMap = new Array<GraphLabelMap>();

    this.edges.forEach((edge: ProcessEdge) => {
      const edgeProperty = this.getEdgeProperties(edge.id);

      // console.log('default node label is ', node.label);
      defaultEdgeMap.push({ id: edge.id, label: '' });

      // create count and time edge map.
      let edgeCountTimeLabel = '';
      const timeEdgeProperty = edgeProperty.find(e => e.name === 'Time');
      if (timeEdgeProperty != null) {
        edgeCountTimeLabel += 'Edge Duration: ' + TimeUtility.pretiffyTimeDuration(timeEdgeProperty.value) + '<br>';
      }

      const countEdgeProperty = edgeProperty.find(e => e.name === 'Count');
      if (countEdgeProperty != null) {
        edgeCountTimeLabel += 'Edge Count: ' + countEdgeProperty.value;
      }
      countTimeEdgeMap.push({ id: edge.id, label: '', title: edgeCountTimeLabel });

    });
    this.graphModelMaps.set('default', { nodes: defaultNodeMap, edges: defaultEdgeMap });
    this.graphModelMaps.set('timeCount', { nodes: countTimeNodeMap, edges: countTimeEdgeMap });
    this.graphModelMaps.set('alert', { nodes: alertNodeMap, edges: defaultEdgeMap });
  }

  getGraphModel(graphModel: string): GraphModel | null {
    if (this.graphModelMaps.has(graphModel)) {
      const graphModels = this.graphModelMaps.get(graphModel);
      return graphModels != null ? graphModels : null;
    } else {
      return null;
    }
  }

  setGraphTrace(paths: Array<ProcessPath>) {
    for (const path of paths) {
      this.graphTraces.set(path.id, path.edgeIdPath);
    }
  }

  getPathDistributionList(): Array<DistributionPath> {
    const distributionPaths = this.paths.map(path => {
      return {
        frequency: (path.properties
          .find(property => property.name === 'Frequency').value * 100)
          .toFixed(3)
          .toString(),
        pathId: path.id
      };
    });
    return distributionPaths;
  }

  getGraphPathEdgeIdPath(pathId: string): Array<string> {
    return this.graphTraces.get(pathId);
  }

  enableAnimation(edgeList: Array<string>) {
    edgeList.forEach(edgeId => {
      this.changeArrowType(edgeId, false);
    });
    this.animateNetworkSubscription$ = timer(0, 700).subscribe(() => {
      this.animateEdges(edgeList);
    });
  }

  disableAnimation() {
    if (this.animateNetworkSubscription$) {
      this.animateNetworkSubscription$.unsubscribe();
    }
    this.clearEdges();
  }

  /**
  * generates accessible alt text for process mining graph.
  */
  generateGraphDescription(): void {
    const numberNodes = this.nodes.length;
    const numberEdges = this.edges.length;
    this.graphDescription = `the process mining graph contains ${numberNodes} nodes and ${numberEdges} edges.
                             Check Event Sequence for path distribution.`;
  }

  getGraphDescription(): string {
    return this.graphDescription;
  }

  /**
   * generates alt text for process mining paths.
   */
  generateGraphPathDescription(nodeIdPath: Array<string>): string {
    let pathDescription = 'the path has the following events: ';
    for (const nodeId of nodeIdPath) {
      pathDescription += this.getNodeEventName(+nodeId) + ', ';
    }
    return pathDescription;
  }

  getGraphPathDescription(): Map<string, string> {
    return this.pathDescriptions;
  }

  getGraphPaths(): Array<ProcessPath> {
    return this.paths;
  }

  /**
   * Method to be called after all nodes have been added to the graph.
   */
  setNodeGradientColor(): void {
    // extract node alert counts and node ids.
    const nodeAlertCounts = this.nodes
      .map((node: ProcessNode) => {
        const nodeId = node.id;
        const nodeAlertCountProperty = node.properties.find(p => p.name === 'AlertCount');

        if (nodeAlertCountProperty != null) {
          return { id: nodeId, alertCount: nodeAlertCountProperty.value };
        } else {
          return { id: nodeId, alertCount: 0 };
        }
      })
      // sort node ids by alert counts.
      .sort((x, y) => {
        if (x.alertCount === y.alertCount) {
          return 0;
        } else if (x.alertCount > y.alertCount) {
          return -1;
        } else {
          return 1;
        }
      });

    // get unique alert counts.
    const alertCounts =
      nodeAlertCounts.map(x => x.alertCount)
        // removing duplicate alert count from alertCounts.
        .filter((alertCount: number, originalPos: number, alertCountArray: number[]) => alertCountArray.indexOf(alertCount) === originalPos)
      ;

    // generating color from d3-color and d3-interpolate.
    const startColor = d3_color.color(NodeColor.PROCESS_HEALTH_DEFAULT);
    const endColor = d3_color.color(NodeColor.PROCESS_HEALTH_RED);
    const colorInterpolator = d3_interpolation.interpolateRgb(endColor, startColor);

    // number of color instances.
    const colorStops = (alertCounts.length) >= 2 ? alertCounts.length : 2;

    // d3 generate samples from interpolation.
    let colorGradients = d3_interpolation.quantize(colorInterpolator, colorStops);
    colorGradients = colorGradients.map(x => d3_color.color(x).hex());

    nodeAlertCounts.forEach((nodeAlertInfo) => {
      if (nodeAlertInfo.alertCount === 0) {
        this.nodeStatusMap.set(nodeAlertInfo.id, NodeColor.PROCESS_HEALTH_GREEN);
      } else {
        const nodeColor = colorGradients[alertCounts.indexOf(nodeAlertInfo.alertCount)];
        this.nodeStatusMap.set(nodeAlertInfo.id, nodeColor);
      }
    });
  }
}

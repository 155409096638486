import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ProcessTableService, SubProcess } from '../service/process-table.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgbModalErrorComponent } from '../../modal/ngb-modal-error.component';
import { NgbModalSuccessComponent } from '../../modal/ngb-modal-success.component';
import { TableEditDisplayComponent } from './table-edit-display/table-edit-display.component';
import { NgbModalConfirmComponent } from '../../modal/ngb-modal-confirm.component';
import { AddProcessModalComponent } from './add-process-modal/add-process-modal.component';
import { Observable } from 'rxjs';


declare var $: any;

@Component({
  selector: 'app-process-table',
  templateUrl: './process-table.component.html',
  styleUrls: ['./process-table.component.scss']
})

export class ProcessTableComponent implements OnInit {
  processes: SubProcess[] = [];      // Holds data from GetAllProcessAlertAndReports
  processAlerts: any[] = [];         // For alerts if needed
  newProcesses: any[] = [];           // Holds data from GetAllNewProcessToOnboard
  activeProjects: any[] = [];         // Holds data from GetAllActiveProjects
  cscpprocesses: any[] = [];         // Holds data from GetAllCSCPProcesses
  
  showAddProcessForm = false;
  addProcessForm: FormGroup;
  selectedTab: string = 'tab1';       // Default selected tab

  showModal: boolean = true;
  selectedRow: any;
  selectedFields: any[] = [];

  tab1Fields = [
    { name: 'scorFunctions', label: 'SCOR Functions', type: 'text' },
    { name: 'serviceName', label: 'Service Name', type: 'text' },
    { name: 'allRulesForThatService', label: 'All Rules For That Service', type: 'text' },
    { name: 'description', label: 'Description', type: 'text' },
    { name: 'org', label: 'Org', type: 'text' },
    { name: 'teamName', label: 'Team Name', type: 'text' },
    { name: 'percentage', label: 'Percentage', type: 'number' },
    { name: 'type', label: 'Type', type: 'text' }
  ];

  tab2Fields = [
    { name: 'scorFunction', label: 'Scor Function', type: 'text' },
    { name: 'serviceName', label: 'Service Name', type: 'text' },
    { name: 'newProcessNames', label: 'Forcasted New Process', type: 'text' },
    { name: 'processDescription', label: 'Process Description', type: 'text' },
    { name: 'org', label: 'Org', type: 'text' },
    { name: 'teamName', label: 'Team Name', type: 'text' },
    { name: 'type', label: 'Type', type: 'text' },
    { name: 'count', label: 'Count', type: 'number' },
    { name: 'status', label: 'Scor Status', type: 'text' }
  ];

  tab3Fields = [
    { name: 'projectNames', label: 'Project Names', type: 'text' },
    { name: 'projectDescription', label: 'Project Description', type: 'text' },
    { name: 'processMonitoring', label: 'Process Monitoring', type: 'text' },
    { name: 'processDescription', label: 'Process Description', type: 'text' },
    { name: 'teamName', label: 'Team Name', type: 'text' },
    { name: 'processCount', label: 'Process Count', type: 'number' }
  ];
  tab4Fields = [
    { name: 'scorFunction', label: 'Scor Function', type: 'text' },
    { name: 'serviceName', label: 'Service Name', type: 'text' },
    { name: 'newProcessNames', label: 'New Processes Name', type: 'text' },
    { name: 'processDescription', label: 'Process Description', type: 'text' },
    { name: 'org', label: 'Org', type: 'text' },
    { name: 'type', label: 'Type', type: 'text' },
    { name: 'count', label: 'Count', type: 'number' },
    { name: 'status', label: 'Status', type: 'text' }
  ];

  constructor(
    private processTableService: ProcessTableService,
    private modalService: NgbModal,
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    // Load data for each tab when component initializes This will initialize the data for each tab when the component is loaded.
    // we may need to update this to load only tab1 the default tab and load the other tabs when they are clicked.
    this.loadProcessAlertsAndReports();
    // this.loadNewProcessesToOnboard();
    // this.loadActiveProjects();
  }

  selectTab(tab: string): void {
    this.selectedTab = tab;
  
    // Load the data for the selected tab dynamically
    switch (tab) {
      case 'tab1':
        this.loadProcessAlertsAndReports();
        break;
      case 'tab2':
        this.loadNewProcessesToOnboard();
        break;
      case 'tab3':
        this.loadActiveProjects();
        break;
        case 'tab4':
          this.loadCscpprocesses();
          break;
    }
    this.cdr.detectChanges();
  }
  

  loadProcessAlertsAndReports(): void {
    this.processTableService.getAllProcessAlertsAndReports().subscribe(
      (data: SubProcess[]) => {
        this.processes = data;
      },
      (error) => {
        console.error('Error loading processes:', error);
        this.showErrorModal("Error loading processes");
      }
    );
  }

  loadNewProcessesToOnboard(): void {
    this.processTableService.getAllNewProcessToOnboard().subscribe(
      (data: any[]) => {
        this.newProcesses = data;  
        console.log('newProcesses:', this.newProcesses);
      },
      (error) => {
        console.error('Error loading new processes:', error);
        this.showErrorModal("Error loading new processes");
      }
    );
  }

  loadActiveProjects(): void {
    this.processTableService.getAllActiveProjects().subscribe(
      (data: any[]) => {
        this.activeProjects = data;
        console.log('activeProjects:', this.activeProjects);
      },
      (error) => {
        console.error('Error loading active projects:', error);
        this.showErrorModal("Error loading active projects");
      }
    );
  }
  loadCscpprocesses(): void {
    this.processTableService.getAllCSCPProcesses().subscribe(
      (data: any[]) => {
        this.cscpprocesses = data;  
      },
      (error) => {
        console.error('Error loading new processes:', error);
        this.showErrorModal("Error loading new processes");
      }
    );
  }
  showErrorModal(message: string): void {
    const modal = this.modalService.open(NgbModalErrorComponent);
    modal.componentInstance.message = message;
    modal.result.then(() => {
      // Do nothing on close
    });
  }


  

  openModal(row: any, fields: any[]): void {
    this.selectedRow = row;
    this.selectedFields = fields;
    this.showModal = true; 
  
    const modalRef = this.modalService.open(TableEditDisplayComponent);
    modalRef.componentInstance.rowData = this.selectedRow;
    modalRef.componentInstance.formFields = this.selectedFields;
  
    // Handle the close event from the modal
    modalRef.componentInstance.close.subscribe(() => {
      this.showModal = false; 
      modalRef.close(); 
    });
  
    // Handle the update event from the modal
    modalRef.componentInstance.update.subscribe((updatedData: any) => {
      this.updateRow(updatedData);
      console.log('Updated data:', updatedData);
      this.showModal = false; 
    });
  }

  closeModal(): void {
    this.showModal = false;
  }

  updateRow(updatedData: any): void {
    let updateCall: Observable<any>;
  
    switch (this.selectedTab) {
      case 'tab1':
        updateCall = this.processTableService.updateProcessAlertAndReport(updatedData);
        break;
      case 'tab2':
        updateCall = this.processTableService.updateProcessToOnboard(updatedData);
        console.log('updated data in updateProcessToOnboard:', updatedData);
        break;
      case 'tab3':
        updateCall = this.processTableService.updateActiveProject(updatedData);
        console.log('updated data in updateActiveProject:', updatedData);
        break;
        case 'tab4':
          updateCall = this.processTableService.updateCSCPProcesses(updatedData);
          console.log('updated data in updateCSCPProcesses:', updatedData);
          break;
      default:
        console.error('Invalid tab selected');
        return;
    }
  
    updateCall.subscribe(
      (response: any) => {
        const index = this.processes.findIndex((process: any) => process.id === updatedData.id);
        if (index !== -1) {
          this.processes[index] = updatedData; 
        }
        const modal = this.modalService.open(NgbModalSuccessComponent);
        modal.componentInstance.message = "Process updated successfully";
        modal.result.then(() => {
          console.log('Process updated successfully:', response);        });
      },
      (error) => {
        console.error('Error updating process in the backend:', error);
        this.showErrorModal('Error updating the process');
      }
    );
  }
  

  
  openAddModal(): void {
    this.selectedRow = {}; 

    // Select fields based on the currently selected tab
    switch (this.selectedTab) {
      case 'tab1':
        this.selectedFields = this.tab1Fields;
        break;
      case 'tab2':
        this.selectedFields = this.tab2Fields;
        break;
      case 'tab3':
        this.selectedFields = this.tab3Fields;
        break;
        case 'tab4':
          this.selectedFields = this.tab4Fields;
          break;
      default:
        this.selectedFields = []; 
    }
    
    this.showModal = true; 
    
    const modalRef = this.modalService.open(TableEditDisplayComponent); 
    modalRef.componentInstance.rowData = this.selectedRow; 
    modalRef.componentInstance.formFields = this.selectedFields; 

    // Handle the close event from the modal
    modalRef.componentInstance.close.subscribe(() => {
      this.showModal = false; 
      modalRef.close(); 
    });
    modalRef.componentInstance.update.subscribe((newData: any) => {
      this.addNewRow(newData); 
      this.showModal = false; 
    });
}

addNewRow(newData: any) {
  const cleanedData = this.cleanData(newData);
  switch (this.selectedTab) {
    case 'tab1':
      this.processTableService.AddProcessAlertAndReports(cleanedData).subscribe(
        (response: any) => {
          this.processes.push(cleanedData);
          const modal = this.modalService.open(NgbModalSuccessComponent);
          modal.componentInstance.message = "Process added successfully";
          this.loadProcessAlertsAndReports();
        },
        (error) => {
          console.error('Error adding process:', error);
          this.showErrorModal('Error adding process');
        }
      );
      break;

    case 'tab2':
      this.processTableService.AddProcessToOnboard(cleanedData).subscribe(
        (response: any) => {
          this.processes.push(cleanedData);
          const modal = this.modalService.open(NgbModalSuccessComponent);
          modal.componentInstance.message = "Process added successfully";
          this.loadNewProcessesToOnboard();
        },
        (error) => {
          console.error('Error adding process to onboard:', error);
          this.showErrorModal('Error adding process to onboard');
        }
      );
      break;

    case 'tab3':
      this.processTableService.AddActiveProjects(cleanedData).subscribe(
        (response: any) => {
          this.processes.push(cleanedData);
          const modal = this.modalService.open(NgbModalSuccessComponent);
          modal.componentInstance.message = "Process added successfully";
          this.loadActiveProjects();
        },
        (error) => {
          console.error('Error adding active project:', error);
          this.showErrorModal('Error adding active project');
        }
      );
      break;
      case 'tab4':
        this.processTableService.AddCSCPProcess(cleanedData).subscribe(
          (response: any) => {
            this.processes.push(cleanedData);
            const modal = this.modalService.open(NgbModalSuccessComponent);
            modal.componentInstance.message = "Process added successfully";
            this.loadCscpprocesses();
          },
          (error) => {
            console.error('Error adding process to onboard:', error);
            this.showErrorModal('Error adding process to onboard');
          }
        );
        break;

    default:
      console.error('Invalid tab selected');
  }
}

cleanData(data: any): any {
  const cleanedData = { ...data }; 
  Object.keys(cleanedData).forEach((key) => {
    if (cleanedData[key] == null) {
      cleanedData[key] = ""; // Set null or undefined values to empty string to avoid errors in the backend
    }
  });
  return cleanedData;
}


deleteProcess(process: any): void {
  // Open confirmation modal
  const modal = this.modalService.open(NgbModalConfirmComponent);
  modal.componentInstance.message = "Are you sure you want to delete this process?";
    modal.result.then(() => {
    let deleteObservable;
    
    if (this.selectedTab === 'tab1') {
      deleteObservable = this.processTableService.DeleteProcessAlertAndReports(process.id);
    } else if (this.selectedTab === 'tab2') {
      deleteObservable = this.processTableService.DeleteProcessToOnboard(process.id);
    } else if (this.selectedTab === 'tab3') {
      deleteObservable = this.processTableService.DeleteActiveProject(process.id);
    }else if (this.selectedTab === 'tab4') {
      deleteObservable = this.processTableService.DeleteCSCPProcesses(process.id);
    }

    deleteObservable.subscribe(
      () => {
        if (this.selectedTab === 'tab1') {
          this.processes = this.processes.filter(p => p.id !== process.id);
        } else if (this.selectedTab === 'tab2') {
          this.newProcesses = this.newProcesses.filter(p => p.id !== process.id);
        } else if (this.selectedTab === 'tab3') {
          this.activeProjects = this.activeProjects.filter(p => p.id !== process.id);
        }else if (this.selectedTab === 'tab4') {
          this.cscpprocesses = this.cscpprocesses.filter(p => p.id !== process.id);
        }

        // Show success modal after deletion
        const successModal = this.modalService.open(NgbModalSuccessComponent);
        successModal.componentInstance.message = "Process deleted successfully";
      },
      (error) => {
        console.error('Error deleting process', error);

        // Show error modal if deletion fails
        const errorModal = this.modalService.open(NgbModalErrorComponent);
        let errorMessage = "";
        if (error.status === 403) {
          errorMessage = "Access Denied";
        } else {
          errorMessage = error.statusText || "An error occurred";
        }
        errorModal.componentInstance.message = "Error occurred while deleting process. Error: " + errorMessage;
      }
    );
  }, () => {
    // Dismissed, do nothing
  });
}

}

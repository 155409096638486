<form [formGroup]="serviceTreeForm">
  <div class="collapse show" id="serviceTreeDetails">
    <div class="d-flex flex-row wrap">
      <!-- Organization -->
      <select class="custom-select-sm mr-3" id="organizationSelect" style="width: 17rem;" aria-label="Organization"
        formControlName="organization">
        <option disabled hidden>Select Organization</option>
        <option disabled hidden>Loading...</option>
        <option *ngFor="let org of organizationList" [ngValue]="org.name">{{ org.name }}</option>
      </select>

      <!-- Service Group -->
      <select class="custom-select-sm mr-3" id="serviceGroupSelect" style="width: 14rem;" aria-label="Service Group"
        formControlName="serviceGroups">
        <option disabled hidden>Select Service Group</option>
        <option disabled hidden>Loading...</option>
        <option *ngFor="let serviceGroup of serviceGroupList" [ngValue]="serviceGroup.name">{{ serviceGroup.name }}</option>
      </select>
      
      <!-- SCOR Functions -->
      <select class="custom-select-sm mr-3" id="teamGroupSelect" style="width: 13rem;" formControlName="teamGroups" aria-label="SCOR Function">
        <option disabled hidden>Select SCOR Function</option>
        <option disabled hidden>Loading...</option>
        <option *ngFor="let process of teamGroupList" [ngValue]="process.name">{{ process.name }}</option>
      </select>

      <div *ngIf="loadLevels !== 3">
        <!-- Business Process-->
        <select class="custom-select-sm mr-3" id="businessProcessSelect" style="width: 13rem;" formControlName="businessProcesses" aria-label="Business Process">
          <option disabled hidden>Select Business Process</option>
          <option disabled hidden>Loading...</option>
          <option *ngFor="let process of businessProcessList" [ngValue]="process.name">{{ process.name }}</option>
        </select>
      </div>

      <!-- show spinner if any service tree is loading. -->
      <div *ngIf="isLoading">
        <span class="text-primary fa fa-spinner fa-spin fa-2x" aria-label="Processing"></span>
      </div>

    </div>

    <!-- error messages for top controls. -->
    <div class="text-danger pt-0 mb-2" *ngIf="serviceTreeForm.errors?.processSelectionInvalid === true">
      <small>{{ serviceTreeForm.errors?.processSelectionMessage }}</small>
    </div>
  </div>
</form>

<div class="modal-header">
    <div>
        <h1 class="modal-title">Current Categories</h1>
        <p>These are your Current Categories for the Paths: </p>
    </div>
</div>

<div class="modal-body" style="margin:0.75rem;">
    <div *ngIf="isDropdownLoading" class="ml-auto">
        <span role="progressbar" class="info-text fa fa-spinner fa-spin fa-3x" aria-label="Loading"></span>
    </div>
    <ng-container *ngIf="!isDropdownLoading">
        <table class="table table-sm" *ngIf="!showEventSequenceTable">
            <!-- form header -->
            <tr style="background-color: #4C5557; color:white;">
                <th style="width:3vw; text-align: center;">Path ID</th>
                <th style="width:20vw; text-align: center;">Path Category</th>
                <th style="width:5vw; text-align: center;">Show Path</th>
            </tr>
        </table>
        <form [formGroup]="tagSelector" *ngIf="!showEventSequenceTable" class="scrolling">
            <div class="input-group input-group-sm mb-2 main-container">
                <div class="my-1 dropdown-container" *ngFor="let currentPath of pathTagForm.controls; index as i">
                    <div class="d-flex">
                        <!-- displays path id -->
                        <label class="input-group-text justify-content-center" style="width: 5rem;">
                            {{currentPath.value.pathId}}
                        </label>
                        <!-- drop down -->
                        <div class="btn-group" (click)="toggleDisplayOptions(i)" style="width: 28vw;">
                            <label class="input-group-text input-group-prepend btn"
                                style="background-color: white; overflow: hidden;">
                                {{this.labelTagArr[i]}}
                            </label>
                            <button class="input-group-text" [aria-label]="displayOptions[i] ? 'expand list' : 'collapse list'" title="expand or collapse list">
                                <span *ngIf="!displayOptions[i]" class="fa fa-chevron-down"></span>
                                <span *ngIf="displayOptions[i]" class="fa fa-chevron-up"></span>
                            </button>
                        </div>
                        <!-- button to view event sequence table -->
                        <button class="btn ml-3" style="width: 6vw;" (click)="showEventSequenceButtonHandler(i)" title="View Path for path id">
                            <span style="text-align: center; font-size: 1.4rem;"
                                class="fa fa-arrow-circle-right"></span>
                        </button>
                    </div>
                    <!-- Dropdown w form controls -->
                    <div class="border dropdown-overlay" style="margin-left: 5rem; width: 28vw;"
                        [formGroup]="currentPath" *ngIf="displayOptions[i]" (change)="disableSaveButtonHandler()">
                        <input class="form-control input" id="inputTagBox" formControlName="formInput"
                            placeholder="Type or Select Category...">
                        <select id="predefinedOptions" class="custom-select" formControlName="tag"
                            size="{{predefinedTagsSize}}">
                            <option *ngFor="let pathType of predefinedTags" [ngValue]="pathType">
                                {{pathType}}
                            </option>
                        </select>
                    </div>
                </div>
            </div>
        </form>
    </ng-container>
    <!-- event sequence table -->
    <div *ngIf="showEventSequenceTable" class="scrolling-table pr-2">
        <div>
            <button (click)="showEventSequenceButtonHandler(currentFormIndexShown)"><span
                    class="fa fa-arrow-circle-left" style="font-size: 1.3rem;"> Back</span></button>
            <h5 class="display-text" style="text-align:center;">
                Viewing Events for Path: {{currentEventSeqShownId}} </h5>
        </div>
        <table class="table table-sm">
            <tr style="position:sticky; top:0; background-color: #4C5557; color:white;">
                <th scope="row" style="width:6vw; text-align: center;">Sequence Number</th>
                <th style="width:21vw; text-align: center;">Event Name</th>
            </tr>
            <tr *ngFor="let currentSequence of currentEventSequenceTableShown, let i = index">
                <th scope="row" style="width:6vw; text-align: center;">{{i+1}}</th>
                <td style="width:21vw; text-align: center;">{{currentSequence}}</td>
            </tr>
        </table>
    </div>
    <ng-container *ngIf="showError">
        <small class="danger-text"> {{ errorMessage }} </small>
    </ng-container>
</div>

<div class="modal-footer">
    <div *ngIf="isSaving" class="ml-2">
        <span role="progressbar" class="info-text fa fa-spinner fa-spin fa-2x" aria-label="Loading"></span>
    </div>
    <ng-container *ngIf="showSuccessMessage">
        <small class="text-success"> {{ successMessage }} </small>
    </ng-container>
    <ng-container *ngIf="!showEventSequenceTable">
        <button type="button" class="btn primary-button" aria-label="Save" [disabled]=disableSave
            (click)="onSubmit()">Save</button>
    </ng-container>
    <button class="btn danger-button" *ngIf="!showEventSequenceTable" [disabled]="!isDropdownLoading"
        (click)="handleCancel()">Cancel</button>
</div>
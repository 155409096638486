export class PowerBIBaseInput {
    constructor(
        public height: string,
        public width: string,
    ) {}
}

export class ReportInput extends PowerBIBaseInput {
    constructor(
        height: string,
        width: string,
        public reportId: string,
        public groupId: string,
        public pageName?: string,
        public filterPaneEnabled?: boolean,
        public filterTable?: string,
        public filterColumn?: string,
        public navContentPaneEnabled?: boolean,
        public navContentPanePosition?: PowerBINavContentPanePosition
    ) {
        super(height, width);
    }
}

export class DashboardInput extends PowerBIBaseInput {
    constructor(
        height: string,
        width: string,
        public dashboardId: string,
        public groupId: string,
        public navContentPaneEnabled?: boolean,
        public navContentPanePosition?: PowerBINavContentPanePosition
    ) {
        super(height, width);
    }
}

export interface PowerBIInput {
    type: PowerBIType;
    value: any;
}

export enum PowerBIType {
    Report = 'Report',
    Dashboard = 'Dashboard',
    Visual = 'Visual'
}

export enum PowerBINavContentPanePosition {
    Left = 'Left',
    Bottom = 'Bottom'
}

import { Injectable, OnDestroy } from '@angular/core';
import { Subject, Observable, ReplaySubject } from 'rxjs';
import { MasterDataService } from './master-data.service';
import { UserDataService } from '../../service/user-data.service';
import { constants } from '../common/constants';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { Router } from '@angular/router';

@Injectable()
export class QueryOptionsService implements OnDestroy {
  // input elements.
  private processId: string;
  private ownerGroupId: string;
  private userAuthorityCheckSubject: ReplaySubject<boolean>;

  constructor(
    private masterDataService: MasterDataService,
    private userDataService: UserDataService,
    private appInsightsService: ApplicationInsights,
    private router: Router
  ) {
    // replay subject to store the last value
    this.userAuthorityCheckSubject = new ReplaySubject<boolean>(1);
  }

  ngOnDestroy(): void {
    this.userAuthorityCheckSubject.complete();
  }

  public setProcessId(processId: string): void {
    this.processId = processId;
  }

  public getProcessId(): string {
    return this.processId;
  }

  // methods for checking authorization.
  public setOwnerGroupId(groupId: string) {
    // check if the user is authorized.
    this.ownerGroupId = groupId;
    // check if user has access to view admin options
    let userHasExecuteQueryAccess = false;
    this.userDataService.checkUserAccess(this.router.url, 'Update', groupId).subscribe((response: boolean) => {
      userHasExecuteQueryAccess = response;
    },
    (error) => {
      console.error('query-options.service: error occured', error);
      this.appInsightsService.trackException(error);
    },
    () => {
      if (userHasExecuteQueryAccess) {
        this.userAuthorityCheckSubject.next(true);
      } else {
        this.userAuthorityCheckSubject.next(false);
      }
    });
  }

  public getOwnerGroupId(): string {
    return this.ownerGroupId;
  }

  public checkUserAuthorization(): Observable<boolean> {
    return this.userAuthorityCheckSubject.asObservable();
  }
}

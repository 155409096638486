import { Component, OnInit, Input, OnDestroy, Output, EventEmitter } from '@angular/core';
import { PageConfigService } from '../../../service/page-config.service';
import { PageName, PageComponentConfig, ComponentType } from '../../../model/page-configuration.model';
import { Subscription } from 'rxjs';
import { PropertyTaggingService } from '../../../service/property-tagging.service';
import { ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'app-display-tab',
  templateUrl: './display-tab.component.html',
  styleUrls: ['./display-tab.component.css', '../../../../../style/common-styles.scss']
})
export class DisplayTabComponent implements OnInit, OnDestroy {
  @Input() set urlTabParameter(processTab: string) {
    this.selectedTabPath = processTab;
    this.handleTabRouting(processTab);
  }
  @Output() tabselectionEvent = new EventEmitter<string>();

  renderComponent: boolean;
  tabNames: Array<string>;
  tabNamesIndexHref: Array<string>;
  tabIds: Array<string>;
  tabNamesIndex: Array<string>;
  componentTypes: Array<ComponentType>;
  selectedTabPath: string;
  componentInputs: Array<any>;
  redrawGraph: boolean;
  enableTab: Array<boolean>;
  urlTabName: string;
  tabPaths: Array<string>;
  drawProcessMap: boolean;
  drawProcessMapV2: boolean;
  activeId: string;

  private allSubscriptions: Subscription;

  constructor(
    private pageConfigService: PageConfigService,
    private propertyTaggingService: PropertyTaggingService,
    private changeDetectorRef: ChangeDetectorRef,
  ) {
    this.renderComponent = false;
    this.componentInputs = new Array<any>();
    this.tabNamesIndex = new Array<string>();
    this.tabNamesIndexHref = new Array<string>();
    this.tabIds = new Array<string>();
    this.redrawGraph = false;
    this.enableTab = new Array<boolean>();
    this.drawProcessMap = false;
    this.drawProcessMapV2 = false;
    this.allSubscriptions = new Subscription();
    this.activeId = '0';
    this.selectedTabPath = '';
  }

  ngOnInit() {
    const pageSubscription = this.propertyTaggingService.getCurrentPagename().subscribe((currentPageName: PageName) => {
      this.processDisplayConfig(currentPageName);
    });
    this.allSubscriptions.add(pageSubscription);

    const componentSubscription = this.propertyTaggingService.getComponentInput()
      .subscribe((displayTabInput) => {
        this.componentInputs = [...displayTabInput.componentInputArray];
        this.enableTab = displayTabInput.enableTab;
        this.handleTabRouting(this.selectedTabPath);
      });
    this.allSubscriptions.add(componentSubscription);

    const componentStatusSubscription = this.propertyTaggingService.getComponentStatus().subscribe((value) => {
      if (value) {
        this.renderComponent = false;
      } else {
        this.renderComponent = true;
      }
    });
    this.allSubscriptions.add(componentStatusSubscription);

    const inputStatusSubscription = this.propertyTaggingService.getInputStatus().subscribe((value) => {
      if (value) {
        this.componentInputs = new Array<any>();
      }
    });
    this.allSubscriptions.add(inputStatusSubscription);
  }

  ngOnDestroy() {
    this.allSubscriptions.unsubscribe();
  }

  processDisplayConfig(currentPageName: PageName): void {
    const displayConfig: Array<PageComponentConfig> = this.pageConfigService.getPageDisplayConfig(currentPageName);
    this.tabNames = displayConfig.map(x => x.tabName);
    this.tabPaths = displayConfig.map(x => x.path);
    this.componentTypes = displayConfig.map(x => x.componentType);
  }

  handleGraphDisplay(navId: string) {
    this.tabselectionEvent.emit(this.tabPaths[+navId]);
    this.handleRedrawEvents(+navId);
  }

  handleTabRouting(tabPath: string) {
    const tabIndex = this.tabPaths?.findIndex(x => x === tabPath).toString();
    this.activeId = tabIndex != null && tabIndex !== '-1' ? tabIndex : '0';
    this.handleRedrawEvents(+this.activeId);
  }

  private handleRedrawEvents(navId: number): void {
    if (Number.isInteger(navId) && navId >= 0 && Array.isArray(this.componentTypes) && this.componentTypes.length > navId) {
      const currentActiveComponentType = this.componentTypes[navId];
      this.redrawGraph = currentActiveComponentType === ComponentType.Graph;
      this.drawProcessMap = currentActiveComponentType === ComponentType.ProcessMap;
      this.drawProcessMapV2 = currentActiveComponentType === ComponentType.ProcessMapV2;
  
      // Manually trigger change detection
      this.changeDetectorRef.detectChanges();
    } else {
      console.log('componentTypes is undefined or navId is out of bounds');
    }
  }
}

<div class="container-fluid pt-3">
  <div class="jumbotron bg-transparent pb-0">
    <h1 class="display-5" #titleDiv tabindex="-1">Business Activity Monitoring Dashboard</h1>
    <hr class="my-4">
    <p class="lead">The BAM dashboard gives the ability to create rules through configuration driven data, create business alerts for BAM Monitoring. Additionally, the dashboard enables viewing of End to End Business process and drill down into the Critical Service Offering architecture workflows and be able to view real time telemetry represented via graph visualization.</p>
    <p class="lead">For Onboarding & Access, please visit: 
      <a class="link-text" href="https://aka.ms/m360wiki" target="_blank">
        https://aka.ms/m360wiki
        <span class="fa fa-link" aria-hidden="true"></span>
    </a></p> 
  </div>
</div>
<app-power-bi-input [reportInput]="powerBIConfig"></app-power-bi-input>

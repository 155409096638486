import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocalCacheService {

  // Default version key value
  private readonly VERSION_KEY = 'VERSION';
  private readonly VERSION_VALUE = "3";

  constructor() {

    const currentVersion = localStorage.getItem(this.VERSION_KEY);

    // If current version is null then we are going to push base value of the version in the cache.
    if (currentVersion === null) {
      localStorage.setItem(this.VERSION_KEY, this.VERSION_VALUE);
    } else if (currentVersion !== this.VERSION_VALUE) {
      // If VERSION_VALUE is different then current version of cache then we will clear the cache and set new version value.
      localStorage.clear();
      localStorage.setItem(this.VERSION_KEY, this.VERSION_VALUE);
    }

  }

  public setItem<T>(key: string, value: T) {
    const val = JSON.stringify(value);
    localStorage.setItem(key, val);
  }

  public getItem<T>(key: string): T {
    const val = JSON.parse(localStorage.getItem(key));
    return val;
  }

  public removeItem(key: string) {
    localStorage.removeItem(key);
  }

}

import { Component, OnInit } from '@angular/core';
import { constants } from '../common/constants';
@Component({
  selector: 'app-crew-health',
  templateUrl: './crew-health.component.html',
  styleUrls: ['./crew-health.component.css']
})
export class CrewHealthComponent implements OnInit {

  dashboardId: number;

  constructor() {
    this.dashboardId = constants.CrewDashboard;
  }

  ngOnInit() {
  }

}

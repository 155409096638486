import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { TreeNode } from "../model/tree-view.model";

@Injectable()
export abstract class TreeDataService {
  abstract getHierarchyByProcessId(id: string): Observable<TreeNode[]>;
  abstract getChildren(item: TreeNode): Observable<TreeNode[]>;
  abstract getParentsById(id:string): Observable<TreeNode[]>;
}

<div class="pt-2">
  <!-- title -->
  <h5 class="display-5 mt-3 ml-3">Administration</h5>
  <hr />
  <!-- service tree selection. -->
  <div class="card bg-transparent mx-2">
    <div class="card-header">
      <div
        class="border border-dark rounded ml-1 mt-2"
        id="Treeview"
      >
        <!-- Treeview -->
        <app-tree-view
          [loadProcess]="false"
          (valueChange)="serviceTreeHandler($event)"
        ></app-tree-view>
      </div>
    </div>
  </div>
  <!-- tabs -->
  <div
    ngbNav
    #nav="ngbNav"
    [(activeId)]="activeTab"
    class="nav-tabs"
    role="tablist"
    aria-label="tablist"
  >
    <div [ngbNavItem]="1">
      <a ngbNavLink class="link-text" (click)="setTabContent(1)">
        Process
      </a>
      <ng-template ngbNavContent>
        <div class="ml-2" style="width: 54rem" *ngIf="isTeamGroupSelected">
          <div class="mr-1" style="text-align: right">
            <button
              type="button"
              class="btn btn-sm btn-outline-dark"
              (click)="addNewProcess()"
              #AddProcessButton
              aria-label="Add Process Button"
              [disabled]="!isTeamGroupSelected"
            >
              <div class="fa-stack" style="cursor: pointer; font-size: 0.75em">
                <i class="text-success fa fa-circle fa-stack-2x"></i>
                <i class="fa fa-plus fa-stack-1x fa-inverse"></i>
              </div>
              Add New Process
            </button>
          </div>
          <div class="mt-1" *ngIf="!isProcessListLoading">
            <app-display-table-v2
              [tableInput]="processTableData.asObservable()"
              [tableOptions]="processTableOptions"
              (rowEditEvent)="editProcess($event)"
            >
            </app-display-table-v2>
          </div>
          <div *ngIf="isProcessListLoading" class="ml-auto">
            <span
              role="progressbar"
              class="info-text fa fa-spinner fa-spin fa-3x"
              aria-label="Loading"
            ></span>
          </div>
        </div>
      </ng-template>
    </div>
    <div [ngbNavItem]="2">
      <a ngbNavLink class="link-text" (click)="setTabContent(2)">
        Data Source
      </a>
      <ng-template ngbNavContent>
        <div class="d-flex justify-content-start">
          <div class="ml-2" style="width: 90rem" *ngIf="isTeamGroupSelected">
            <div class="mr-1" style="text-align: right">
              <button
                type="button"
                class="btn btn-sm btn-outline-dark"
                (click)="addNewDataSource()"
                #AddDataSourceButton
                aria-label="Add Data Source Button"
                [disabled]="!isTeamGroupSelected"
              >
                <div
                  class="fa-stack"
                  style="cursor: pointer; font-size: 0.75em"
                >
                  <i class="text-success fa fa-circle fa-stack-2x"></i>
                  <i class="fa fa-plus fa-stack-1x fa-inverse"></i>
                </div>
                Add New Data Source
              </button>
            </div>
            <div class="mt-1" *ngIf="!isDataSourceListLoading">
              <app-display-table-v2
                [tableInput]="dataSourceTableData.asObservable()"
                [tableOptions]="dataSourceTableOptions"
                (rowEditEvent)="editDataSource($event)"
              >
              </app-display-table-v2>
            </div>
            <div *ngIf="isDataSourceListLoading" class="ml-auto">
              <span
                role="progressbar"
                class="info-text fa fa-spinner fa-spin fa-3x"
                aria-label="Loading"
              ></span>
            </div>
          </div>
        </div>
      </ng-template>
    </div>

    <!-- <div [ngbNavItem]="3">
      <a ngbNavLink class="link-text" (click)="setTabContent(3)">
        Sub Processes
      </a>
      <ng-template ngbNavContent>
        <div class="d-flex justify-content-start">
          <div class="ml-2" style="width: 90rem">
            <app-display-table-v2
            [tableInput]="subProcessesTableData.asObservable()"
            [tableOptions]="subProcessesTableOptions"
          >
            </app-display-table-v2>
          </div>
        </div>
      </ng-template>
    </div>

    
    <div [ngbNavItem]="4">
      <a ngbNavLink class="link-text" (click)="setTabContent(4)">
        Business Process
      </a>
      <ng-template ngbNavContent>
        <div class="d-flex justify-content-start">
          <div class="ml-2" style="width: 90rem">
            <app-display-table-v2
              [tableInput]="businessProcessTableData.asObservable()"
              [tableOptions]="businessProcessTableOptions"
            >
            </app-display-table-v2>
          </div>
        </div>
      </ng-template>
    </div>

    <div [ngbNavItem]="5">
      <a ngbNavLink class="link-text" (click)="setTabContent(5)">
        Sub Process
      </a>
      <ng-template ngbNavContent>
        <div class="d-flex justify-content-start">
          <div class="ml-2" style="width: 90rem">
            <app-display-table-v2
              [tableInput]="subProcessTableData.asObservable()"
              [tableOptions]="subProcessTableOptions"
            >
            </app-display-table-v2>
          </div>
        </div>
      </ng-template>
    </div>

    <div [ngbNavItem]="6">
      <a ngbNavLink class="link-text" (click)="setTabContent(6)">
        Bam Alerts
      </a>
      <ng-template ngbNavContent>
        <div class="d-flex justify-content-start">
          <div class="ml-2" style="width: 90rem">
            <app-display-table-v2
              [tableInput]="bamAlertsTableData.asObservable()"
              [tableOptions]="bamAlertsTableOptions"
            >
            </app-display-table-v2>
          </div>
        </div>
      </ng-template>
    </div>

    <div [ngbNavItem]="7">
      <a ngbNavLink class="link-text" (click)="setTabContent(7)">
        Event KPI
      </a>
      <ng-template ngbNavContent>
        <div class="d-flex justify-content-start">
          <div class="ml-2" style="width: 90rem">
            <app-display-table-v2
              [tableInput]="eventKpiTableData.asObservable()"
              [tableOptions]="eventKpiTableOptions"
            >
            </app-display-table-v2>
          </div>
        </div>
      </ng-template>
    </div> -->

  </div>
  <div [ngbNavOutlet]="nav" class="mt-2"></div>
</div>
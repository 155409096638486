import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, AbstractControl, Validators, ValidationErrors } from '@angular/forms';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-date-range',
  templateUrl: './date-range.component.html',
  styleUrls: ['./date-range.component.css', '../../../../../style/common-styles.scss']
})
export class DateRangeComponent implements OnInit, OnDestroy {
  @Input() isRequestInProgress: boolean;
  @Output() selectedDateRange = new EventEmitter<Array<string>>();
  @Output() cancelFilterExecution = new EventEmitter<boolean>();

  dateRange = new UntypedFormGroup({
    startDateTimeControl: new UntypedFormControl('', Validators.required),
    endDateTimeControl: new UntypedFormControl('', Validators.required)
  }, this.validateDates);

  isValueUnchanged: boolean;

  subscriptions: Subscription;

  constructor(private appInsights: ApplicationInsights) {
    this.isRequestInProgress = false;
    this.isValueUnchanged = true;

    this.subscriptions = new Subscription();
  }

  ngOnInit() {
    // setup current time as end date.
    const currentDate = new Date();
    (this.dateRange.get('endDateTimeControl') as UntypedFormControl).setValue(currentDate);

    // set start date as 90 days ago.
    const earlierDate = new Date();
    earlierDate.setDate(earlierDate.getDate() - 90);
    (this.dateRange.get('startDateTimeControl') as UntypedFormControl).setValue(earlierDate);

    const formValueChanges = this.dateRange.valueChanges.subscribe(
      () => {
        this.isValueUnchanged = false;
      });
    this.subscriptions.add(formValueChanges);
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  applyFilter(): void {
    const startDate = new Date((this.dateRange.get('startDateTimeControl') as UntypedFormControl).value as string).toISOString();
    const endDate = new Date((this.dateRange.get('endDateTimeControl') as UntypedFormControl).value as string).toISOString();

    const dateRange: Array<string> = [startDate, endDate];
    this.appInsights.trackTrace({ message: `date-range: selected value emitted from ${dateRange.toString()}`});
    this.selectedDateRange.emit(dateRange);
    this.isValueUnchanged = true;
  }

  cancelExecution(): void {
    this.appInsights.trackTrace({ message: 'date-range: execution cancelled'});
    this.cancelFilterExecution.emit(true);
  }

  validateDates(dateFormGroup: AbstractControl): ValidationErrors | null {
    const startDate = new Date((dateFormGroup.get('startDateTimeControl') as UntypedFormControl).value as string);
    const endDate = new Date((dateFormGroup.get('endDateTimeControl') as UntypedFormControl).value as string);
    const currentDate: Date = new Date();

    if (endDate < startDate) {
      return { isDateError: true, errorMessage: 'End Date is earlier than Start Date' };
    } else if (endDate > currentDate) {
      return { isDateError: true, errorMessage: 'End Date is later than Today\'s Date' };
    } else {
      return null;
    }
  }

}

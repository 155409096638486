import { Component, OnInit, AfterViewInit, Input} from '@angular/core';
import { ScenarioHealthService } from '../service/scenario-health.service';
import { ScenarioStatus } from '../model/scenarioStatus';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgbModalErrorComponent } from '../../modal/ngb-modal-error.component';
import { UserDataService } from '../../service/user-data.service';
import { constants } from '../common/constants';
import { ModalRef } from '../../model/common.model';
import { IcMIncident } from '../model/icmIncident';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css', '../../../style/common-styles.scss']
})

export class DashboardComponent implements OnInit, AfterViewInit {
  weekStart: Date;
  scenarios: ScenarioStatus[];
  viewingWeek: number;
  editable: boolean;
  enabledTill: number;
  loading: string;
  message: string;
  // private dragStart: boolean;
  // private dragValue: number;
  // private dragRow: number;
  // private bulkStatus: Map<string, string>;

  public options = {
    hasNeedle: true,
    needleColor: 'gray',
    arcColors: ['#dc3545', '#ffc107', '#28a745'],
    arcDelimiters: [90, 95],
    rangeLabel: ['0', '100'],
    needleStartValue: 50,
  };

  dashboardId: number;
  scoreCardName: string;

  @Input() set trackDashboardId(id: number) {
    this.dashboardId = id;
    if (id === constants.ScenarioHealthDashboard) {
      this.scoreCardName = constants.ScenarioScoreCardName;
    } else if (id === constants.CrewDashboard) {
      this.scoreCardName = constants.CrewScoreCardName;
    }
    this.loadData();
  }

  constructor(private scenarioHealthService: ScenarioHealthService,
    private userDataService: UserDataService, private modalService: NgbModal, private router: Router) {
    // check if user has access to update
    this.userDataService.checkUserAccess(this.router.url, 'Update', '', this.dashboardId.toString()).subscribe((response: boolean) => {
      this.editable = response;
    },
    (error: Error) => {
      this.showError(error);
    },
    () => {
      this.viewingWeek = 0;
    this.weekStart = this.getStartDate(this.getToday());
    this.enabledTill = this.viewingWeek !== 0 ? 6 : this.weekStart.diffDate(this.getToday().getDatePart());
    });
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    const focusElement: HTMLElement = document.getElementById('page-title') as HTMLElement;
    focusElement.focus();
  }

  private loadData() {
    this.scenarios = null;
    this.loading = "Loading...";
    this.scenarioHealthService.GetStatus(this.dashboardId, new Date(this.weekStart)).subscribe((r) => {
      this.scenarios = r;
      this.loading = "";
    }, (error: Error) => {
      this.showError(error);
      this.loading = "";
    });
  }

  changeWeek(value: number) {
    this.viewingWeek = this.viewingWeek + value;
    this.weekStart = this.getStartDate(this.getToday().addDays(this.viewingWeek * 7));
    this.enabledTill = this.viewingWeek !== 0 ? 6 : this.weekStart.diffDate(this.getToday().getDatePart());
    this.loadData();
  }

  getStatusClass(value: any) {
    return value === null || value === undefined ? "" : value === 0 ? "red" : "green";
  }

  setStatus(scenarioId: number, i: number, j: number, s: number) {
    if (this.scenarios[i].dailyStatus[j] === s) {
      return;
    }
    if (s === 0) {
      this.message = "*Please ensure to fill in comments and ICM for status in Red";
    }
    this.scenarios[i].dailyStatus[j] = s;
    this.scenarios[i].updated[j] = { by: "you", on : new Date().toISOString() };
    const dailyStatus = [];
    dailyStatus.push({
      "scenarioId": scenarioId,
      "date": this.weekStart.clone().addDays(j).toISOString(),
      "status": s
    });
    this.scenarioHealthService.UpdateStatus(dailyStatus).subscribe(() => {
    }, (error: Error) => this.showError(error));
  }

  setComment(scenarioId: number, i: number, textContent: string) {
    if ((this.scenarios[i].comments ? this.scenarios[i].comments : "") === textContent) {
      return;
    }
    this.scenarios[i].comments = textContent;
    this.scenarioHealthService.UpdateWeeklyStatus(scenarioId, this.scenarios[i], this.weekStart)
    .subscribe(() => { }, (error: Error) => this.showError(error));
  }

  setIcM(scenarioId: number, i: number, icm: IcMIncident[]) {
    if ((this.scenarios[i].icm ? this.scenarios[i].icm : "") === icm) {
      return;
    }
    this.scenarios[i].icm = icm;
    this.scenarioHealthService.UpdateWeeklyStatus(scenarioId, this.scenarios[i], this.weekStart)
    .subscribe(() => { }, (error: Error) => this.showError(error));
  }

  getStartDate(date: Date) {
    return date.addDays(-(date.getUTCDay() + 1) % 7);
  }

  getToday(): Date {
    return new Date(Date.now() - 28800000).getDatePart(); // make it PST specific
  }

  getPercentage(status: number[]): number {
    return Math.round(status.reduce((x, y) => x + (y ? y : 0), 0) / (this.enabledTill + 1) * 10000) / 100;
  }

  getScenarioHealth(): number {
    if (this.scenarios) {
      return Math.round(this.scenarios.reduce((x, y) => (x + this.getPercentage(y.dailyStatus)), 0) / this.scenarios.length * 100) / 100;
    } else {
      return 0;
    }
  }

  // @HostListener('document:mouseup', ['$event'])
  // onmouseup(event: any) {
  //   if (Object.keys(this.bulkStatus).length > 0 && this.dragStart === true) {
  //     const modal = this.modalService.open(NgbModalConfirmComponent);
  //     modal.componentInstance.message = "Do you want to save all changes?";
  //     modal.result.then(() => {
  //       const dailyStatus = [];
  //       Object.keys(this.bulkStatus).forEach(element => {
  //         dailyStatus.push(this.bulkStatus[element]);
  //       });
  //       this.scenarioHealthService.UpdateStatus(dailyStatus).subscribe(() => { }, error => this.showError(error));
  //     }, () => {
  //       this.revertBack();
  //     }).then(() => this.bulkStatus = {});
  //   }
  //   this.dragStart = false;
  // }

  // private revertBack() {
  //   Object.keys(this.bulkStatus).forEach(element => {
  //     this.scenarios[this.bulkStatus[element].index].dailyStatus[element] = this.bulkStatus[element].oldStatus;
  //     this.scenarios[this.bulkStatus[element].index].updated[element] = null;
  //   });
  // }

  // mouseDown(i: number, j: number) {
  //   this.dragValue = this.scenarios[i].dailyStatus[j];
  //   this.bulkStatus = {};
  //   if (this.dragValue !== null) {
  //     this.dragStart = true;
  //     this.dragRow = i;
  //   }
  // }

  // mouseOver(scenarioId: number, i: number, j: number) {
  //   if (this.dragStart && i === this.dragRow && j <= this.enabledTill) {
  //     if (this.bulkStatus[j] === undefined && this.dragValue !== this.scenarios[i].dailyStatus[j]) {
  //       this.bulkStatus[j] = {
  //         "index": i,
  //         "scenarioId": scenarioId,
  //         "date": this.weekStart.clone().addDays(j).toISOString(),
  //         "status": this.dragValue,
  //         "oldStatus": this.scenarios[i].dailyStatus[j]
  //       };
  //     }
  //     this.scenarios[i].dailyStatus[j] = this.dragValue;
  //     this.scenarios[i].updated[j] = { by: "you", on : new Date().toISOString() };
  //   }
  // }

  showError(error: Error) {
    if (error.message === "Cannot send request to registered endpoint if the user is not authenticated") {
      const modal = this.modalService.open(NgbModalErrorComponent);
      (modal.componentInstance as ModalRef).message = "Login expired refresh the browser to continue";
    } else {
      this.modalService.open(NgbModalErrorComponent);
    }
  }
}

<div>
  <!-- <ngx-datatable [rows]="rows" [columns]="columns"></ngx-datatable> -->
  <table class="table table-bordered ml-3 mr-2" style="table-layout: fixed;">
    <thead>
      <tr tabindex="0">
        <ng-container *ngFor="let column of columns">
          <th scope="col">{{ column }}</th>
        </ng-container>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let row of rows" >
        <tr tabindex="0">
          <ng-container *ngFor="let cell of row">
            <td>{{ cell }}</td>
          </ng-container>
        </tr>
      </ng-container>
    </tbody>
  </table>
</div>

<!-- breadcrumb -->
<div *ngIf="!isEventTypeLoading" class="ml-3 mt-3">
  <a routerLink="/event-broker/event-catalog" style="color: #0F0FFF">Event Catalog</a>
  <span class="mx-2 fa fa-caret-right"></span>{{eventType.name}}
  <a class = "close mr-4" href="https://aka.ms/SCEventBroker" target="_blank" rel="noreferrer noopener" aria-label="SCEventBroker wiki">
    <i class="fa fa-question-circle-o"></i>
  </a>
</div>
<hr>
<!-- title -->
<h4 class="mx-3">{{eventType.name}} Event</h4>
<br>

<!-- Details table -->
<div class="ml-3 mr-2">
  <!-- Add loader if details list is not loaded-->
  <div *ngIf="!detailsTable.isLoaded" class="text-center">
    <span class="fa fa-spinner fa-spin fa-2x" style="font-size:5rem;" aria-label="Loading"></span>
  </div>
  <!-- Display table with all details data -->
  <div class="d-flex flex-rows mb-2">
    <h5 class="text-secondary">Details</h5>
    <!-- Edit Button -->
    <button class="btn btn-sm info-button border border-secondary ml-auto" type="button" id="editButton"
      (click)="editEventDetails()" style="width: 5vw;" [hidden]="detailsTable.hidden">Edit
    </button>
  </div>
  <div *ngIf="detailsTable.isLoaded">
    <table class="table table-sm" [hidden]="detailsTable.hidden">
      <tbody>
        <ng-container *ngFor="let detail of (detailsTable.data | async)?.rows" class="d-flex">
          <tr>
            <th scope="row" class="col-2">{{detail[0]}}</th>
            <td class="col-10">{{detail[1]}}</td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </div>
</div>

<!-- Subscriptions table -->
<div class="ml-3 mt-5 mr-2">
  <!-- Add loader if subscription list is not loaded-->
  <div *ngIf="!subsTable.isLoaded" class="text-center">
    <span class="fa fa-spinner fa-spin fa-2x" style="font-size:5rem;" aria-label="Loading"></span>
  </div>
  <!-- Display table with all subscription data -->
  <div class="d-flex flex-rows mb-2">
    <h5 class="text-secondary">Subscriptions</h5>
    <!-- Create Subscription Button -->
    <button class="btn btn-sm info-button border border-secondary ml-auto" type="button" id="createSubscriptionButton"
      (click)="createSubscription()" style="width: 12vw;" [hidden]="subsTable.hidden">
      Create Subscription
    </button>
  </div>
  <div *ngIf="subsTable.isLoaded">
    <app-display-table-v2 [hidden]="subsTable.hidden" [tableInput]="subsTable.data.asObservable()"
      [tableOptions]="subsTable.options" [searchTable]="subsTable.search.asObservable()"
      (rowNavEvent)="viewSubscriptionDetails($event)">
    </app-display-table-v2>
  </div>
</div>

<div *ngIf="!isProd">

<!-- Publishers table -->
<div class="ml-3 mt-5 mr-2">
  <!-- Add loader if subscription list is not loaded-->
  <div *ngIf="!pubsTable.isLoaded" class="text-center">
    <span class="fa fa-spinner fa-spin fa-2x" style="font-size:5rem;" aria-label="Loading"></span>
  </div>
  <!-- Display table with all subscription data -->
  <div class="d-flex flex-rows mb-2">
    <h5 class="text-secondary">Publishers</h5>
  </div>
  <div *ngIf="pubsTable.isLoaded">
    <app-display-table-v2 [hidden]="pubsTable.hidden" [tableInput]="pubsTable.data.asObservable()"
      [tableOptions]="pubsTable.options" [searchTable]="pubsTable.search.asObservable()"
      (rowEditEvent)="viewSubscriptionDetails($event)">
    </app-display-table-v2>
  </div>
</div>

<!-- Test Events table -->
<div class="ml-3 mt-5 mr-2">
  <!-- Add loader if subscription list is not loaded-->
  <div *ngIf="!testsTable.isLoaded" class="text-center">
    <span class="fa fa-spinner fa-spin fa-2x" style="font-size:5rem;" aria-label="Loading"></span>
  </div>
  <!-- Display table with all subscription data -->
  <div class="d-flex flex-rows mb-2">
    <h5 class="text-secondary">Test Events</h5>
    <!-- Create Subscription Button -->
    <button class="btn btn-sm info-button border border-secondary ml-auto" type="button" id="sendTestEventButton"
      (click)="createSubscription()" style="width: 10rem;" [hidden]="testsTable.hidden">
      Send Test Event
    </button>
  </div>
  <div *ngIf="testsTable.isLoaded">
    <app-display-table-v2 [hidden]="testsTable.hidden" [tableInput]="testsTable.data.asObservable()"
      [tableOptions]="testsTable.options" [searchTable]="testsTable.search.asObservable()"
      (rowEditEvent)="viewSubscriptionDetails($event)">
    </app-display-table-v2>
  </div>
</div>

</div>

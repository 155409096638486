<div *ngIf="isLoading">
  <span class="fa fa-spinner fa-spin fa-2x ml-auto"></span>
</div>
<form [formGroup]="directQueryForm" *ngIf="!isLoading">
  <div class="d-flex flex-column">

    <!-- Rule Name Section -->
    <div class="d-flex flex-row">
      <div class="input-group input-group-sm mb-1">
        <div class="input-group-prepend">
          <span class="input-group-text" id="rule-name" style="width: 17rem;">
            Rule Name
            <ng-template #popContent>
              <div>
                <b>Suggested Naming Convention:</b>
                &lt;&lt;Classifier&gt;&gt;_&lt;&lt;DocumentType&gt;&gt;_&lt;&lt;StartEvent&gt;&gt;_&lt;&lt;EndEvent&gt;&gt;_&lt;&lt;TypeOfAnomaly&gt;&gt;
                <br>
                <b>e.g:</b> StdExchange_ServiceOrder_CreateReturnOrder_CreateDeliverOrder_SLA
              </div>
            </ng-template>
            <ng-template #popTitle><b>Rule Name Information</b></ng-template>
            <span class="fa fa-info-circle ml-2" [ngbPopover]="popContent" [popoverTitle]="popTitle">
            </span>
          </span>
        </div>
        <input type="text" class="form-control required" style="max-width: 26rem;" formControlName="ruleName"
          aria-label="Rule Name Field" aria-describedby="rule-name-field">
      </div>
    </div>
    <div class="mb-2">
      <small
        *ngIf="directQueryForm.controls['ruleName'].errors?.isInvalidRuleNameError === true && directQueryForm.controls['ruleName'].touched"
        class="alert-text" style="margin-left: 17rem;" aria-live="assertive">
        {{ directQueryForm.controls['ruleName'].errors?.invalidRuleNameError }}
      </small>
    </div>

    <!-- Description Section -->
    <div class="d-flex flex-row">
      <div class="input-group input-group-sm mb-1">
        <div class="input-group-prepend">
          <span class="input-group-text" id="description" style="width: 17rem;">
            Description
          </span>
        </div>
        <textarea type="text" aria-label="Description" class="rounded" rows="2" style="width:58vw;"
          formControlName="description" maxlength="1000">
        </textarea>
      </div>
    </div>

    <!-- Data Source Section  -->
    <div class="d-flex flex-row" style="width: 86rem;">
      <div class="input-group input-group-sm mb-1">
        <div class="input-group-prepend">
          <span class="input-group-text" id="data-source" style="width: 17rem;">
            Data Source
            <span class="fa fa-info-circle ml-2" ngbPopover="Data Source for the rule query">
            </span>
          </span>
        </div>
        <select class="custom-select-sm required" id="dropdownDataSourceMenuButton" style="width: 26rem;"
          aria-label="data source dropdown" formControlName="dataSourceId">
          <option *ngFor="let source of dataSourceList" [ngValue]="source.id">{{ source.name }}</option>
        </select>
      </div>
    </div>

    <!-- Query Text Area Section -->
    <div class="d-flex flex-row">
      <div class="input-group input-group-sm mb-1">
        <div class="input-group-prepend">
          <span class="input-group-text" id="query" style="width: 17rem;">
            Query
            <ng-template #queryPopoverBody>
              <div>
                Rule Query can be specified using Template Variable.
                Learn about template variables at
                <a class="link-text" href="{{templatizedRulesWikiUrl}}" target="_blank"
                  aria-label="QOS Onboarding Wiki Link">Template Variable Wiki.</a>
              </div>
            </ng-template>
            <ng-template #queryPopoverTitle>
              <b>Templatized Rule Query</b>
            </ng-template>
            <span class="fa fa-info-circle ml-2" [ngbPopover]="queryPopoverBody" [popoverTitle]="queryPopoverTitle"
              placement="right">
            </span>
          </span>
        </div>
        <textarea title="Direct query editor" aria-label="Direct query editor" class="rounded required" rows="3"
          style="width:58vw;" formControlName="templatizedRuleQuery">
        </textarea>
        <span class="fa fa-info-circle mt-2 ml-1" ngbPopover="View expanded query."
          triggers="mouseenter:mouseleave" (click)="viewExpandedQuery()">
        </span>
      </div>
    </div>
    <div class="mb-2 d-flex flex-column" style="margin-left: 17rem;"
      *ngIf="directQueryForm.controls['templatizedRuleQuery'].dirty || directQueryForm.controls['templatizedRuleQuery'].touched">
      <div>
        <small *ngIf="directQueryForm.controls.templatizedRuleQuery.errors?.isAiNameError === true" class="alert-text" aria-live="assertive">
          {{ directQueryForm.controls.templatizedRuleQuery.errors?.AiNameError }}
        </small>
      </div>
      <div>
        <small *ngIf="directQueryForm.controls.templatizedRuleQuery.errors?.inValidTemplateVariable === true"
          class="alert-text" aria-live="assertive">
          {{ directQueryForm.controls.templatizedRuleQuery.errors?.templateVariableErrorMessage }}
        </small>
      </div>
    </div>

    <!-- Rule Metadata Section -->

    <!-- Remove duplicate switch. -->
    <div class="d-flex flex-row">
      <div class="input-group input-group-sm mb-1" style="max-width: 15rem;">
        <div class="custom-control custom-switch">
          <input type="checkbox" class="custom-control-input" id="customSwitch1" formControlName="removeDuplicates">
          <label class="custom-control-label" for="customSwitch1">Suppress Duplicate Alerts
          </label>
        </div>
        <span class="fa fa-info-circle my-1 ml-1"
          ngbPopover="Enable this field to remove the duplicate records from the alert">
        </span>
      </div>
    </div>
    <div class="d-flex flex-row input-group input-group-sm mb-1">
      <div class="input-group-prepend">
        <span class="input-group-text" id="label-recur" style="width: 17rem;" for="customSwitch1">
          Lookback Every
          <span class="fa fa-info-circle ml-2"
            ngbPopover="Lookback period to go back and check for duplicate records in alert">
          </span>
        </span>
      </div>
      <input type="number" min="0" class="form-control form-control-sm" formControlName="lookbackPeriod" maxlength="80"
        for="customSwitch1" style="max-width: 13rem;" title="Time Value" aria-label="Time Value">

      <select class="form-control form-control-sm" formControlName="lookbackPeriodUnit" title="Time Unit"
        for="customSwitch1" aria-label="Time Unit" style="max-width: 13rem;">
        <option value="d">Day</option>
        <option value="h">Hour</option>
      </select>
    </div>
    <div class="mb-2">
      <small
        *ngIf="directQueryForm.errors?.isLookbackPeriodError === true &&
           (directQueryForm.controls['lookbackPeriod'].touched || directQueryForm.controls['lookbackPeriodUnit'].touched)"
        class="alert-text" style="margin-left: 17rem;" aria-live="assertive">
        {{ directQueryForm.errors?.lookbackPeriodError }}
      </small>
    </div>

    <!-- Unique Key Fields -->
    <small class="link-text" tabindex="0"> * "Unique Key Fields" is required field to remove duplicate enablement
    </small>
    <div class="d-flex flex-row mb-1">
      <div class="input-group input-group-sm">
        <div class="input-group-prepend">
          <span class="input-group-text" id="unique-key-fields" style="width: 17rem; text-align: left;">
            <div>
              Unique Key Fields
              <span class="fa fa-info-circle ml-2"
                ngbPopover="Unique Key Field Names for the rule query. Provide Composite Keys separated by comma ','">
              </span>
              <br />
              (Composite keys separated by comma ',')
            </div>
          </span>
        </div>
        <textarea type="text" class="form-control form-control-sm required" formControlName="uniqueKeyFields"
          maxlength="400" style="max-width: 26rem;" aria-label="Unique Key Fields" aria-describedby="unique-key-fields">
      </textarea>
      </div>
    </div>
    <div class="mb-1">
      <small
        *ngIf="directQueryForm.controls['uniqueKeyFields'].errors?.isInvalidUniqueKeyFields === true && !directQueryForm.controls['uniqueKeyFields'].pristine"
        class="alert-text" style="margin-left: 17rem;" aria-live="assertive">
        {{ directQueryForm.controls['uniqueKeyFields'].errors?.invalidUniqueKeyFieldsError }}
      </small>
    </div>

    <!-- Event Timestamp Field -->
    <div class="d-flex flex-row mb-1">
      <div class="input-group input-group-sm">
        <div class="input-group-prepend">
          <span class="input-group-text" id="event-timestamp-field" style="width: 17rem;">
            Event Timestamp Field (optional)
            <span class="fa fa-info-circle ml-2" ngbPopover="Event Timestamp Field Name for the rule query">
            </span>
          </span>
        </div>
        <input type="text" class="form-control" style="max-width: 26rem;" formControlName="eventTimestampField"
          aria-label="Event Timestamp Field" aria-describedby="event-timestamp-field">
      </div>
    </div>

    <!-- End Event Name -->
    <div class="d-flex flex-row mb-1">
      <div class="input-group input-group-sm">
        <div class="input-group-prepend">
          <span class="input-group-text" id="end-event-name" style="width: 17rem;">
            End Event Name (optional)
            <span class="fa fa-info-circle ml-2"
              ngbPopover="Additional metadata for end to end Process Health. It is an optional field.">
            </span>
          </span>
        </div>
        <input type="text" class="form-control" style="max-width: 26rem;" formControlName="endEventName"
          aria-label="End Event Name (optional)" aria-describedby="end-event-name-optional-field">
      </div>
    </div>
  </div>
</form>

import { Alert } from "../model/Alert.model";

export class Condition {
  constructor(
    public conditionIndex: number,
    public entity1: string,
    public entityColumn1: string,
    public operator: string,
    // TODO: rhsType should be removed after backend changes
    public rhsType: string,
    public entity2: string,
    public entityColumn2: string,
    public variableName: string,
    public variableType: string,
  ) { }
}

export class ConditionSet {
  constructor(
    public conditionSetIndex: number,
    public joiningOperator: string,
    public conditions: Array<Condition>,
  ) { }
}

export class RuleConfig {
  public id: number;
  public ruleName: string;
  public ruleJson: string;
  public templatizedRuleQuery: string;
  public alert: Alert;
  public mappingId: string;
  public dataSourceId: number;
  public removeDuplicates: boolean;
  public lookbackPeriod: number;
  public lookbackPeriodUnit: string;
  public updatedBy: string;
  public validFrom: Date;
  public endEventName: string;
  public uniqueKeyFields: string;
  public eventTimestampField: string;
  public description: string;
  /**
   *
   */
  constructor() {
    this.dataSourceId = 1;
    this.templatizedRuleQuery = "";
    this.endEventName = "";
    this.removeDuplicates = false;
    this.lookbackPeriod = 0;
    this.lookbackPeriodUnit = null;
    this.uniqueKeyFields = "";
    this.eventTimestampField = "";
    this.description = "";
    this.ruleName = "";
    this.mappingId = "";
  }
}

export class RuleMasterData {
  public id: number;
  public entityName: string;
  public columnName: string;
  public columnType: string;
}

export class EntityMetadata {
  public id: number;
  public entityName: string;
  public iKey: string;
  public filter1: string;
  public filter1Value: string;
  public filter2: string;
  public filter2Value: string;
  public filter3: string;
  public filter3Value: string;
  public tableName: string;
  public mappingId: string;
}

export class QueryParam {
  constructor(
    public query: string,
    public ruleJson: string,
    public dataSourceId: number,
    public mappingId: string,
  ) { }
}

export class RuleOperator {
  public id: number;
  public operator: string;
  public operatorName: string;
  public dataType: string;
  public operatorType: string;
}

export class DataType {
  public id: number;
  public type: string;
}

export interface ServiceGroupResponse {
  Id: string;
  Name: string;
  OrganizationId: string;
}

export interface TeamGroupResponse {
  Id: string;
  Name: string;
  ServiceGroupId: string;
}

export interface TeamGroupAdminResponse {
  Id: string;
}

<div class="container-fluid pt-3">
  <form class="addForm" (ngSubmit)="checkAliasAfterSubmit(dashboard.owners)" #dashboardForm="ngForm">
    <div class="form-group row">
      <label for="name" class="col-sm-4 col-lg-3 col-form-label">Name of the Monitoring Solution
        <span class="danger-text ml-1" aria-label="Required Field">*</span>
      </label>
      <div class="col-sm-7 col-lg-6">
        <input type="text" class="form-control required" id="name" required aria-required="true" placeholder="Name of the Solution" [(ngModel)]="dashboard.name" name="name" maxlength="80">
      </div>
    </div>
    <div class="form-group row">
      <label for="type" class="col-sm-4 col-lg-3 col-form-label">Type of the Monitoring Solution
        <span class="danger-text ml-1" aria-label="Required Field">*</span>
      </label>
      <div class="col-sm-7 col-lg-6">
        <select class="form-control required" id="type" required aria-required="true" placeholder="Type of the Solution" [(ngModel)]="dashboard.type" name="type" #type="ngModel">
          <option *ngFor="let type of typeList" [value]="type">{{type}}</option>
        </select>
      </div>
    </div>
    <div class="form-group row">
      <label for="purpose" class="col-sm-4 col-lg-3 col-form-label">
        Purpose 
        <span class="danger-text ml-1" aria-label="Required Field">*</span><br>
        <span class="font-italic text-sm">(Free text description of the Monitoring Solution i.e., what, why)</span>
      </label>
      <div class="col-sm-8 col-lg-7">
        <textarea class="form-control required" id="purpose" required aria-required="true" placeholder="Description of the Solution" [(ngModel)]="dashboard.purpose" name="purpose" rows="3" #purpose="ngModel" maxlength="200"></textarea>
      </div>
    </div>
    <div class="form-group row">
      <label for="location" class="col-sm-4 col-lg-3 col-form-label">
        Location of Monitoring Solution 
        <span class="danger-text ml-1" aria-label="Required Field">*</span><br>
        <span class="font-italic text-sm">(URL/Location)</span>
      </label>
      <div class="col-sm-7 col-lg-6">
        <input type="text" class="form-control required" id="location" required aria-required="true" placeholder="Url/Location" [(ngModel)]="dashboard.url" name="location" #location="ngModel" maxlength="1000">
      </div>
    </div>
    <div class="form-group row">
      <label for="dataClassification" class="col-sm-4 col-lg-3 col-form-label">Data Classification
        <span class="danger-text ml-1" aria-label="Required Field">*</span>
      </label>
      <div class="col-sm-7 col-lg-6">
        <select class="form-control required" id="dataClassification" required aria-required="true" placeholder="Type of data classification" [(ngModel)]="dashboard.dataClassification" name="dataClassification" #dataClassification="ngModel">
          <option *ngFor="let classification of dataClassificationList" [value]="classification">{{classification}}</option>
        </select>
      </div>
    </div>
    <div class="form-group row">
      <label for="accessMethod" class="col-sm-4 col-lg-3 col-form-label">Access Method
        <span class="danger-text ml-1" aria-label="Required Field">*</span>
      </label>
      <div class="col-sm-7 col-lg-6">
        <select class="form-control required" id="accessMethod" required aria-required="true" placeholder="Access method" [(ngModel)]="dashboard.accessMethod" name="accessMethod" #accessMethod="ngModel">
          <option *ngFor="let accessMethod of accessMethodList" [value]="accessMethod">{{accessMethod}}</option>
        </select>
      </div>
    </div>
    <div class="form-group row">
      <label for="owners" class="col-sm-4 col-lg-3 col-form-label">
        Owners 
        <span class="danger-text ml-1" aria-label="Required Field">*</span><br>
        <span class="font-italic text-sm">(Seperated by semi-colon)</span>
      </label>
      <div class="col-sm-7 col-lg-6">
        <div *ngIf="isAliasInvalid">
          <input type="text" class="form-control required" id="owners" required aria-required="true" placeholder="george;john;" appOwnerValidation="invalid" [(ngModel)]="dashboard.owners"
            name="owners" #owners="ngModel" (focusout)="checkAlias(dashboard.owners)" maxlength="80">
        </div>
        <div *ngIf="!isAliasInvalid">
          <input type="text" class="form-control required" id="owners" required aria-required="true" placeholder="george;john;" appOwnerValidation="valid" [(ngModel)]="dashboard.owners"
            name="owners" #owners="ngModel" (focusout)="checkAlias(dashboard.owners)" maxlength="80">
        </div>
      </div>
      <div *ngIf="isValidationInProgress" class="col-auto pl-0 pt-1">
        <span role="progressbar" class="text-danger fa fa-spinner fa-spin fa-2x"></span>
      </div>
      <div *ngIf="isAliasInvalid" class="col-auto">
        <div class="alert alert-danger pt-2" role="alert" aria-label="Please enter a valid alias" aria-live="assertive" style="height: 2.4rem;">
          <span>{{ invalidAliasMessage }}</span>
        </div>
      </div>
    </div>
    <div class="form-group row">
      <label for="dataSource" class="col-sm-4 col-lg-3 col-form-label">
        Data Source <br>
      </label>
      <div class="col-sm-7 col-lg-6">
        <select class="form-control" id="dataSource" (ngModelChange)="disableDataSourceValueInput($event)" placeholder="data source" [(ngModel)]="dashboard.dataSource" name="dataSource" #dataSource="ngModel">
          <option *ngFor="let dataSource of dataSourceList" [value]="dataSource">{{dataSource}}</option>
        </select>
      </div>
    </div>
    <div class="form-group row">
      <label for="dataSourceValue" class="col-sm-4 col-lg-3 col-form-label">
        Data Source Value <br>
      </label>
      <div class="col-sm-7 col-lg-6">
        <input type="text" class="form-control" id="dataSourceValue" [disabled]="disableDataSourceValue" placeholder="Name of data source" [(ngModel)]="dashboard.dataSourceValue" name="dataSourceValue" #dataSourceValue="ngModel" maxlength= "30">
      </div>
    </div>
    <div class="form-group row">
      <label for="serviceTree" class="col-sm-4 col-lg-3 col-form-label">
        Service Tree <br>
        <span class="font-italic text-sm">(Service Component Name) <br></span>
      </label>
      <div class="col-sm-7 col-lg-6">
        <div class="input-group">
          <input type="text" class="form-control" id="serviceTree" placeholder="Service component Name" [(ngModel)]="dashboard.serviceTree" name="serviceTree" maxlength="100">
          <div class="input-group-append">
            <button class="btn border border-secondary" type="button" data-toggle="modal" data-target="#myModal" (click)="open()">Browse</button>
          </div>
        </div>
      </div>
    </div>
    <div class="form-group row">
      <label for="alerting" class="col-sm-4 col-lg-3 col-form-label">Is Alerting Enabled ?
        <span class="danger-text ml-1" aria-label="Required Field">*</span>
      </label>
      <div class="col-sm-7 col-lg-6">
        <select class="form-control required" id="alerting" (ngModelChange)="disableSecurityGroupInput($event)" required aria-required="true" placeholder="Yes/No" [(ngModel)]="dashboard.alerting" name="alerting" #alerting="ngModel">
          <option *ngFor="let alert of alertList" [value]="alert">{{alert}}</option>
        </select>
      </div>
    </div>
    <div class="form-group row">
      <label for="securityGroup" class="col-sm-4 col-lg-3 col-form-label">Security Group
        <span class="danger-text ml-1" aria-label="Required Field">*</span>
      </label>
      <div class="col-sm-7 col-lg-6">
        <input type="text" class="form-control required" id="securityGroup" [disabled]="disableSecurityGroup" required aria-required="true" placeholder="Name of security group" [(ngModel)]="dashboard.securityGroup" name="securityGroup" #securityGroup="ngModel" maxlength="20">
      </div>
    </div>
    <div class="form-group row">
      <label for="environment" class="col-sm-4 col-lg-3 col-form-label">
        Environment <br>
      </label>
      <div class="col-sm-7 col-lg-6">
        <select class="form-control" id="environment" placeholder="Environment" [(ngModel)]="dashboard.environment" name="environment" #environment="ngModel">
          <option *ngFor="let environment of environmentList" [value]="environment">{{environment}}</option>
        </select>
      </div>
    </div>
    <div class="form-group row">
      <div class="col-sm-2 col-lg-2 col-xl-1">
        <button type="button" class="btn secondary-button" aria-label="Reset" (click)="newDashboard()">Reset</button>
      </div>
      <div class="col-sm-2 col-lg-2 col-xl-1">
        <button type="submit" class="btn primary-button" aria-label="Submit" [disabled]="!dashboardForm.form.valid">Submit</button>
      </div>
    </div>
  </form>
</div>

import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { BehaviorSubject, interval, Observable, of, partition, ReplaySubject, Subject, Subscription } from 'rxjs';
import { mergeMap, pairwise, retry, share, switchMap, withLatestFrom, startWith, filter, map } from 'rxjs/operators';
import { EventTypeSubscription } from '../model/event-type-subscription.model';
import { EventCatalogService } from '../service/event-catalog.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgbModalErrorComponent } from '../../modal/ngb-modal-error.component';
import { NgbModalConfirmDeleteComponent } from '../../modal/ngb-modal-confirm-delete.component';
import { APP_CONFIG } from '../../common/constants';
import { TableOptions, TableInputV2 } from '../../bam-dashboard/model/common.model';
import { AppConfig } from '../../model/app-config.model';
import { SaveSubscriptionComponent } from '../save-subscription/save-subscription.component';
import { SaveSubscriptionModal } from '../model/save-subscription-modal-model';
import moment from 'moment';
import { ReportInput } from '../../power-bi/model/power-bi.model';
import { ProblemDetailsHttpErrorResponse } from '../../common/model/problem-details-http-error-response.model';
import { ProblemDetails } from '../../common/model/problem-details.model';

class Table {
  isLoaded: boolean;
  loadTable: boolean;
  hidden: boolean;

  // display table components.
  columns: Array<string>;
  options: TableOptions;
  data: ReplaySubject<TableInputV2>;
  search: Subject<string>;

  constructor() {
    this.isLoaded = false;
    this.loadTable = false;
    this.hidden = false;

    this.columns = [];
    this.options = {
      addEditColumn: false,
      editColumns: [],
      enableSearch: false,
      searchColumns: [],
      columnWidths: [],
      centeredColumns: [],
      wrapTextColumns: []
    };
    this.data = new ReplaySubject<TableInputV2>(1);
    this.search = new Subject<string>();
  }

  complete(): void {
    this.data.complete();
    this.search.complete();
  }
}

@Component({
  selector: 'app-subscription-details',
  templateUrl: './subscription-details.component.html',
  styleUrls: ['./subscription-details.component.css'],
})
export class SubscriptionDetailsComponent implements OnInit, OnDestroy {

  /*
  Description:
  - subscription details page - arrive here from main catalog page to see more information about the event subscription
*/

  // Observables
  eventTypeSub$: Observable<EventTypeSubscription>;
  subStatusPollingInterval$: BehaviorSubject<number>;
  subStatus$: Observable<string>;
  isModifying$: Observable<string>;

  // current EventTypeSubscription
  eventTypeSubscription: EventTypeSubscription;

  // tables
  detailsTable: Table;
  eventHandlerDetailsTable: Table;
  deliveryFailuresTable: Table;
  deliveryFailuresIcMTable: Table;

  // Embed PowerBI
  powerBIConfig: ReportInput;
  displayDeliveryFailuresPowerBI: boolean;

  // hide dev features
  isProd: boolean;

  // Subscription
  private subscriptions: Subscription;

  constructor(
    private router: Router,
    private eventCatalogService: EventCatalogService,
    private modalService: NgbModal,
    private route: ActivatedRoute,
    @Inject(APP_CONFIG) appConfig: AppConfig,
  ) {
    this.isProd = appConfig.environment === 'PROD' || appConfig.environment === 'UAT';
    this.subscriptions = new Subscription();

    // Details table (use basic bootstrap table instead of display-table-v2, don't need to set options here)
    this.detailsTable = new Table();

    // Event Handler Details table (use basic bootstrap table instead of display-table-v2, don't need to set options here)
    this.eventHandlerDetailsTable = new Table();

    // Delivery Failures table (use basic bootstrap table instead of display-table-v2, don't need to set options here)
    this.deliveryFailuresTable = new Table();

    // Delivery Failures icM table (use basic bootstrap table instead of display-table-v2, don't need to set options here)
    this.deliveryFailuresIcMTable = new Table();

    this.powerBIConfig = appConfig.eventFailuresPowerBIReport;
    this.displayDeliveryFailuresPowerBI = false;
  }

  ngOnInit(): void {
    //// Observables ////

    // set up route params observable - reuse this logic as needed
    const routeParams$ = this.route.paramMap.pipe(
      switchMap((params: ParamMap) => {
        const subIdorName = params.get('id');
        const parentIdOrName = params.get('eventId');
        return of([subIdorName, parentIdOrName]);
      })
    );

    // set up event subscription observables
      // get id from route, call API, check for valid sub, compare to route parent
      // partition splits the data flow based on sub and route conditions
      // downstream subscriptions only triggers if partition directs the data there
    const getEventTypeSub$ = routeParams$.pipe(
      switchMap(([subIdOrName, _]): Observable<EventTypeSubscription> => {
        if (isNaN(Number(subIdOrName))) {
          return this.eventCatalogService.getEventTypeSubscriptionByName(subIdOrName);
        } else {
          return this.eventCatalogService.getEventTypeSubscriptionById(+subIdOrName);
        }
      }),
      share()
    );
    const [emptySub$, validSub$] = partition(getEventTypeSub$, (sub: EventTypeSubscription) => sub === undefined);
    const validSubWithRoute$ = validSub$.pipe(
      withLatestFrom(routeParams$, (sub: EventTypeSubscription, [_, parentIdOrName]) => [sub, parentIdOrName]),
    );
    const [invalidRoute$, validRoute$] = partition(validSubWithRoute$, ([sub, parentIdOrName]) => {
        sub = sub as EventTypeSubscription;
        const stringRouteDiffers = parentIdOrName.toString().toLowerCase() !== sub.parentEventName.toLowerCase();
        const idRouteDiffers = +parentIdOrName !== sub.parentEventId;
        return stringRouteDiffers && idRouteDiffers;
      }
    );
    this.eventTypeSub$ = validRoute$.pipe(
      switchMap(([sub, _] ) => of(sub as EventTypeSubscription)),
    );

    // set up status polling observables
      // data flows from initial refresh interval value set by BehaviorSubject
      // interval() picks up this and starts emitting a counter every int milliseconds
      // when interval() fires, grab subscription id and call OData API for subscription status only
      // subscribe to this observable and update polling interval BehaviorSubject to set a new interval as needed
    this.subStatusPollingInterval$ = new BehaviorSubject(500);
    this.subStatus$ = this.subStatusPollingInterval$
    .pipe(
      switchMap((int: number) => interval(int)),
      withLatestFrom(this.eventTypeSub$, (_, sub) => sub),
      switchMap((sub: EventTypeSubscription) => this.eventCatalogService.getEventTypeSubscriptionStatus(sub.id)),
      retry(3),
      map((s: any) => s['status']),
      share()
    );

    // set up modification status observable - template also uses the async pipe in ngIf, hence the string value
    this.isModifying$ = this.subStatus$
    .pipe(
      map((status) => this.isModifying(status) ? "yes" : "no"),
    );

    //// Subscriptions ////

    // set up empty event subscription - redirect to parent event page
    const emptyEventTypeSub = emptySub$
    .pipe(
      switchMap(() => routeParams$),
    )
    .subscribe(
      ([subIdOrName, parentIdOrName]) => {
        const errorModal = this.modalService.open(NgbModalErrorComponent);
        errorModal.componentInstance.message
          = `Subscription with id '${subIdOrName}' does not exist for event with id '${parentIdOrName}'.` +
            ` Redirecting to the event with id '${parentIdOrName}'.`;
        if (errorModal) {
          errorModal.result
            .then(() => {
              this.router.navigateByUrl(`event-broker/event/${parentIdOrName.toString().toLowerCase()}`);
            })
            .catch(() => { });
        }
      },
      (error: ProblemDetailsHttpErrorResponse) => this.handleHttpErrorResponse("Error occurred while loading subscription", error));
    this.subscriptions.add(emptyEventTypeSub);

    // set up invalid route subscription - redirect to subscription page with correct parent
    const invalidRouteSub = invalidRoute$
    .subscribe(
      ([subInput, parentIdOrName]) => {
        const sub = subInput as EventTypeSubscription;
        const errorModal = this.modalService.open(NgbModalErrorComponent);
        errorModal.componentInstance.message
          = `Subscription with id '${sub.id}' and name '${sub.name}' does not exist for event with id '${parentIdOrName}'.` +
          ` Redirecting to this subscription under the event '${sub.parentEventName}'.`;
        if (errorModal) {
          errorModal.result
            .then(() => {
              this.router.navigateByUrl(`event-broker/event/${sub.parentEventName.toLowerCase()}/subscription/${sub.name.toLowerCase()}`);
            })
            .catch(() => { });
        }
      },
      (error: ProblemDetailsHttpErrorResponse) => this.handleHttpErrorResponse("Error occurred while loading subscription", error));
    this.subscriptions.add(invalidRouteSub);

    // set up eventTypeSub subscription - load the page if we get a valid subscription
    const eventTypeSubSub = this.eventTypeSub$
    .subscribe(
      (eventTypeSub: EventTypeSubscription) => {
        this.loadDetails(eventTypeSub);
        this.loadEventHandlerDetails(eventTypeSub);
        this.loadDeliveryFailures(eventTypeSub);
        this.loadDeliveryFailuresIcM(eventTypeSub);
        this.eventTypeSubscription = eventTypeSub;
      },
      (error: ProblemDetailsHttpErrorResponse) => this.handleHttpErrorResponse("Error occurred while loading subscription", error));
    this.subscriptions.add(eventTypeSubSub);

    // set up status polling interval updates - update more frequently if we have a modification status vs Ready/Failed
    const subStatusSub = this.subStatus$
    .subscribe(
      (status: string) => this.subStatusPollingInterval$.next(this.isModifying(status) ? 5000 : 300000),  // 5 seconds or 5 minutes
      (error: ProblemDetailsHttpErrorResponse) => this.handleHttpErrorResponse("Error occurred while polling subscription status", error)
    );
    this.subscriptions.add(subStatusSub);

    // set up reload trigger subscription - if status changes to 'Ready' or 5 min elapses, reload the page to pull in any updates
    const reloadSub = this.subStatus$
    .pipe(
      startWith('initial load'),
      pairwise(),
      filter(([prev,next]) => prev !== 'initial load' && next === 'Ready'),
    )
    .subscribe(
      () => window.location.reload(),       // we could reload the data, but reloading page reuses logic and less chance of bugs
      (error: ProblemDetailsHttpErrorResponse) => this.handleHttpErrorResponse("Error occurred while reloading subscription", error)
    );
    this.subscriptions.add(reloadSub);
  }

  ngOnDestroy(): void {
    // all child subscriptions will be unsubscribed to avoid memory leaks
    this.subscriptions.unsubscribe();
    this.detailsTable.complete();
  }

  loadDetails(eventTypeSub: EventTypeSubscription): void {
    const tableInput = new TableInputV2();
    tableInput.rows = new Array<Array<string>>();

    tableInput.rows.push(["Subscription Name", eventTypeSub.name]);
    tableInput.rows.push(["Subscription Id", String(eventTypeSub.id)]);
    tableInput.rows.push(["Description", eventTypeSub.description]);
    tableInput.rows.push(["Contact Alias", eventTypeSub.contactAlias]);
    tableInput.rows.push(["Admin SG", eventTypeSub.ownerGroupAlias]);
    tableInput.rows.push(["Schema", eventTypeSub.schema]);
    tableInput.rows.push(["Subscriber Team", eventTypeSub.teamName]);
    tableInput.rows.push(["Subscriber Service", eventTypeSub.serviceName]);
    tableInput.rows.push(["Subscriber Service GUID", eventTypeSub.serviceId]);
    tableInput.rows.push(["Filters", eventTypeSub.filters]);
    tableInput.rows.push(["Updated By", eventTypeSub.updatedBy]);
    tableInput.rows.push(["Updated On", String(moment.utc(eventTypeSub.updatedOn).local().format('MM-DD-YYYY HH:mm'))]);

    this.detailsTable.data.next(tableInput);
    this.detailsTable.isLoaded = true;
  }

  loadEventHandlerDetails(eventTypeSubscription: EventTypeSubscription): void {
    const tableInput = new TableInputV2();
    tableInput.rows = new Array<Array<string>>();

    const eh = eventTypeSubscription.eventHandler;
    tableInput.rows.push(["Type", eh.ehType]);
    eh.requiredArgs.forEach(a => tableInput.rows.push([a.displayName, a.value]));
    //eh.optionalArgs.forEach(a => tableInput.rows.push([a.displayName, a.value]));

    this.eventHandlerDetailsTable.data.next(tableInput);
    this.eventHandlerDetailsTable.isLoaded = true;
  }

  loadDeliveryFailures(eventTypeSub: EventTypeSubscription): void {
    const tableInput = new TableInputV2();
    tableInput.rows = new Array<Array<string>>();

    tableInput.rows.push(["Enabled", String(eventTypeSub.enableDeliveryFailureEmail)]);
    if (eventTypeSub.enableDeliveryFailureEmail) {
      tableInput.rows.push(["Minimum Interval", String(eventTypeSub.minDeliveryFailureAlertInterval)+" "+eventTypeSub.minDeliveryFailureAlertIntervalUnit+"s"]);
      this.displayDeliveryFailuresPowerBI = true;
    }
    this.deliveryFailuresTable.data.next(tableInput);
    this.deliveryFailuresTable.isLoaded = true;
  }

  loadDeliveryFailuresIcM(eventTypeSub: EventTypeSubscription): void {
    const tableInput = new TableInputV2();
    tableInput.rows = new Array<Array<string>>();

    tableInput.rows.push(["Enabled", String(eventTypeSub.enableDeliveryFailureIcM)]);
    if (eventTypeSub.enableDeliveryFailureIcM) {
      tableInput.rows.push(["IcM Team Public Id", String(eventTypeSub.icmRoutingId)]);
      tableInput.rows.push(["Default Severity", String(eventTypeSub.severity)]);
    }
    this.deliveryFailuresIcMTable.data.next(tableInput);
    this.deliveryFailuresIcMTable.isLoaded = true;
  }

  isModifying(status: string): boolean {
    return (status === "Pending"
      || status === "Creating"
      || status === "Updating"
      || status === "Deleting");
  }

  editEventTypeSubscriptionDetails(eventTypeSubscription: EventTypeSubscription): void {
    const editEventSubModal = this.modalService.open(SaveSubscriptionComponent);
    (editEventSubModal.componentInstance as SaveSubscriptionModal).eventTypeSubscription = eventTypeSubscription;
    if (editEventSubModal) {
      editEventSubModal.result
        .then((updatedEventTypeSubName: string) => {
          this.router.navigateByUrl('/', {skipLocationChange: true}).then(
            () => {
              const updatedUrl = 'event-broker/event/'+eventTypeSubscription.parentEventName+'/subscription/'+updatedEventTypeSubName;
              this.router.navigateByUrl(updatedUrl);
            }
          );
        })
        .catch(() => { });
    }
    return;
  }

  deleteEventTypeSubscription(eventTypeSubscription: EventTypeSubscription): void {
    // display delete confirmation
    const deleteEventSubModal = this.modalService.open(NgbModalConfirmDeleteComponent);
    deleteEventSubModal.componentInstance.message = "Subscription delete request will be submitted. " +
      "Refresh the catalog in a few minutes to confirm deletion.";
    deleteEventSubModal.result
      .then(() => {
        // send delete request
        this.eventCatalogService.deleteEventTypeSubscription(eventTypeSubscription.id)
        .subscribe(
          () => {
            this.router.navigateByUrl('event-broker/event/' + eventTypeSubscription.parentEventName.toLowerCase());
          },
          (error: ProblemDetailsHttpErrorResponse) => this.handleHttpErrorResponse("Error occurred while deleting subscription", error)
        );
      })
      .catch(() => { });

    return;
  }

  handleHttpErrorResponse(message: string, errResponse: ProblemDetailsHttpErrorResponse): string {
    const pd: ProblemDetails = errResponse.error;
    message += ': ' + pd.title;
    console.error(message, pd);

    const errorModal = this.modalService.open(NgbModalErrorComponent);
    errorModal.componentInstance.message = message;
    return message;
  }
}

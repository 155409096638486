import { Component, OnInit, AfterViewChecked, Inject } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { RuleConfig } from '../model/rule.model';
import { RuleService } from '../service/rule.service';
import { Alert } from '../model/Alert.model';
import { UntypedFormControl, UntypedFormGroup, Validators, AbstractControl } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgbModalErrorComponent } from '../../modal/ngb-modal-error.component';
import { NgbModalSuccessComponent } from '../../modal/ngb-modal-success.component';
import { MasterDataService } from '../service/master-data.service';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { Observable, forkJoin } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { APP_CONFIG, ngbModalOptions } from '../../common/constants';
import { AppConfig } from '../../model/app-config.model';
import { ModalRef, ODataError } from '../../model/common.model';
import { Location } from '@angular/common';
import moment from 'moment';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.css', '../../../style/common-styles.scss']
})
export class AlertComponent implements OnInit, AfterViewChecked {

  // form-group
  alertForm = new UntypedFormGroup({
    alertName: new UntypedFormControl('', [Validators.required]),
    description: new UntypedFormControl('', Validators.required),
    severity: new UntypedFormControl('', [Validators.required, Validators.pattern('^[1-4]*$')]),
    frequencyValue: new UntypedFormControl('', [Validators.required, Validators.pattern('^[0-9]*$')]),
    frequencyType: new UntypedFormControl('', Validators.required),
    startTime: new UntypedFormControl(''),
    endTime: new UntypedFormControl(''),
    isEmailEnabled: new UntypedFormControl(''),
    alertEmail: new UntypedFormControl('', [Validators.required, this.emailValidator]),
    isIcMEnabled: new UntypedFormControl(''),
    icmRoutingId: new UntypedFormControl('', [Validators.required, Validators.pattern('^..*\\\\..*$')]),
    isDynamicIcmSeverityEnabled: new UntypedFormControl(''),
    sev3Threshold: new UntypedFormControl('0', [Validators.required, Validators.pattern('^[0-9]*$')]),
    sev2Threshold: new UntypedFormControl('0', [Validators.required, Validators.pattern('^[0-9]*$')]),
    sev1Threshold: new UntypedFormControl('0', [Validators.required, Validators.pattern('^[0-9]*$')]),

  }, { validators: [this.recurranceValidator, this.icmSelectionValidator, this.dynamicSeverityValidator] });

  // Rule related attributes
  ruleId: number;
  rule: RuleConfig;
  isAlertLoaded: boolean;
  isSaveInProgress: boolean;
  isViewContentChecked = false;

  ICMRouteList: Array<string>;

  // Alert Status
  lastRunTime: Date;
  jobStatus: string;

  isNewAlert = false;

  templatizeBAMAlertWikiUrl: string;
  icmTeamPublicIdLookupUrl: string;

  // Route related
  routeId: number;

  constructor(
    private location: Location,
    private router: Router,
    private route: ActivatedRoute,
    private ruleService: RuleService,
    private modalService: NgbModal,
    private masterDataService: MasterDataService,
    private appInsightsService: ApplicationInsights,
    @Inject(APP_CONFIG) appConfig: AppConfig,
  ) {
    this.isAlertLoaded = false;
    this.templatizeBAMAlertWikiUrl = appConfig.templatizeBAMAlertWikiUrl;
    this.icmTeamPublicIdLookupUrl = appConfig.icmTeamPublicIdLookupUrl;

    // subscribe to router events for navigation
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.routeId = event.id;
      }
    });
  }

  ngOnInit() { 
    // Get rule by ruleId and populate
    this.route.params.subscribe(
      params => {
        this.ruleId = params['id'] as number;
        this.getRuleAndPopulate(this.ruleId);
      }
    );

    this.onEmailIcMToggleChanges();
    this.onDynamicIcmSeverityToggleChanges();
  }

  ngAfterViewChecked() {
    const focusElement: HTMLElement = document.getElementById('alert-name') as HTMLElement;
    if (!this.isViewContentChecked && focusElement != null) {
      focusElement.focus();
      this.isViewContentChecked = true;
    }
  }

  onEmailIcMToggleChanges(): void {
    (this.alertForm.get('isEmailEnabled') as UntypedFormControl).valueChanges.subscribe(val => {
      if (val === true) {
        (this.alertForm.get('alertEmail') as UntypedFormControl).enable();
      } else {
        (this.alertForm.get('alertEmail') as UntypedFormControl).disable();
      }
    });
    (this.alertForm.get('isIcMEnabled') as UntypedFormControl).valueChanges.subscribe(val => {
      if (val === true) {
        (this.alertForm.get('icmRoutingId') as UntypedFormControl).enable();
      } else {
        (this.alertForm.get('icmRoutingId') as UntypedFormControl).disable();
        (this.alertForm.get('icmRoutingId') as UntypedFormControl).markAsUntouched();
      }
    });
  }

  onDynamicIcmSeverityToggleChanges(): void {
    (this.alertForm.get('isDynamicIcmSeverityEnabled') as UntypedFormControl).valueChanges.subscribe(val => {
      if (val === true) {
        (this.alertForm.get('sev3Threshold') as UntypedFormControl).enable();
        (this.alertForm.get('sev2Threshold') as UntypedFormControl).enable();
        (this.alertForm.get('sev1Threshold') as UntypedFormControl).enable();

        (this.alertForm.get('severity') as UntypedFormControl).disable();
        (this.alertForm.get('severity') as UntypedFormControl).setValue(0);
      } else {
        (this.alertForm.get('sev3Threshold') as UntypedFormControl).disable();
        (this.alertForm.get('sev2Threshold') as UntypedFormControl).disable();
        (this.alertForm.get('sev1Threshold') as UntypedFormControl).disable();

        (this.alertForm.get('sev3Threshold') as UntypedFormControl).setValue(0);
        (this.alertForm.get('sev2Threshold') as UntypedFormControl).setValue(0);
        (this.alertForm.get('sev1Threshold') as UntypedFormControl).setValue(0);

        (this.alertForm.get('severity') as UntypedFormControl).enable();
      }
    });
  }

  // Method to save the alert and go back to rule component.
  saveAlert(): void {
    this.rule.alert = Object.assign(this.rule.alert, this.alertForm.getRawValue() as Alert);

    this.isSaveInProgress = true;
    this.rule.alert.lastModified = new Date();

    if (this.rule.alert.isEmailEnabled) {
      // cleaning up all the emails before sending to backend.
      const alertEmails = this.rule.alert.alertEmail.split(';')
        .map(x => x.trim())
        .filter(x => x !== '');
      this.rule.alert.alertEmail = alertEmails.join(';');
    }

    if (!(this.alertForm.get('icmRoutingId') as UntypedFormControl).valid) {
      this.rule.alert.icmRoutingId = '';
    }

    if (this.rule.alert.id === undefined) {
      this.ruleService.updateRule(this.rule).subscribe(
        this.handleUpdateRuleResponse,
        this.handleUpdateRuleErrorResponse);
    } else {
      this.ruleService.updateAlert(this.rule).subscribe(
        this.handleUpdateRuleResponse,
        this.handleUpdateRuleErrorResponse);
    }
  }

  handleUpdateRuleResponse = (response: HttpResponse<any>) => {
    this.isSaveInProgress = false;
    if (response.status === 204) {
      const successModal = this.modalService.open(NgbModalSuccessComponent, ngbModalOptions);
      (successModal.componentInstance as ModalRef).message = "Successfully Saved Alert";
      successModal.result.then(
        () => {
          this.router.navigate(['bam-dashboard/rule', this.rule.id]);
        }
      );
    } else {
      const modal = this.modalService.open(NgbModalErrorComponent);
      (modal.componentInstance as ModalRef).message = "Error saving alert. Please try again.";
      this.isSaveInProgress = false;
    }
  }

  handleUpdateRuleErrorResponse = (error: HttpErrorResponse) => {
    this.isSaveInProgress = false;
    const modal = this.modalService.open(NgbModalErrorComponent);
    let errorMessage = "";
    if (error.status === 403) {
      errorMessage = "Access Denied";
    } else if (error.status === 400) {
      errorMessage = (error.error as ODataError).value;
    } else {
      errorMessage = error.statusText;
    }
    (modal.componentInstance as ModalRef).message = "Error saving alert. " + errorMessage;
  }

  cancelClick(): void {
    if (this.routeId === 1) {
      this.router.navigate(['bam-dashboard/rule', this.rule.id]);
    } else {
      this.location.back();
    }
  }

  getRuleAndPopulate(id: number): void {
    const $rule = this.ruleService.getRuleById(id);
    const $ICMMasterData = this.masterDataService.getCurrentlyUsedTeamGroupIcMRoutingIds(id.toString());
    forkJoin([$rule, $ICMMasterData]).subscribe(
      (response) => {
        this.rule = response[0] as unknown as RuleConfig;
        if (this.rule.alert === null) {
          this.isNewAlert = true;
          this.rule.alert = new Alert();
          this.rule.alert.isEmailEnabled = false;
          this.rule.alert.isIcMEnabled = false;
        }
        this.ICMRouteList = new Array<string>();
        this.ICMRouteList = response[1];
      },
      (err: HttpErrorResponse) => {
        this.appInsightsService.trackException({exception: err});
        const modal = this.modalService.open(NgbModalErrorComponent);
        (modal.componentInstance as ModalRef).message = "Error in populating alert. Please contact administrator.";
      },
      () => {
        this.isAlertLoaded = true;

        // Set Alert status
        if (this.rule.alert.lastRun !== null && this.rule.alert.lastRun !== undefined) {
          if (this.rule.alert.lastRunCompletedAt > this.rule.alert.lastRun) {
            this.jobStatus = "Success";
          } else if (moment(this.rule.alert.lastRun).add(5, 'minutes') > moment() // current time is less than last run + 5 mins
            && this.rule.alert.lastRunCompletedAt < this.rule.alert.lastRun) {
            this.jobStatus = "Running";
          } else if (moment(this.rule.alert.lastRun).add(5, 'minutes') < moment() // current time is greater than last run + 5 mins
            && this.rule.alert.lastRunCompletedAt < this.rule.alert.lastRun) { 
            this.jobStatus = "Failed";
          }
        }
        this.lastRunTime = this.rule.alert.lastRun;

        // Building form group
        (this.alertForm.get('alertName') as UntypedFormControl).setValue(this.rule.alert.alertName);
        (this.alertForm.get('description') as UntypedFormControl).setValue(this.rule.alert.description);
        (this.alertForm.get('severity') as UntypedFormControl).setValue(this.rule.alert.severity);
        (this.alertForm.get('frequencyValue') as UntypedFormControl).setValue(this.rule.alert.frequencyValue);
        (this.alertForm.get('frequencyType') as UntypedFormControl).setValue(this.rule.alert.frequencyType);
        (this.alertForm.get('startTime') as UntypedFormControl).setValue(this.rule.alert.startTime);
        (this.alertForm.get('endTime') as UntypedFormControl).setValue(this.rule.alert.endTime);
        (this.alertForm.get('isEmailEnabled') as UntypedFormControl).setValue(this.rule.alert.isEmailEnabled);
        (this.alertForm.get('isIcMEnabled') as UntypedFormControl).setValue(this.rule.alert.isIcMEnabled);
        (this.alertForm.get('icmRoutingId') as UntypedFormControl).setValue(this.rule.alert.icmRoutingId);
        (this.alertForm.get('alertEmail') as UntypedFormControl).setValue(this.rule.alert.alertEmail);
        (this.alertForm.get('isDynamicIcmSeverityEnabled') as UntypedFormControl).setValue(this.rule.alert.isDynamicIcmSeverityEnabled);
        (this.alertForm.get('sev3Threshold') as UntypedFormControl).setValue(this.rule.alert.sev3Threshold);
        (this.alertForm.get('sev2Threshold') as UntypedFormControl).setValue(this.rule.alert.sev2Threshold);
        (this.alertForm.get('sev1Threshold') as UntypedFormControl).setValue(this.rule.alert.sev1Threshold);
      }
    );
  }

  recurranceValidator(group: AbstractControl): { [key: string]: any } | null {
    const frequencyType = (group.get('frequencyType') as UntypedFormControl).value as string;
    const frequencyValue = (group.get('frequencyValue') as UntypedFormControl).value as number;

    if ((frequencyType === 'Minute' && frequencyValue < 5)
      || (frequencyType === 'Hour' && frequencyValue <= 0)
      || (frequencyType === 'Day' && frequencyValue <= 0)) {
      return { isRecurranceError: true, recurranceError: "Please enter minimum 5 minutes of recurrence time.." };
    } else {
      return null;
    }
  }

  icmSelectionValidator(group: AbstractControl): { [key: string]: any } | null {
    const isEnable = (group.get('isIcMEnabled') as UntypedFormControl).value;
    const isValid = (group.get('icmRoutingId') as UntypedFormControl).valid;

    if (isEnable && !isValid) {
      return { isIcmSelectError: true, IcmSelectError: "Please enter valid IcM Team Public Id" };
    } else {
      return null;
    }
  }

  emailValidator(control: AbstractControl): { [key: string]: any } | null {
    const emailList = control.value as string;
    if (emailList) {
      let emails: Array<string> = emailList.split(';');
      emails = emails.map(x => x.trim());
      emails = emails.filter(x => x !== '');
      // test for empty emails.
      if (emails.length === 0) {
        return { isInvalidEmail: true, invalidEmailError: "Please enter valid emails" };
      }

      let valid = true;
      emails.forEach(email => {
        const isValid = /^[_a-zA-Z0-9]+(\-[_a-zA-Z0-9]+)*(\.[_a-zA-Z0-9]+)*@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*(\.[a-zA-Z]{2,4})$/.test(email);

        if (!isValid) {
          valid = false;
        }
      });
      return valid ? null : { isInvalidEmail: !valid, invalidEmailError: "Please enter valid emails" };
    } else {
      return null;
    }
  }

  dynamicSeverityValidator(group: AbstractControl): { [key: string]: any } | null {
    const sev3 = (group.get('sev3Threshold') as UntypedFormControl).value as number;
    const sev2 = (group.get('sev2Threshold') as UntypedFormControl).value as number;
    const sev1 = (group.get('sev1Threshold') as UntypedFormControl).value as number;

    if (
      (sev3 !== 0 && sev2 !== 0 && sev3 >= sev2) ||
      (sev3 !== 0 && sev1 !== 0 && sev3 >= sev1) ||
      (sev2 !== 0 && sev1 !== 0 && sev2 >= sev1)
    ) {
      return {
        isSevThresholdError: true,
        sevThresholdError: "Sev3,Sev2,Sev1 thresholds should be either in ascending order Or undefined(0)"
      };
    } else {
      return null;
    }
  }

  icmRoutingIdTeamGroupSearch = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term => term.length < 0 ? []
        : this.ICMRouteList.map(x => x).filter(x => x.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10))
    )

  onFocus = (e: Event) => {
    e.stopPropagation();
    setTimeout(() => {
      const inputEvent: Event = new Event('input');
      e.target?.dispatchEvent(inputEvent);
    }, 0);
  }
}

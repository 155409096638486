import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { APP_CONFIG } from '../../common/constants';
import { AppConfig } from '../../model/app-config.model';



export interface SubProcess {
  id: number;
  name: string;
  description: string;
  businessID: number;
  businessProcessName: string;
  businessProcessDescription: string;
  subprocessName: string;
  supprocessCount: number;
  subprocessID: number;
  scoreID: string;
  processDiagram: string;
  bamAlertID: string;
  eventRuleID: number;
  org: string;
  scoreID2: number;
}

@Injectable({
  providedIn: 'root'
})
export class ProcessTableService {
  private baseUrl: string;
  constructor(private httpClient: HttpClient,
    @Inject(APP_CONFIG) private appConfig: AppConfig
  ) {
    this.baseUrl = `${this.appConfig.baseUrlProcessDataCollector}/Process`;

  }

  getAllProcesses(): Observable<SubProcess[]> {
    return this.httpClient.get<SubProcess[]>(`${this.baseUrl}/GetAllProcess`).pipe(
      map((response: SubProcess[]) => response)
    );
  }

  getAllActiveProjects(): Observable<SubProcess[]> {
    return this.httpClient.get<SubProcess[]>(`${this.baseUrl}/GetAllActiveProjects`).pipe(
      map((response: SubProcess[]) => response)
    );
  }
  getAllCSCPProcesses(): Observable<SubProcess[]> {
    return this.httpClient.get<SubProcess[]>(`${this.baseUrl}/GetAllCSCPProcesses`).pipe(
      map((response: SubProcess[]) => response)
    );
  }

  getAllNewProcessToOnboard(): Observable<SubProcess[]> {
    return this.httpClient.get<SubProcess[]>(`${this.baseUrl}/GetAllNewProcessToOnboard`).pipe(
      map((response: SubProcess[]) => response)
    );
  }


  getAllProcessAlertsAndReports(): Observable<SubProcess[]> {
    return this.httpClient.get<SubProcess[]>(`${this.baseUrl}/GetAllProcessAlertAndReports`).pipe(
      map((response: SubProcess[]) => response)
    );
  }

  AddProcessAlertAndReports(process: SubProcess): Observable<any> { 
    return this.httpClient.post(`${this.baseUrl}/AddProcessAlertAndReports`, process, { responseType: 'text' }).pipe(
      map((response: any) => {
        return response; 
      })
    );
  }
  AddCSCPProcess(process: SubProcess): Observable<any> { 
    return this.httpClient.post(`${this.baseUrl}/AddCSCPProcess`, process, { responseType: 'text' }).pipe(
      map((response: any) => {
        return response; 
      })
    );
  }
  AddProcessToOnboard(process: SubProcess): Observable<any> {
    return this.httpClient.post(`${this.baseUrl}/AddProcessToOnboard`, process, { responseType: 'text' }).pipe(
      map((response: any) => {
        return response;
      })
    );
  }
  AddActiveProjects(process: SubProcess): Observable<any> {
    return this.httpClient.post(`${this.baseUrl}/AddActiveProject`, process, { responseType: 'text' }).pipe(
      map((response: any) => {
        return response;
      })
    );
  }
  
  updateProcessAlertAndReport(process: SubProcess): Observable<any> {
    return this.httpClient.put(`${this.baseUrl}/UpdateProcessAlertAndReports/${process.id}`, process, { responseType: 'text' }).pipe(
      map((response: any) => response)
    );
  }
  updateCSCPProcesses(process: SubProcess): Observable<any> {
    return this.httpClient.put(`${this.baseUrl}/UpdateCSCPProcess/${process.id}`, process, { responseType: 'text' }).pipe(
      map((response: any) => response)
    );
  }
  updateActiveProject(process: SubProcess): Observable<any> {
    return this.httpClient.put(`${this.baseUrl}/updateActiveProject/${process.id}`, process, { responseType: 'text' }).pipe(
      map((response: any) => response)
    );
  }
  updateProcessToOnboard(process: SubProcess): Observable<any> {
    console.log("updateProcessToOnboard : ",process);
    return this.httpClient.put(`${this.baseUrl}/UpdateProcessToOnboard/${process.id}`, process, { responseType: 'text' }).pipe(
      map((response: any) => response)
    );
  }
  DeleteProcessAlertAndReports(id: number): Observable<any> {
    return this.httpClient.delete(`${this.baseUrl}/DeleteProcessAlertAndReports/${id}`, { responseType: 'text' });
  }
  
  DeleteProcessToOnboard(id: number): Observable<any> {
    return this.httpClient.delete(`${this.baseUrl}/DeleteProcessToOnboard/${id}`, { responseType: 'text' });
  }
  DeleteCSCPProcesses(id: number): Observable<any> {
    return this.httpClient.delete(`${this.baseUrl}/DeleteCSCPProcess/${id}`, { responseType: 'text' });
  }
  
  DeleteActiveProject(id: number): Observable<any> {
    return this.httpClient.delete(`${this.baseUrl}/DeleteActiveProject/${id}`, { responseType: 'text' });
  }
  
} 
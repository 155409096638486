import { NgbModalErrorComponent } from '../../modal/ngb-modal-error.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HttpErrorResponse } from '@angular/common/http';

export function showError(modalService: NgbModal, error: HttpErrorResponse, errorMessage?: string) {
    const modal = modalService.open(NgbModalErrorComponent);
    if (error.message === "Cannot send request to registered endpoint if the user is not authenticated") {
        modal.componentInstance.message = "Login expired refresh the browser to continue";
    } else if (error.status === 403) {
        modal.componentInstance.message = "User is unauthorized. Please contact support";
    } else if (error.status === 409) {
        modal.componentInstance.message = error.error.error.message;
    } else {
        if (errorMessage != null) {
            modal.componentInstance.message = errorMessage;
        } else {
            modal.componentInstance.message = 'Error occured. Please try again after sometime.';
        }
    }
}

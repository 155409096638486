import { Component, OnDestroy, OnInit } from '@angular/core';
import { QoSRouterPage } from '../common/constants';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { QoSService } from '../service/qos.service';
import { Service, ServiceInput, Operation, OperationType, QosRoutingState } from '../model/qos.model';
import { NgbModalErrorComponent } from '../../modal/ngb-modal-error.component';
import { UpdateComponent } from '../update-component/update-component.component';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { UntypedFormControl } from '@angular/forms';
import { debounceTime, switchMap } from 'rxjs/operators';
import { of, Subscription } from 'rxjs';
import { cloneDeep } from 'lodash-es';

@Component({
  selector: 'app-administration',
  templateUrl: './administration.component.html',
  styleUrls: ['./administration.component.css']
})
export class AdministrationComponent implements OnInit, OnDestroy {

  activeTab: string;
  tabs = [OperationType.Request, OperationType.RemoteDependency];
  levelsToLoadServiceTree = 3;
  isTeamGroupSelected = false;
  loadedOperations: boolean;

  teamGroupId: string;
  teamGroupName: string;
  serviceInput: ServiceInput;
  filteredData: Service[];
  requestsData: Service[];
  remoteDependencyData: Service[];

  searchBox = new UntypedFormControl('');

  private subscriptions: Subscription;

  constructor(
    private qosService: QoSService,
    private modalService: NgbModal,
    private router: Router,
  ) {
    this.loadedOperations = false;
    this.subscriptions = new Subscription();
  }

  ngOnInit() {
    this.registerSearchChanges();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  teamGroupIdEventHandler(teamGroupId: string) {
    this.teamGroupId = teamGroupId;
    this.isTeamGroupSelected = true;
    this.getOperationsByTeamGroup();
  }

  teamGroupNameEventHandler(name: string) {
    this.teamGroupName = name;
  }

  getOperationsByTeamGroup() {
    this.loadedOperations = false;
    const operations = this.qosService.getAllOperationsByTeamGroup(this.teamGroupId).subscribe(
      (response) => {
        this.serviceInput = response.serviceInput;
        this.requestsData = response.requestsData;
        this.remoteDependencyData = response.remoteDependencyData;
        this.setTabContent(this.activeTab ? this.activeTab : OperationType.Request);
        this.loadedOperations = true;
      },
      (error: HttpErrorResponse) => this.showError(error, error.error)
    );
    this.subscriptions.add(operations);
  }

  setTabContent(tab: string) {
    if (tab === OperationType.RemoteDependency) {
      this.filteredData = this.remoteDependencyData;
      this.activeTab = OperationType.RemoteDependency;
    } else if (tab === OperationType.Request) {
      this.filteredData = this.requestsData;
      this.activeTab = OperationType.Request;
    }
  }

  registerSearchChanges() {
    const searchSubscription = this.searchBox.valueChanges.pipe(
      debounceTime(500),
      switchMap((searchTerm: string) => {
        searchTerm = searchTerm.trim().toLowerCase();
        if (searchTerm === '') {
          this.setTabContent(this.activeTab);
          return of(this.filteredData);
        } else {
          let filteredData: Service[];
          if (this.activeTab === OperationType.Request) {
            filteredData = this.requestsData.map(r => this.searchFields(r, searchTerm))
              .filter(r => r !== null);
          } else if (this.activeTab === OperationType.RemoteDependency) {
            filteredData = this.remoteDependencyData.map(r => this.searchFields(r, searchTerm))
              .filter(r => r !== null);
          }
          return of(filteredData);
        }
      }),
    ).subscribe(
      (filteredData: Service[]) => {
        this.filteredData = filteredData;
      }
    );
    this.subscriptions.add(searchSubscription);
  }

  searchFields(service: Service, searchTerm: string): Service {
    if (service.serviceName.trim().toLowerCase().includes(searchTerm)) {
      return service;
    } else if (service.serviceOid.trim().toLowerCase().includes(searchTerm)) {
      return service;
    } else {
      service = cloneDeep(service);
      service.operations = service.operations.filter(ops => ops.name.trim().toLowerCase().includes(searchTerm));
      if (service.operations.length > 0) {
        return service;
      } else {
        return null;
      }
    }
  }

  addOperations(): void {
    const addState: QosRoutingState = {
      previousUrl: QoSRouterPage.Admin,
      teamGroupName: this.teamGroupName,
      selectedServiceId: null,
      serviceInput: this.serviceInput
    };
    this.router.navigate(['quality-of-service/add-component'], { state: addState });
  }

  // open modal with single entry
  updateOperation(service: Service, operation: Operation) {
    const updateModal = this.modalService.open(UpdateComponent);
    const updateService = new Service();
    updateService.serviceName = service.serviceName;
    updateService.serviceOid = service.serviceOid;
    updateService.operations = new Array<Operation>();
    updateService.operations.push(operation);
    updateModal.componentInstance.service = updateService;

    if (updateModal) {
      updateModal.result
        .then(() => {
          // reload the list after successfull update or delete.
          this.getOperationsByTeamGroup();
        })
        // no processing necessary, when dismiss or cancel button used, the error callback is called.
        .catch(() => { });
    }
  }

  manageIkey(): void {
    const state: QosRoutingState = {
      previousUrl: QoSRouterPage.Admin,
      teamGroupName: this.teamGroupName,
      selectedServiceId: null,
      serviceInput: this.serviceInput
    };
    this.router.navigate(['quality-of-service/manage-ikeys'], { state: state });
  }

  showError(error: Error, errorMessage?: string) {
    if (error.message === "Cannot send request to registered endpoint if the user is not authenticated") {
      const modal = this.modalService.open(NgbModalErrorComponent);
      modal.componentInstance.message = "Login expired, refresh the browser to continue";
    } else {
      const modal = this.modalService.open(NgbModalErrorComponent);
      if (errorMessage != null) {
        modal.componentInstance.message = errorMessage;
      } else {
        modal.componentInstance.message = 'Error occured. Please try again after sometime.';
      }
    }
  }

}

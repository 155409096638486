<div class='container-fluid pt-2'>
  <div class="d-flex flex-row">
    <div class="col-12" style="text-align:center;">
      <div style="font-size:2em;font-weight:bold;padding-top:1em;">
        <div> 
          <span id="page-title" tabindex="0" aria-label=scoreCardName>{{scoreCardName}}</span>
            <a href="{{reportUrl}}" target="_blank" *ngIf="reportUrl">
              <img class="img-fluid ml-4 mb-2" src="../assets/images/PowerBILogo.png" title="Power BI Report Link"
              alt="Power BI Report Link" aria-label="Power BI Report Link" style="width: 2.5rem;" />
            </a>
        </div>
      </div>
      <div style="text-align:center;">
        <button type="button" class="btn btn-default" aria-label="Go to Previous Week" 
          style="font-size: 1.5em;" (click)="changeWeek(-1)">&lt; Previous Week
        </button>
        <span aria-label="Start date of Week" style="font-size:1.25em;margin-top:0.5em;padding-left:100px;">
          {{ weekStart | date:"dd-MMM-yyyy" : "UTC"}} : </span>
        <span aria-label="Start date of Week" style="font-size:1.25em;margin-top:0.5em;padding-right:100px;">
            {{ weekEnd | date:"dd-MMM-yyyy" : "UTC"}}</span>
        <button type="button" class="btn btn-default" aria-label="Go to Next Week" 
          style="font-size: 1.5em;" (click)="changeWeek(1)" [disabled]="viewingWeek===-1">Next Week &gt;
        </button>
      </div>
      <div class="float-right" style="margin-top: -9em;padding-top: 2em">
        <div class="text-center">
          <span style="font-weight:bold;">{{ allUpHealthDialName }}</span>
        </div>
        <div class="progress" style="width: 10rem;"> 
          <div class="progress-bar" [style.width.%]="allUpHealthDialScore" role="progressbar" 
            attr.aria-valuenow="allUpHealthDialScore" aria-valuemin="0" aria-valuemax="100" aria-label="average performance of a scor"
            [ngClass]="allUpHealthDialColor">
            {{ allUpHealthDialScore }}%
          </div>  
        </div>  
        <div class="text-center">    
          <span style="font-weight:bold;font-size: small;">{{ showLowerBound ? "LB:"+allUpHealthDialLB+"%, UB:"+allUpHealthDialUB+"%" : "Target: "+allUpHealthDialUB+"%" }}</span>
        </div> 
      </div>
    </div>
  </div>
  <br>
  
  <div class="d-flex flex-row" style="justify-content:center;" *ngIf="scenarios && scenarios.length>0">
    <div *ngFor="let entry of healthMap | keyvalue : asIsOrder" class="mx-1 px-1" style="width: 10rem;margin-top: auto;">
      <div class="text-center">
        <span style="font-weight:bold;">{{entry.key}}</span>
      </div>
      <div class="progress"> 
        <div class="progress-bar" [style.width.%]="entry.value[0]" role="progressbar" 
          attr.aria-valuenow="{{entry.value[0]}}" aria-valuemin="0" aria-valuemax="100" aria-label="average performance of a scor"
          [ngClass]="{'red' : showLowerBound && entry.value[2] > 0 ? entry.value[0] < entry.value[2]: entry.value[0] < entry.value[1], 
                      'green' : entry.value[0] >= entry.value[1],
                      'yellow' : showLowerBound && entry.value[2] > 0 && entry.value[0] >= entry.value[2] && entry.value[0] < entry.value[1]}">
          {{entry.value[0]}}%
        </div>  
      </div>  
      <div class="text-center">    
        <span style="font-weight:bold;font-size: small;">{{ showLowerBound ? "LB:"+entry.value[2]+"%, UB:"+entry.value[1]+"%" : "Target: "+entry.value[1]+"%" }}</span>
      </div> 
    </div>
  </div>
  <br>
  <div class="d-flex flex-row">
    <div *ngIf="wikiUrl" class="col-3">
      <a class="link-text" href={{wikiUrl}} target="_blank" style="font-size: 1.25em;">Performance Metric Wiki</a> 
    </div>
    <div *ngIf="canViewAutomatedValue" class="input-group input-group-sm">
      <div class="custom-control custom-switch ml-auto" >
        <input type="checkbox" class=" custom-control-input" id="customSwitch1" [formControl]="showAutomatedValueToggle" aria-label="Show Automated Values">
        <label class="custom-control-label" for="customSwitch1">Show Automated Values</label>
      </div>
    </div>
  </div>
  <div class="d-flex flex-row">
    <div class="col-4"></div>
    <div class="col-4" style="text-align:center;">{{loading}}</div>
    <div class="col-4"></div>
  </div>
  <div class="table-responsive" *ngIf="scenarios && scenarios.length>0">
    <table class="table table-bordered table-striped" aria-label="Scenario Health details">
      <thead class="thead-dark">
        <tr tabindex="0">
          <th id="h1" class="text-center" scope="col" style="width:5%;font-weight:bold;padding-top:1px;padding-bottom:25px;">#</th>
          <th id="h2" class="text-center" scope="col" style="width:5%;font-weight:bold;padding-top:1px;padding-bottom:25px;">Area</th>
          <th id="h3" class="text-center" scope="col" style="width:25%;font-weight:bold;padding-top:1px;padding-bottom:25px;">Critical Scenario</th>
          <th id="h4" class="text-center" scope="col" style="width:15%;font-weight:bold;padding-top:1px;padding-bottom:25px;">Owner</th>
          <th *ngIf="showAutomatedValue" id="h8" class="text-center" scope="col" style="width:7%;font-weight:bold;">Auto Value (%)</th>
          <th id="h5" class="text-center" scope="col" style="width:7%;font-weight:bold;">Actual Score (%)</th>
          <th *ngIf="showLowerBound" id="h10" class="text-center" scope="col" style="width:7%;font-weight:bold;">Lower Bound (%)</th>
          <th id="h6" class="text-center" scope="col" style="width:7%;font-weight:bold;">{{showLowerBound ? "Upper Bound (%)" : "Target Score (%)"}}</th>
          <th id="h7" class="text-center" scope="col" style="width:31%;font-weight:bold;padding-top:1px;padding-bottom:25px;">Details</th>
          <th id="h9" class="text-center" scope="col" style="width:5%;font-weight:bold;padding-top:1px;padding-bottom:25px;">{{editable ? "Edit" : "View"}}</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let scenario of scenarios; let i=index; let even=even;" [ngClass]="{'even': even}">
          <td headers="h1" class="text-center">{{scenario.scenario.serialNumber}}</td>
          <td headers="h2" class="text-center">{{scenario.scenario.area}}</td>
          <td headers="h3" style="text-align:left;">
            {{scenario.scenario.name}}
            <ng-container *ngIf="scenario.scenario.reportLink">
              <a href="{{scenario.scenario.reportLink}}" target="_blank" rel="noopener noreferrer" title="External Report" aria-label="External Report"><i class='fa fa-external-link'></i></a>
            </ng-container>
          </td>
          <td headers="h4" class="text-center">{{scenario.scenario.owner}}</td>
          <td *ngIf="showAutomatedValue" headers="h8" class="text-center" style="font-weight:bold;" 
          [title]="(scenario.automatedValue === null)? 'Manual' : (scenario.automatedValue === -1)? 'Not Authorized To View' : scenario.automatedValue"
          [attr.aria-label]="(scenario.automatedValue === null)? 'Manual' : (scenario.automatedValue === -1)? 'Not Authorized To View' : scenario.automatedValue"
          [textContent]="(scenario.automatedValue === null)? 'Manual' : (scenario.automatedValue === -1)? 'Not Authorized To View' : scenario.automatedValue"
          [ngClass]="scenario.autoScoreColor">
          </td>
          <td headers="h5" class="text-center" style="font-weight:bold;" 
            [title]="(scenario.performance === 0)? 'Set Score':'Updated by '+scenario.updated.by+' on '+scenario.updated.on" 
            [attr.aria-label]="(scenario.performance === 0)? 'Set Score':'Updated by '+scenario.updated.by+' on '+scenario.updated.on" 
            [textContent]="scenario.performance"
            [ngClass]="scenario.actualScoreColor">
          </td>
          <td *ngIf="showLowerBound" headers="h10" class="text-center" style="font-weight:bold;">{{scenario.scenario.intermediateTarget}}</td>
          <td headers="h6" class="text-center" style="font-weight:bold;"
            [title]="(scenario.scenario.targetPerformance < showTargetAsterix) ? targetTitle : ''">
            {{scenario.scenario.targetPerformance}}
            <span *ngIf="scenario.scenario.targetPerformance < showTargetAsterix" style="color: red;">*</span>
          </td>
          <td headers="h7" class="text-left" (dblclick)="updateDetails(scenario)" 
              style="white-space:pre-wrap" 
              [attr.aria-label]="'Details (double click to view or edit)'">{{scenario.comments}}<ng-container *ngIf="scenario.comments && scenario.icm && scenario.icm.length"><br><br></ng-container>
            <ng-container *ngFor="let icm of scenario.icm; first as isFirst"><br *ngIf="!isFirst"><b>IcM: </b>
              <a class="link-text" href="{{icmUrlPrefix}}/{{icm.icmNumber}}" target="_blank" rel="noopener noreferrer">
                {{icm.icmNumber}}
                <span class="fa fa-link" aria-hidden="true"></span>
              </a> 
              <ng-container *ngIf="icm.comments"> - {{icm.comments}}</ng-container>
            </ng-container>
          </td>
          <td *ngIf="editable" headers="h9" class="text-center" style="font-weight:bold;"><a class="tab-click" role='button' [routerLink]="[]" aria-label="Edit Details"
            (click)="updateDetails(scenario)"><span class="fa fa-edit"></span></a></td>
          <td *ngIf="!editable" headers="h9" class="text-center" style="font-weight:bold;"><a class="tab-click" role='button' [routerLink]="[]" aria-label="View Details"
            (click)="updateDetails(scenario)"><span class="fa fa-eye"></span></a></td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
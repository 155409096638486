import { Component, OnInit, OnDestroy, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription, of, ReplaySubject, forkJoin } from 'rxjs';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { switchMap } from 'rxjs/operators';
import { TemplateVariable, TemplateVariableRule } from '../model/template-variable.model';
import { TemplateVariableService } from '../service/template-variable.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgbModalSuccessComponent } from '../../modal/ngb-modal-success.component';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandlingService } from '../../service/error-handling.service';
import { TableOptions, TableInputV2, RowEditEvent } from '../model/common.model';
import { RuleService } from '../service/rule.service';
import { NgbModalErrorComponent } from '../../modal/ngb-modal-error.component';
import { constants } from '../common/constants';

@Component({
  selector: 'app-template-variable',
  templateUrl: './template-variable.component.html',
  styleUrls: ['./template-variable.component.scss']
})
export class TemplateVariableComponent implements OnInit, OnDestroy, AfterViewInit {
  processId: number;
  templateVariableId: number;

  templateVariableForm = new UntypedFormGroup({
    name: new UntypedFormControl('', [Validators.required]),
    description: new UntypedFormControl(''),
    variableValue: new UntypedFormControl('', [Validators.required]),
    validFrom: new UntypedFormControl(''),
    updatedBy: new UntypedFormControl(''),
    languageType: new UntypedFormControl('', [Validators.required])
  });

  subscriptions: Subscription;
  saveSubscription: Subscription;

  isOperationLoading: boolean;
  isTemplateVariableLoading: boolean;
  isUpdate: boolean;

  // display table components.
  tableColumns: Array<string>;
  tableOptions: TableOptions;
  tableData: ReplaySubject<TableInputV2>;
  ruleList: Array<TemplateVariableRule>;

  @ViewChild('pageTitle') pageTitle: ElementRef;

  readonly ruleSuccessMessage = 'Valid Rule Update.';

  constructor(
    private templateVariableService: TemplateVariableService,
    private modal: NgbModal,
    private route: ActivatedRoute,
    private router: Router,
    private errorService: ErrorHandlingService,
    private ruleService: RuleService,
  ) {
    this.subscriptions = new Subscription();

    this.isOperationLoading = false;
    this.isTemplateVariableLoading = false;
    this.isUpdate = false;

    // table initializations.
    this.tableColumns = ['Rule Id', 'Rule Name', 'Status', 'Status Message', 'Edit'];
    this.tableOptions = {
      addEditColumn: false,
      editColumns: ['Edit'],
      enableSearch: false,
      columnWidths: [0.4, 1.8, 0.5, 1, 0.25],
      centeredColumns: ['Rule Id', 'Status', 'Status Message']
    };
    this.tableData = new ReplaySubject<TableInputV2>(1);
  }

  ngOnInit(): void {
    const routerSubscription = this.route.params.pipe(
      switchMap((params) => {
        this.processId = +params['processId'];
        if (params['id'] === 'new') {
          const templateVariable = new TemplateVariable();
          templateVariable.id = 0;
          templateVariable.processId = this.processId;
          this.isUpdate = false;
          return of(templateVariable);
        } else {
          this.templateVariableId = params['id'];
          this.isTemplateVariableLoading = true;
          this.isUpdate = true;
          return this.templateVariableService.getTemplateVariable(this.templateVariableId);
        }
      }),
      switchMap((templateVariable: TemplateVariable) => {
        const ruleList = templateVariable.ruleIdList;
        return forkJoin([of(templateVariable), this.ruleService.getRuleByIdList(ruleList)]);
      })
    ).subscribe(
      (response: [TemplateVariable, TemplateVariableRule[]]) => {
        const templateVariable = response[0];
        this.ruleList = response[1];
        this.setTemplateForm(templateVariable);
        this.displayTable(this.ruleList, true);
        this.isTemplateVariableLoading = false;
      },
      (error: HttpErrorResponse) => {
        console.error(error);
        this.errorService.displayError(error);
      }
    );

    this.subscriptions.add(routerSubscription);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  ngAfterViewInit(): void {
    this.pageTitle.nativeElement.focus();
  }

  private setTemplateForm(templateVariable: TemplateVariable): void {
    this.templateVariableForm.controls.name.setValue(templateVariable.name);
    this.templateVariableForm.controls.description.setValue(templateVariable.description);
    this.templateVariableForm.controls.languageType.setValue(templateVariable.languageType);
    this.templateVariableForm.controls.variableValue.setValue(templateVariable.value);
    this.templateVariableForm.controls.validFrom.setValue(templateVariable.validFrom);
    this.templateVariableForm.controls.updatedBy.setValue(templateVariable.updatedBy);
  }

  private getTemplateForm(): TemplateVariable {
    const templateVariable = new TemplateVariable();

    if (this.isUpdate) {
      templateVariable.id = this.templateVariableId;
    } else {
      templateVariable.id = 0;
    }
    templateVariable.name = this.templateVariableForm.controls.name.value;
    templateVariable.description = this.templateVariableForm.controls.description.value;
    templateVariable.languageType = this.templateVariableForm.controls.languageType.value;
    templateVariable.value = this.templateVariableForm.controls.variableValue.value;

    templateVariable.processId = this.processId;

    return templateVariable;
  }

  private displayTable(ruleList: Array<TemplateVariableRule>, resetError: boolean, errorMap?: Map<number, string>): void {
    if (this.isUpdate && ruleList != null) {
      const tableInput = new TableInputV2();
      tableInput.columns = this.tableColumns;
      tableInput.rows = new Array<Array<string>>();

      ruleList.forEach(rule => {
        const rowArray = new Array<string>();
        rowArray.push(rule.id.toString());
        rowArray.push(rule.ruleName);
        if (resetError) {
          rowArray.push(constants.SuccessStatus);
          rowArray.push(this.ruleSuccessMessage);
        } else {
          const errorMessage = errorMap.get(rule.id);
          if (errorMessage === this.ruleSuccessMessage) {
            rowArray.push(constants.SuccessStatus);
          } else {
            rowArray.push(constants.FailureStatus);
          }
          rowArray.push(errorMessage);
        }
        rowArray.push(rule.id.toString());
        tableInput.rows.push(rowArray);
      });
      this.tableData.next(tableInput);
    }
  }

  saveTemplateVariable(update: boolean): void {
    const templateVariable = this.getTemplateForm();
    this.isOperationLoading = true;
    if (update) {
      const updateSubscription = this.templateVariableService.updateTemplateVariable(templateVariable).subscribe(
        (updatedTemplateVariable: TemplateVariable) => {
          this.isUpdate = true;
          this.setTemplateForm(updatedTemplateVariable);
          this.isOperationLoading = false;
          this.displayTable(this.ruleList, true);
          const modal = this.modal.open(NgbModalSuccessComponent);
          modal.componentInstance.message = 'Template Variable updated.';
        },
        (error: HttpErrorResponse) => {
          // show error using common component.
          this.isOperationLoading = false;
          console.error('error occured while updating template variable: ', error);
          const innerError = error.error;
          const modal = this.modal.open(NgbModalErrorComponent);
          if (Object.keys(innerError).indexOf('value') >= 0) {
            modal.componentInstance.message = innerError.value;
          } else {
            modal.componentInstance.message = 'Error occured while updating template variable. Check table for details.';
            const errorMessages = innerError;
            const errorMap = new Map<number, string>();
            Object.keys(errorMessages).forEach(value => {
              errorMap.set(+value, errorMessages[value]);
            });

            this.displayTable(this.ruleList, false, errorMap);
          }
        }
      );
      this.subscriptions.add(updateSubscription);
    } else {
      const saveSubscription = this.templateVariableService.saveTemplateVariable(templateVariable).subscribe(
        (updatedTemplateVariable: TemplateVariable) => {
          this.isOperationLoading = false;
          const modal = this.modal.open(NgbModalSuccessComponent);
          modal.componentInstance.message = 'Template Variable saved.';
          modal.result.then(
            () => {
              this.router.navigate([`bam-dashboard/process/${this.processId}/template-variable/${updatedTemplateVariable.id}`]);
            }
          );
        },
        (error: HttpErrorResponse) => {
          // show error using common component.
          this.isOperationLoading = false;
          console.error('error occured while adding template variable: ', error);
          this.errorService.displayError(error);
        }
      );
      this.subscriptions.add(saveSubscription);
    }
  }

  cancel(): void {
    this.navigateToTemplateList();
  }

  private navigateToTemplateList(): void {
    const url = `bam-dashboard/process/${this.processId}/template-variable`;
    this.router.navigate([url]);
  }

  delete(): void {
    this.isOperationLoading = true;
    const deleteSubscription = this.templateVariableService.deleteTemplateVariable(this.templateVariableId).subscribe(
      () => {
        this.isOperationLoading = false;
        const successModal = this.modal.open(NgbModalSuccessComponent);
        successModal.componentInstance.message = 'Template Variable successfully deleted.';
        successModal.result.then(
          () => {
            this.navigateToTemplateList();
          }
        );
      },
      (error: HttpErrorResponse) => {
        this.isOperationLoading = false;
        console.error('Error occured while deleting template variable', error);
        this.errorService.displayError(error);
      }
    );
    this.subscriptions.add(deleteSubscription);
  }

  showError(input: RowEditEvent): void {
    if (input.value === this.ruleSuccessMessage) {
      const errorModal = this.modal.open(NgbModalSuccessComponent);
      errorModal.componentInstance.message = input.value;
    } else {
      const errorModal = this.modal.open(NgbModalErrorComponent);
      errorModal.componentInstance.message = input.value;
    }
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeComponent } from './home/home.component';
import { Routes, RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ProcessFlowComponent } from './process-flow/process-flow.component';
import { ProcessDiscoveryComponent } from './process-discovery/process-discovery.component';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime-ex';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AlertComponent } from './alert/alert.component';
import { NgxJsonViewerModule } from 'ngx-json-viewer';
import { LabelledInputComponent } from './common/form-components/labelled-input/labelled-input.component';
import { AuthRoleGuard } from '../auth-role-guard';
import { ServiceTreeComponent } from './service-tree/service-tree.component';
import { DisplayGraphComponent } from './common/display-components/display-graph/display-graph.component';
import { DisplayObjectComponent } from './common/display-components/display-object/display-object.component';
import { DisplayTableComponent } from './common/display-components/display-table/display-table.component';
import { QueryOptionComponent } from './query-option/query-option.component';
import { DisplayTabComponent } from './common/display-components/display-tab/display-tab.component';
import { PowerBIModule } from '../power-bi/power-bi.module';
import { ProcessHealthViewComponent } from './process-health-view/process-health-view.component';
import { DateRangeComponent } from './common/form-components/date-range/date-range.component';
import { DisplayProcessMapComponent } from './common/display-components/display-process-map/display-process-map.component';
import { EventSequencesComponent } from './common/event-sequences/event-sequences.component';
import { DirectQueryComponent } from './direct-query/direct-query.component';
import { RuleComponent } from './rule/rule.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TemplateVariableComponent } from './template-variable/template-variable.component';
import { TemplateVariableListComponent } from './template-variable-list/template-variable-list.component';
import { QueryDisplayModalComponent } from './query-display-modal/query-display-modal.component';
import { AdministrationComponent } from './administration/administration.component';
import { ModifyProcessComponent } from './modify-process/modify-process.component';
import { SharedModule } from '../common/shared.module';
import { NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { ModifyDataSourceComponent } from './modify-data-source/modify-data-source.component';
import { FeatureFlagGuard } from '../feature-flag-guard';
import { DisplayProcessMapV2Component } from './common/display-components/display-process-map-v2/display-process-map-v2.component';
import { PathTaggingLabelComponent } from './path-tagging-label/path-tagging-label.component';
import { PathTaggingComponent } from './path-tagging/path-tagging.component';
import { ProcessTableComponent } from './process-table/process-table.component';
import { BusinessProcessComponent } from './business-process/business-process.component';
import { SubProcessComponent } from './sub-process/sub-process.component';
import { BamAlertsComponent } from './bam-alerts/bam-alerts.component';
import { EventKpiComponent } from './event-kpi/event-kpi.component';
import { TableUpdateComponent } from './process-table/table-update/table-update.component';
import { TableEditDisplayComponent } from './process-table/table-edit-display/table-edit-display.component';
import { AddProcessModalComponent } from './process-table/add-process-modal/add-process-modal.component';


const bamDashboardRoutes: Routes = [
  {
    path: 'bam-dashboard', component: HomeComponent, canActivate: [AuthRoleGuard, FeatureFlagGuard],
    data: { featureFlag: 'bam-dashboard' }
  },
  {
    path: 'bam-dashboard/process-flow', component: ProcessFlowComponent, canActivate: [AuthRoleGuard, FeatureFlagGuard],
    data: { featureFlag: 'bam-dashboard/process-flow' }
  },
  {
    path: 'bam-dashboard/process-discovery', component: ProcessDiscoveryComponent, canActivate: [AuthRoleGuard, FeatureFlagGuard],
    data: { featureFlag: 'bam-dashboard/process-discovery' }
  },
  {
    path: 'bam-dashboard/process-table', component: ProcessTableComponent, canActivate: [AuthRoleGuard, FeatureFlagGuard],
    data: { featureFlag: 'bam-dashboard/process-table' }
  },
  {
    path: 'bam-dashboard/process-discovery/:id', component: ProcessDiscoveryComponent, canActivate: [AuthRoleGuard, FeatureFlagGuard],
    children: [
      { path: 'admin', component: ProcessDiscoveryComponent },
      { path: 'power-bi-report', component: ProcessDiscoveryComponent },
      { path: 'process-map', component: ProcessDiscoveryComponent },
      { path: 'process-map-v2', component: ProcessDiscoveryComponent },
      { path: 'sample-data', component: ProcessDiscoveryComponent },
    ],
    data: { featureFlag: 'bam-dashboard/process-discovery' }
  },
  {
    path: 'bam-dashboard/process-health', component: ProcessHealthViewComponent, canActivate: [AuthRoleGuard, FeatureFlagGuard],
    data: { featureFlag: 'bam-dashboard/process-health' }
  },
  {
    path: 'bam-dashboard/rule/:id/alert', component: AlertComponent, canActivate: [AuthRoleGuard, FeatureFlagGuard],
    data: { featureFlag: 'bam-dashboard/rule' }
  },
  {
    path: 'bam-dashboard/rule/:id', component: RuleComponent, canActivate: [AuthRoleGuard, FeatureFlagGuard],
    data: { featureFlag: 'bam-dashboard/rule' }
  },
  {
    path: 'bam-dashboard/process/:processId/template-variable', component: TemplateVariableListComponent,
    canActivate: [AuthRoleGuard, FeatureFlagGuard],
    data: { featureFlag: 'bam-dashboard/rule' }
  },
  {
    path: 'bam-dashboard/process/:processId/template-variable/:id', component: TemplateVariableComponent,
    canActivate: [AuthRoleGuard, FeatureFlagGuard],
    data: { featureFlag: 'bam-dashboard/rule' }
  },
  {
    path: 'bam-dashboard/administration/process', component: AdministrationComponent,
    canActivate: [AuthRoleGuard, FeatureFlagGuard],
    data: { featureFlag: 'bam-dashboard/admin' }
  },
  {
    path: 'bam-dashboard/administration/data-source', component: AdministrationComponent,
    canActivate: [AuthRoleGuard, FeatureFlagGuard],
    data: { featureFlag: 'bam-dashboard/admin' }
  }
];
@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        OwlDateTimeModule,
        OwlNativeDateTimeModule,
        BrowserAnimationsModule,
        NgxJsonViewerModule,
        PowerBIModule,
        NgbModule,
        SharedModule,
        NgbNavModule,
        RouterModule.forChild(bamDashboardRoutes),
    ],
    declarations: [
        HomeComponent,
        ProcessFlowComponent,
        ProcessDiscoveryComponent,
        AlertComponent,
        LabelledInputComponent,
        ServiceTreeComponent,
        DisplayGraphComponent,
        DisplayObjectComponent,
        DisplayTableComponent,
        QueryOptionComponent,
        DisplayTabComponent,
        ProcessHealthViewComponent,
        DateRangeComponent,
        DisplayProcessMapComponent,
        EventSequencesComponent,
        DirectQueryComponent,
        RuleComponent,
        TemplateVariableComponent,
        TemplateVariableListComponent,
        QueryDisplayModalComponent,
        AdministrationComponent,
        ModifyProcessComponent,
        ModifyDataSourceComponent,
        DisplayProcessMapV2Component,
        PathTaggingLabelComponent,
        PathTaggingComponent,
        BusinessProcessComponent,
        SubProcessComponent,
        BamAlertsComponent,
        EventKpiComponent,
        TableUpdateComponent,
        TableEditDisplayComponent,
        AddProcessModalComponent,
    ],
    exports: [
        ServiceTreeComponent,
    ]
})
export class BamDashboardModule { }

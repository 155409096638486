import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Observable, throwError, forkJoin, of} from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Scenario } from '../model/scenario';
import { ScenarioManualInput } from '../model/scenarioManualInput';
import { APP_CONFIG } from '../../common/constants';
import { ScenarioStatus, ScenarioPerformance } from '../model/scenarioStatus';
import { CacheService } from '../../service/cache.service';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { AppConfig } from '../../model/app-config.model';

@Injectable({
  providedIn: 'root'
})
export class ScenarioHealthService {

  private baseUrlWithVersion: string;
  private baseUrlWithVersionManualMonitoring: string;

  constructor(private httpClient: HttpClient, @Inject(APP_CONFIG) appConfig: AppConfig, private cacheService: CacheService,
    private appInsightsService: ApplicationInsights) {
    this.baseUrlWithVersion = appConfig.baseUrlScenarioHealth;
    this.baseUrlWithVersionManualMonitoring = appConfig.baseUrlManualMonitoring;
  }

  private GetMasterData(dashboardId: number): Observable<Scenario[]> {
    return this.cacheService.getCached<Scenario[]>(this.baseUrlWithVersion + "Master/" + dashboardId,
    (url) => this.httpClient.get<Scenario[]>(url).pipe(
      map(result => result.sort((x, y) => x.serialNumber < y.serialNumber ? -1 : 1)),
      catchError((x: HttpErrorResponse) => this.handleError(x))
    ));
  }

  private GetValidMasterData(dashboardId: number, validFrom: Date, validTo: Date): Observable<Scenario[]> {
    return this.httpClient.get<Scenario[]>(this.baseUrlWithVersion + "Master/" + dashboardId + "/" + validFrom.toISOString() +
                                            "/" + validTo.toISOString()).pipe(
      // fix missing serial numbers and sort
      map((result: Scenario[]) => {
        result.sort((x, y) => x.serialNumber < y.serialNumber ? -1 : 1);
        let sequence = 1;
        result.forEach((scenario: Scenario) => {
          if (scenario.serialNumber !== sequence) {
            scenario.serialNumber = sequence;
          }
          sequence += 1;
        });
        return result;
      }),
      catchError((x: HttpErrorResponse) => this.handleError(x))
    );
  }

  private GetStatusForDay(dashboardId: number, dateFrom: Date, dateTo: Date): Observable<any[]> {
    return this.httpClient.get<any[]>(this.baseUrlWithVersion + "Daily/" + dashboardId + "/" +
                                          dateFrom.toISOString() + "/" + dateTo.toISOString()).pipe(
      catchError((x: HttpErrorResponse) => {
        if (!(x.error instanceof ErrorEvent) && x.status === 404) {
          const obj = new Array();
          return of(obj);
        } else {
          return this.handleError(x);
        }}));
  }

  private GetStatusForWeek(dashboardId: number, dateFrom: Date): Observable<any[]> {
    return this.httpClient.get<any[]>(this.baseUrlWithVersion + "Weekly/" + dashboardId + "/" + dateFrom.toISOString()).pipe(
      catchError((x: HttpErrorResponse) => {
        if (!(x.error instanceof ErrorEvent) && x.status === 404) {
          const obj = new Array();
          return of(obj);
        } else {
          return this.handleError(x);
        }}));
  }
  public UpdateStatus(dailyStatus: any[]) {
    return this.httpClient.put(this.baseUrlWithVersion + "Daily", dailyStatus).pipe(
      catchError((x: HttpErrorResponse) => this.handleError(x))
    );
  }

  public UpdateWeeklyStatus(scenarioId: number, scenario: ScenarioStatus, weekStart: Date) {
    const weeklyInfo = [];
    weeklyInfo.push({
      "scenarioId": scenarioId,
      "startOfWeek": weekStart.toISOString(),
      "comments": scenario.comments,
      "icMLink": JSON.stringify(scenario.icm)
    });

    return this.httpClient.put(this.baseUrlWithVersion + "Weekly", weeklyInfo).pipe(
      catchError((x: HttpErrorResponse) => this.handleError(x))
    );
  }

  public UpdateWeeklyPerformance(scenarioId: number, scenario: ScenarioPerformance, weekStart: Date) {
    const weeklyInfo = [];
    weeklyInfo.push({
      "scenarioId": scenarioId,
      "startOfWeek": weekStart.toISOString(),
      "comments": scenario.comments,
      "automatedValue": scenario.automatedValue,
      "performance": scenario.performance ? scenario.performance : 0,
      "icMLink": JSON.stringify(scenario.icm)
    });

    return this.httpClient.put(this.baseUrlWithVersion + "Weekly", weeklyInfo).pipe(
      catchError((x: HttpErrorResponse) => this.handleError(x))
    );
  }

  public GetPerformance(dashboardId: number, dateFrom: Date, dateTo: Date): Observable<ScenarioPerformance[]> {
    dateFrom.getDatePart();
    const master = this.GetValidMasterData(dashboardId, dateFrom, dateTo);
    const statusWeek = this.GetStatusForWeek(dashboardId, dateFrom);
    return forkJoin([master, statusWeek]).pipe(map(results => {
      const statusLine = new Array<ScenarioPerformance>();
      results[0].forEach(x => {
          const line = new ScenarioPerformance();
          line.scenario = x;
          const weekStatus = results[1].filter(y => y.scenarioId === x.scenarioId);
          if (weekStatus.length > 0) {
            line.comments = weekStatus[0].comments;
            line.icm = JSON.parse(weekStatus[0].icMLink);
            line.automatedValue = weekStatus[0].automatedValue;
            line.performance = weekStatus[0].performance;
            line.updated = {by: weekStatus[0].lastModifiedBy, on: weekStatus[0].lastModifiedDate.split('.')[0] };
          }
          statusLine.push(line);
        });
        return statusLine;
      }));
  }

  public GetStatus(dashboardId: number, dateFrom: Date): Observable<ScenarioStatus[]> {
    dateFrom.getDatePart();
    const dateTo = dateFrom.clone().addDays(6);
    const master = this.GetMasterData(dashboardId);
    const status = this.GetStatusForDay(dashboardId, dateFrom, dateTo);
    const statusWeek = this.GetStatusForWeek(dashboardId, dateFrom);
    return forkJoin([master, status, statusWeek]).pipe(map(results => {
      const statusLine = new Array<ScenarioStatus>();
      results[0].forEach(x => {
          const line = new ScenarioStatus();
          line.scenario = x;
          const weekStatus = results[2].filter(y => y.scenarioId === x.scenarioId);
          if (weekStatus.length > 0) {
            line.comments = weekStatus[0].comments;
            line.icm = JSON.parse(weekStatus[0].icMLink);
          }
          const dailyStatus = results[1].filter( y => y.scenarioId === x.scenarioId);
          if (dailyStatus.length > 0) {
              dailyStatus.map(
                y => {
                  const diff = dateFrom.diffDate(new Date(y.date + 'Z').getDatePart());
                  if (diff > -1 && diff < 7) {
                    line.dailyStatus[diff] = y.status === true ? 1 : y.status === false ? 0 : null;
                    line.updated[diff] = {by: y.lastModifiedBy, on: y.lastModifiedDate.split('.')[0] };
                  }
                });
          }
          statusLine.push(line);
        });
        return statusLine;
      }));
  }

  private GetManualInputForWeek(dateFrom: Date): Observable<any[]> {
    return this.httpClient.get<any[]>(this.baseUrlWithVersionManualMonitoring + "Weekly/" + dateFrom.toISOString()).pipe(
      catchError((x) => {
        if (!(x.error instanceof ErrorEvent) && x.status === 404) {
          const obj = new Array();
          return of(obj);
        } else {
          return this.handleError(x);
        }}));
  }

  public GetManualInputStatus(dashboardId: number, dateFrom: Date): Observable<ScenarioManualInput[]> {
    dateFrom.getDatePart();
    const master = this.GetMasterData(dashboardId);
    const statusWeek = this.GetManualInputForWeek(dateFrom);
    return forkJoin([master, statusWeek]).pipe(map(results => {
      const statusLine = new Array<ScenarioManualInput>();
      results[0].forEach(x => {
          const line = new ScenarioManualInput();
          line.scenario = x;
          const weekStatus = results[1].filter(y => y.scenarioId === x.scenarioId);
          if (weekStatus.length > 0) {
            line.criCount = weekStatus[0].criCount;
            line.criTransactionCount = weekStatus[0].criTransactionCount;
            line.manualMonitoringHours = weekStatus[0].manualMonitoringHours;
          }
          statusLine.push(line);
        });
        return statusLine;
      }));
  }

  public UpdateWeeklyInput(comments: any[]) {
    return this.httpClient.put(this.baseUrlWithVersionManualMonitoring + "Weekly", comments).pipe(
      catchError((x) => {
        if (x.status === 200) {
          const obj = new Array();
          return of(obj);
        } else {
          this.handleError(x);
        }
      })
    );
  }

  public getCurrentViewingWeek(): number {
    return -1;
  }

  public getViewingWeekStartDate(viewingWeek: number): Date {
    return this.getStartDate(this.getToday().addDays(viewingWeek * 7));
  }

  public getViewingWeekEndDate(viewingWeek: number): Date {
    return this.getViewingWeekStartDate(viewingWeek).addDays(6);
  }

  private getStartDate(date: Date) {
    return date.addDays(-(date.getUTCDay() + 1) % 7);
  }

  private getToday(): Date {
    return new Date(Date.now() - 28800000).getDatePart(); // make it PST specific
  }

  public updateReportLinksToAbsoluteUrl(scenarios: ScenarioPerformance[]) {
    scenarios.map((s) => {
      if (s.scenario.reportLink) {
        s.scenario.reportLink = (s.scenario.reportLink.startsWith('http')
                                ? s.scenario.reportLink : 'http://' + s.scenario.reportLink);
      }
    });
  }

  private handleError(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred.
      errorMessage = `An error occurred: ${error.error.message}`;
      this.appInsightsService.trackException(error);
    } else if (error instanceof Error) {
      this.appInsightsService.trackException(error);
      errorMessage = error.message;
    } else {
      // The backend returned an unsuccessful response code
      errorMessage = `Server returned code: ${error.status}, error message is: ${error.message}`;
      this.appInsightsService.trackException(error);
    }
    return throwError(errorMessage);
  }
}

import { PropertyTag } from './page-configuration.model';

export class ProcessGraph {
  nodes: Array<ProcessNode>;
  edges: Array<ProcessEdge>;
  properties: Array<ProcessProperty>;
}

export class ProcessGraphInstance extends ProcessGraph {
  public instancePath: InstancePath;
}

export class ProcessGraphMap extends ProcessGraph {
  public createdOn: Date;
  public paths: Array<ProcessPath>;
}

export class ProcessNode {
  constructor(
    public id: number,
    public event: string,
    public displayName: string,
    public properties: Array<ProcessProperty>,
  ) { }
}

export class ProcessEdge {
  constructor(
    public id: number,
    public to: string,
    public from: string,
    public properties: Array<ProcessProperty>,
  ) { }
}

export class ProcessProperty {
  constructor(
    public name: string,
    public value: any,
    public propertyTags: Array<PropertyTag>
  ) { }
}

export class InstancePath {
  constructor(
    public nodeIdPath: Array<string>,
    public edgeIdPath: Array<string>,
    public nodeEventPath: Array<string>,
  ) { }
}

export class ProcessPath {
  constructor(
    public id: string,
    public nodeIdPath: Array<string>,
    public edgeIdPath: Array<string>,
    public properties: Array<ProcessProperty>,
    public nodeEventPath: Array<string>,
  ) { }
}

export class GraphModel {
  constructor(
    public nodes: Array<GraphLabelMap>,
    public edges: Array<GraphLabelMap>,
  ) { }
}

export class GraphLabelMap {
  constructor(
    public id: number,
    public label: string,
    public title?: string
  ) { }
}

export class DistributionPath {
  constructor(
    public frequency: string,
    public pathId: string,
  ) { }
}

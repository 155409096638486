<div class="px-1 pt-1" *ngIf="!isLoading">
    <div class="d-flex flex-row mb-1">
        <h6>Paths:</h6>
        <div class="text-right ml-auto">
            <button type="button" class="btn btn-sm ml-2 py-0 mt-2" (click)="handleEditPathLabels()" style="background-color: #ffd500">Edit Path
                Categories</button>
            <button type="button" class="btn btn-sm secondary-button py-0 ml-2 mt-2" [disabled]="selectedPathId == null"
                (click)="clearChart()">Clear</button>
        </div>
    </div>
    <div #barChartDiv appDivDimChange (divDimChange)="resizeChart()"
        style="height: 57vh; flex: 1; overflow-y: auto; overflow-x: hidden;" class="border-top border-dark py-1">
    </div>
</div>
<div class="ml-auto" *ngIf="isLoading">
    <span role="progressbar" class="info-text fa fa-spinner fa-spin fa-3x" aria-label="Loading"></span>
</div>
<!-- title -->
<h5 class="display-5 ml-3 mt-3">Rule Engine Configuration</h5>
<hr>

<!-- [Section - 1] Add service tree structure -->
<div class="card bg-transparent mx-2">
  <div class="card-header">
    <div class="border border-dark rounded ml-1 mt-2" id="Treeview">
      <!-- Treeview -->
      <app-tree-view [loadProcess]="true" (valueChange)="processIdEventHandler($event)"></app-tree-view>
    </div>
  </div>
</div>


<!-- [Section - 2] Rule table and buttons, search -->
<div *ngIf="loadRuleTable" class="d-flex flex-column">
  <div *ngIf="isProcessSelected && isRuleListLoaded" class="card bg-transparent mx-2">
    <div class="card-header">
      <div class="d-flex flex-rows">
        <!-- Process Template Variables Button -->
        <button class="btn btn-sm primary-button ml-1" aria-label="Process Template Variables" type="button"
          (click)="navigateToTemplateVariableList()" [disabled]="!isProcessSelected">
          Process Template Variables
        </button>
        <!-- Add New Rule Button -->
        <button class="btn btn-sm primary-button border border-secondary ml-2" type="button" id="addNewRuleButton"
          (click)="addNewRule('New')" style="width: 10rem;" [disabled]="!isRuleListLoaded">
          Add New Rule
        </button>
        <!-- Search by Rule Name or description -->
        <div class="ml-auto search">
          <span class="fa fa-search"></span>
          <input type="text" [formControl]="search" placeholder="Search by Name/Description" style="width: 20rem;"
            aria-label="Search Rule">
        </div>
      </div>
    </div>
  </div>
  <br />
  <!-- Add loader if rule list is not loaded-->
  <div *ngIf="isProcessSelected && !isRuleListLoaded" class="text-center mt-5">
    <span class="fa fa-spinner fa-spin fa-2x" style="font-size:75px;" aria-label="Loading"></span>
  </div>
  <!-- Display table with all rules -->
  <div *ngIf="isProcessSelected && isRuleListLoaded" class="mx-2 mt-2">
    <app-display-table-v2 [tableInput]="tableData.asObservable()" [tableOptions]="tableOptions" [searchTable]="tableSearch.asObservable()" 
      (rowEditEvent)="editRow($event)">
    </app-display-table-v2>
  </div>
</div>

<!-- Rule Area -->
<div *ngIf="!loadRuleTable" class="d-flex flex-column">
  <div class="card bg-transparent mx-2">
    <!-- [Section - 3] Template button and Last Modifed rule info -->
    <div class="card-header">
      <div class="d-flex flex-rows">
        <!-- Process Template Variables Button -->
        <button class="btn btn-sm primary-button ml-1" aria-label="Process Template Variables" type="button"
          (click)="navigateToTemplateVariableList()" [disabled]="!isProcessSelected">
          Process Template Variables
        </button>
        <div class="ml-auto">
          <!-- Rule Last Modified Date and User -->
          <div style="float:right;" *ngIf="!isNewRule" tabindex="0">
            <h6 class="display-text">
              <small>
                <span>Last modified by {{ ruleConfig.updatedBy }} on
                  {{ ruleConfig.validFrom | date: 'MMM d, y HH:mm' }}
                </span>
              </small>
            </h6>
          </div>
        </div>
        <!-- button toggle to select  -->
        <!-- <div *ngIf="isNewRule" class="btn-group ml-auto" role="group">
          <button type="button" class="btn btn-sm" style="height:2rem;"
            [ngClass]="{'primary-button': queryToggle !== 'Rule Engine', 'btn-secondary': queryToggle === 'Rule Engine'}"
            (click)="toggleSelector('button2')">
            Direct Query
          </button>
          <button type="button" class="btn btn-sm" style="height:2rem;" [disabled]="true"
            [ngClass]="{'primary-button': queryToggle === 'Rule Engine', 'btn-secondary': queryToggle !== 'Rule Engine'}"
            (click)="toggleSelector('button1')">
            Rule Engine
          </button>
        </div> -->
      </div>
    </div>

    <div *ngIf="isProcessSelected">
      <div class="card-body p-0 d-flex flex-column">

        <!-- [Section - 4] 1-Direct Query Component ; 2-[In-future] Rule-Config component (Rule Engine)  -->
        <div *ngIf="hasDirectQuery" class="pl-1 ml-3 pt-3">
          <app-direct-query [ruleConfigSet]="ruleConfig" [ruleNames]="ruleNames" [(isValid)]="isValidChildForm" [groupId]="groupId"></app-direct-query>
        </div>

        <!-- [Section - 5] Action Buttons: Save, Reset, Delete, Add/Edit Alert -->
        <div class="d-flex flex-row my-2 mx-auto">
          <div *ngIf="isSaveInProgress" class="ml-auto">
            <span class="text-info fa fa-spinner fa-spin fa-2x mr-3" aria-label="Processing"></span>
          </div>

          <button class="btn btn-sm secondary-button mr-3" type="button" [disabled]="isNewRule" (click)="resetRule()">
            <span>Reset Rule</span>
          </button>

          <button class="btn btn-sm primary-button mr-3" type="button" (click)="saveRule()"
             [disabled]="!isValidChildForm">
            <span>Save Rule</span>
          </button>

          <button type="button" class="btn btn-sm danger-button mr-3" data-toggle="modal" [disabled]="isNewRule"
            (click)="deleteRule()">
            <span>Delete Rule</span>
          </button>

          <div *ngIf="isRuleSaved || !isNewRule" class="btn-group ml-auto" role="group">
            <button class="btn btn-sm secondary-button mr-3" type="button" (click)="addEditAlert()">
              <span>Add/Edit Alert</span>
            </button>
          </div>
        </div>
      </div>

      <!-- [Section - 6] Execute Query Button ; Cancel Execution Button -->
      <div class="card-header text-black">
        <div class="d-flex flex-row justify-content-center">
          <div class="mr-3 mt-1">
            <button class="btn btn-sm primary-button" type="button" [disabled]="disableExecute" (click)="executeRule()">
              <span>Execute Rule</span>
            </button>
          </div>
          <div class="mr-3 mt-1">
            <button class="btn btn-sm danger-button" type="button" [disabled]="disableCancel"
              (click)="cancelExecuteRule()">
              <span>Cancel Execution</span>
            </button>
          </div>
          <div *ngIf="isExecuteInProgress" class="mr-3 mt-2">
            <span class="text-info fa fa-spinner fa-spin fa-2x" aria-label="Processing"></span>
          </div>
        </div>
      </div>

      <!-- [Section - 7] Display Execution Result -->
      <div *ngIf="hasResults">
        <div class="card-header">
          <div class="d-flex flex-rows">
            <h5><span class="mt-2 mr-2" style="color:black;" aria-label="Rule Execution Result" tabindex="0">Results
              </span></h5>
            <button *ngIf="!hasError" class="btn btn-sm btn-outline-primary secondary-button ml-auto" type="button"
              (click)="exportToCSV()">
              <span>Export CSV</span>
            </button>
          </div>
          <ng-container *ngIf="hasError">
            <p style="background-color:white;" [innerHTML]="resultErrorMsg"></p>
          </ng-container>
          <ng-container *ngIf="!hasError">
            <div class="vertical-scroll" role="table">
              <table class="table table-bordered table-striped" style="width:100%;word-wrap:break-word;height:45vh;">
                <thead class="thead-dark">
                  <tr tabindex="0">
                    <th scope="col" class="text-capitalize" *ngFor="let col of columns">{{col.label}}</th>
                  </tr>
                </thead>
                <tbody style="font-size:smaller;">
                  <tr *ngFor="let row of rows">
                    <td *ngFor="let col of columns">
                      {{row[col.fieldNm]}}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>

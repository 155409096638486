<div class="modal-header">
  <h4 class="modal-title">Edit/Insert Row Data</h4>
  <button type="button" class="close" aria-label="Close" (click)="closeModal()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <div *ngFor="let field of formFields">
    <label>{{ field.label }}:</label>
    <input [(ngModel)]="rowData[field.name]" [type]="field.type" class="form-control" />
  </div>
</div>

<div class="modal-footer">
  <button type="button" class="btn btn-secondary" (click)="closeModal()">Close</button>
  <button type="button" class="btn btn-primary" (click)="handleUpdate()">Update</button>
</div>

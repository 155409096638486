<div class="modal-header">
  <ng-container *ngIf="!isUpdate">
    <h4 class="modal-title">Add New Subscription</h4>
  </ng-container>
  <ng-container *ngIf="isUpdate">
    <h4 class="modal-title">Update Subscription</h4>
  </ng-container>
  <div>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="cancel()"
      [disabled]="isLoadingMasterData"
    >
      <span aria-hidden="true">&times;</span>
    </button>
    <a
      class="close"
      href="https://aka.ms/SCEventBrokerSubscriptionOnboarding"
      target="_blank"
      rel="noreferrer noopener"
      aria-label="SCEventBroker Subscription Onboarding wiki"
    >
      <i class="fa fa-question-circle-o"></i>
    </a>
  </div>
</div>

<div class="modal-body">
  <div *ngIf="isLoadingMasterData || isLoadingParentEvent" class="ml-auto">
    <span
      role="progressbar"
      class="info-text fa fa-spinner fa-spin fa-3x"
      aria-label="Loading"
    ></span>
  </div>
  <ng-container *ngIf="!isLoadingMasterData && !isLoadingParentEvent">
    <h6>Details</h6>
    <form [formGroup]="subscriptionForm">
      <!--Event Name-->
      <div class="input-group input-group-sm mb-2">
        <div class="input-group-prepend">
          <label
            class="input-group-text"
            id="eventNameLabel"
            style="width: 9rem"
          >
            Event Name
          </label>
        </div>
        <input
          type="text"
          formControlName="eventName"
          class="form-control required"
          aria-label="Event Name"
          aria-describedby="eventNameLabel"
        />
      </div>
      <!--Subscription Name-->
      <div class="input-group input-group-sm mb-2">
        <div class="input-group-prepend">
          <label
            class="input-group-text"
            id="subscriptionNameLabel"
            style="width: 9rem"
          >
            Subscription Name
            <span class="danger-text ml-1" aria-label="Required Field">*</span>
          </label>
        </div>
        <input
          type="text"
          formControlName="name"
          class="form-control required"
          aria-label="Subscription Name"
          aria-describedby="subscriptionNameLabel"
        />
      </div>
      <div
        *ngIf="shouldShowValidationError(subscriptionForm.controls.name)"
        class="mb-3"
      >
        <small class="danger-text"
          >Subscription Name must be PascalCase format with alphanumeric
          characters or periods.</small
        >
      </div>
      <!--Description-->
      <div class="input-group input-group-sm mb-2">
        <div class="input-group-prepend">
          <label
            class="input-group-text"
            id="descriptionLabel"
            style="width: 9rem"
          >
            Description
            <span class="danger-text ml-1" aria-label="Required Field">*</span>
          </label>
        </div>
        <textarea
          type="text"
          formControlName="description"
          class="form-control required"
          aria-label="Description"
          aria-describedby="descriptionLabel"
        >
        </textarea>
      </div>
      <!--Contact Alias-->
      <div class="input-group input-group-sm mb-2">
        <div class="input-group-prepend">
          <label
            class="input-group-text"
            id="contactAliasLabel"
            style="width: 9rem"
          >
            Contact Alias
            <span class="danger-text ml-1" aria-label="Required Field">*</span>
            <span
              class="fa fa-info-circle ml-auto"
              ngbPopover="For notifications or inquries"
              popoverTitle="Contact Alias"
              container="body"
            ></span>
          </label>
        </div>
        <input
          type="text"
          formControlName="contact"
          class="form-control required"
          aria-label="contactAlias"
          aria-describedby="contactAliasLabel"
        />
      </div>
      <div
        *ngIf="shouldShowValidationError(subscriptionForm.controls.contact)"
        class="mb-3"
      >
        <small class="danger-text"
          >Contact alias must be provided with domain
          (alias@microsoft.com)</small
        >
      </div>
      <!--Owner SG-->
      <div class="input-group input-group-sm mb-2">
        <div class="input-group-prepend">
          <label
            class="input-group-text"
            id="ownerGroupAliasLabel"
            style="width: 9rem"
          >
            Admin SG
            <span class="danger-text ml-1" aria-label="Required Field">*</span>
            <span
              class="fa fa-info-circle ml-auto"
              ngbPopover="For edit/delete access to this subscription"
              popoverTitle="Admin SG"
              container="body"
            ></span>
          </label>
        </div>
        <input
          type="text"
          formControlName="ownerGroupAlias"
          class="form-control required"
          aria-label="ownerGroupAlias"
          aria-describedby="ownerGroupAliasLabel"
        />
      </div>
      <div
        *ngIf="
          shouldShowValidationError(subscriptionForm.controls.ownerGroupAlias)
        "
        class="mb-3"
      >
        <small class="danger-text"
          >Admin SG must be provided with domain (sg@microsoft.com)</small
        >
      </div>
      <!--Team-->
      <div class="input-group input-group-sm mb-2">
        <div class="input-group-prepend">
          <label class="input-group-text" id="teamLabel" style="width: 9rem">
            Team
            <span class="danger-text ml-1" aria-label="Required Field">*</span>
            <span
              class="fa fa-info-circle ml-auto"
              ngbPopover="Service Tree Team Name"
              popoverTitle="Team"
              container="body"
            ></span>
          </label>
        </div>
        <input
          type="text"
          formControlName="team"
          class="form-control required"
          aria-label="team"
          aria-describedby="teamLabel"
        />
      </div>
      <div *ngIf="subscriptionForm.controls.team.invalid && subscriptionForm.controls.team.touched && subscriptionForm.controls.team.dirty" class="mb-3">
        <ng-container *ngIf="subscriptionForm.controls.team?.errors.pattern">
          <small class="danger-text">Team name should be alphanumeric without any spaces.</small>
        </ng-container>
      </div>
      <!--Service-->
      <div class="input-group input-group-sm mb-2">
        <div class="input-group-prepend">
          <label class="input-group-text" id="serviceLabel" style="width: 9rem">
            Service
            <span class="danger-text ml-1" aria-label="Required Field">*</span>
            <span
              class="fa fa-info-circle ml-auto"
              ngbPopover="Service Tree Service Name"
              popoverTitle="Service"
              container="body"
            ></span>
          </label>
        </div>
        <input
          type="text"
          formControlName="service"
          class="form-control required"
          aria-label="service"
          aria-describedby="serviceLabel"
        />
      </div>
      <div *ngIf="subscriptionForm.controls.service.invalid && subscriptionForm.controls.service.touched && subscriptionForm.controls.service.dirty" class="mb-3">
        <ng-container *ngIf="subscriptionForm.controls.service?.errors.pattern">
          <small class="danger-text">Service name should be alphanumeric without any spaces.</small>
        </ng-container>
      </div>
      <!--ServiceId-->
      <div class="input-group input-group-sm mb-2">
        <div class="input-group-prepend">
          <label
            class="input-group-text"
            id="serviceIdLabel"
            style="width: 9rem"
          >
            Service GUID
            <span class="danger-text ml-1" aria-label="Required Field">*</span>
            <span
              class="fa fa-info-circle ml-auto"
              ngbPopover="Service Tree Service GUID"
              popoverTitle="Service GUID"
              container="body"
            ></span>
          </label>
        </div>
        <input
          type="text"
          formControlName="serviceId"
          class="form-control required"
          aria-label="serviceId"
          aria-describedby="serviceIdLabel"
        />
      </div>
      <div
        *ngIf="shouldShowValidationError(subscriptionForm.controls.serviceId)"
        class="mb-3"
      >
        <small class="danger-text">ServiceId must be a valid GUID.</small>
      </div>
      <!--Filters-->
      <div class="input-group input-group-sm mb-2">
        <div class="input-group-prepend">
          <label class="input-group-text" id="filterLabel" style="width: 9rem">
            Filters
            <ng-template #popContent
              >Provide Event Grid advanced filters JSON. Visit the
              <a
                href="https://aka.ms/SCEventBrokerSubscriptionOnboarding"
                target="_blank"
                rel="noreferrer noopener"
              >
                subscription onboarding wiki</a
              >
              for more information.
            </ng-template>
            <span
              class="fa fa-info-circle ml-auto"
              [ngbPopover]="popContent"
              popoverTitle="Filters"
              container="body"
            ></span>
          </label>
        </div>
        <textarea
          type="text"
          formControlName="filters"
          class="form-control required"
          aria-label="Filters"
          aria-describedby="filterLabel"
        >
        </textarea>
      </div>
      <div
        *ngIf="shouldShowValidationError(subscriptionForm.controls.filters)"
        class="mb-3"
      >
        <small class="danger-text">Filters must be valid JSON.</small>
      </div>

      <!--Delivery Failure Alerts-->
      <h6>Delivery Failure Alerts</h6>
      <div class="d-flex flex-row">
        <div class="input-group input-group-sm mb-1" style="max-width: 30rem">
          <div class="custom-control custom-switch">
            <input
              type="checkbox"
              class="custom-control-input"
              id="customSwitch1"
              formControlName="enableAlerts"
            />
            <label class="custom-control-label" for="customSwitch1"
              >Enable Email Alerts to Contact Alias
            </label>
          </div>
          <span
            class="fa fa-info-circle my-1 ml-1"
            ngbPopover="Enable this field to receive Email Alerts for events that failed to deliver"
          >
          </span>
        </div>
      </div>
      <div class="d-flex flex-row input-group input-group-sm mb-1">
        <div class="input-group-prepend">
          <span
            class="input-group-text"
            id="label-recur"
            style="width: 17rem"
            for="customSwitch1"
          >
            Minimum Delay Between Alerts
            <span
              class="fa fa-info-circle ml-2"
              ngbPopover="Minimum Delay Between Alerts to go back and check for duplicate records in alert"
            >
            </span>
          </span>
        </div>
        <input
          type="number"
          min="0"
          class="form-control form-control-sm"
          formControlName="minimumDelayBetweenAlerts"
          maxlength="80"
          for="customSwitch1"
          style="max-width: 13rem"
          title="Time Value"
          aria-label="Time Value"
        />

        <select
          class="form-control form-control-sm"
          formControlName="minimumDelayBetweenAlertsUnit"
          title="Time Unit"
          for="customSwitch1"
          aria-label="Time Unit"
          style="max-width: 13rem"
        >
          <option value="Hour">Hour</option>
        </select>
      </div>
      <div class="mb-2">
        <small
          *ngIf="
            subscriptionForm.errors?.isMinimumDelayError === true &&
            (subscriptionForm.controls['minimumDelayBetweenAlerts'].touched ||
              subscriptionForm.controls['minimumDelayBetweenAlertsUnit']
                .touched)
          "
          class="alert-text"
          style="margin-left: 16rem"
          aria-live="assertive"
        >
          {{ subscriptionForm.errors?.minimumDelayError }}
        </small>
      </div>

      <!--Delivery Failure IcM-->
      <h6>Delivery Failure IcM</h6>
      <div class="d-flex flex-row">
        <div class="input-group input-group-sm mb-3" style="max-width: 12rem">
          <div class="custom-control custom-switch">
            <input
              type="checkbox"
              class="custom-control-input"
              id="customSwitch2"
              formControlName="enableIcM"
            />
            <label class="custom-control-label" for="customSwitch2"
              >Enable IcM</label
            >
          </div>
        </div>
      </div>
      <div class="d-flex flex-row input-group input-group-sm mb-2">
        <div class="input-group-prepend">
          <ng-template #icmPopContent>
            <div>
              To direct alerts to the appropriate IcM team, provide a valid IcM
              Team Public Id ("SERVICE\TEAM") or select from the list of
              previously used ids. To find your IcM Team Public Id, visit
              <a
                class="link-text ml-2"
                href="{{ icmTeamPublicIdLookupUrl }}"
                target="_blank"
                aria-label="IcM Team Public Id Lookup Link"
                >IcM Team Public Id Lookup</a
              >
            </div>
          </ng-template>
          <ng-template #icmPopTitle><b>IcM Team Public Id</b></ng-template>
          <span
            class="input-group-text"
            id="icm-label-description"
            style="width: 11rem"
            >IcM Team Public Id:
            <span
              class="fa fa-info-circle ml-2 mr-1"
              [ngbPopover]="icmPopContent"
              [popoverTitle]="icmPopTitle"
              container="body"
            >
            </span>
            :</span
          >
        </div>
        <input
          type="text"
          class="form-control form-control-sm required"
          id="icm-routingid"
          formControlName="icmRoutingId"
          maxlength="80"
          style="max-width: 26rem"
          title="IcM Team Public Id (type or select previously used id)"
          aria-label="IcM Team Public Id (type or select previously used id)"
          placeholder="Type in IcM Team Public Id"
        />
        <div class="mb-2">
          <small
            *ngIf="
              subscriptionForm.errors?.isIcmSelectError === true &&
              subscriptionForm.controls['icmRoutingId'].touched
            "
            class="alert-text"
            style="margin-left: 13rem"
            aria-live="assertive"
          >
            {{ subscriptionForm.errors?.IcmSelectError }}
          </small>
        </div>
      </div>
      <!-- Default Severity -->
      <div class="d-flex flex-row input-group input-group-sm mb-2">
        <div class="input-group-prepend">
          <span
            class="input-group-text"
            id="label-severity"
            style="width: 11rem"
            >Default Severity:</span
          >
        </div>
        <select
          class="form-control form-control-sm required"
          formControlName="severity"
          style="max-width: 13rem"
          title="Severity"
          aria-label="Severity"
        >
          <option value="1">1</option>
          <option value="2">2</option>
          <option value="3">3</option>
          <option value="4">4</option>
        </select>
      </div>
      <div class="mb-2">
        <small
          *ngIf="subscriptionForm.errors?.isAlertDisabledError === true"
          class="alert-text"
          aria-live="assertive"
        >
          {{ subscriptionForm.errors?.AlertDisabledError }}
        </small>
      </div>

      <!--Event Handler-->
      <h6>Event Handler</h6>
      <div class="input-group input-group-sm mb-2">
        <div class="input-group-prepend">
          <label
            class="input-group-text"
            id="eventHandlerTypeLabel"
            style="width: 9rem"
          >
            Type
            <span class="danger-text ml-1" aria-label="Required Field">*</span>
          </label>
        </div>
        <select
          class="custom-select-sm form-control required"
          id="eventHandlerType"
          style="width: 20rem"
          aria-label="Event Handler Type"
          formControlName="eventHandlerType"
        >
          <option
            *ngFor="let eh of masterData.eventHandler"
            [ngValue]="eh.ehType"
          >
            {{ eh.ehType }}
          </option>
        </select>
      </div>
      <div
        *ngIf="
          shouldShowValidationError(subscriptionForm.controls.eventHandlerType)
        "
        class="mb-3"
      >
        <small class="danger-text"
          >Self-serve onboarding with
          {{ subscriptionForm.controls.eventHandlerType.value }} event handler
          type is not supported yet. Please reach out to scebadmin@microsoft.com
          for assistance.</small
        >
      </div>

      <div formGroupName="eventHandlerParams">
        <div
          *ngFor="
            let p of getFormGroupKeys(
              subscriptionForm.get('eventHandlerParams')
            )
          "
        >
          <div class="input-group input-group-sm mb-2">
            <div class="input-group-prepend">
              <label
                class="input-group-text"
                [id]="'eventHandler'+ p + 'Label'"
                style="width: 9rem"
                [for]="'Event Handler' + p"
              >
                {{ p }}
                <span class="danger-text ml-1" aria-label="Required Field"
                  >*</span
                >
              </label>
            </div>
            <input
              type="text"
              formControlName="{{ p }}"
              class="form-control required"
              [id]="'Event Handler' + p"
              [aria-label]="'Event Handler' + p"
              [aria-labelledby]="'eventHandler'+ p +'Label'"
            />
          </div>
        </div>
      </div>
    </form>
    <div
      class="d-flex flex-column"
      *ngIf="
        !subscriptionForm.controls.eventHandlerType.pristine &&
        !subscriptionForm.controls.eventHandlerType.invalid
      "
    >
      <div class="ml-auto">
        <ng-template #validateEventHandlerWiki
          >Validate Event Handler before creating/updating subscriptions. View
          more details at:
          <a
            class="link-text"
            href="https://aka.ms/SCEventBrokerHandlerValidation"
            target="_blank"
            rel="noreferrer noopener"
          >
            Event Handler Validation.
            <span class="fa fa-link" aria-hidden="true"></span>
          </a>
          Message may display corrective steps to fix event handler validation
          errors.
        </ng-template>
        <span
          class="fa fa-info-circle"
          [ngbPopover]="validateEventHandlerWiki"
          popoverTitle="Filters"
          container="body"
        ></span>
      </div>
      <div class="ml-auto">
        <ng-container *ngIf="isValidating" class="mr-2">
          <span
            role="progressbar"
            class="info-text fa fa-spinner fa-spin fa-2x mr-1"
            aria-label="validating"
          ></span>
        </ng-container>
        <button
          type="button"
          class="btn primary-button"
          aria-label="Validate Event Handler"
          [disabled]="subscriptionForm.controls?.eventHandlerParams?.invalid"
          (click)="validateEventHandler()"
        >
          Validate Event Handler
        </button>
      </div>
      <div *ngIf="eventHandlerValidationError.length != 0" class="mt-1">
        <textarea readonly style="width: 100%" rows="5">{{
          eventHandlerValidationError
        }}</textarea>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="showError">
    <small class="danger-text"> {{ errorMessage }} </small>
  </ng-container>
</div>

<div class="modal-footer">
  <div *ngIf="isSaving" class="ml-2">
    <span
      role="progressbar"
      class="info-text fa fa-spinner fa-spin fa-2x"
      aria-label="saving"
    ></span>
  </div>
  <div *ngIf="isLoadingMasterData" class="ml-2">
    <span
      role="progressbar"
      class="info-text fa fa-spinner fa-spin fa-2x"
      aria-label="loading"
    ></span>
  </div>
  <ng-container *ngIf="!isUpdate">
    <button
      type="button"
      class="btn primary-button"
      aria-label="Save"
      (click)="saveSubscription()"
      [disabled]="isLoading || !subscriptionForm.valid"
    >
      Save
    </button>
  </ng-container>
  <ng-container *ngIf="isUpdate">
    <button
      type="button"
      class="btn primary-button"
      aria-label="Update"
      (click)="saveSubscription()"
      [disabled]="isLoading || !subscriptionForm.valid"
    >
      Update
    </button>
  </ng-container>
  <button
    type="button"
    class="btn secondary-button"
    aria-label="Cancel"
    (click)="cancel()"
    [disabled]="isLoadingMasterData"
  >
    Cancel
  </button>
</div>

<!-- title -->
<h5 class="display-5 ml-3 mt-3">BAM Process Flow </h5>
<hr>
<!-- Business Process selection block. -->
<div class="d-flex flex-column border border-dark mx-1 pb-1 px-2">
  <!-- business process title. -->
  <div class="d-flex flex-row ml-3 mt-1">
    <h6>Business Process Query </h6>
    <div *ngIf="isBusinessProcessCollapsed" class="ml-auto py-0">
      <button class="btn btn p-0 mb-1" data-toggle="collapse" data-target="#buisnessProcessDetails"
        aria-label="Open Business Process View" title="Open Business Process View"
        (click)="toggleBusinessProcessView()">
        <span class="fa fa-angle-double-down"></span>
      </button>
    </div>
  </div>

  <div class="collapse show" id="buisnessProcessDetails">
    <app-service-tree (businessProcessIdEvent)="processIdEventHandler($event)"
      (resetControlsEvent)="removeRequiredControls($event)" [trackServiceTree]=null>
    </app-service-tree>

    <!-- error message -->
    <div *ngIf="displayError === true">
      <small class="danger-text">{{ errorMessage }}</small>
    </div>
    <form [formGroup]="requiredControls" (ngSubmit)="processResults()">
      <!-- required values block. -->
      <div *ngIf="isProcessSelected" class="d-flex flex-column">
        <!-- block title. -->
        <div class="d-flex flex-row ml-3 mt-2">
          <h6>Required Values</h6>
        </div>

        <!-- load required filter values. -->
        <div formArrayName="conditionSets">
          <div
            *ngFor="let conditionSet of requiredControls.controls.conditionSets.controls; let conditionSetIndex=index">
            <app-labelled-input [formGroupIndex]="conditionSetIndex"
              [instanceFilter]="requiredControlsLabel[conditionSetIndex]"></app-labelled-input>
          </div>
        </div>

        <!-- process query button. -->
        <div class="d-flex flex-row mb-1">
          <button class="btn btn-sm primary-button" type="submit" [disabled]="!requiredControls.valid">Process</button>
          <button class="btn btn-sm danger-button ml-2" type="button" [disabled]="!isRuleLoading"
            (click)="cancelExecution()">Cancel</button>
          <div *ngIf="isRuleLoading === true">
            <span class="ml-2 text-primary fa fa-spinner fa-spin fa-2x" aria-label="Processing"></span>
          </div>
          <!-- toggle up button. -->
          <div *ngIf="!isBusinessProcessCollapsed" class="ml-auto p-0">
            <button class="btn p-0" data-toggle="collapse" data-target="#buisnessProcessDetails"
              aria-label="Collapse Business Process View" title="Collapse Business Process View"
              (click)="toggleBusinessProcessView()">
              <span class="fa fa-angle-double-up"></span>
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>

<!-- title bar for Business Process Query. -->
<div class="d-flex flex-row border-top border-right border-left border-dark mx-1 mt-1 px-2">
  <div class="ml-3 mt-1">
    <h6>Business Process Flow</h6>
  </div>
  <div class="custom-control custom-switch mt-1 ml-auto">
    <input type="checkbox" class="custom-control-input" id="customSwitch1" [formControl]="refreshDataControl"
      role="switch" aria-checked="mixed" [attr.aria-label]="currentPathDescription">
    <label class="custom-control-label" for="customSwitch1">Show Current Path</label>
  </div>
</div>
<!-- Business Process Graph. -->
<div class="d-flex flex-row" [ngClass]="isBusinessProcessCollapsed ? 'networkPaneExpanded': 'networkPaneCollapsed'"
  [attr.aria-label]="graphDescription" tabindex="0">
  <!-- network div. -->
  <div class="border border-dark mx-1" id="processFlowGraph" style="width: 65vw; flex: 1;">
  </div>
</div>

<!-- tab component -->
<app-display-tab [urlTabParameter]=null></app-display-tab>

import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { APP_CONFIG } from '../../common/constants';
import { AppConfig } from '../../model/app-config.model';
import { ODataResponse } from '../../model/common.model';

@Injectable({
  providedIn: 'root'
})
export class MasterDataService {

  private baseUrlWithVersion;

  constructor(private httpClient: HttpClient, @Inject(APP_CONFIG) appConfig: AppConfig) {
    this.baseUrlWithVersion = appConfig.baseUrlWithVersion;
  }

  getComboBoxData(): Observable<any> {
    return this.httpClient.get<ODataResponse<Array<{name: string, value: string}>>>(this.baseUrlWithVersion +
      "MasterData?$filter=GroupName eq 'CreateScreen' and SubGroupName  eq 'ComboBox'&$select=name,value").pipe(
      catchError((x: HttpErrorResponse) => this.handleError(x)), map(res => {
        return res.value.reduce((rv: {[key: string]: any}, x) => {
          const rvKey: (keyof typeof rv) = x.name;
          (rv[rvKey] = rv[rvKey] || []).push(x.value);
          return rv;
        }, {});
      })
    );
  }

  private handleError(error: HttpErrorResponse) {
    let errorMessage = '';
    let statusCode = 0;
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred.
      errorMessage = `An error occurred: ${error.error.message}`;
    } else {
      // The backend returned an unsuccessful response code
      errorMessage = `Server returned code: ${error.status}, error message is: ${error.message}`;
      statusCode = error.status;
    }
    console.error(errorMessage);
    return throwError({ errorMessage, statusCode});
  }
}

import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ServiceTreeNode } from '../../model/servicetreenode.model';
import { ServiceTreeService } from '../../service/service-tree.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-service-tree',
  templateUrl: './service-tree.component.html',
  styleUrls: ['./service-tree.component.css']
})
export class ServiceTreeComponent implements OnInit {

  @Input() nodes: ServiceTreeNode[];

  constructor(private service: ServiceTreeService) {
   }

  @Output() nodeSelected = new EventEmitter<ServiceTreeNode>();

  expand(node: ServiceTreeNode) {
    if (node.isChildrensLoaded) {
      node.isExpanded = !node.isExpanded;
    } else {
      this.service.getNodes(node.type, node.id).subscribe((data) => {
        node.childrens = data;
        node.isExpanded = true;
        node.isChildrensLoaded = true;
      });
    }
  }

  nodeSelecetedChild(event: any) {
    this.nodeSelected.emit(event);
  }

  nodeClicked(node: ServiceTreeNode) {
    this.nodeSelected.emit(node);
  }

  keyPressExpand(event: any, node: ServiceTreeNode) {
    if(event.code === "Space" || event.code === "Enter") {
      this.expand(node);
    }
  }

  keyPressNode(event: any, node: ServiceTreeNode) {
    if(event.code === "Space" || event.code === "Enter") {
      this.nodeClicked(node);
    }
  }

  ngOnInit() {
  }
}

@Component({
  selector: 'app-service-tree-modal',
  template: `<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">Service Tree</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <app-service-tree  [nodes]=nodes (nodeSelected)="activeModal.close($event)"></app-service-tree>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-outline-dark" (click)="activeModal.dismiss('Close click')">Close</button>
</div>`
})
export class ServiceTreeModalComponent {
  nodes: ServiceTreeNode[];
  constructor(public activeModal: NgbActiveModal, private serviceTreeService: ServiceTreeService) {
    this.serviceTreeService.getNodes("root", "").subscribe(
      (data) => {
      this.nodes = data;
    });
  }
}

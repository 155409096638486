export class ServiceTreeNode {
    id: string;
    name: string;
    isExpanded = false;
    type: string;
    childrens: ServiceTreeNode[];
    isChildrensLoaded = false;

    public constructor(init?: Partial<ServiceTreeNode>) {
        Object.assign(this, init);
    }
    haveChildren(): boolean {
        return (this.childrens !== undefined && this.childrens.length > 0);
    }
    getIconClass(): string {
        if ((this.childrens === undefined || this.childrens.length === 0) && this.isChildrensLoaded) {
            return "fa-blank";
        } else if (this.isExpanded) {
            return "fa-minus";
        } else {
            return "fa-plus";
        }
    }
  }

import { HttpClient, HttpHeaders, HttpErrorResponse } from "@angular/common/http";
import { Inject, Injectable } from '@angular/core';
import { Dashboard } from "../model/dashboard.model";
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { APP_CONFIG } from "../../common/constants";
import { ODataResponse } from '../../model/common.model';
import { AppConfig } from '../../model/app-config.model';

@Injectable({
  providedIn: 'root'
})

export class CatalogService {

  private baseUrlWithVersion;
  constructor(private httpClient: HttpClient, private aiService: ApplicationInsights, @Inject(APP_CONFIG) appConfig: AppConfig) {
    this.baseUrlWithVersion = appConfig.baseUrlWithVersion;
  }

  addDashboard(dashboard: Dashboard) {
      return this.httpClient.post<Dashboard>(this.baseUrlWithVersion + "Catalog", dashboard, { observe: 'response' }).pipe(
      catchError((x: HttpErrorResponse) => this.handleError(x))
    );
  }

  getCatalog(): Observable<Dashboard[]> {
    return this.httpClient.get<ODataResponse<Dashboard[]>>(this.baseUrlWithVersion + "Catalog").pipe(
      catchError((x: HttpErrorResponse) => this.handleError(x)), map(res => {
        return res["value"];
      })
    );
  }

  getCatalogBySearch(searchText: string): Observable<Dashboard[]> {
    // constructing the filter string using OData Query Options.
    const filterCatalogString = "Catalog?$filter=indexof(tolower(name),'" + searchText + "') gt -1 " +
      "OR " +
      "indexof(tolower(purpose),'" + searchText + "') gt -1";

    return this.httpClient.get<ODataResponse<Dashboard[]>>(this.baseUrlWithVersion + filterCatalogString).pipe(
        catchError((x: HttpErrorResponse) => this.handleError(x)), map(res => {
        return res["value"];
      })
    );
  }

  getDashboardById(id: number): Observable<Dashboard> {
    return this.httpClient.get<Dashboard>(this.baseUrlWithVersion + "Catalog(" + id + ")").pipe(
      catchError((x) => this.handleError(x))
    );
  }

  updateDashboard(dashboard: Dashboard) {
    return this.httpClient.put(this.baseUrlWithVersion + "Catalog(" + dashboard.id + ")", dashboard, { observe: 'response' });
  }

  deleteDashboard(dashboard: Dashboard) {
    return this.httpClient.delete(this.baseUrlWithVersion + "Catalog(" + dashboard.id + ")", { observe: 'response' });
  }

  handleError(error: HttpErrorResponse) {
    let errorMessage = '';
    let statusCode = 0;
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred.
      errorMessage = `An error occurred: ${error.error.message}`;
      this.aiService.trackException(error);
    } else {
      // The backend returned an unsuccessful response code
      errorMessage = `Server returned code: ${error.status}, error message is: ${error.message}`;
      this.aiService.trackException(error);
      statusCode = error.status;
    }
    console.error(errorMessage);
    return throwError({ errorMessage, statusCode});
  }

}

import { Inject, Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { APP_CONFIG } from '../common/constants';
import { AppConfig } from '../model/app-config.model';
import { OneAuthZService, PDPResponse } from './oneAuthZ.service';

@Injectable({
  providedIn: 'root'
})
export class UserDataService {

  private username: string;
  private oid: string;
  private _name: string;
  private userRoles: Array<string>;

  constructor(private msalService: MsalService, private oneAuthZService: OneAuthZService,
    @Inject(APP_CONFIG) private appConfig: AppConfig) {
      var account=this.msalService.instance.getActiveAccount();
      this.username = account.username;
      this.oid = account.localAccountId;
      this._name = account.name;
      this.userRoles = (account.idTokenClaims as any).roles;
  }

  get userName(): string {
    return this.username;
  }

  get name(): string {
    return this._name;
  }

  get objectId(): string {
    return this.oid;
  }

  /**
   * check if the user has access to the requested resource over the specified scope.
   * @param path part of url route the user wants to access.
   * @param action action to be performed over the resource.
   * @param groupId team group id of the user.
   * @param resourceId id of the accessed resource.
   */
  public checkUserAccess(path: string, action: string, groupId?: string, resourceId?: string): Observable<boolean> {
    // In case of AAD auth, check for M360Reader role
    if (!this.appConfig.enableOneAuthZAuth) {
      const role = action === "Read" ? "M360Reader" : "M360Writer";
      if (this.userRoles.includes(role)) {
        return new Observable<boolean>(resolve => {
          resolve.next(true);
          resolve.complete();
        });
      } else {
        return new Observable<boolean>(resolve => {
          resolve.next(true);
          resolve.complete();
        });
      };
    } else {
      if (groupId === undefined) {
        groupId = '';
      }
      if (resourceId === undefined) {
        resourceId = '';
      } else {
        resourceId = '/' + resourceId;
      }

      path = path.startsWith('/') ? path.substring(1) : path;
      let resource = null;
      if (path.startsWith('bam-dashboard')) {
        resource = "BAMDashboard/UI";
      } else if (path.startsWith('quality-of-service')) {
        resource = "QOS/UI";
      } else if (path.startsWith('monitoring-solution')) {
        resource = "MonitoringCatalog/UI";
      } else if (path.startsWith('score-card')) {
        resource = "HealthTracker/Dashboard";
      } else if (path.startsWith('DashboardAutomatedValues')) {
        resource = "HealthTracker/DashboardAutomatedValues";
      } else if (path.startsWith('event-broker')) {
        resource = "EventBroker/UI";
      }

      const scope = '/M360/' + this.appConfig.environment + '/' + resource + resourceId;
      return this.oneAuthZService.CheckAccess(scope, action, this.oid, groupId)
        .pipe(map((response: PDPResponse) => response.isAccessGranted));
    }
  }
}

import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-ngb-error-modal',
  template: `
  <div class="modal-header" style="background-color:rgb(200,68,66);color:white">
    <div class="col-xs-1 text-center">
        <i class="fa fa-exclamation-triangle fa-2x"></i>
    </div>
    <h4 class="modal-title" style="margin-left:10px">Error</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.close('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
      <p [innerHTML]="message"></p>
    </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" ngbAutofocus (click)="activeModal.close('Close click')">Ok</button>
  </div>
  `
})
export class NgbModalErrorComponent {
  @Input() message: any;

  constructor(
    public activeModal: NgbActiveModal,
    private sanitizer: DomSanitizer,
  ) {
    this.message = this.sanitizer.bypassSecurityTrustHtml("<p>Oops something gone wrong :)</br>Please try again in sometime</p>");
  }
}

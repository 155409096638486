<!-- title -->
<div *ngIf="eventTypeSub$ | async as eventTypeSub" class="ml-3 mt-3">
  <a routerLink="/event-broker/event-catalog" style="color: #0F0FFF">Event Catalog</a>
  <span class="mx-2 fa fa-caret-right"></span>
  <a routerLink="/event-broker/event/{{eventTypeSub.parentEventName.toLowerCase()}}" style="color: #0F0FFF">{{eventTypeSub.parentEventName}}</a>
  <span class="mx-2 fa fa-caret-right"></span>{{eventTypeSub.name}}
  <a class = "close mr-4" href="https://aka.ms/SCEventBroker" target="_blank" rel="noreferrer noopener" aria-label="SCEventBroker wiki">
    <i class="fa fa-question-circle-o"></i>
  </a>
</div>
<hr>
<!-- title -->
<div *ngIf="eventTypeSub$ | async as eventTypeSub">
  <h4 class="mx-3">{{eventTypeSub.name}} Subscription</h4>
</div>
<br>

<!-- Subscription status -->
<div class="ml-3 mr-2">
<!-- Add loader if subscription status is not loaded-->
  <div class="d-flex flex-rows mb-2">
    <h5 class="text-secondary">Status</h5>
  </div>
  <div *ngIf="subStatus$ | async as subStatus; else loading" class="ml-3">
    <div [ngSwitch]="subStatus">
      <div *ngSwitchCase="'Pending'">
        <span class="fa fa-spinner fa-spin"></span><span> Subscription Creation In Progress</span>
      </div>
      <div *ngSwitchCase="'Creating'">
        <span class="fa fa-spinner fa-spin"></span><span> Subscription Creation In Progress</span>
      </div>
      <div *ngSwitchCase="'Updating'">
        <span class="fa fa-spinner fa-spin"></span><span class="text-danger"><b> Subscription Update In Progress</b></span>
      </div>
      <div *ngSwitchCase="'Deleting'">
        <span class="fa fa-spinner fa-spin"></span><span class="text-danger"><b> Subscription Deletion In Progress</b></span>
      </div>
      <div *ngSwitchCase="'Ready'">
        <span class="fa fa-check-circle text-success"></span><span> Ready</span>
      </div>
      <div *ngSwitchCase="'Failed'">
        <span class="fa fa-times-circle-o danger-text"></span><span class="text-danger"><b> Failed to Modify Subscription</b></span>
      </div>
      <div *ngSwitchDefault>{{subStatus}}</div>
    </div>
  </div>
  <ng-template #loading>
    <div class="text-center">
      <span class="fa fa-spinner fa-spin fa-2x" style="font-size:5rem;" aria-label="Loading"></span>
    </div>
  </ng-template>
</div>

<!-- Details table -->
<div class="ml-3 mt-5 mr-2">
  <!-- Add loader if details list is not loaded-->
  <div *ngIf="!detailsTable.isLoaded" class="text-center">
    <span class="fa fa-spinner fa-spin fa-2x" style="font-size:5rem;" aria-label="Loading"></span>
  </div>
  <!-- Display table with all details data -->
  <div class="d-flex flex-rows mb-2">
    <h5 class="text-secondary">Details</h5>
    <div class = "ml-auto">
    <div *ngIf="isModifying$ | async as isModifying" class="ml-3">
      <!-- Edit Button -->
      <button class="btn btn-sm info-button border border-secondary mr-1" type="button" id="editButton"
        (click)="editEventTypeSubscriptionDetails(eventTypeSubscription)" style="width: 5vw;"
        [disabled]="isModifying === 'yes'">Edit
      </button>
      <!-- Delete Button -->
      <button class="btn btn-sm info-button border border-secondary" type="button" id="deleteButton"
        (click)="deleteEventTypeSubscription(eventTypeSubscription)" style="width: 5vw;" 
        [disabled]="isModifying === 'yes'">Delete
      </button>
    </div>
  </div>
  </div>
  <div *ngIf="detailsTable.isLoaded">
    <table class="table table-sm" [hidden]="detailsTable.hidden">
      <tbody>
        <ng-container *ngFor="let detail of (detailsTable.data | async)?.rows" class="d-flex">
          <tr>
            <th scope="row" class="col-3">{{detail[0]}}</th>
            <td class="col-9">{{detail[1]}}</td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </div>
</div>

<!-- Event Handler Details table -->
<div class="ml-3 mt-5 mr-2">
  <!-- Add loader if event handler details list is not loaded-->
  <div *ngIf="!eventHandlerDetailsTable.isLoaded" class="text-center">
    <span class="fa fa-spinner fa-spin fa-2x" style="font-size:5rem;" aria-label="Loading"></span>
  </div>
  <!-- Display table with all event handler details data -->
  <div class="d-flex flex-rows mb-2">
    <h5 class="text-secondary">Event Handler</h5>
  </div>
  <div *ngIf="eventHandlerDetailsTable.isLoaded">
    <table class="table table-sm" [hidden]="eventHandlerDetailsTable.hidden">
      <tbody>
        <ng-container *ngFor="let detail of (eventHandlerDetailsTable.data | async)?.rows" class="d-flex">
          <tr>
            <th scope="row" class="col-3">{{detail[0]}}</th>
            <td class="col-9">{{detail[1]}}</td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </div>
</div>

<!-- Delivery Failure IcM table -->
<div class="ml-3 mt-5 mr-2">
  <!-- Add loader if delivery failure icm list is not loaded-->
  <div *ngIf="!deliveryFailuresIcMTable.isLoaded" class="text-center">
    <span class="fa fa-spinner fa-spin fa-2x" style="font-size:5rem;" aria-label="Loading"></span>
  </div>
  <!-- Display table with all delivery failure icm data -->
  <div class="d-flex flex-rows mb-2">
    <h5 class="text-secondary">Delivery Failures IcM</h5>
  </div>
  <div *ngIf="deliveryFailuresIcMTable.isLoaded">
    <table class="table table-sm" [hidden]="deliveryFailuresIcMTable.hidden">
      <tbody>
        <ng-container *ngFor="let detail of (deliveryFailuresIcMTable.data | async)?.rows" class="d-flex">
          <tr>
            <th scope="row" class="col-3">{{detail[0]}}</th>
            <td class="col-9">{{detail[1]}}</td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </div>
</div>

<!-- Delivery Failure Alerts table -->
<div class="ml-3 mt-5 mr-2">
  <!-- Add loader if delivery failure alerts list is not loaded-->
  <div *ngIf="!deliveryFailuresTable.isLoaded" class="text-center">
    <span class="fa fa-spinner fa-spin fa-2x" style="font-size:5rem;" aria-label="Loading"></span>
  </div>
  <!-- Display table with all delivery failure alerts data -->
  <div class="d-flex flex-rows mb-2">
    <h5 class="text-secondary">Delivery Failures Alert</h5>
  </div>
  <div *ngIf="deliveryFailuresTable.isLoaded">
    <table class="table table-sm" [hidden]="deliveryFailuresTable.hidden">
      <tbody>
        <ng-container *ngFor="let detail of (deliveryFailuresTable.data | async)?.rows" class="d-flex">
          <tr>
            <th scope="row" class="col-3">{{detail[0]}}</th>
            <td class="col-9">{{detail[1]}}</td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </div>
</div>

<div *ngIf="deliveryFailuresTable.isLoaded && !deliveryFailuresTable.hidden && displayDeliveryFailuresPowerBI">
  <app-power-bi-input [reportInput]="powerBIConfig" [filterValue]="eventTypeSubscription.name"></app-power-bi-input>
</div>
import { NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { InjectionToken } from '@angular/core';

export const constants = {
  // Power BI
  ReportEmbedUrl: 'https://msit.powerbi.com/reportEmbed?',
  DashboardEmbedUrl: 'https://msit.powerbi.com/dashboardEmbed?',
  PowerBIDomainUrl: 'https://msit.powerbi.com/',

  // Power BI Input Type
  Dashboard: 'dashboard',
  Report: 'report',

  // Tree Item Type
  ROOT: 'Root',
  SOURCE: 'Source',
  DIVISION: 'Division',
  ORGANIZATION: 'Organization',
  SERVICE_GROUP: 'ServiceGroup',
  TEAM_GROUP: 'TeamGroup',
  BUSINESS_PROCESS: 'BusinessProcess',
};

// to stop closing the modal by clicking backdrop or outside the modal, add options.
export const ngbModalOptions: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
};

// App config injector
export const APP_CONFIG = new InjectionToken<any>('Main Application Configuration');

// Constant dictionary for transfering url to App Names
export const urlToAppNames: { [key: string]: string[] } = {
  //Event Broker
  '/event-broker': ['Event Broker'],
  '/event-broker/event-catalog': ['Event Broker', 'Event Catalog'],
  '/event-broker/event-activity': ['Event Broker', 'Event Activity'],

  // Dashboard Catalog
  '/monitoring-solution': ['Dashboard Catalog'],
  '/monitoring-solution/add-dashboard': ['Dashboard Catalog', 'Add New Solution'],
  '/monitoring-solution/browse-catalog': ['Dashboard Catalog', 'Browse All Solutions'],

  //Quality Of Service
  '/quality-of-service': ['Quality Of Service'],
  '/quality-of-service/admin': ['Quality Of Service', 'QosAdministration'],
  '/quality-of-service/view': ['Quality Of Service', 'QoS View'],

  // Control Tower
  '/control-tower': ['Control Tower'],

  //BAM Dashboard
  '/bam-dashboard': ['BAM Dashboard'],
  '/bam-dashboard/administration': ['BAM Dashboard', 'Administration'],
  '/bam-dashboard/rule': ['BAM Dashboard', 'Rule Config'],
  '/bam-dashboard/process-discovery': ['BAM Dashboard', 'Process Discovery'],
  '/bam-dashboard/process-health': ['BAM Dashboard', 'Process Health'],
  '/bam-dashboard/process-flow': ['BAM Dashboard', 'Process Flow'],
  '/bam-dashboard/process': ['BAM Dashboard', 'Rule Config'],
  '/bam-dashboard/process-table': ['BAM Dashboard', 'Process Onboarding'],

  //Score Card
  '/score-card': ['Score Card'],
  '/score-card/sc-scenario-health': ['Score Card', 'SC Scenario Health'],
  '/score-card/cloud-sc-scenario-health': ['Score Card', 'Cloud SC Scenario Health'],
  '/score-card/sap-cbs-health': ['Score Card', 'SAP Core Business Services'],
  '/score-card/sap-cffcf-health': ['Score Card', 'CFF Core Finance'],
  '/score-card/sap-mctgtm-health': ['Score Card', 'SAP Modern Commerce Tax GTM'],
  '/score-card/sap-dap-health': ['Score Card', 'Data Analytics and Planning'],
  '/score-card/sap-mdm-health': ['Score Card', 'SAP Master Data Management'],
  '/score-card/sap-plat-health': ['Score Card', 'SAP Platform'],
  '/score-card/sap-itr-health': ['Score Card', 'SAP ITR Services'],
  '/score-card/crew-health': ['Score Card', 'Crew Health'],
  '/score-card/monitoring-dashboard': ['Score Card', 'Monitoring Dashboard'],

  '/contact-us': ['Contact Us'],
};

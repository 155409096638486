import { Component, Inject, OnInit } from '@angular/core';
import { DashboardInput } from '../../power-bi/model/power-bi.model';
import { APP_CONFIG } from '../../common/constants';
import { AppConfig } from '../../model/app-config.model';

@Component({
  selector: 'app-cscp',
  templateUrl: './cscp.component.html',
  styleUrls: ['./cscp.component.scss']
})
export class CscpComponent implements OnInit {

  powerBIConfig: DashboardInput;

  constructor(@Inject(APP_CONFIG) appConfig: AppConfig) {
    this.powerBIConfig = appConfig.IcMCscpPowerBIDashboard;
  }

  ngOnInit() {
  }

}

// models for page config.
export class PageConfig {
    id: string;
    pageName: PageName;
    displayConfig: Array<DisplayConfig>;
}

export class DisplayConfig {
    id: string;
    // used to display the name of the tab if configType is DisplayTab.
    configName: string;
    objectType: ObjectType;
    configType: ConfigType;
    componentType: ComponentType;
    sequenceNumber: number;
    enabled: boolean;
    path: string;
}

export enum ObjectType {
    Node = 'Node',
    Edge = 'Edge',
    Path = 'Path',
    Graph = 'Graph'
}

export enum ConfigType {
    DisplayTab = 'DisplayTab',
    Dropdown = 'Dropdown',
    System = 'System'
}

export enum ComponentType {
    Object = 'Object',
    Graph = 'Graph',
    Table = 'Table',
    Report = 'Report',
    ProcessMap = 'ProcessMap',
    ProcessMapV2 = 'ProcessMapV2',
    QueryOptions = 'QueryOptions',
    None = 'None'
}

export enum PageName {
    ProcessFlow = 'ProcessFlow',
    ProcessDiscovery = 'ProcessDiscovery'
}

export class PagePropertyTagConfig {
    pageName: PageName;
    propertyTags: Array<PropertyTagConfig>;
}

export class PropertyTagConfig {
    propertyTag: PropertyTag;
    propertyConfigs: string;
}

export enum PropertyTag {
    Payload = 'Payload',
    CSOGraph  = 'CSOGraph',
    MetricsTable  = 'MetricsTable',
    SampleCases = 'SampleCases',
    PathEdgeMetrics = 'PathEdgeMetrics',
    PathMetrics = 'PathMetrics',
    PowerBIReport = 'PowerBIReport',
    ProcessMiningGraph = 'ProcessMiningGraph',
    ProcessMiningGraphV2 = 'ProcessMiningGraphV2',
    QueryOptions = 'QueryOptions',
    System = 'System'
}

export class PageComponentConfig {
    id: string;
    tabName: string;
    componentType: ComponentType;
    path: string;
}

// Datatypes for component inputs.
export class TableInput {
    columns: Array<any>;
    rows: Array<any>;
    constructor() {
        this.columns = new Array<any>();
        this.rows = new Array<any>();
    }
}

// Input for display component.
export class DisplayTabInput {
    constructor(
        public componentInputArray: Array<any>,
        public enableTab: Array<boolean>,
        ) {}
}

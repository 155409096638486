import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormControl } from '@angular/forms';
import { Column, Row, SortBy, DataGrid } from '@harmony/enablers/components/data-grid/data-grid';
import SearchBox from '@harmony/enablers/components/search-box/search-box';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { Subscription } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { BusinessGoal, DisplayBusinessGoal } from '../model/business-goal.model';
import { Scenario } from '../model/scenario.model';
import { BusinessGoalService } from '../service/business-goal.service';

@Component({
  selector: 'app-list-business-goal',
  templateUrl: './list-business-goal.component.html',
  styleUrls: ['./list-business-goal.component.scss'],
  providers: [BusinessGoalService],
})
export class ListBusinessGoalComponent implements OnInit, AfterViewInit, OnDestroy {
  // list table.
  @ViewChild('listAllRules') dataTable: ElementRef<HTMLElement>;
  // search control
  @ViewChild('searchControl') search: ElementRef<HTMLElement>;
  searchBox: SearchBox;

  // scenario.
  scenario: Scenario;

  // business goal.
  businessGoals: Array<BusinessGoal>;
  columns: Array<Column>;
  filteredRows: Array<Row>;
  filteredActiveFormArray: FormArray;
  businessGoalIndex: number;

  // loading variables.
  isScenarioLoading: boolean;
  isBusinessGoalListLoading: boolean;
  isBusinessGoalUpdating: boolean;

  // all subscriptions for component.
  subscription: Subscription;

  constructor(private businessGoalService: BusinessGoalService, private appInsights: ApplicationInsights) {
    this.isScenarioLoading = false;
    this.isBusinessGoalListLoading = false;
    this.isBusinessGoalUpdating = false;
    this.columns = this.generateDataGridColumns();
    this.filteredRows = this.generateEmptyRowsForLoading(3);
    this.filteredActiveFormArray = new FormArray([]);
    this.subscription = new Subscription();
    this.businessGoalIndex = -1;
  }

  ngOnInit(): void {
    this.isScenarioLoading = true;
    this.isBusinessGoalListLoading = true;
    const scenarioSubscription = this.businessGoalService
      .getScenario(1)
      .pipe(
        mergeMap((s: Scenario) => {
          this.scenario = s;
          this.isScenarioLoading = false;
          return this.businessGoalService.getBusinessGoalList(s.scenarioId);
        })
      )
      .subscribe(
        (businessGoals: Array<BusinessGoal>) => {
          this.businessGoals = businessGoals;
          this.performInitialSorting();
          const rows = new Array<Row<BusinessGoal>>();
          businessGoals.forEach((businessGoal: BusinessGoal, index: number) => {
            rows.push({
              id: index,
              cells: businessGoal as unknown as Record<string, string>,
            });
          });
          this.isBusinessGoalListLoading = false;
          this.generateFilteredRows(this.businessGoals, '');
          this.appInsights.trackEvent({ name: 'Business Goals listed.' });
        },
        (error: unknown) => {
          // todo: display error in dialog box.
          console.error('Error occured while loading scenario: ', error);
          this.isBusinessGoalListLoading = false;
          this.isScenarioLoading = false;
        }
      );
    this.subscription.add(scenarioSubscription);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  ngAfterViewInit(): void {
    this.dataTable.nativeElement.addEventListener('he-sort', (e) => this.sortRows(e));
    (this.dataTable.nativeElement as DataGrid).sortBy = 'businessGoal';
    this.searchBox = this.search.nativeElement as SearchBox;
    // setup search event.
    this.search.nativeElement.addEventListener('he-input', (e) => {
      this.generateFilteredRows(this.businessGoals, this.searchBox.value);
    });
    // handle clear event.
    this.search.nativeElement.addEventListener('he-clear', (e) => {
      this.generateFilteredRows(this.businessGoals, '');
    });
  }

  activateBusinessGoal(index: number): void {
    this.businessGoalIndex = index;
    const updatedBusinessGoalName = this.filteredRows[index].cells as unknown as DisplayBusinessGoal;
    const updatedBusinessGoalIndex = this.businessGoals.findIndex(
      (x) => x.businessGoalName === updatedBusinessGoalName.businessGoal
    );
    this.businessGoals[updatedBusinessGoalIndex].isActive = !this.businessGoals[updatedBusinessGoalIndex].isActive;
    this.isBusinessGoalUpdating = true;
    const businessGoalUpdate = this.businessGoalService
      .updateBusinessGoal(this.businessGoals[updatedBusinessGoalIndex])
      .subscribe(
        (updatedBusinessGoal: BusinessGoal) => {
          this.businessGoals[updatedBusinessGoalIndex].isActive = updatedBusinessGoal.isActive;
          this.businessGoals[updatedBusinessGoalIndex].lastModifiedAt = updatedBusinessGoal.lastModifiedAt;
          updatedBusinessGoalName.active = updatedBusinessGoal.isActive;
          updatedBusinessGoalName.lastModifiedOn = new Date(updatedBusinessGoal.lastModifiedAt).toString();
          this.isBusinessGoalUpdating = false;
        },
        (error: unknown) => {
          this.isBusinessGoalUpdating = false;
          console.error('error occured', error);
        }
      );
    this.subscription.add(businessGoalUpdate);
  }

  /**
   * configure columns for generated data column.
   */
  private generateDataGridColumns(): Array<Column> {
    const columns: Array<Column> = [
      {
        field: 'businessGoal',
        content: 'Business Goal',
        sortable: true,
      },
      {
        field: 'lastModifiedOn',
        content: 'Last Modified',
      },
      {
        field: 'createdBy',
        content: 'Created By',
      },
      {
        field: 'active',
        content: 'Active',
      },
    ];
    return columns;
  }

  // create empty objects for loading.
  private generateEmptyRowsForLoading(numberOfEmptyRows: number): Array<Row> {
    const rows = new Array<Row>();
    for (let index = 0; index < numberOfEmptyRows; index++) {
      rows.push({
        id: index,
        cells: {} as DisplayBusinessGoal as unknown as Record<string, string>,
      });
    }
    return rows;
  }

  // filter rows.
  private generateFilteredRows(businessGoals: Array<BusinessGoal>, searchTerm: string = null): void {
    searchTerm = searchTerm.toLowerCase();
    const filteredGoals = businessGoals.filter(
      (b) => b.businessGoalName.toLowerCase().includes(searchTerm) || b.createdBy.toLowerCase().includes(searchTerm)
    );
    const rows = new Array<Row<DisplayBusinessGoal>>();
    filteredGoals.forEach((businessGoal: BusinessGoal, index: number) => {
      const displayBusinessGoal = {} as DisplayBusinessGoal;
      displayBusinessGoal.businessGoal = businessGoal.businessGoalName;
      displayBusinessGoal.active = businessGoal.isActive;
      displayBusinessGoal.lastModifiedOn = new Date(businessGoal.lastModifiedAt).toString();
      displayBusinessGoal.createdBy = businessGoal.createdBy;
      rows.push({
        id: index,
        cells: displayBusinessGoal as unknown as Record<string, string>,
      });
    });
    this.filteredRows = rows;
    this.generateActiveColumn(filteredGoals);
  }

  // get isActive values from business goal.
  private generateActiveColumn(businessGoals: Array<BusinessGoal>) {
    this.filteredActiveFormArray.clear();
    businessGoals.forEach((goal, index: number) => {
      const formControl = new FormControl<boolean>(goal.isActive);
      const valueChanges = formControl.valueChanges.subscribe((x) => {
        this.activateBusinessGoal(index);
      });
      this.subscription.add(valueChanges);
      this.filteredActiveFormArray.push(formControl);
    });
  }

  private performInitialSorting(): void {
    // sort by active, inactive status.
    this.businessGoals.sort((a, b) => {
      if (a.isActive && !b.isActive) {
        return -1;
      } else if (!a.isActive && b.isActive) {
        return 1;
      } else if (a.isActive && b.isActive) {
        return 0;
      } else if (!a.isActive && !b.isActive) {
        return 0;
      }
    });

    // within active, inactive status, sort by business name.
    this.businessGoals.sort((a, b) => {
      if (a.isActive && !b.isActive) {
        return 0;
      } else if (!a.isActive && b.isActive) {
        return 0;
      } else if (a.isActive && b.isActive) {
        return a.businessGoalName < b.businessGoalName ? -1 : 1;
      } else if (!a.isActive && !b.isActive) {
        return a.businessGoalName < b.businessGoalName ? -1 : 1;
      }
    });
  }

  private sortRows(e: any): void {
    const eventDetail = e['detail'] as SortBy;
    this.filteredRows.sort((a, b) => {
      const aValue = a.cells as unknown as DisplayBusinessGoal;
      const bValue = b.cells as unknown as DisplayBusinessGoal;

      if (eventDetail.isAscending) {
        return aValue.businessGoal < bValue.businessGoal ? -1 : 1;
      } else {
        return aValue.businessGoal < bValue.businessGoal ? 1 : -1;
      }
    });
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeComponent } from './home/home.component';
import { RouterModule, Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { FeatureFlagGuard } from '../feature-flag-guard';
import { MdoComponent } from './mdo/mdo.component';
import { SceComponent } from './sce/sce.component';
import { PowerBIModule } from '../power-bi/power-bi.module';
import { CscpComponent } from './cscp/cscp.component';

const icmRoutes: Routes = [
  {
    path: 'icm',
    component: HomeComponent,
    canActivate: [MsalGuard, FeatureFlagGuard],
    data: { featureFlag: 'icm'}
  },
  {
    path: 'icm/mdo',
    component: MdoComponent,
    canActivate: [MsalGuard, FeatureFlagGuard],
    data: { featureFlag: 'icm/mdo' }
  },
  {
    path: 'icm/sce',
    component: SceComponent,
    canActivate: [MsalGuard, FeatureFlagGuard],
    data: { featureFlag: 'icm/sce' }
  },
  {
    path: 'icm/cscp',
    component: CscpComponent,
    canActivate: [MsalGuard, FeatureFlagGuard],
    data: { featureFlag: 'icm/cscp' }
  },
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(icmRoutes),
    PowerBIModule,
  ],
  declarations: [HomeComponent, MdoComponent, SceComponent, CscpComponent],
})
export class IcMModule { }

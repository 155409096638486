import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ReplaySubject, Subject, Subscription } from 'rxjs';
import { constants } from '../common/constants';
import { RuleConfig, QueryParam } from '../model/rule.model';
import { RuleService } from '../service/rule.service';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { HttpErrorResponse } from '@angular/common/http';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgbModalErrorComponent } from '../../modal/ngb-modal-error.component';
import { NgbModalSuccessComponent } from '../../modal/ngb-modal-success.component';
import { UntypedFormControl } from '@angular/forms';
import { UserDataService } from '../../service/user-data.service';
import { NgbModalConfirmComponent } from '../../modal/ngb-modal-confirm.component';
import { APP_CONFIG, ngbModalOptions } from '../../common/constants';
import { ServicetreeDataService } from '../../common/service/servicetree-data.service';
import { TreeviewService } from '../../common/service/treeview.service';
import { TreeLevelData, TreeSelection } from '../../common/model/tree-view.model';
import { TreeDataService } from '../../common/interface/tree-data-service';
import { TableOptions, TableInputV2, RowEditEvent } from '../model/common.model';
import moment from 'moment';
import { LocalCacheService } from '../../service/local-cache.service';
import { ModalRef } from '../../model/common.model';
import { HierarchyTreeService } from '../../service/hierarchy-tree.service';
import { HierarchyTreeNode } from '../model/process.model';
import { AppConfig } from '../../model/app-config.model';

@Component({
  selector: 'app-rule',
  templateUrl: './rule.component.html',
  styleUrls: ['./rule.component.css'],
  providers: [
    { provide: TreeDataService, useClass: ServicetreeDataService },
    TreeviewService,
  ]
})
export class RuleComponent implements OnInit, OnDestroy {

  /*
  Description:
  - rule component is a parent component of the Rule-Config screen in BAM.
  - It contains service-tree, rule dropdown, rule name field, action buttons
    like Save, Delete, Reset, Add/Edit Alert, Execute Query and Cancel Execute.
  - It displays rule child component form as per the type.
    Currently, we have only one rule type called direct-query and it is default.
  - This component handles all the responsibility of action buttons, displaying the query execution result.
*/

  // Subscription
  private subscriptions: Subscription;
  executeSubscribe: any;
  getRuleByIdSubscribe: any;

  // Booleans
  isProcessSelected = false;
  isRuleListLoaded: boolean;
  isExecuteInProgress: boolean;
  disableCancel = true;
  disableExecute = false;
  isNewRule = false;
  isRuleSaved = false;
  hasDirectQuery = true;
  hasResults = false;
  isSaveInProgress: boolean;
  hasError = false;
  loadRuleTable = false;

  // Routing Related
  lastProcessId: string;

  // Rule Related
  ruleNames: Array<string>;
  ruleConfig: RuleConfig;
  queryToggle = constants.DirectQuery;

  // Service Tree
  businessProcessId: string;
  groupId: string;
  hierarchyLevelsToLoad: number;

  // Error
  resultErrorMsg = "";

  // Result Data
  columns: any;
  rows: any;

  // Validation Status From Child Component
  isValidChildForm: boolean;

  // display table components.
  tableColumns: Array<string>;
  tableOptions: TableOptions;
  tableData: ReplaySubject<TableInputV2>;
  tableSearch: Subject<string>;

  search = new UntypedFormControl('');

  constructor(
    private router: Router,
    private ruleService: RuleService,
    private appInsightsService: ApplicationInsights,
    private modalService: NgbModal,
    private route: ActivatedRoute,
    private localCacheService: LocalCacheService,
    private hierarchyTreeService: HierarchyTreeService, 
    private treeviewService: TreeviewService,
    @Inject(APP_CONFIG) appConfig: AppConfig,
  ) {
    this.hierarchyLevelsToLoad =  appConfig.hierarchyLevelsToLoad;
    this.ruleConfig = new RuleConfig();
    this.ruleNames = new Array<string>();
    this.subscriptions = new Subscription();
    this.isExecuteInProgress = false;
    this.isValidChildForm = false;
    
    // seting table output columns.
    this.tableColumns = ['Id', 'Rule Name', 'Rule Description', 'Last Modified By', 'Last Modified Date', 'Alert Last Run Time', 'Status', 'Edit Alert', 'Edit Rule'];
    this.tableOptions = {
      addEditColumn: true,
      editColumns: ['Edit Alert', 'Edit Rule'],
      enableSearch: true,
      searchColumns: ['Rule Name', 'Rule Description'],
      columnWidths: [0.2, 0.8, 0.8, 0.5, 0.5, 0.5, 0.2, 0.25, 0.25],
      centeredColumns: ['Id', 'Status'],
      wrapTextColumns: ['Rule Description']
    };
    this.tableData = new ReplaySubject<TableInputV2>(1);
    this.tableSearch = new Subject<string>();

    this.isRuleListLoaded = false;
  }

  ngOnInit(): void {
    // Create treeview configuration
    this.treeviewService.setHeight(200);
    this.treeviewService.setTitle("Business Process");

    // ROUTING OPTIONS: rule/new, rule/(id), rule/all
    const routeSubscription = this.route.paramMap.subscribe((val) => {
      const id = val.get('id');
      if (id === 'new') {
        this.treeviewService.loadTreeView(true);
        this.CreateNewRule();
      } else if (id != null && !isNaN(Number(id))) {
        // fetch the existing rule
        this.isRuleSaved = true;
        this.isNewRule = false;
        
        let mappingId = "";

        this.getRuleByIdSubscribe = this.ruleService.getRuleById(Number(id)).subscribe(
          (response: RuleConfig) => {
            this.populateRule(response);
            mappingId = response.mappingId;
          },
          (error: HttpErrorResponse) => {
            this.appInsightsService.trackException(error, { componentName: 'Rule Component: Error fetching rule by ID'});
            const errorModal = this.modalService.open(NgbModalErrorComponent);
            (errorModal.componentInstance as ModalRef).message = "Error while fetching rule";
          }, () => {
            const selectedItem = this.localCacheService.getItem("SELECTED_ITEM") as TreeLevelData[];
            let process;
            if (selectedItem != null) {
              process = selectedItem.find(x => x.type === constants.BUSINESS_PROCESS);
            }
            if (selectedItem != null && process != null) {
              const processId = selectedItem.find(x => x.type === constants.BUSINESS_PROCESS).id;
              if (String(mappingId) !== String(processId)) {
                this.treeviewService.loadTreeView(true, mappingId);
              } else {
                this.treeviewService.loadTreeView(true);
              }
            } else {
              this.treeviewService.loadTreeView(true, mappingId);
            }
          }
        );
        this.subscriptions.add(this.getRuleByIdSubscribe);
      } else {
        this.treeviewService.loadTreeView(true);
        this.loadRuleTable = true;
        if (this.businessProcessId !== undefined) {
          this.getAllRules(this.businessProcessId);
        }
      }
    });
    this.subscriptions.add(routeSubscription);

    // search table
    const searchSubscription = this.search.valueChanges.subscribe(
      (searchValue: string) => {
        this.tableSearch.next(searchValue);
      }
    );
    this.subscriptions.add(searchSubscription);
  }

  ngOnDestroy(): void {
    // all child subscriptions will be unsubscribed to avoid memory leaks
    this.subscriptions.unsubscribe();
    this.tableData.complete();
    this.tableSearch.complete();
  }

  // Add new rule
  addNewRule(): void {
    this.loadRuleTable = false;
    this.router.navigate(['bam-dashboard/rule/new']);
  }

  // Edit Rule
  editRow(input: RowEditEvent): void {
    this.loadRuleTable = false;
    if (input.column === 'Edit Alert') {
      this.router.navigateByUrl('bam-dashboard/rule/' + input.value + '/alert');
    } else if (input.column === 'Edit Rule') {
      this.router.navigate(['bam-dashboard/rule', input.value]);
    }
  }

  // Creating a new rule
  CreateNewRule() {
    this.ruleConfig = new RuleConfig();
    this.ruleConfig.mappingId = this.businessProcessId;
    this.isNewRule = true;
    this.isRuleSaved = false;
    this.loadRuleTable = false;
  }

  // Populate existing rule
  populateRule(rule: RuleConfig): void {
    this.loadRuleTable = false;
    this.ruleConfig = rule;
  }

  // Get all rules by business process
  getAllRules(businessProcessId: string): void {
    const getRulesByProcessSubscribe = this.ruleService.getAllRulesByProcess(businessProcessId).subscribe(
      (ruleList: RuleConfig[]) => {
        this.displayRuleTable(ruleList);
        // create rule names list to validate new rule name
        this.ruleNames = new Array<string>();
        for (const rule of ruleList) {
          this.ruleNames.push(rule.ruleName.toLowerCase());
        }
      },
      (err) => {
        this.appInsightsService.trackException(err, { componentName: 'RuleConfig Component: Error fetching rule names'});
        const e: HttpErrorResponse = err;
        if (e.status !== 404) {
          const errorModal = this.modalService.open(NgbModalErrorComponent);
          errorModal.componentInstance.message = "Something went wrong, please try again later";
        }
      }
    );
    this.subscriptions.add(getRulesByProcessSubscribe);
  }

  // dispaly rule table
  displayRuleTable(ruleList: RuleConfig[]): void { 
    const tableInput = new TableInputV2();
    tableInput.columns = this.tableColumns;
    tableInput.rows = new Array<Array<string>>();
    ruleList.forEach((rule: RuleConfig) => {
      const row = new Array<string>();
      row.push(String(rule.id));
      row.push(rule.ruleName);
      row.push(rule.description);
      row.push(rule.updatedBy);
      row.push(String(moment(rule.validFrom).format('MM-DD-YYYY HH:mm')));

      // Set Alert last run and status
      let jobStatus = '';
      if (rule.alert != null && rule.alert.lastRun !== null && rule.alert.lastRun !== undefined) {
        if (rule.alert.lastRunCompletedAt > rule.alert.lastRun) {
          jobStatus = "Success";
        } else if (moment(rule.alert.lastRun).add(5, 'minutes') > moment() // current time is less than last run + 5 mins
          && rule.alert.lastRunCompletedAt < rule.alert.lastRun) {
          jobStatus = "Running";
        } else if (moment(rule.alert.lastRun).add(5, 'minutes') < moment() // current time is greater than last run + 5 mins
          && rule.alert.lastRunCompletedAt < rule.alert.lastRun) {
          jobStatus = "Failed";
        }
        row.push(String(moment(rule.alert.lastRun).format('MM-DD-YYYY HH:mm')));
        row.push(jobStatus);
      } else {
        row.push('');
        row.push('');
      }

      row.push(String(rule.id));
      row.push(String(rule.id));
        
      tableInput.rows.push(row);
    });
    this.tableData.next(tableInput);
    this.isRuleListLoaded = true;
  }

  // Reset Booleans
  resetValues(): void {
    this.resultErrorMsg = "";
    this.hasResults = false;
    this.isExecuteInProgress = false;
    this.disableCancel = true;
    this.disableExecute = false;
    this.isValidChildForm = false;
  }

  // #region  ACTION BUTTONS
  resetRule(): void {
    if (!this.isNewRule) {
      this.getRuleByIdSubscribe = this.ruleService.getRuleById(this.ruleConfig.id).subscribe((response) => {
        this.ruleConfig = response;
      });
      this.subscriptions.add(this.getRuleByIdSubscribe);
    }
  }

  saveRule(): void {
    this.isSaveInProgress = true;
    if (this.isNewRule && this.ruleConfig.id == null) {
      this.AddRule();
    } else {
      this.UpdateRule();
    }
  }

  private AddRule() {
    this.ruleConfig.mappingId = this.businessProcessId.toString();
    const addRuleSubscribe = this.ruleService.addRule(this.ruleConfig).subscribe((response) => {
      this.isSaveInProgress = false;
      if (response.status === 201) {
        this.isRuleSaved = true;
        this.isNewRule = false;
        this.ruleConfig = response.body;
        const successModal = this.modalService.open(NgbModalSuccessComponent, ngbModalOptions);
        successModal.componentInstance.message = "Rule added successfully";
        successModal.result.then(() => {
          this.router.navigate(['bam-dashboard/rule', this.ruleConfig.id]);
          this.getAllRules(this.ruleConfig.mappingId);
        });
      }
    }, (err) => {
      this.appInsightsService.trackException(err, { componentName: 'RuleConfig Component: Error occurred while adding a rule'});
      this.isSaveInProgress = false;
      const errorResponse: HttpErrorResponse = err;
      const errorModal = this.modalService.open(NgbModalErrorComponent);
      let errorMessage = "";
      if (errorResponse.status === 403) {
        errorMessage = "Access Denied";
      } else if (errorResponse.status === 400) {
        errorMessage = errorResponse.error.value;
      } else {
        errorMessage = errorResponse.statusText;
      }
      errorModal.componentInstance.message = "Error occurred while adding a Rule. Error:" + errorMessage;
    });
    this.subscriptions.add(addRuleSubscribe);
  }

  private UpdateRule() {
    const updateRuleSubscribe = this.ruleService.updateRule(this.ruleConfig).subscribe((response) => {
      this.isSaveInProgress = false;
      if (response.status === 204) {
        // NOTE: Remove getRuleById method once ODATA PUT return content with 200 status code rather than 204 without content.
        // Existing bug ref: https://github.com/OData/WebApi/issues/125
        this.getRuleByIdSubscribe = this.ruleService.getRuleById(this.ruleConfig.id).subscribe((result) => {
          if (result.id === this.ruleConfig.id) {
            this.ruleConfig = result;
          }
        }, (err) => {
          this.appInsightsService.trackException(err, { componentName: 'RuleConfig Component: Error occurred while fetching updated rule by ID'});
          this.isSaveInProgress = false;
          const errorModal = this.modalService.open(NgbModalErrorComponent);
          errorModal.componentInstance.message = "Error occurred while fetching updated rule";
        });
        this.subscriptions.add(this.getRuleByIdSubscribe);
        const successModal = this.modalService.open(NgbModalSuccessComponent);
        successModal.componentInstance.message = "Rule updated successfully";
      }
    }, (err) => {
      this.appInsightsService.trackException(err, { componentName: 'RuleConfig Component: Error occurred while updating a rule'});
      this.isSaveInProgress = false;
      const errorResponse: HttpErrorResponse = err;
      const errorModal = this.modalService.open(NgbModalErrorComponent);
      let errorMessage = "";
      if (errorResponse.status === 403) {
        errorMessage = "Access Denied";
      } else if (errorResponse.status === 400) {
        errorMessage = errorResponse.error.value;
      } else {
        errorMessage = errorResponse.statusText;
      }
      errorModal.componentInstance.message = "Error occurred while updating a Rule. Error: " + errorMessage;
    });
    this.subscriptions.add(updateRuleSubscribe);
  }

  deleteRule(): void {
    const modal = this.modalService.open(NgbModalConfirmComponent);
    modal.componentInstance.message = "Are you sure you want to delete?";
    modal.result.then(() => {
      const deleteRuleSubscribe = this.ruleService.deleteRule(this.ruleConfig.id).subscribe(
        () => {
          const successModal = this.modalService.open(NgbModalSuccessComponent, ngbModalOptions);
          successModal.componentInstance.message = "Rule deleted successfully";
          successModal.result.then(() => {
            this.router.navigate(['bam-dashboard/rule/all']);
          });
        },
        (error: HttpErrorResponse) => {
          this.appInsightsService.trackException(error, { componentName: 'RuleConfig Component: Error while deleting a rule'});
          const errorModal = this.modalService.open(NgbModalErrorComponent);
          let errorMessage = "";
          if (error.status === 403) {
            errorMessage = "Access Denied";
          } else {
            errorMessage = error.statusText;
          }
          errorModal.componentInstance.message = "Error occured while deleting a rule. Error: " + errorMessage;
        }
      );
      this.subscriptions.add(deleteRuleSubscribe);
    }, () => { });
  }

  addEditAlert(): void {
    this.router.navigateByUrl('bam-dashboard/rule/' + this.ruleConfig.id + '/alert');
  }
  // #endregion

  // #region  EXECUTE RULE FUNCTIONS
  executeRule(): void {
    // validate execute rule parameters
    if (this.ruleConfig.dataSourceId == null) {
      const errorModal = this.modalService.open(NgbModalErrorComponent);
      errorModal.componentInstance.message = "Please select a data source to execute rule";
      return;
    } else if (typeof this.ruleConfig.templatizedRuleQuery === 'undefined' || this.ruleConfig.templatizedRuleQuery.length === 0) {
      const errorModal = this.modalService.open(NgbModalErrorComponent);
      errorModal.componentInstance.message = "Please add valid query to execute";
      return;
    }
    this.isExecuteInProgress = true;
    this.disableCancel = false;
    this.disableExecute = true;

    if (this.queryToggle === constants.DirectQuery) {
      this.executeRuleQuery();
    } else {
      const errorModal = this.modalService.open(NgbModalErrorComponent);
      errorModal.componentInstance.message = "Only direct query rule execution is supported. Please check with M360 Team.";
    }
  }

  private executeRuleQuery() {
    const query = new QueryParam(
      this.ruleConfig.templatizedRuleQuery,
      this.ruleConfig.ruleJson,
      this.ruleConfig.dataSourceId,
      this.businessProcessId.toString()
    );
    this.executeSubscribe = this.ruleService.executeRuleQuery(query).subscribe(
      (response) => {
        this.hasResults = true;
        this.hasError = false;
        this.rows = response.body;
        if (this.rows.length === 0) {
          this.hasError = true;
          this.resultErrorMsg = "No data found";
        }
        this.columns = this.ruleService.getColumnsFromRows(this.rows);
      },
      (err) => {
        this.handleExecuteRuleQueryError(err);
      },
      () => {
        this.isExecuteInProgress = false;
        this.disableCancel = true;
        this.disableExecute = false;
      }
    );
    this.subscriptions.add(this.executeSubscribe);
  }

  private handleExecuteRuleQueryError(err: any) {
    this.appInsightsService.trackException(err, { componentName: 'RuleConfig Component: Error occurred while executing a rule'});
    this.disableExecute = false;
    this.hasResults = true;
    this.hasError = true;
    const errorResponse: HttpErrorResponse = err;
    this.isExecuteInProgress = false;
    if (errorResponse.status === 403) {
      this.resultErrorMsg =
        "Status: " + errorResponse.status +
        "; <br/> Status Text: " + errorResponse.statusText +
        "; <br/> Error: Caller is not authorized to perform this action";
    } else {
      this.resultErrorMsg =
        "Status: " + errorResponse.status +
        "; <br/> Status Text: " + errorResponse.statusText +
        "; <br/> Error: " + errorResponse.error;
    }
  }

  cancelExecuteRule(): void {
    this.isExecuteInProgress = false;
    this.disableCancel = true;
    this.disableExecute = false;
    this.executeSubscribe.unsubscribe();
  }
  // #endregion

  // EXPORT RESULT
  exportToCSV() {
    this.ruleService.exportTableToCSV(this.columns, this.rows);
  }

  // EVENT HANDLER FUNCTIONS
  processIdEventHandler(serviceTreeSelection: TreeSelection): void {
    const processId = serviceTreeSelection.selectionHierarchy.find(x => x.type === constants.BUSINESS_PROCESS).id;
    this.businessProcessId = String(processId);
    this.ruleConfig.mappingId = String(processId);
    this.isRuleListLoaded = false;
    const internalId = (serviceTreeSelection.selectionHierarchy.length == this.hierarchyLevelsToLoad+1 ? serviceTreeSelection.selectionHierarchy[1].id : serviceTreeSelection.selectionHierarchy[0].id);
    const hierarchyServiceSubscription = this.hierarchyTreeService.getTreeNodeById(internalId).subscribe((response: HierarchyTreeNode) => {
      this.groupId = response.nodeId;
      this.isProcessSelected = processId != null ? true : false;
    });
    this.subscriptions.add(hierarchyServiceSubscription);
    this.getAllRules(processId);
    if (this.lastProcessId != null) {
      this.isNewRule = false;
      this.router.navigate(['bam-dashboard/rule/all']);
    }
    this.lastProcessId = processId;
  }

  // TOGGLE HANDLING
  toggleSelector(selectedButton: string) {
    switch (selectedButton) {
      case 'button1': {
        this.hasDirectQuery = false;
        this.ruleConfig.ruleJson = "";
        this.queryToggle = constants.RuleEngine;
        break;
      }
      case 'button2': {
        this.hasDirectQuery = true;
        this.ruleConfig.ruleJson = null;
        this.queryToggle = constants.DirectQuery;
        this.ruleConfig.templatizedRuleQuery = '';
        break;
      }
    }
  }

  navigateToTemplateVariableList(): void {
    const url = `bam-dashboard/process/${this.businessProcessId}/template-variable`;
    this.router.navigate([url]);
  }
}

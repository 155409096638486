import { Scenario } from './scenario';
import { IcMIncident } from './icmIncident';

export class ScenarioStatus {
  constructor() {
    this.dailyStatus = [null, null, null, null, null, null, null];
    this.updated = [null, null, null, null, null, null, null];
  }
    public scenario: Scenario;
    public dailyStatus: number[];
    public updated: any[];
    public comments: string;
    public icm: IcMIncident[];
  }

export class ScenarioPerformance {
  constructor() {
    this.performance = 0;
    this.automatedValue = null;
    this.updated = null;
  }
    public scenario: Scenario;
    public automatedValue: number;
    public performance: number;
    public updated: any;
    public comments: string;
    public icm: IcMIncident[];
    public autoScoreColor : string;
    public actualScoreColor : string;
}

import { Component, Inject, OnInit } from '@angular/core';
import { ReportInput } from '../../power-bi/model/power-bi.model';
import { APP_CONFIG } from '../../common/constants';
import { AppConfig } from '../../model/app-config.model';

@Component({
  selector: 'app-monitoring-dashboard',
  templateUrl: './monitoring-dashboard.component.html',
  styleUrls: ['./monitoring-dashboard.component.css']
})
export class MonitoringDashboardComponent implements OnInit {

  powerBIConfig: ReportInput;
  constructor(@Inject(APP_CONFIG) appConfig: AppConfig) {
    this.powerBIConfig = appConfig.monitoringDashboardPowerBIReport;
  }

  ngOnInit() {
  }

}

import { GraphDataService } from "../../service/graph-data.service";
import { AliasStatus } from "../../model/graph-data.model";
import { Observable, forkJoin } from "rxjs";

export class AliasValidation {

  constructor(private graphDataService: GraphDataService) { }

  checkAlias(aliases: string): Observable<Array<AliasStatus>> {
    // splitting aliases based on ';'.
    const aliasSplit = aliases.split(';');

    // combining all observables together for all aliases.
    const aliasRequests: Array<Observable<AliasStatus>> = new Array<Observable<AliasStatus>>();
    for (let i = 0; i < aliasSplit.length; i++) {
      aliasSplit[i] = aliasSplit[i].trim();
      if (aliasSplit[i] !== '') {
        aliasRequests.push(this.graphDataService.isAliasValid(aliasSplit[i]));
      }
    }
    const aliasResponse$: Observable<Array<AliasStatus>> = forkJoin(aliasRequests);

    // creating an observable for collecting all the responses together.
    const allAliasResponse$: Observable<Array<AliasStatus>> = new Observable((observer) => {
      aliasResponse$.subscribe(
        (aliasResponse) => { observer.next(aliasResponse); },
        (error) => { console.log('AliasValidation: unhandled error in checkAlias ', error); },
        () => { observer.complete(); }
      );
    });

    return allAliasResponse$;
  }
}

import { Component, OnDestroy, AfterViewInit, ViewChild, ElementRef, Input } from '@angular/core';
import { DisplayGraphService } from '../../../service/display-graph.service';
import { GraphMap } from '../../graph/graph-map';
import { DistributionPath } from '../../../model/graph.model';
import { Subject, Subscription } from 'rxjs';
import { ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'app-display-process-map',
  templateUrl: './display-process-map.component.html',
  styleUrls: ['./display-process-map.component.css']
})
export class DisplayProcessMapComponent implements OnDestroy, AfterViewInit {
  @ViewChild('processMiningDiv') processMiningGraphDiv: ElementRef<HTMLElement>;
  @Input() set redrawGraph(value: boolean) {
    if (value) {
      this.displayProcessMap();
    }
  }

  processMiningGraph: GraphMap;

  // distributions panel.
  distributions: Array<DistributionPath>;
  selectedDistributionPathId: string;
  emitPath: Subject<Array<DistributionPath>>;
  enablePathDiv: boolean;
  isNewMapLoaded: boolean;
  mapIsEmpty: boolean;

  mapCreatedOn: Date;

  graphDescription: string;
  distributionDescription: string;
  distributionDescriptionMap: Map<string, string>;

  private allSubscriptions: Subscription;

  constructor(
    private processMapService: DisplayGraphService,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    this.distributions = new Array<DistributionPath>();
    this.selectedDistributionPathId = "";
  
    this.enablePathDiv = false;
    this.emitPath = new Subject<Array<DistributionPath>>();
    this.isNewMapLoaded = true;
    this.mapIsEmpty = false;
  
    this.allSubscriptions = new Subscription();
  }

  ngAfterViewInit(): void {
    this.processMiningGraph = this.processMapService.getProcessMap();
    this.mapIsEmpty = this.processMiningGraph.isEmpty();
    this.displayProcessMap();

    // subscribe to an observable to track changes made to process mining graph.
    const graphSubscription = this.processMapService.getGraphMapChanges().subscribe(
      (graphMap: GraphMap) => {
        this.processMiningGraph = graphMap;
        this.mapIsEmpty = this.processMiningGraph.isEmpty();
        this.changeDetectorRef.detectChanges(); 
      });

    this.allSubscriptions.add(graphSubscription);
  }

  ngOnDestroy() {
    this.allSubscriptions.unsubscribe();
    this.emitPath.complete();
  }

  animateSelectedPath(pathId: string): void {
    this.processMiningGraph.disableAnimation();
    this.selectedDistributionPathId = pathId;
    const defaultModel = this.processMiningGraph.getGraphModel('default');
    if (defaultModel) {
      this.processMiningGraph.updateLabels(defaultModel);
    }
    this.processMiningGraph.enableAnimation(this.processMiningGraph.getGraphPathEdgeIdPath(pathId));
    this.changeDetectorRef.detectChanges(); 
  }

  showDefaultGraphModel(): void {
    this.selectedDistributionPathId = '';
    this.processMiningGraph.disableAnimation();
    const timeCountModel = this.processMiningGraph.getGraphModel('timeCount');
    if (timeCountModel != null) {
      this.processMiningGraph.updateLabels(timeCountModel);
    }
    this.changeDetectorRef.detectChanges(); 
  }

  togglePathDiv(): void {
    this.enablePathDiv = !this.enablePathDiv;
    if (this.enablePathDiv && this.isNewMapLoaded) {
      this.emitPath.next(this.distributions);
      this.isNewMapLoaded = false;
    }
    this.changeDetectorRef.detectChanges(); 
  }

  private displayProcessMap(): void {
    if (this.processMiningGraph && this.processMiningGraphDiv && this.processMiningGraphDiv.nativeElement) {
      this.processMiningGraph.setHTMLElement(this.processMiningGraphDiv.nativeElement);
      this.processMiningGraph.initializeGraph();
      this.processMiningGraph.redrawGraph();
      this.isNewMapLoaded = true;
      this.enablePathDiv = false;

      // html elements accessed using ViewRef are available in afterViewInit lifecycle hook.
      setTimeout(() => {
        this.graphDescription = this.processMiningGraph.getGraphDescription();

        this.distributionDescriptionMap = this.processMiningGraph.getGraphPathDescription();
        this.mapCreatedOn = this.processMiningGraph.getGraphMapCreatedDate();
        // setting path values.
        this.distributions = this.processMiningGraph.getPathDistributionList();
        this.distributions.sort((a, b) => (+(a.frequency) > +(b.frequency) ? -1 : 1));

        // start with the node and time count, durations.
        const timeCountModel = this.processMiningGraph.getGraphModel('timeCount');
        if (timeCountModel != null) {
          this.processMiningGraph.updateLabels(timeCountModel);
        }

        // Manually trigger change detection
        this.changeDetectorRef.detectChanges();
      }, 1);
    } else {
      console.log('processMiningGraph or processMiningGraphDiv is undefined');
    }
  }

  // code for showing path metrics, if needed.
  // commented, as code removed from process discovery component.
  // populateTabs(selectedPathId?: string) => {
  //   // this.propertyTaggingService.clearInput();
  //   // this.propertyTaggingService.createPageComponentInput(this.currentPageName);
  //   // add graph properties.
  //   for (const property of processMiningGraph.getGraphProperties()) {
  //     this.propertyTaggingService.addProperty(this.currentPageName, property);
  //   }
  //   if (typeof selectedPathId !== 'undefined' && selectedPathId !== null) {
  //     const selectedPathProperties = processMiningGraph.getGraphPathPropertiesByPathId(selectedPathId);
  //     // Creating properties metrics
  //     for (let index = 0; index < selectedPathProperties.length; index++) {
  //       this.propertyTaggingService.addProperty(this.currentPageName, selectedPathProperties[index]);
  //     }
  //   }
}

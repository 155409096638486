<div class='container-fluid pt-2'>
    <div class="row">
      <div class="col-12" style="text-align:center;">
        <div style="font-size:2em;font-weight:bold;padding-top:2em;">
          <div> <span id="page-title" tabindex="0" aria-label="Scenario Health Score Card">{{scoreCardName}}</span></div>
        </div>
      </div>
  
    </div>
    <div class="row" style="margin-top:-2em;">
      <div class="col-3 text-danger">{{message}}</div>
      <div class="col-3" style="text-align:right;"><button type="button" class="btn btn-default"
          aria-label="Go to Previous Week" style="font-size: 1.5em;" (click)="changeWeek(-1)">&lt; Previous
          Week</button></div>
      <div class="col-4" style="text-align:right;"><button type="button" class="btn btn-default"
          aria-label="Go to Next Week" style="font-size: 1.5em;" (click)="changeWeek(1)" [disabled]="viewingWeek===0">Next
          Week &gt;</button></div>
      <div class="col-3"></div>
    </div>
    <div class="row">
      <div class="col-4"></div>
      <div class="col-4" style="text-align:center;">{{loading}}</div>
      <div class="col-4"></div>
    </div>
    <table class="scenario" style="width:100%;" *ngIf="scenarios && scenarios.length>0"
      aria-label="Scenario Health details">
      <thead>
        <tr tabindex="0">
          <th scope="col" style="width:2%">#</th>
          <th scope="col" style="width:4%">Area</th>
          <th scope="col" style="width:15%">Critical Process Uptime</th>
          <th scope="col" style="width:10%">SME</th>
          <th scope="col" style="width:10%">Owner</th>
          <th scope="col" style="width:7%">Time Period where desired state is applicable</th>
          <th scope="col" style="width:4%" *ngFor="let number of [0,1,2,3,4,5,6]">
            {{ weekStart.clone().addDays(number) | date:"dd-MMM" : "UTC"}}</th>
          <th scope="col" style="width:3%">%</th>
          <th scope="col" style="width:10%">Comments</th>
          <th scope="col" style="width:10%">ICM</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let scenario of scenarios; let i=index; let even=even;" [ngClass]="{'even': even}">
          <td align="center">{{scenario.scenario.serialNumber}}</td>
          <td align="center">{{scenario.scenario.area}}</td>
          <td style="text-align:left;">{{scenario.scenario.name}}</td>
          <td align="center">{{scenario.scenario.sme}}</td>
          <td align="center">{{scenario.scenario.owner}}</td>
          <td align="center">{{scenario.scenario.description}}</td>
          <td *ngFor="let number of [0,1,2,3,4,5,6]; let j=index;" [ngClass]="{
                        'defaultStatus':getStatusClass(scenario.dailyStatus[j]) === '',
                        'danger-button':getStatusClass(scenario.dailyStatus[j]) === 'red',
                        'primary-button':getStatusClass(scenario.dailyStatus[j]) === 'green'
                      }">
            <div ngbDropdown class="d-inline-block" *ngIf="editable===true && j<=enabledTill">
              <button role="button"
                [title]="(getStatusClass(scenario.dailyStatus[j]) === '')? 'Set Status': 
                 ((getStatusClass(scenario.dailyStatus[j]) === 'red')?'Unhealthy':'Healthy')+' updated by '+scenario.updated[j].by+' on '+scenario.updated[j].on"
                [attr.aria-label]="(getStatusClass(scenario.dailyStatus[j]) === '')? 'Set Status': 
                  ((getStatusClass(scenario.dailyStatus[j]) === 'red')?'Unhealthy':'Healthy')+' updated by '+scenario.updated[j].by+' on '+scenario.updated[j].on"
                 class="btn" [attr.id]="i+'-'+j" ngbDropdownToggle>&nbsp;
              </button>
              <div ngbDropdownMenu [attr.aria-labelledby]="i+'-'+j"
                style="min-width: auto; padding:0.5rem 0.5rem;margin-left:-0.5rem;">
                <button role="button" class="dropdown-item danger-button" title="Unhealthy" aria-label="Unhealthy"
                  (click)="setStatus(scenario.scenario.scenarioId,i,j,0)">&nbsp;</button>
                <button role="button" class="dropdown-item primary-button" title="Healthy" aria-label="Healthy"
                  (click)="setStatus(scenario.scenario.scenarioId,i,j,1)">&nbsp;</button>
              </div>
            </div>
          </td>
          <td align="center">{{getPercentage(scenario.dailyStatus)}}</td>
          <td [attr.contenteditable]="editable" (blur)="setComment(scenario.scenario.scenarioId,i,$event.target.textContent)"
            [textContent]="scenario.comments?scenario.comments:''" style="word-break:break-all"></td>
          <td [attr.contenteditable]="editable" (blur)="setIcM(scenario.scenario.scenarioId,i,$event.target.textContent)" [textContent]="scenario.icm?scenario.icm:''"
            style="word-break:break-all">
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  
<div
  *ngIf="renderComponent"
  class="mt-2 mx-1"
  style="min-height: 25vh; overflow-x: hidden; overflow-y: hidden"
>
  <div
    ngbNav
    #nav="ngbNav"
    class="nav-tabs"
    role="tablist"
    aria-label="tablist"
    [destroyOnHide]="false"
    (shown)="handleGraphDisplay($event)"
    [activeId]="+activeId"
    animation="false"
  >
    <div
      *ngFor="let tabName of tabNames; let i = index"
      [ngbNavItem]="i"
    >
      <ng-container *ngIf="this.enableTab[i]">
        <a ngbNavLink class="link-text">
          {{ tabName }}
        </a>
      </ng-container>
      <ng-container
        *ngIf="
          this.componentTypes[i] === 'Object' && this.enableTab[i] === true"
      >
        <ng-template ngbNavContent>
          <app-display-object
            [displayObject]="this.componentInputs[i]"
          ></app-display-object>
        </ng-template>
      </ng-container>
      <ng-container
        *ngIf="this.componentTypes[i] === 'Graph' && this.enableTab[i] === true"
      >
        <ng-template ngbNavContent>
          <app-display-graph
            [graphName]="this.componentInputs[i]"
            [redraw]="redrawGraph"
          ></app-display-graph>
        </ng-template>
      </ng-container>
      <ng-container
        *ngIf="this.componentTypes[i] === 'Table' && this.enableTab[i] === true"
      >
        <ng-template ngbNavContent>
          <app-display-table
            [rows]="this.componentInputs[i]?.rows"
            [columns]="this.componentInputs[i]?.columns"
          >
          </app-display-table>
        </ng-template>
      </ng-container>
      <ng-container
        *ngIf="
          this.componentTypes[i] === 'Report' && this.enableTab[i] === true"
      >
        <ng-template ngbNavContent>
          <app-power-bi-input
            [reportInput]="this.componentInputs[i]"
          ></app-power-bi-input>
        </ng-template>
      </ng-container>
      <ng-container
        *ngIf="
          this.componentTypes[i] === 'ProcessMap' && this.enableTab[i] === true"
      >
        <ng-template ngbNavContent>
          <app-display-process-map
            [redrawGraph]="this.drawProcessMap"
          ></app-display-process-map>
        </ng-template>
      </ng-container>
      <ng-container
        *ngIf="
          this.componentTypes[i] === 'QueryOptions' &&
          this.enableTab[i] === true
        "
      >
        <ng-template ngbNavContent>
          <app-display-query-option></app-display-query-option>
        </ng-template>
      </ng-container>
      <ng-container
        *ngIf="
          this.componentTypes[i] === 'ProcessMapV2' &&
          this.enableTab[i] === true
        "
      >
        <ng-template ngbNavContent>
          <app-display-process-map-v2
            [redrawGraph]="this.drawProcessMapV2"
          ></app-display-process-map-v2>
        </ng-template>
      </ng-container>
    </div>
  </div>
  <div [ngbNavOutlet]="nav" class="mt-2"></div>
</div>

import { Component, OnInit, Input, Output, OnDestroy, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { GraphMap } from '../common/graph/graph-map';
import { ProcessPath } from '../model/graph.model';
import { UntypedFormBuilder, UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import { PathTags } from '../model/path-tags.model';
import { ProcessService } from '../service/process.service';

@Component({
  selector: 'app-path-tagging-label',
  templateUrl: './path-tagging-label.component.html',
  styleUrls: ['./path-tagging-label.component.scss'],
  providers: [ProcessService]
})
export class PathTaggingLabelComponent implements OnInit, OnDestroy {
  @Input() processId: string;
  @Input() graphMap: GraphMap;
  @Output() handleSubmit: EventEmitter<any> = new EventEmitter<any>();

  pathTagData = new Array<PathTags>();
  currentEventSequenceTableShown = new Array<string>();
  eventSequencePathIdMapping = new Map<string, string>();

  currentFormIndexShown: number;
  currentEventSeqShownId: number;
  currentIndexShown: number;
  predefinedTagsSize: number;
  previousToggleIndex: number;

  showEventSequenceTable: boolean;
  disableSave: boolean;
  isDropdownLoading: boolean;
  showSuccessMessage: boolean;
  showError: boolean;
  isSaving: boolean;
  showSequence: boolean;

  errorMessage: string;
  successMessage: string;
  defaultPathTag: string;

  updateTagSubscription: Subscription;
  allSubscriptions: Subscription;

  predefinedTags: Set<string>;

  tagSelector = this.fb.group({
    pathTag: this.fb.array([]),
  });

  displayOptions: Array<boolean>;
  labelTagArr: Array<string>;

  constructor(public fb: UntypedFormBuilder, private processService: ProcessService) {
    this.isDropdownLoading = false;
    this.disableSave = true;
    this.isSaving = false;
    this.showError = false;
    this.showSuccessMessage = false;
    this.showSequence = false;
    this.defaultPathTag = "Type or Select Category";
    this.allSubscriptions = new Subscription();
    this.displayOptions = new Array<boolean>();
    this.labelTagArr = new Array<string>();
    this.predefinedTags = new Set<string>(['Happy Path', 'Not Desirable', 'Process Error', 'Data Error']);
    this.predefinedTagsSize = this.predefinedTags.size;
  }

  ngOnInit(): void {
    this.isDropdownLoading = true;
    const paths: Array<ProcessPath> = this.graphMap.getGraphPaths();

    const tagSubscription = this.processService.getProcessTagMap(this.processId).subscribe(
      (labeledPathData: Map<string, string>) => {
        for (const path of paths) {
          // has been sliced to get rid of "start" and "end" in the string
          const pathSequence = path.nodeEventPath.slice(1, path.nodeEventPath.length - 1).join('->');
          // reverse mapping
          this.eventSequencePathIdMapping.set(path.id, pathSequence);

          // find index in labeledPathData where labelled path may already exist
          const labeledPathCategory = labeledPathData.get(pathSequence);
          this.displayOptions.push(false);  // bool array for toggle display

          // if current path sequence is labelled, will be pushed with its label
          if (labeledPathCategory !== undefined) {
            this.pathTagData.push({pathSequence: path.id, tags: labeledPathCategory });
            this.labelTagArr.push(labeledPathCategory);
          } else {
            this.pathTagData.push({pathSequence: path.id, tags: null });
            this.labelTagArr.push("Type or Select Category");
          }
        }
        // creates dynamic form controls
        this.createPathTagForm(this.pathTagData);
        this.isDropdownLoading = false;
      },
      (err: HttpErrorResponse) => {
        this.isDropdownLoading = false;
        this.showError = true;
        this.errorMessage = err.message;
      }
    );
    this.allSubscriptions.add(tagSubscription);
  }

  get pathTagForm(): UntypedFormArray {
    return this.tagSelector.controls["pathTag"] as UntypedFormArray;
  }

  createPathTagForm(pathsData: Array<PathTags>) {
    for (let i = 0; i < pathsData.length; i++) {
      let updatedPathTagValue: string;
      let updatedInputValue: string;

      if (pathsData[i].tags == null) {
        updatedPathTagValue = this.defaultPathTag;
      } else {
        if (this.predefinedTags.has(pathsData[i].tags)) {
          updatedPathTagValue = pathsData[i].tags;
          updatedInputValue = "";
        } else {
          updatedInputValue = "";
          updatedPathTagValue = "";
        }
      }

      const newPath = this.fb.group({
        pathId: pathsData[i].pathSequence,
        tag: [updatedPathTagValue],
        formInput: [updatedInputValue],
        serialId: i
      });

      const tagChanges = newPath.valueChanges.subscribe(value => {
        const index = value.serialId;
        const targetFormGroup = this.pathTagForm.at(index) as UntypedFormGroup;
        const targetTagControl = targetFormGroup.get("tag");
        const targetInputControl = targetFormGroup.get("formInput");

        if (targetInputControl.value !== "" && targetTagControl.value === this.labelTagArr[index]) {
          // typing something after select
          targetFormGroup.controls["tag"].setValue("", { emitEvent: false });
          this.labelTagArr[index] = targetInputControl.value;
        } else if (targetInputControl.value === this.labelTagArr[index] && targetTagControl.value !== "") {
          // selecting something after formInput
          targetFormGroup.controls["formInput"].setValue("", { emitEvent: false });
          this.labelTagArr[index] = targetTagControl.value;
        } else if (targetTagControl.value !== "") {
          // initial select or multiple selects
          targetFormGroup.controls["formInput"].setValue("", { emitEvent: false });
          this.labelTagArr[index] = targetTagControl.value;
          // or keystroke is backspace
        } else {
          // initial formInput or continuous formInput
          targetFormGroup.controls["tag"].setValue("", { emitEvent: false });
          this.labelTagArr[index] = targetInputControl.value;
        }
      });
      this.pathTagForm.push(newPath);
      this.allSubscriptions.add(tagChanges);
    }
  }

  toggleDisplayOptions(currentToggleIndex: number) {
    this.displayOptions[currentToggleIndex] = !this.displayOptions[currentToggleIndex];
    if (this.previousToggleIndex != null && this.previousToggleIndex !== currentToggleIndex) {
      this.displayOptions[this.previousToggleIndex] = false;
    }
    this.previousToggleIndex = currentToggleIndex;
  }

  onSubmit() {
    this.isSaving = true;
    const processServiceTagData = new Array<PathTags>();

    // creates array with only changed form values, translated sequence of changed value
    for (let i = 0; i < this.pathTagForm.length; i++) {
      if (this.labelTagArr[i] !== this.pathTagData[i].tags && this.labelTagArr[i] !== this.defaultPathTag) {
        const translatedIdSequence = this.eventSequencePathIdMapping.get(this.pathTagForm.value[i].pathId);
        processServiceTagData.push({ pathSequence: translatedIdSequence, tags: this.labelTagArr[i] });
      }
    }
    this.updateTagSubscription = this.processService.updateProcessTags(this.processId, processServiceTagData).subscribe(
      (data: Array<PathTags>) => {
        this.showSuccessMessage = true;
        this.disableSave = true;
        this.showError = false;
        this.successMessage = "Tags have been saved and updated";
        this.isSaving = false;
        this.showSequence = false;
        this.handleSubmit.emit();
      },
      (err: HttpErrorResponse) => {
        this.showSuccessMessage = false;
        this.isSaving = false;
        this.showError = true;
        this.errorMessage = err.message;
      }
    );

    this.allSubscriptions.add(this.updateTagSubscription);
  }

  handleCancel() {
    this.updateTagSubscription.unsubscribe();
    this.isDropdownLoading = false;
  }

  disableSaveButtonHandler() {
    this.disableSave = false;
  }

  showEventSequenceButtonHandler(sequenceIndex: number) {
    this.showEventSequenceTable = !this.showEventSequenceTable;
    this.currentFormIndexShown = sequenceIndex;

    this.currentEventSeqShownId = this.pathTagForm.value[sequenceIndex].pathId;
    this.currentEventSequenceTableShown = this.eventSequencePathIdMapping.get(this.pathTagForm.value[sequenceIndex].pathId).split('->');
  }

  ngOnDestroy(): void {
    this.allSubscriptions.unsubscribe();
  }
}

<!-- title -->
<a class="link-text float-right mr-5" href={{wikiUrl}} target="_blank" style="font-size: 1.25em;" rel="noreferrer noopener">Wiki</a> 
<h5 class="display-5 ml-3 mt-3">BAM Process Discovery </h5>

<hr>
<!-- Business Process selection block. -->
<form (ngSubmit)="processResults()">
  <div class="card bg-transparent mx-2">
    <div class="card-header">
      <div class="d-flex flex-rows">
        <div class="border border-dark rounded ml-1 mt-2" id="Treeview">
          <!-- Treeview -->
          <app-tree-view [loadProcess]="true" (valueChange)="serviceTreeHandler($event)"></app-tree-view>
        </div>
        <div class="mt-2">
          <button class="btn btn-sm primary-button ml-2" type="submit" [disabled]="!isProcessSelected || isGraphLoading">
            Refresh
          </button>
        </div>
        <div class="ml-2 mt-2">
          <button class="btn btn-sm danger-button mr-2" type="button" [disabled]="!isGraphLoading"
            (click)="cancelClick()">
            Cancel
          </button>
        </div>
        <div *ngIf="isGraphLoading" class="mt-2">
          <span class="text-primary fa fa-spinner fa-spin fa-2x" aria-label="Processing"></span>
        </div>
      </div>
    </div>
  </div>
</form>

<!-- tab component -->
<app-display-tab (tabselectionEvent)="tabPathHandler($event)" [urlTabParameter]="urlProcessTab"> </app-display-tab>

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PowerBiInputComponent } from './power-bi-input/power-bi-input.component';

@NgModule({
  declarations: [PowerBiInputComponent],
  imports: [
    CommonModule
  ],
  exports: [
    PowerBiInputComponent
  ]
})
export class PowerBIModule { }

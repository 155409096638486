import { Component, Inject, OnInit } from '@angular/core';
import { APP_CONFIG } from '../../common/constants';
import { AppConfig } from '../../model/app-config.model';
import { ReportInput } from '../../power-bi/model/power-bi.model';

@Component({
  selector: 'app-event-activity',
  templateUrl: './event-activity.component.html',
  styleUrls: ['./event-activity.component.css']
})
export class EventActivityComponent implements OnInit {
  powerBIConfig: ReportInput;
  constructor(@Inject(APP_CONFIG) appConfig: AppConfig) {
    this.powerBIConfig = appConfig.eventActivityPowerBIReport;
  }

  ngOnInit() {
  }
}

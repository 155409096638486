import { Component, Inject, Input, OnDestroy, OnInit } from "@angular/core";
import {
  AbstractControl,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from "@angular/forms";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { Subscription } from "rxjs";
import { EventTypeSubscriptionMasterData } from "../model/event-type-subscription-master-data.model";
import { EventTypeSubscription } from "../model/event-type-subscription.model";
import { EventType } from "../model/event-type.model";
import { EventCatalogService } from "../service/event-catalog.service";
import { EventHandler } from "../model/event-handler.model";
import { GraphDataService } from "../../service/graph-data.service";
import { switchMap } from "rxjs/operators";
import { ProblemDetailsHttpErrorResponse } from "../../common/model/problem-details-http-error-response.model";
import { APP_CONFIG } from "../../common/constants";
import { AppConfig } from "../../model/app-config.model";

@Component({
  selector: "app-save-subscription",
  templateUrl: "./save-subscription.component.html",
  styleUrls: ["./save-subscription.component.scss"],
})
export class SaveSubscriptionComponent implements OnInit, OnDestroy {
  @Input() eventTypeSubscription: EventTypeSubscription;
  @Input() parentEventTypeId: number;
  @Input() parentEventTypeName: string;

  parentEvent: EventType;
  isUpdate: boolean;

  subscriptionForm = new UntypedFormGroup(
    {
      eventName: new UntypedFormControl("", [Validators.required]),
      name: new UntypedFormControl("", [Validators.required, Validators.pattern("^[A-Z]+[A-Za-z0-9.]*$")]),
      description: new UntypedFormControl("", [Validators.required]),
      contact: new UntypedFormControl("", [Validators.required, Validators.email]),
      ownerGroupAlias: new UntypedFormControl("", [Validators.required, Validators.email]),
      team: new UntypedFormControl("", [Validators.required]),
      service: new UntypedFormControl("", [Validators.required]),
      serviceId: new UntypedFormControl("", [
        Validators.required,
        Validators.pattern("^[A-Fa-f0-9]{8}-[A-Fa-f0-9]{4}-[A-Fa-f0-9]{4}-[A-Fa-f0-9]{4}-[A-Fa-f0-9]{12}$"),
      ]),
      filters: new UntypedFormControl("", [this.jsonValidator]),
      enableAlerts: new UntypedFormControl(""),
      minimumDelayBetweenAlerts: new UntypedFormControl(""),
      minimumDelayBetweenAlertsUnit: new UntypedFormControl(""),
      enableIcM: new UntypedFormControl(""),
      icmRoutingId: new UntypedFormControl("", [Validators.required, Validators.pattern("^..*\\\\..*$")]),
      severity: new UntypedFormControl("", [Validators.required, Validators.pattern("^[1-4]*$")]),
      eventHandlerType: new UntypedFormControl("", [Validators.required, this.supportedEventHandlerTypesValidator]),
      eventHandlerParams: new UntypedFormGroup({}),
    },
    { validators: [this.minimumDelayBetweenAlertsValidator, this.icmSelectionValidator] }
  );

  masterData: EventTypeSubscriptionMasterData;
  subscription: Subscription;
  isLoadingParentEvent: boolean;
  isLoadingMasterData: boolean;
  isSaving: boolean;
  showError: boolean;
  errorMessage: string;
  isValidating: boolean;
  eventHandlerValidationError: string;
  icmTeamPublicIdLookupUrl: string;

  supportedEventHandlers: Array<string> = ["WebHook", "EventHub", "ServiceBusQueue", "ServiceBusTopic"];

  constructor(
    private activeModal: NgbActiveModal,
    private eventCatalogService: EventCatalogService,
    private graphDataService: GraphDataService,
    @Inject(APP_CONFIG) private appConfig: AppConfig
  ) {
    this.subscription = new Subscription();
    this.isLoadingMasterData = false;
    this.showError = false;
    this.errorMessage = "";
    this.isUpdate = false;
    this.isValidating = false;
    this.eventHandlerValidationError = "";
    this.icmTeamPublicIdLookupUrl = this.appConfig.icmTeamPublicIdLookupUrl;
  }

  ngOnInit(): void {
    // subscribe to alert config
    this.onEnableAlertChange();
    this.onEnableIcMChange();

    // load existing event type configuration if needed
    if (this.eventTypeSubscription !== undefined) {
      this.isUpdate = true;
      this.parentEventTypeId = this.eventTypeSubscription.parentEventId;
      this.parentEventTypeName = this.eventTypeSubscription.parentEventName;

      // disable immutable fields
      this.subscriptionForm.controls.eventHandlerType.disable();
      // load values into form group
      this.subscriptionForm.controls.name.setValue(this.eventTypeSubscription.name);
      this.subscriptionForm.controls.description.setValue(this.eventTypeSubscription.description);
      this.subscriptionForm.controls.contact.setValue(this.eventTypeSubscription.contactAlias);
      this.subscriptionForm.controls.ownerGroupAlias.setValue(this.eventTypeSubscription.ownerGroupAlias);
      this.subscriptionForm.controls.team.setValue(this.eventTypeSubscription.teamName);
      this.subscriptionForm.controls.service.setValue(this.eventTypeSubscription.serviceName);
      this.subscriptionForm.controls.serviceId.setValue(this.eventTypeSubscription.serviceId);
      this.subscriptionForm.controls.filters.setValue(this.eventTypeSubscription.filters);
      this.subscriptionForm.controls.enableAlerts.setValue(this.eventTypeSubscription.enableDeliveryFailureEmail);
      this.subscriptionForm.controls.minimumDelayBetweenAlertsUnit.setValue(
        this.eventTypeSubscription.minDeliveryFailureAlertIntervalUnit
      );
      this.subscriptionForm.controls.minimumDelayBetweenAlerts.setValue(
        this.eventTypeSubscription.minDeliveryFailureAlertInterval
      );
      this.subscriptionForm.controls.enableIcM.setValue(this.eventTypeSubscription.enableDeliveryFailureIcM);
      this.subscriptionForm.controls.icmRoutingId.setValue(this.eventTypeSubscription.icmRoutingId);
      this.subscriptionForm.controls.severity.setValue(this.eventTypeSubscription.severity);
      this.subscriptionForm.controls.eventHandlerType.setValue(this.eventTypeSubscription.eventHandler.ehType);
      this.createEventHandlerFormGroup(this.eventTypeSubscription.eventHandler);
    } else {
      // Initialize alert controls
      this.minimunDelayControlEnablement(false);
      this.subscriptionForm.controls.enableAlerts.setValue(false);
      this.subscriptionForm.controls.minimumDelayBetweenAlerts.setValue(0);
      this.subscriptionForm.controls.minimumDelayBetweenAlertsUnit.setValue(null);
      //Initialize icm controls
      this.icmControlEnablement(false);
      this.subscriptionForm.controls.enableIcM.setValue(false);
      this.subscriptionForm.controls.icmRoutingId.setValue(null);
      this.subscriptionForm.controls.severity.setValue(4);
    }

    // this is passed in and immutable
    this.subscriptionForm.controls.eventName.setValue(this.parentEventTypeName);
    this.subscriptionForm.controls.eventName.disable();

    // get master data for dropdowns
    this.isLoadingMasterData = true;
    const masterDataSubscription = this.eventCatalogService
      .getEventTypeSubscriptionMasterData()
      .subscribe(
        (masterData: EventTypeSubscriptionMasterData) => {
          this.masterData = masterData;
        },
        (errResponse: ProblemDetailsHttpErrorResponse) => {
          console.error("Error occurred while fetching event type master data", errResponse);
          this.setError(errResponse.error.title);
          this.isSaving = false;
        }
      )
      .add(() => (this.isLoadingMasterData = false));
    this.subscription.add(masterDataSubscription);

    // get parent event
    this.isLoadingParentEvent = true;
    const parentEventSubscription = this.eventCatalogService.getEventTypeByName(this.parentEventTypeName).subscribe(
      (eventType: EventType) => {
        this.parentEvent = eventType;
        this.isLoadingParentEvent = false;
      },
      (errResponse: ProblemDetailsHttpErrorResponse) => {
        console.error("Error occurred while fetching parent event type for subscription", errResponse);
        this.setError(errResponse.error.title);
        this.isSaving = false;
      }
    );
    this.subscription.add(parentEventSubscription);

    // subscribe to event handler type change
    this.onEventHandlerTypeChange();

    // subscribe to alert config
    this.onEnableAlertChange();
    this.onEnableIcMChange();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  cancel(): void {
    this.activeModal.dismiss();
  }

  saveSubscription(): void {
    this.isSaving = true;

    const savedSubscription = new EventTypeSubscription();

    if (this.isUpdate) {
      savedSubscription.id = this.eventTypeSubscription.id;
      savedSubscription.rowVersion = this.eventTypeSubscription.rowVersion;
    }

    savedSubscription.name = this.subscriptionForm.controls.name.value as string;
    savedSubscription.description = this.subscriptionForm.controls.description.value as string;
    savedSubscription.schema = this.parentEvent.schemaType;
    savedSubscription.contactAlias = this.subscriptionForm.controls.contact.value as string;
    savedSubscription.ownerGroupAlias = this.subscriptionForm.controls.ownerGroupAlias.value as string;
    savedSubscription.teamName = this.subscriptionForm.controls.team.value as string;
    savedSubscription.serviceName = this.subscriptionForm.controls.service.value as string;
    savedSubscription.serviceId = this.subscriptionForm.controls.serviceId.value as string;
    savedSubscription.filters = this.subscriptionForm.controls.filters.value as string;
    savedSubscription.enableDeliveryFailureEmail = this.subscriptionForm.controls.enableAlerts.value as boolean;
    savedSubscription.minDeliveryFailureAlertInterval = this.subscriptionForm.controls.minimumDelayBetweenAlerts
      .value as number;
    savedSubscription.minDeliveryFailureAlertIntervalUnit = this.subscriptionForm.controls.minimumDelayBetweenAlertsUnit
      .value as string;
    savedSubscription.enableDeliveryFailureIcM = this.subscriptionForm.controls.enableIcM.value as boolean;
    savedSubscription.icmRoutingId = this.subscriptionForm.controls.icmRoutingId.value as string;
    savedSubscription.severity = this.subscriptionForm.controls.severity.value as number;
    savedSubscription.parentEventName = this.parentEventTypeName;
    savedSubscription.parentEventId = this.parentEventTypeId;

    const ehType = this.subscriptionForm.controls.eventHandlerType.value as string;
    savedSubscription.eventHandler = this.getEventHandlerFromFormGroup(
      ehType,
      this.subscriptionForm.get("eventHandlerParams") as UntypedFormGroup
    );

    // all other fields will be updated by API

    // get group id for alias then submit to save
    const groupId$ = this.graphDataService.getGroupId(savedSubscription.ownerGroupAlias);
    const saveEventTypeSubSubscription = groupId$
      .pipe(
        switchMap((groupId: string) => {
          savedSubscription.ownerGroupId = groupId;
          if (this.isUpdate) {
            return this.eventCatalogService.updateEventTypeSubscription(savedSubscription);
          } else {
            return this.eventCatalogService.createEventTypeSubscription(savedSubscription, this.parentEvent);
          }
        })
      )
      .subscribe(
        () => {
          this.activeModal.close(savedSubscription.name);
        },
        (errResponse: ProblemDetailsHttpErrorResponse) => {
          console.error("Error occurred while saving subscription", errResponse);
          this.setError(errResponse.error.title);
          this.isSaving = false;
        }
      );

    this.subscription.add(saveEventTypeSubSubscription);
  }

  setError(msg: string) {
    this.showError = true;
    this.errorMessage = msg;
  }

  clearError() {
    this.showError = false;
    this.errorMessage = "";
  }

  getFormGroupKeys(f: UntypedFormGroup): string[] {
    return Object.keys(f?.controls ?? {});
  }

  onEnableAlertChange() {
    const enableAlertSubscription = this.subscriptionForm.get("enableAlerts").valueChanges.subscribe((response) => {
      this.minimunDelayControlEnablement(response);
    });
    this.subscription.add(enableAlertSubscription);
  }

  onEnableIcMChange() {
    const enableIcMSubscription = this.subscriptionForm.get("enableIcM").valueChanges.subscribe((response) => {
      this.icmControlEnablement(response);
    });
    this.subscription.add(enableIcMSubscription);
  }

  onEventHandlerTypeChange(): void {
    const eventHandlerTypeSubscription = this.subscriptionForm
      .get("eventHandlerType")
      .valueChanges.subscribe((val: string) => {
        const eh = this.masterData.eventHandler.find((e) => e.ehType === val);
        this.createEventHandlerFormGroup(eh);
      });

    this.subscription.add(eventHandlerTypeSubscription);
  }

  createEventHandlerFormGroup(eventHandler: EventHandler) {
    const ehForms: { [key: string]: UntypedFormControl } = {};

    eventHandler.requiredArgs.forEach((a) => {
      ehForms[a.displayName] = new UntypedFormControl(a.value, Validators.required);
      if (this.isUpdate && !a.canUpdate) {
        ehForms[a.displayName].disable();
      }
    });
    //eventHandler.optionalArgs.forEach(a => ehForms[a.displayName] = new FormControl(a.value));

    const formGroup = new UntypedFormBuilder().group(ehForms);
    this.subscriptionForm.setControl("eventHandlerParams", formGroup);
  }

  getEventHandlerFromFormGroup(ehType: string, fg: UntypedFormGroup): EventHandler {
    const eh = this.masterData.eventHandler.find((e) => e.ehType === ehType);
    eh.requiredArgs.forEach((a) => (a.value = fg.controls[a.displayName].value));
    //eh.optionalArgs.forEach(a => a.value = fg.controls[a.displayName].value);

    return eh;
  }

  minimumDelayBetweenAlertsValidator(group: AbstractControl): { [key: string]: any } | null {
    const enableAlerts = group.get("enableAlerts").value;
    const minimumDelayBetweenAlerts = group.get("minimumDelayBetweenAlerts").value;
    const minimumDelayBetweenAlertsUnit = group.get("minimumDelayBetweenAlertsUnit").value;

    if (
      enableAlerts &&
      (minimumDelayBetweenAlerts === undefined ||
        minimumDelayBetweenAlertsUnit === undefined ||
        minimumDelayBetweenAlertsUnit === null ||
        minimumDelayBetweenAlerts <= 0)
    ) {
      group.get("minimumDelayBetweenAlerts").markAsTouched();
      return {
        isMinimumDelayError: true,
        minimumDelayError: "Please select valid Minimun Delay",
      };
    } else {
      return null;
    }
  }

  icmSelectionValidator(group: AbstractControl): { [key: string]: any } | null {
    const isEnable = (group.get("enableIcM") as UntypedFormControl).value;
    const isValid = (group.get("icmRoutingId") as UntypedFormControl).valid;
    const enableAlerts = (group.get("enableAlerts") as UntypedFormControl).value;

    if (isEnable && !isValid) {
      return { isIcmSelectError: true, IcmSelectError: "Please enter valid IcM Team Public Id" };
    } else if (isEnable && !enableAlerts) {
      return {
        isAlertDisabledError: true,
        AlertDisabledError: "Please enable delivery failure alert configuration above to enable ICM",
      };
    } else {
      return null;
    }
  }

  jsonValidator(control: AbstractControl): ValidationErrors | null {
    if (control.value !== "") {
      try {
        JSON.parse(control.value);
      } catch (e) {
        return { jsonInvalid: true };
      }
    }

    return null;
  }

  supportedEventHandlerTypesValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const eventHandlerType = (control.value as string).replace(/\s/g, "");
      if (this.supportedEventHandlers !== undefined) {
        console.log("event handler type: ", eventHandlerType);
        if (this.supportedEventHandlers.findIndex((x: string) => x === eventHandlerType) === -1) {
          return { eventHandlerTypeNotSupported: true };
        } else {
          return null;
        }
      } else {
        return null;
      }
    };
  }

  shouldShowValidationError(formControl: AbstractControl): boolean {
    return formControl.invalid && formControl.touched && formControl.dirty;
  }

  validateEventHandler(): void {
    const eventType = this.parentEvent.name;
    const schemaType = this.parentEvent.schemaType;
    const eventHandlerType = this.subscriptionForm.controls.eventHandlerType.value as string;
    if (this.supportedEventHandlers.findIndex((x: string) => x === eventHandlerType.replace(/\s/g, "")) === -1) {
      return;
    }
    const eventHandler = this.getEventHandlerFromFormGroup(
      "WebHook",
      this.subscriptionForm.controls.eventHandlerParams as UntypedFormGroup
    );

    this.isValidating = true;
    const environment = this.appConfig.environment === "PROD" ? "PROD" : "PPE";
    const validationSubscription = this.eventCatalogService
      .getEventHandlerValidationStatus(eventHandler, eventHandlerType, eventType, schemaType, environment)
      .subscribe(
        () => {
          this.eventHandlerValidationError = "Event Handler Validated successfully.";
          this.isValidating = false;
        },
        (errorResponse: ProblemDetailsHttpErrorResponse) => {
          this.eventHandlerValidationError = errorResponse.error.title;
          this.isValidating = false;
        },
        () => {
          this.isValidating = false;
        }
      );
    this.subscription.add(validationSubscription);
  }

  private minimunDelayControlEnablement(response: any) {
    if (response === true) {
      this.subscriptionForm.get("minimumDelayBetweenAlerts").enable({ emitEvent: false });
      this.subscriptionForm.get("minimumDelayBetweenAlertsUnit").enable({ emitEvent: false });
    } else {
      this.subscriptionForm.get("minimumDelayBetweenAlerts").disable({ emitEvent: false });
      this.subscriptionForm.get("minimumDelayBetweenAlertsUnit").disable({ emitEvent: false });
    }
  }

  private icmControlEnablement(response: any): void {
    if (response === true) {
      this.subscriptionForm.get("icmRoutingId").enable({ emitEvent: false });
      this.subscriptionForm.get("severity").enable({ emitEvent: false });
    } else {
      this.subscriptionForm.get("icmRoutingId").disable({ emitEvent: false });
      this.subscriptionForm.get("severity").disable({ emitEvent: false });
      this.subscriptionForm.get("icmRoutingId").markAsUntouched();
    }
  }
}

import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.css', '../../style/common-styles.scss']
})
export class ContactUsComponent implements AfterViewInit {
  @ViewChild('titleDiv') titleDiv: ElementRef<HTMLElement>;
  constructor() { }

  ngAfterViewInit() {
    this.titleDiv.nativeElement.focus();
  }

}

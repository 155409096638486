export class Alert {

  public id: number;
  public frequencyType: string;
  public frequencyValue: number;
  public startTime: Date;
  public endTime: Date;
  public alertName: string;
  public description: string;
  public severity: number;
  public isDynamicIcmSeverityEnabled: boolean;
  public sev3Threshold: number;
  public sev2Threshold: number;
  public sev1Threshold: number;
  public isEmailEnabled: boolean;
  public isIcMEnabled: boolean;
  public alertEmail: string;
  public icmRoutingId: string;
  public lastRun: Date;
  public lastRunCompletedAt: Date;
  public lastModified: Date;
  public updatedBy: string;

}

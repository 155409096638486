import { OnInit, Input, Component, Inject } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UntypedFormGroup, UntypedFormControl, Validators, ValidatorFn, AbstractControl } from '@angular/forms';
import { cloneDeep } from 'lodash-es';
import { ScenarioHealthService } from '../service/scenario-health.service';
import { ScenarioPerformance } from '../model/scenarioStatus';
import { IcMIncident } from '../model/icmIncident';
import { APP_CONFIG } from '../../common/constants';
import { AppConfig } from '../../model/app-config.model';

@Component({
  selector: 'app-details-update',
  templateUrl: './details-update.component.html',
  styleUrls: ['./details-update.component.css']
})
export class DetailsUpdateComponent implements OnInit {
  @Input() scenarioPerf: ScenarioPerformance;
  @Input() weekStart: Date;
  icmIncidents: Array<IcMIncident>;

  updateForm = new UntypedFormGroup({
    score: new UntypedFormControl('', [
      Validators.required,
      Validators.min(0),
      Validators.max(100),
      Validators.pattern(/^[0-9].*$/)]),
    summary: new UntypedFormControl(''),
    icmId: new UntypedFormControl('', [
      Validators.required,
      Validators.minLength(9),
      Validators.maxLength(9),
      Validators.pattern(/^[0-9]{1,9}$/),
    ]),
    icmComments: new UntypedFormControl('')
  }, { validators: [this.duplicateIcMValidator()] });

  showDeleteConfirmation: boolean;
  isLoading: boolean;
  showError: boolean;
  errorMessage: string;
  icmUrlPrefix: string;
  showDirtyIcMError: boolean;

  constructor(
    private activeModal: NgbActiveModal,
    private scenarioHealthService: ScenarioHealthService,
    @Inject(APP_CONFIG) appConfig: AppConfig
    ) {
    this.icmUrlPrefix = appConfig.icmUrlPrefix ?? "https://portal.microsofticm.com/imp/v3/incidents/details";
    this.showDeleteConfirmation = false;
    this.isLoading = false;
    this.showError = false;
    this.errorMessage = '';
    this.showDirtyIcMError = false;
  }

  ngOnInit() {
    this.icmIncidents = cloneDeep(this.scenarioPerf.icm);
    if (!this.icmIncidents) {
      this.icmIncidents = new Array<IcMIncident>();
    }
    this.updateForm.get('score').setValue(this.scenarioPerf.performance);
    this.updateForm.get('summary').setValue(this.scenarioPerf.comments);
  }

  cancelUpdate(): void {
    this.activeModal.dismiss();
  }

  updateDetails(): void {
    if (this.updateForm.get('icmId').dirty || this.updateForm.get('icmComments').dirty) {
      this.showDirtyIcMError = true;
      return;
    }

    // don't call the API, just return if no update
    if (JSON.stringify(this.scenarioPerf.icm) === JSON.stringify(this.icmIncidents)
          && this.scenarioPerf.performance === Number(this.updateForm.get('score').value)
          && this.scenarioPerf.comments === this.updateForm.get('summary').value) {
      this.activeModal.close(this.scenarioPerf);
    }

    const newScenarioPerf = cloneDeep(this.scenarioPerf);
    newScenarioPerf.performance = Number(this.updateForm.get('score').value);
    newScenarioPerf.comments = this.updateForm.get('summary').value as string;
    newScenarioPerf.icm = this.icmIncidents;

    this.isLoading = true;
    this.scenarioHealthService.UpdateWeeklyPerformance(newScenarioPerf.scenario.scenarioId, newScenarioPerf, this.weekStart).subscribe(
      () => {
        this.activeModal.close(newScenarioPerf);
      },
      (error: string) => {
        this.isLoading = false;
        this.showError = true;
        this.errorMessage = error;
      }
    );
  }

  addIcM() {
    const incidentNumber = this.updateForm.get('icmId').value as number;
    const comment = this.updateForm.get('icmComments').value as string;
    this.icmIncidents.push(new IcMIncident(incidentNumber, comment));
    this.resetDirtyIcMForms();
  }

  deleteIcM(id: number, comments: string) {
    const icmIndex = this.icmIncidents.findIndex((icm) => {
      return icm.icmNumber === id && icm.comments === comments;
    });

    if (icmIndex > -1) {
      this.icmIncidents.splice(icmIndex, 1);
    }

    this.updateForm.updateValueAndValidity();
  }

  duplicateIcMValidator(): ValidatorFn {
    return (group: AbstractControl): { [key: string]: any } | null => {
      const uniqueIcMCount = new Set(this.icmIncidents?.map((icm) => {
        return icm.icmNumber;
      })).size;

      if (this.icmIncidents?.length !== uniqueIcMCount) {
        return {
          duplicateIcMError: true
        };
      } else {
        return null;
      }
    };
  }

  resetDirtyIcMForms(): void {
    this.updateForm.get('icmId').reset();
    this.updateForm.get('icmComments').reset();
    this.showDirtyIcMError = false;
  }
}

<!-- title -->
<h5 class="display-5 mt-2 ml-2">Manage Instrumentation Keys</h5>
<hr />

<!-- service name. -->
<div class="ml-2">
  <div class="d-inline-flex">
    <div class="input-group input-group-sm mb-2">
      <div class="input-group-prepend">
        <label class="input-group-text" id="serviceNameLabel" style="width: 11rem;">Service Name
          <span class="danger-text ml-1" aria-label="Required Field">*</span>
          <span id="info_serviceName" class="fa fa-info-circle ml-2"
            ngbPopover="Service Name of application from Service Tree.">
          </span>
        </label>
      </div>
      <select class="custom-select" id="serviceNameSelect" [formControl]="serviceName" aria-label="Service Name"
        aria-required="true" aria-labelledby="serviceNameLabel" aria-describedby="info_serviceName"
        #ServiceNameSelectField style="min-width: 25rem;">
        <option disabled hidden>Loading...</option>
        <option disabled hidden>Select Service Name</option>
        <ng-container *ngFor="let serviceTreeService of serviceTreeServiceList">
          <option [ngValue]="serviceTreeService">{{ serviceTreeService.name }}</option>
        </ng-container>
      </select>
    </div>
    <div *ngIf="isServiceTreeServiceLoading" class="ml-2">
      <span
        role="progressbar"
        class="info-text fa fa-spinner fa-spin fa-2x"
        aria-label="Loading"
      ></span>
    </div>
  </div>

  <ng-container *ngIf="isServiceSelected">
    <!-- service oid. -->
    <div class="input-group input-group-sm mb-2">
      <div class="input-group-prepend">
        <label class="input-group-text" id="ServiceOidLabel" style="width: 11rem;">Service Oid
          <span id="info_serviceOid" class="fa fa-info-circle ml-2"
            ngbPopover="Service Oid is an unique id for the selected service. It is readonly field.">
          </span>
        </label>
      </div>
      <input type="text" [formControl]="serviceOid" class="form-control" aria-label="Service Oid"
        aria-labelledby="ServiceOidLabel" style="max-width: 25rem;" aria-describedby="info_serviceOid" readonly>
    </div>

    <!-- button to add ikey. -->
    <div class="d-inline-flex">
      <div class="input-group input-group-sm">
        <div class="input-group-prepend">
          <label class="input-group-text" id="ikeyLabel" style="width: 11rem"
            >Instrumentation Key
            <span class="danger-text ml-1" aria-label="Required Field">*</span>
          </label>
        </div>
        <input
          type="text"
          [formControl]="iKey"
          class="form-control p-0"
          aria-label="Instrumentation Key"
          aria-required="true"
          aria-labelledby="ikeyLabel"
          style="min-width: 25rem"
        />
      </div>
      <div class="ml-2">
        <button
          type="button"
          class="btn btn-sm btn-outline-dark py-0"
          (click)="addIkey()"
          style="width: 9rem; height: 1.9rem"
          aria-label="Add New Key Button"
          [disabled]="!iKey.valid"
        >
          <div class="fa-stack mr-1" style="cursor: pointer; font-size: 0.75em" aria-hidden="true">
            <i class="text-success fa fa-circle fa-stack-2x"></i>
            <i class="fa fa-plus fa-stack-1x fa-inverse"></i>
          </div>
          <span> Add New Key</span>
        </button>
      </div>
    </div>
    <!-- error text for AI Key. -->
    <div class="mb-2">
      <small
        class="alert-text"
        role="alert"
        aria-live="assertive"
        *ngIf="iKey.errors?.pattern"
        >Instrumentation Key should be a GUID.</small
      >
      <small
        class="alert-text"
        role="alert"
        aria-live="assertive"
        *ngIf="iKey.errors?.duplicateError"
        >Instrumentation Key already added.</small
      >
    </div>
    <!-- </div> -->

    <!-- list of current ikeys. -->
    <ng-container *ngIf="iKeyList.length > 0 || iKeysAdded.length > 0">
      <table class="table table-bordered table-striped" style="width: 46rem; max-width: 100%;">
        <thead class="thead-dark">
          <tr>
            <th scope="col" style="width: 2rem" id="h1">Index</th>
            <th scope="col" style="width: 40rem" id="h2">
              Instrumentation Key
            </th>
            <th scope="col" style="width: 3rem" id="h3">Delete</th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let ikey of iKeyList; let i = index">
            <tr>
              <td class="text-center" headers="h1">{{ i + 1 }}</td>
              <td headers="h2">{{ ikey }}</td>
              <td class="text-center" headers="h3">
                <a class="fa-stack" style="cursor: pointer; font-size:0.75em;" aria-label="Delete entry" [routerLink]="[]"
                  (click)="deleteIKey(ikey)">
                  <i class="text-danger fa fa-circle fa-stack-2x"></i>
                  <i class="fa fa-minus fa-stack-1x fa-inverse"></i>
                </a>
              </td>
            </tr>
          </ng-container>
          <ng-container *ngFor="let ikey of iKeysAdded; let i = index">
            <tr>
              <td class="text-center" headers="h1">New</td>
              <td headers="h2">{{ ikey }}</td>
              <td class="text-center" headers="h3">
                <a class="fa-stack" style="cursor: pointer; font-size:0.75em;" aria-label="Delete entry" [routerLink]="[]"
                  (click)="deleteIKey(ikey)">
                  <i class="text-danger fa fa-circle fa-stack-2x"></i>
                  <i class="fa fa-minus fa-stack-1x fa-inverse"></i>
                </a>
              </td>
            </tr>
          </ng-container>
        </tbody>
      </table>

      <!-- save/cancel buttons. -->
      <div class="d-flex flex-row">
        <button
          type="button"
          class="btn btn-sm primary-button"
          (click)="saveIkeyList()"
          [disabled]="isSaveInProgress || iKeysAdded.length === 0"
          aria-label="Save"
        >
          Save
        </button>
        <button
          type="button"
          class="btn btn-sm secondary-button ml-2"
          (click)="cancel()"
          [disabled]="isSaveInProgress"
          aria-label="Cancel"
        >
          Cancel
        </button>
        <ng-container *ngIf="isSaveInProgress || isDeleteInProgress">
          <span
            class="info-text fa fa-spinner fa-spin fa-2x ml-2 mt-2"
            role="progressbar"
            aria-label="Loading"
          ></span>
        </ng-container>
      </div>
    </ng-container>
  </ng-container>
</div>

import {
  Component, OnInit, EventEmitter, Output,
  Input, AfterViewInit, OnDestroy
} from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { ServiceTreeService } from '../../service/service-tree.service';
import { MasterDataService } from '../service/master-data.service';
import { filter, mergeMap } from 'rxjs/operators';
import { Organization, ServiceGroup, TeamGroup, Process, ServiceTreeSelection } from '../model/process.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgbModalErrorComponent } from '../../modal/ngb-modal-error.component';
import { LocalCacheService } from '../../service/local-cache.service';
import { constants } from '../common/constants';
import { forkJoin, Subscription } from 'rxjs';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';

@Component({
  selector: 'app-service-tree',
  templateUrl: './service-tree.component.html',
  styleUrls: ['./service-tree.component.css']
})
export class ServiceTreeComponent implements OnInit, AfterViewInit, OnDestroy {

  // form options.
  serviceTreeForm = new UntypedFormGroup({
    organization: new UntypedFormControl(''),
    serviceGroups: new UntypedFormControl(''),
    teamGroups: new UntypedFormControl(''),
    businessProcesses: new UntypedFormControl('')
  });

  // process meta data.
  organizationList: Array<Organization>;
  serviceGroupList: Array<ServiceGroup>;
  teamGroupList: Array<TeamGroup>;
  businessProcessList: Array<Process>;

  subscriptions: Subscription;

  isLoading: boolean;

  // don't emit business process id if business process id is set using trackServiceTree.
  emitEvent: boolean;


  currentServiceTreeSelection: ServiceTreeSelection;
  /**
   * event which captures what the current service tree selection is.
   * subscribing components can use whichever field is needed and discard the rest.
   */
  @Output() serviceTreeSelectionEvent = new EventEmitter<ServiceTreeSelection>();

  /**
   * @deprecated. all events below are deprecated. Use serviceTreeSelection instead.
   */
  // Emitting properties to parent component; You can add new properties to send to any other parent component.
  @Output() organizationIdEvent = new EventEmitter<string>();

  /**
   * @deprecated
   */
  @Output() serviceGroupIdEvent = new EventEmitter<string>();

  /**
   * @deprecated
   */
  @Output() teamGroupIdEvent = new EventEmitter<string>();

  /**
   * @deprecated
   */
  @Output() teamGroupNameEvent = new EventEmitter<string>();

  /**
   * @deprecated
   */
  @Output() businessProcessIdEvent = new EventEmitter<string>();

  /**
   * @deprecated
   */
  @Output() resetControlsEvent = new EventEmitter<boolean>();

  /**
   * @deprecated
   */
  @Output() resetBusinessProcessEvent = new EventEmitter<boolean>();

  @Input() set trackServiceTree(businessProcessId: string) {
    const currentProcessId = this.localCacheService.getItem(constants.BUSINESS_PROCESS);
    let isBacktracking: boolean;
    if (businessProcessId != null && businessProcessId !== undefined) {
      isBacktracking = true;
      this.emitEvent = false;
    } else if (currentProcessId === businessProcessId) {
      isBacktracking = false;
      this.emitEvent = false;
    } else {
      isBacktracking = false;
      this.emitEvent = true;
    }

    if (isBacktracking) {
      this.currentServiceTreeSelection.backTrack = true;
      this.backtrackServiceTree(businessProcessId);
    } else {
      this.currentServiceTreeSelection.backTrack = false;
      this.loadServiceTreeData();
    }
  }

  @Input() loadLevels: number;

  constructor(
    private serviceTreeService: ServiceTreeService,
    private masterDataService: MasterDataService,
    private appInsightsService: ApplicationInsights,
    private modalService: NgbModal,
    private localCacheService: LocalCacheService
  ) {
    this.subscriptions = new Subscription();

    this.serviceTreeForm.get('organization').setValue('Select Organization');
    this.serviceTreeForm.get('serviceGroups').setValue('Select Service Group');
    this.serviceTreeForm.get('teamGroups').setValue('Select SCOR Function');
    this.serviceTreeForm.get('businessProcesses').setValue('Select Business Process');

    // form list data.
    this.organizationList = new Array<Organization>();
    this.serviceGroupList = new Array<ServiceGroup>();

    // set current service tree selection to null;
    this.currentServiceTreeSelection = new ServiceTreeSelection();
    this.currentServiceTreeSelection.organization = null;
    this.currentServiceTreeSelection.serviceGroup = null;
    this.currentServiceTreeSelection.teamGroup = null;
    this.currentServiceTreeSelection.process = null;
    this.currentServiceTreeSelection.backTrack = false;

    // register value changes
    this.registerOrganizationValueChanges();
    this.registerServiceGroupValueChanges();
    this.registerTeamGroupValueChanges();
    this.registerBusinessProcessValueChanges();

    this.emitEvent = true;
  }

  ngOnInit() { }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  ngAfterViewInit() {
    const focusElement: HTMLElement = document.getElementById('organizationSelect') as HTMLElement;
    focusElement.focus();
  }

  // This method loads the organization data from service-tree.
  loadServiceTreeData() {
    this.isLoading = true;
    this.appInsightsService.trackTrace({ message: 'Fetching Organizations'});
    this.serviceTreeForm.get('organization').setValue('Loading...');
    const orgListSubscription = this.serviceTreeService.getOrganizations().subscribe(
      (organizationList: Organization[]) => {
        this.appInsightsService.trackTrace({ message: 'Fetched Organizations'});
        this.organizationList = organizationList;

        // Organization: If cache have value then set that value in dropdown
        if (this.localCacheService.getItem(constants.ORGANIZATION) === null ||
          this.organizationList.filter(c => c.id === this.localCacheService.getItem(constants.ORGANIZATION)).length === 0) {
          this.serviceTreeForm.get('organization').setValue('Select Organization');
        } else {
          const cachedOrganization = this.organizationList.filter(c => c.id === this.localCacheService.getItem(constants.ORGANIZATION))[0];
          this.serviceTreeForm.get('organization').setValue(cachedOrganization.name);
        }

        this.isLoading = false;
      },
      (error) => {
        console.error('error occured in fetching organizations', error);
        this.appInsightsService.trackException(error);
        const errorModal = this.modalService.open(NgbModalErrorComponent);
        errorModal.componentInstance.message = 'Error fetching organizations. Please try again after sometime.';
        this.serviceTreeForm.setErrors({
          processSelectionInvalid: true,
          processSelectionMessage: 'Error loading organizations'
        });
        this.isLoading = false;
      }
    );

    this.subscriptions.add(orgListSubscription);
  }

  registerOrganizationValueChanges(): void {
    // Populate service groups based on selected organization.
    const orgChanges = this.serviceTreeForm.get('organization').valueChanges.pipe(
      filter((organizationName: string) => ['Select Organization', 'Loading...'].indexOf(organizationName) < 0),
      mergeMap((organizationName: string) => {
        this.isLoading = true;
        this.resetControlsEvent.emit(true);
        this.serviceTreeForm.get('serviceGroups').setValue('Loading...');
        this.appInsightsService.trackTrace({ message: 'Fetching Service Groups'});

        // Organization: Set selected Organization by user to local storage.
        const organizationId = this.getOrganizationId(organizationName);
        if (organizationId !== this.localCacheService.getItem(constants.ORGANIZATION)) {
          this.localCacheService.setItem(constants.ORGANIZATION, organizationId);
          if (this.localCacheService.getItem(constants.SERVICE_GROUP) !== null) {
            this.localCacheService.removeItem(constants.SERVICE_GROUP);
          }
          if (this.localCacheService.getItem(constants.TEAM_GROUP) !== null) {
            this.localCacheService.removeItem(constants.TEAM_GROUP);
          }
          if (this.localCacheService.getItem(constants.BUSINESS_PROCESS) !== null) {
            this.localCacheService.removeItem(constants.BUSINESS_PROCESS);
          }
        }
        const organization = this.organizationList.find(x => x.name === organizationName);
        this.setOrganization(organization);
        this.emitCurrentServiceTreeSelection();
        this.organizationIdEvent.emit(organizationId);
        return this.serviceTreeService.getServiceGroups(organizationId);
      }),
    ).subscribe(
      (serviceGroupList: ServiceGroup[]) => {
        this.appInsightsService.trackTrace({ message: 'Fetched Service Groups'});
        this.serviceGroupList = serviceGroupList;

        // Service Group: If cache have value then set that value in dropdown
        if (this.localCacheService.getItem(constants.SERVICE_GROUP) === null ||
          this.serviceGroupList.filter(c => c.id === this.localCacheService.getItem(constants.SERVICE_GROUP)).length === 0) {
          this.serviceTreeForm.get('serviceGroups').setValue('Select Service Group');
        } else {
          const cachedServiceGroup = this.serviceGroupList.filter(c => c.id === this.localCacheService.getItem(constants.SERVICE_GROUP))[0];
          this.serviceTreeForm.get('serviceGroups').setValue(cachedServiceGroup.name);
        }

        // Team Group: reset team group if value in cache is null
        if (this.localCacheService.getItem(constants.TEAM_GROUP) === null) {
          this.serviceTreeForm.get('teamGroups').setValue('Select SCOR Function');
          this.teamGroupList = new Array<TeamGroup>();
        }

        // Business Process: reset businessprocess if value in cache is null
        if (this.localCacheService.getItem(constants.BUSINESS_PROCESS) === null) {
          this.serviceTreeForm.get('businessProcesses').setValue('Select Business Process');
          this.businessProcessList = new Array<Process>();
          this.resetBusinessProcessEvent.emit(true);
        }

        this.isLoading = false;
        if (serviceGroupList.length === 0) {
          this.serviceTreeForm.setErrors({
            processSelectionInvalid: true,
            processSelectionMessage: 'No Service Group available for selected Organization'
          });
        }
      },
      (error) => {
        console.log('error occured while fetching service group list', error);
        this.appInsightsService.trackException(error);
        const errorModal = this.modalService.open(NgbModalErrorComponent);
        errorModal.componentInstance.message = 'Error occured while fetching service group list.';
        this.isLoading = false;
        this.serviceTreeForm.setErrors({
          processSelectionInvalid: true,
          processSelectionMessage: 'Error loading service group list'
        });
      }
    );

    this.subscriptions.add(orgChanges);
  }

  registerServiceGroupValueChanges(): void {
    // Populate team groups based on selected service group.
    const serviceGroupChanges = this.serviceTreeForm.get('serviceGroups').valueChanges.pipe(
      filter((serviceGroupName: string) => ['Select Service Group', 'Loading...'].indexOf(serviceGroupName) < 0),
      mergeMap((serviceGroupName: string) => {
        this.isLoading = true;
        this.resetControlsEvent.emit(true);
        this.serviceTreeForm.get('teamGroups').setValue('Loading...');
        this.appInsightsService.trackTrace({ message: 'Fetching Teamgroups'});

        // Service Group: Set selected service group by user to local storage.
        const serviceGroupId = this.getServiceGroupId(serviceGroupName);
        if (serviceGroupId !== this.localCacheService.getItem(constants.SERVICE_GROUP)) {
          this.localCacheService.setItem(constants.SERVICE_GROUP, serviceGroupId);
          if (this.localCacheService.getItem(constants.TEAM_GROUP) !== null) {
            this.localCacheService.removeItem(constants.TEAM_GROUP);
          }
          if (this.localCacheService.getItem(constants.BUSINESS_PROCESS) !== null) {
            this.localCacheService.removeItem(constants.BUSINESS_PROCESS);
          }
        }
        const serviceGroup = this.serviceGroupList.find(x => x.name === serviceGroupName);
        this.setServiceGroup(serviceGroup);
        this.emitCurrentServiceTreeSelection();
        this.serviceGroupIdEvent.emit(serviceGroupId);
        return this.masterDataService.getTeamGroups(serviceGroupId);
      }),
    ).subscribe(
      (teamGroupList: TeamGroup[]) => {
        this.appInsightsService.trackTrace({ message: 'Fetched Teamgroups'});
        this.teamGroupList = teamGroupList;

        // Team Group: If cache have value then set that value in dropdown
        if (this.localCacheService.getItem(constants.TEAM_GROUP) === null) {
          this.serviceTreeForm.get('teamGroups').setValue('Select SCOR Function');
        } else {
          const cachedTeamGroup = this.teamGroupList.filter(c => c.id === this.localCacheService.getItem(constants.TEAM_GROUP))[0];
          this.serviceTreeForm.get('teamGroups').setValue(cachedTeamGroup.name);
        }

        // Business Process: reset businessprocess if value in cache is null
        if (this.localCacheService.getItem(constants.BUSINESS_PROCESS) === null) {
          this.serviceTreeForm.get('businessProcesses').setValue('Select Business Process');
          this.businessProcessList = new Array<Process>();
          this.resetBusinessProcessEvent.emit(true);
        }

        this.isLoading = false;
        if (teamGroupList.length === 0) {
          this.serviceTreeForm.setErrors({
            processSelectionInvalid: true,
            processSelectionMessage: 'No SCOR Function available for selected organization'
          });
        }
      },
      (error) => {
        console.log('error occured while fetching team group list', error);
        this.appInsightsService.trackException(error);
        const errorModal = this.modalService.open(NgbModalErrorComponent);
        errorModal.componentInstance.message = 'Error occured while fetching team group list.';
        this.isLoading = false;
        this.serviceTreeForm.setErrors({
          processSelectionInvalid: true,
          processSelectionMessage: 'Error loading team group list'
        });
      }
    );

    this.subscriptions.add(serviceGroupChanges);
  }

  // Teamgroup selected value capture.
  registerTeamGroupValueChanges(): void {
    // populate business process based on team group selected
    const teamGroupChanges = this.serviceTreeForm.get('teamGroups').valueChanges.pipe(
      filter((teamGroupName: string) => ['Select SCOR Function', 'Loading...'].indexOf(teamGroupName) < 0),
      mergeMap((teamGroupName: string) => {
        this.isLoading = true;
        this.resetControlsEvent.emit(true);
        this.serviceTreeForm.get('businessProcesses').setValue('Loading...');
        this.appInsightsService.trackTrace({ message: 'Fetching BusinessProcesses'});

        // TeamGroup: Set selected TeamGroup by user to local storage.
        const teamGroupId = this.getTeamGroupId(teamGroupName);
        if (teamGroupId !== this.localCacheService.getItem(constants.TEAM_GROUP)) {
          this.localCacheService.setItem(constants.TEAM_GROUP, teamGroupId);
          if (this.localCacheService.getItem(constants.BUSINESS_PROCESS) !== null) {
            this.localCacheService.removeItem(constants.BUSINESS_PROCESS);
          }
        }
        const teamGroup = this.teamGroupList.find(x => x.name === teamGroupName);
        this.setTeamGroup(teamGroup);
        this.emitCurrentServiceTreeSelection();
        this.teamGroupIdEvent.emit(teamGroupId);
        this.teamGroupNameEvent.emit(teamGroupName);
        return this.masterDataService.getBusinessProcessList(teamGroupId);
      }),
    ).subscribe(
      (businessProcessList: Process[]) => {
        this.appInsightsService.trackTrace({ message: 'Fetched Business Process List'});
        this.businessProcessList = businessProcessList;

        if (businessProcessList.length === 0) {
          this.serviceTreeForm.get('businessProcesses').setValue('Select Business Process');
          this.resetBusinessProcessEvent.emit(true);
          // if service tree component is used by qos to load only top 3 levels, do not display the error message
          if (this.loadLevels !== 3) {
            this.serviceTreeForm.setErrors({
              processSelectionInvalid: true,
              processSelectionMessage: 'No business process configured for selected SCOR Function'
            });
          }
        } else {
          this.serviceTreeForm.get('businessProcesses').setValue('Select Business Process');
          this.resetBusinessProcessEvent.emit(true);

          // Business Process: If cache have value then set that value in dropdown
          if (this.localCacheService.getItem(constants.BUSINESS_PROCESS) === null) {
            this.serviceTreeForm.get('businessProcesses').setValue('Select Business Process');
            this.resetBusinessProcessEvent.emit(true);
          } else {
            const cachedBusinessProcess = businessProcessList
              .find(c => String(c.id) === this.localCacheService.getItem(constants.BUSINESS_PROCESS));
            this.serviceTreeForm.get('businessProcesses').setValue(cachedBusinessProcess.name);
          }
        }
        this.isLoading = false;
      },
      (error) => {
        this.appInsightsService.trackException(error);
        const errorModal = this.modalService.open(NgbModalErrorComponent);
        errorModal.componentInstance.message = 'Error occured while fetching business process list.';
        this.isLoading = false;
        this.serviceTreeForm.setErrors({
          processSelectionInvalid: true,
          processSelectionMessage: 'Error loading business process list'
        });
      }
    );

    this.subscriptions.add(teamGroupChanges);
  }

  // capture selected business process
  registerBusinessProcessValueChanges(): void {
    const processChanges = this.serviceTreeForm.get('businessProcesses').valueChanges.pipe(
      filter((businessProcessName: string) => ['Select Business Process', 'Loading...'].indexOf(businessProcessName) < 0),
    ).subscribe(
      (businessProcessName: string) => {
        this.resetControlsEvent.emit(true);
        // Business Process: Set selected business process by user to local storage.
        const businessProcessId = this.getBusinessProcessId(businessProcessName);
        this.localCacheService.setItem(constants.BUSINESS_PROCESS, String(businessProcessId));

        this.appInsightsService.trackTrace({ message: `Process Id: ${businessProcessId} selected`});
        const process = this.businessProcessList.find(x => x.name === businessProcessName);
        this.setProcess(process);
        this.emitCurrentServiceTreeSelection();
        // after first backtrack event is emitted, backtracking is completed.
        this.currentServiceTreeSelection.backTrack = false;
        if (this.emitEvent) {
          this.businessProcessIdEvent.emit(businessProcessId);
        }
        this.emitEvent = true;
        this.resetBusinessProcessEvent.emit(false);
      }
    );

    this.subscriptions.add(processChanges);
  }

  backtrackServiceTree(businessProcessId: string) {
    const processChanges = this.masterDataService.getBusinessProcessById(businessProcessId).pipe(
      mergeMap((response) => {
        this.isLoading = true;
        const teamGroupId = response.teamGroupId;
        return this.masterDataService.getTeamGroupById(teamGroupId);
      }),
    ).subscribe((response) => {
      const teamGroup = response;
      const serviceGroupId = teamGroup.serviceGroupId;
      const serviceGroupById = this.masterDataService.getServiceGroupById(serviceGroupId);
      const organizations = this.serviceTreeService.getOrganizations();
      // TODO: use switchmap and merge this subscription with outer one.
      const innerForkSubscription = forkJoin([serviceGroupById, organizations]).subscribe((result) => {
        const serviceGroup = result[0];
        this.organizationList = result[1];
        const org = this.organizationList.filter(x => x.id === serviceGroup.organizationId)[0];

        // set service tree values in cache
        this.localCacheService.setItem(constants.SERVICE_GROUP, serviceGroup.id);
        this.localCacheService.setItem(constants.ORGANIZATION, org.id);
        this.localCacheService.setItem(constants.TEAM_GROUP, teamGroup.id);
        this.localCacheService.setItem(constants.BUSINESS_PROCESS, String(businessProcessId));
        this.serviceTreeForm.get('organization').setValue(org.name);

        this.isLoading = false;
      },
        (error) => {
          this.appInsightsService.trackException(error);
          const errorModal = this.modalService.open(NgbModalErrorComponent);
          errorModal.componentInstance.message = 'Error fetching divisions. Please try again after sometime.';
          this.serviceTreeForm.setErrors({
            processSelectionInvalid: true,
            processSelectionMessage: 'Error loading divisions'
          });
          this.isLoading = false;
        });

        this.subscriptions.add(innerForkSubscription);
    },
      (error) => {
        this.appInsightsService.trackException(error);
        const errorModal = this.modalService.open(NgbModalErrorComponent);
        errorModal.componentInstance.message = 'Error fetching team group. Please try again after sometime.';
        this.serviceTreeForm.setErrors({
          processSelectionInvalid: true,
          processSelectionMessage: 'Error loading team group'
        });
        this.isLoading = false;
      });

      this.subscriptions.add(processChanges);
  }

  getServiceGroupId(name: string): string {
    return this.serviceGroupList.find(serviceGroup => serviceGroup.name === name).id;
  }

  getOrganizationId(name: string): string {
    return this.organizationList.find(org => org.name === name).id;
  }

  getTeamGroupId(name: string): string {
    return this.teamGroupList.find(teamGroup => teamGroup.name === name).id;
  }

  getBusinessProcessId(name: string): string {
    return this.businessProcessList.find(businessProcess => businessProcess.name === name).id;
  }

  /**
   * emit current selection of the service tree method.
   */
  emitCurrentServiceTreeSelection(): void {
    this.serviceTreeSelectionEvent.emit(this.currentServiceTreeSelection);
  }

  setOrganization(organization: Organization): void {
    this.currentServiceTreeSelection.organization = organization;
    this.currentServiceTreeSelection.serviceGroup = null;
    this.currentServiceTreeSelection.teamGroup = null;
    this.currentServiceTreeSelection.process = null;
  }

  setServiceGroup(serviceGroup: ServiceGroup): void {
    this.currentServiceTreeSelection.serviceGroup = serviceGroup;
    this.currentServiceTreeSelection.teamGroup = null;
    this.currentServiceTreeSelection.process = null;
  }

  setTeamGroup(teamGroup: TeamGroup): void {
    this.currentServiceTreeSelection.teamGroup = teamGroup;
    this.currentServiceTreeSelection.process = null;
  }

  setProcess(process: Process): void {
    this.currentServiceTreeSelection.process = process;
  }
}

import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import moment from 'moment';
import { forkJoin, ReplaySubject, Subscription } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { NgbModalErrorComponent } from '../../modal/ngb-modal-error.component';
import { ModalRef } from '../../model/common.model';
import { constants } from '../common/constants';
import { DataSourceModal, ProcessModal } from '../model/admin.model';
import { RowEditEvent, TableInputV2, TableOptions } from '../model/common.model';
import { DataSource } from '../model/data-source.model';
import { HierarchyTreeNode, Process} from '../model/process.model';
import { ModifyDataSourceComponent } from '../modify-data-source/modify-data-source.component';
import { ModifyProcessComponent } from '../modify-process/modify-process.component';
import { MasterDataService } from '../service/master-data.service';
import { ProcessService } from '../service/process.service';
import { TreeSelection } from '../../common/model/tree-view.model';
import { TreeviewService } from '../../common/service/treeview.service';
import { TreeDataService } from '../../common/interface/tree-data-service';
import { ServicetreeDataService } from '../../common/service/servicetree-data.service';
import { HierarchyTreeService } from '../../service/hierarchy-tree.service';

import { ProcessTableService, SubProcess } from '../service/process-table.service';
import { BusinessProcessComponent } from '../business-process/business-process.component';
import { SubProcessComponent } from '../sub-process/sub-process.component';
import { BamAlertsComponent } from '../bam-alerts/bam-alerts.component';
import { EventKpiComponent } from '../event-kpi/event-kpi.component';


@Component({
  selector: 'app-administration',
  templateUrl: './administration.component.html',
  styleUrls: ['./administration.component.scss'],
  providers: [ProcessService,
    TreeviewService,
    { provide: TreeDataService, useClass: ServicetreeDataService }
  ]
})
export class AdministrationComponent implements OnInit, OnDestroy {
  // process meta data.
  businessProcessList: Array<Process>;

  // loading indicators.
  isProcessListLoading: boolean;
  isTeamGroupSelected: boolean;
  selectedTeamGroupId: string;

  // display process table components.
  processTableColumns: Array<string>;
  processTableOptions: TableOptions;
  processTableData: ReplaySubject<TableInputV2>;

  // display process table components.
  dataSourceTableColumns: Array<string>;
  dataSourceTableOptions: TableOptions;
  dataSourceTableData: ReplaySubject<TableInputV2>;

  // display process table components.
  subProcessesTableColumns: Array<string>;
  subProcessesTableOptions: TableOptions;
  subProcessesTableData: ReplaySubject<TableInputV2>;

  // New properties for the new components
  businessProcessTableColumns: Array<string>;
  businessProcessTableOptions: TableOptions;
  businessProcessTableData: ReplaySubject<TableInputV2>;
  
  subProcessTableColumns: Array<string>;
  subProcessTableOptions: TableOptions;
  subProcessTableData: ReplaySubject<TableInputV2>;
  
  bamAlertsTableColumns: Array<string>;
  bamAlertsTableOptions: TableOptions;
  bamAlertsTableData: ReplaySubject<TableInputV2>;
  
  eventKpiTableColumns: Array<string>;
  eventKpiTableOptions: TableOptions;
  eventKpiTableData: ReplaySubject<TableInputV2>;

  subscription: Subscription;

  // Changes after data source
  activeTab: number;
  isDataSourceListLoading: boolean;
  isSubProcessesListLoading: Boolean;
  isBusinessProcessListLoading: boolean;
  isSubProcessListLoading: boolean;
  isBamAlertsListLoading: boolean;
  isEventKpiListLoading: boolean;

  constructor(
    private masterDataService: MasterDataService,
    private processTableService: ProcessTableService,
    private modalService: NgbModal,
    private route: ActivatedRoute,
    private router: Router,
    private treeviewService: TreeviewService,
    private hierarchyTreeService: HierarchyTreeService, 
  ) {
    this.subscription = new Subscription();

    this.isTeamGroupSelected = false;
    this.isProcessListLoading = false;
    this.isDataSourceListLoading = false;
    this.isSubProcessesListLoading = false;
    this.isBusinessProcessListLoading = false;
    this.isSubProcessListLoading = false;
    this.isBamAlertsListLoading = false;
    this.isEventKpiListLoading = false;

    this.processTableColumns = ['Process Id', 'Process Name', 'Description', 'Edit'];
    this.processTableOptions = {
      addEditColumn: true,
      editColumns: ['Edit'],
      enableSearch: false,
      columnWidths: [0.4, 1.2, 1.8, 0.5],
      centeredColumns: ['Process Id']
    };
    this.processTableData = new ReplaySubject<TableInputV2>(1);

    this.dataSourceTableColumns = ['Id', 'Name', 'Description', 'Type', 'Updated By', 'Updated On', 'Expires On', 'Data Source Status', 'Edit'];
    this.dataSourceTableOptions = {
      addEditColumn: true,
      editColumns: ['Edit'],
      enableSearch: false,
      columnWidths: [0.4, 1.2, 1.4, 0.5, 0.7, 1.0, 1.0, 1.0, 0.5],
      centeredColumns: ['Id', 'Data Source Status'],
      wrapTextColumns: ['Description']
    };
    this.dataSourceTableData = new ReplaySubject<TableInputV2>(1);

    this.subProcessesTableColumns = ['Id', 'Sub Process Name', 'Business Process', 'Bam Alert Id', 'Event Id', 'Org'];
    this.subProcessesTableOptions = {
      addEditColumn: false,
      editColumns: [],
      enableSearch: false,
      columnWidths: [0.4, 1.0, 0.5, 0.5, 0.5, 0.7],
      centeredColumns: ['Id'],
      wrapTextColumns: []
    };
    this.subProcessesTableData = new ReplaySubject<TableInputV2>(1);

    this.activeTab = 1;
  }

  ngOnInit(): void {
    // Create treeview configuration
    this.treeviewService.setHeight(200);
    this.treeviewService.setTitle("Level");
    this.treeviewService.loadTreeView(false);

    // tab
    const path = this.route.snapshot.url[2].path;
    if (path === "process") {
      this.activeTab = 1;
    } else if (path === "data-source") {
      this.activeTab = 2;
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  setTabContent(tabId: number): void {
    if (tabId === 1) {
      this.router.navigate(['bam-dashboard/administration/process']);
    } else if (tabId === 2) {
      this.router.navigate(['bam-dashboard/administration/data-source']);
    }
  }
  serviceTreeHandler(serviceTreeSelection: TreeSelection): void {
    const internalId = serviceTreeSelection.selectionHierarchy[0].id;
    const hierarchyServiceSubscription = this.hierarchyTreeService.getTreeNodeById(internalId).pipe(
      map((response: HierarchyTreeNode) => {
        return response.nodeId;
    }),
    switchMap((teamgroupId: string) => {
      this.selectedTeamGroupId = teamgroupId;
      this.isProcessListLoading = true;
      this.isDataSourceListLoading = true;
      this.isTeamGroupSelected = true;

      const processTableInput = this.masterDataService.getBusinessProcessList(teamgroupId).pipe(
        map(x => this.generateProcessTableInput(x))
      );

      const dataSourceTableInput = this.masterDataService.getDataSourcesByGroupId(teamgroupId).pipe(
        map((x) => {
          x = x.filter(d => d.typeDetails.isSelfServe === true);
          return this.generateDataSourceTableInput(x);
        })
      );
      return forkJoin([processTableInput, dataSourceTableInput])
    })).subscribe(
      (tableInput: TableInputV2[]) => {
        this.processTableData.next(tableInput[0]);
        this.dataSourceTableData.next(tableInput[1]);
        this.isProcessListLoading = false;
        this.isDataSourceListLoading = false;
      },
      (error: HttpErrorResponse) => {
        console.error('error occured while fetching data: ', error);
        const errorModal = this.modalService.open(NgbModalErrorComponent);
        (errorModal.componentInstance as ModalRef).message = error.message;
        this.isProcessListLoading = false;
        this.isDataSourceListLoading = false;
      }
    );
    this.subscription.add(hierarchyServiceSubscription);
  }
  // with new service get all process call
  // serviceTreeHandler(serviceTreeSelection: TreeSelection): void {
  //   const internalId = serviceTreeSelection.selectionHierarchy[0].id;
  //   const hierarchyServiceSubscription = this.hierarchyTreeService.getTreeNodeById(internalId).pipe(
  //     map((response: HierarchyTreeNode) => {
  //       return response.nodeId;
  //   }),
  //   switchMap((teamgroupId: string) => {
  //     this.selectedTeamGroupId = teamgroupId;
  //     this.isProcessListLoading = true;
  //     this.isDataSourceListLoading = true;
  //     this.isSubProcessesListLoading = true;
  //     this.isTeamGroupSelected = true;
  //     this.isBusinessProcessListLoading = true;
  //     this.isSubProcessListLoading = true;
  //     this.isBamAlertsListLoading = true;
  //     this.isEventKpiListLoading = true;

  //     const processTableInput = this.masterDataService.getBusinessProcessList(teamgroupId).pipe(
  //       map(x => this.generateProcessTableInput(x))
  //     );

  //     const dataSourceTableInput = this.masterDataService.getDataSourcesByGroupId(teamgroupId).pipe(
  //       map((x) => {
  //         x = x.filter(d => d.typeDetails.isSelfServe === true);
  //         return this.generateDataSourceTableInput(x);
  //       })
  //     );

  //     const subProcessesTableInput = this.processTableService.getAllProcesses().pipe(
  //       map(x => this.generateSubProcessesTableInput(x))
  //     );
      

  //     return forkJoin([processTableInput, dataSourceTableInput, subProcessesTableInput])
  //   })).subscribe(
  //     (tableInput: TableInputV2[]) => {
  //       this.processTableData.next(tableInput[0]);
  //       this.dataSourceTableData.next(tableInput[1]);
  //       this.subProcessesTableData.next(tableInput[2]);
  //       this.isProcessListLoading = false;
  //       this.isDataSourceListLoading = false;
  //       this.isSubProcessesListLoading = false;
  //     },
  //     (error: HttpErrorResponse) => {
  //       console.error('error occured while fetching data: ', error);
  //       const errorModal = this.modalService.open(NgbModalErrorComponent);
  //       (errorModal.componentInstance as ModalRef).message = error.message;
  //       this.isProcessListLoading = false;
  //       this.isDataSourceListLoading = false;
  //       this.isSubProcessesListLoading = false;
  //     }
  //   );
  //   this.subscription.add(hierarchyServiceSubscription);
  // }

  generateProcessTableInput(processList: Process[]): TableInputV2 {
    const tableInput = new TableInputV2();
    tableInput.columns = this.processTableColumns;
    tableInput.rows = new Array<Array<string>>();

    processList.forEach((process: Process) => {
      const row = new Array<string>();
      row.push(process.id);
      row.push(process.name);
      row.push(process.description);
      row.push(process.id);
      tableInput.rows.push(row);
    });

    return tableInput;
  }

  generateSubProcessesTableInput(processList: SubProcess[]): TableInputV2 {
    const tableInput = new TableInputV2();
    tableInput.columns = this.subProcessesTableColumns;
    tableInput.rows = new Array<Array<string>>();

    processList.forEach((subProcess: SubProcess) => {
      const row = new Array<string>();
      row.push(subProcess.businessID.toString());
      row.push(subProcess.subprocessName)
      row.push(subProcess.businessProcessName)
      row.push(subProcess.bamAlertID)
      row.push(subProcess.eventRuleID.toString())
      row.push(subProcess.org);
      tableInput.rows.push(row);
    });

    return tableInput;
  }


  generateDataSourceTableInput(dataSourceList: DataSource[]): TableInputV2 {
    const tableInput = new TableInputV2();
    tableInput.columns = this.dataSourceTableColumns;
    tableInput.rows = new Array<Array<string>>();

    dataSourceList.forEach((dataSource: DataSource) => {
      const row = new Array<string>();
      row.push(dataSource.id.toString());
      row.push(dataSource.name);
      row.push(dataSource.description);
      row.push(dataSource.type);
      row.push(dataSource.updatedBy);
      if (dataSource.updatedOn != null) {
        row.push(String(moment(dataSource.updatedOn).format('MM-DD-YYYY HH:mm')));
      } else {
        row.push("");
      }
      let expiredStatus = "";
      if (dataSource.expiresOn != null) {
        row.push(String(moment(dataSource.expiresOn).format('MM-DD-YYYY HH:mm')));
        if (moment(dataSource.expiresOn) < moment()) {
          expiredStatus = "Expired";
        } else {
          expiredStatus = "Active";
        }
      } else {
        row.push("");
        expiredStatus = "-";
      }
      row.push(expiredStatus);
      row.push(dataSource.id.toString());
      tableInput.rows.push(row);
    });

    return tableInput;
  }

  addNewProcess(): void {
    const processUpdateModal = this.modalService.open(ModifyProcessComponent);
    const teamGroupId = this.selectedTeamGroupId;
    (processUpdateModal.componentInstance as ProcessModal).teamGroupId = teamGroupId;
    (processUpdateModal.componentInstance as ProcessModal).isUpdate = false;
    if (processUpdateModal) {
      processUpdateModal.result
        .then(() => {
          this.updateProcessList();
        })
        .catch(() => { })
        ;
    }
  }

  addNewDataSource(): void {
    const dataSourceUpdateModal = this.modalService.open(ModifyDataSourceComponent);
    const teamGroupId = this.selectedTeamGroupId;
    (dataSourceUpdateModal.componentInstance as DataSourceModal).teamGroupId = teamGroupId;
    (dataSourceUpdateModal.componentInstance as DataSourceModal).isUpdate = false;
    (dataSourceUpdateModal.componentInstance as DataSourceModal).dataSourceId = null;
    if (dataSourceUpdateModal) {
      dataSourceUpdateModal.result
        .then(() => {
          this.updateDataSourceList();
        })
        .catch(() => { })
        ;
    }
  }

  editProcess(input: RowEditEvent): void {
    const processUpdateModal = this.modalService.open(ModifyProcessComponent);
    const teamGroupId = this.selectedTeamGroupId;
    (processUpdateModal.componentInstance as ProcessModal).teamGroupId = teamGroupId;
    (processUpdateModal.componentInstance as ProcessModal).isUpdate = true;
    (processUpdateModal.componentInstance as ProcessModal).processId = +input.value;
    if (processUpdateModal) {
      processUpdateModal.result
        .then(() => {
          this.updateProcessList();
        })
        .catch(() => { })
        ;
    }
  }

  editDataSource(input: RowEditEvent): void {
    const dataSourceUpdateModal = this.modalService.open(ModifyDataSourceComponent);
    const teamGroupId = this.selectedTeamGroupId;
    (dataSourceUpdateModal.componentInstance as DataSourceModal).teamGroupId = teamGroupId;
    (dataSourceUpdateModal.componentInstance as DataSourceModal).isUpdate = true;
    (dataSourceUpdateModal.componentInstance as DataSourceModal).dataSourceId = +input.value;
    if (dataSourceUpdateModal) {
      dataSourceUpdateModal.result
        .then(() => {
          this.updateDataSourceList();
        })
        .catch(() => { })
        ;
    }
  }

  updateProcessList(): void {
    const teamGroupId = this.selectedTeamGroupId;
    this.isProcessListLoading = true;
    const processListSubscription = this.masterDataService.getBusinessProcessList(teamGroupId).subscribe(
      (processList: Process[]) => {
        const tableInput = this.generateProcessTableInput(processList);
        this.processTableData.next(tableInput);
        this.isProcessListLoading = false;
      },
      (error: HttpErrorResponse) => {
        console.error('error occured while fetching process list: ', error);
        const errorModal = this.modalService.open(NgbModalErrorComponent);
        (errorModal.componentInstance as ModalRef).message = error.message;
        this.isProcessListLoading = false;
      }
    );

    this.subscription.add(processListSubscription);
  }

  updateDataSourceList(): void {
    const teamGroupId = this.selectedTeamGroupId;
    this.isDataSourceListLoading = true;
    // TO-DO: Get datasource list by TG when method available
    const dataSourceListSubscription = this.masterDataService.getDataSourcesByGroupId(teamGroupId).pipe(
      map((x) => {
        x = x.filter(d => d.typeDetails.isSelfServe === true);
        return this.generateDataSourceTableInput(x);
      })
    ).subscribe(
      (tableInput: TableInputV2) => {
        this.dataSourceTableData.next(tableInput);
        this.isDataSourceListLoading = false;
      },
      (error: HttpErrorResponse) => {
        console.error('error occured while fetching data source list: ', error);
        const errorModal = this.modalService.open(NgbModalErrorComponent);
        (errorModal.componentInstance as ModalRef).message = error.message;
        this.isDataSourceListLoading = false;
      }
    );

    this.subscription.add(dataSourceListSubscription);
  }

  updateSubProcessesList(): void {
    // const teamGroupId = this.selectedTeamGroupId;
    this.isSubProcessesListLoading = true;
    const subProcessesListSubscription = this.processTableService.getAllProcesses().subscribe(
      (subProcessList: SubProcess[]) => {
        const tableInput = this.generateSubProcessesTableInput(subProcessList);
        this.subProcessesTableData.next(tableInput);
        this.isSubProcessesListLoading = false;
      },
      (error: HttpErrorResponse) => {
        console.error('error occured while fetching sub process list: ', error);
        const errorModal = this.modalService.open(NgbModalErrorComponent);
        (errorModal.componentInstance as ModalRef).message = error.message;
        this.isSubProcessesListLoading = false;
      }
    );

    this.subscription.add(subProcessesListSubscription);
  }
}


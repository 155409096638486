import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { delay, map } from 'rxjs/operators';
import { APP_CONFIG } from '../../common/constants';
import { AppConfig } from '../../model/app-config.model';
import { ODataResponse } from '../../model/common.model';
import { BusinessGoal } from '../model/business-goal.model';
import { Scenario } from '../model/scenario.model';

@Injectable()
export class BusinessGoalService {
  constructor(private httpClient: HttpClient, @Inject(APP_CONFIG) private appConfig: AppConfig) {}

  public getScenario(scenarioId: number): Observable<Scenario> {
    const scenarioUrl = this.appConfig.baseUrlWithVersionBAMDashboard + 'Scenario?filter eq ' + scenarioId;
    return this.httpClient.get<ODataResponse<Scenario[]>>(scenarioUrl).pipe(
      map((s: ODataResponse<Scenario[]>) => {
        const scenarioList = s.value;
        const scenario = scenarioList.find((x) => x.scenarioId === scenarioId);
        return scenario;
      })
    );
  }

  public getBusinessGoalList(scenarioId: number): Observable<Array<BusinessGoal>> {
    const businessGoalUrl = this.appConfig.baseUrlWithVersionBAMDashboard + 'BusinessGoal?filter eq ' + scenarioId;
    return this.httpClient
      .get<ODataResponse<Array<BusinessGoal>>>(businessGoalUrl)
      .pipe(map((businessGoals: ODataResponse<Array<BusinessGoal>>) => businessGoals.value));
  }

  public updateBusinessGoal(updatedBusinessGoal: BusinessGoal): Observable<BusinessGoal> {
    const businessGoalUrl = this.appConfig.baseUrlWithVersionBAMDashboard + 'BusinessGoal';
    return this.httpClient.put<BusinessGoal>(businessGoalUrl, updatedBusinessGoal);
  }
}

import { Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { from } from 'rxjs';
import { constants } from '../../bam-dashboard/common/constants';
import { Observable } from 'rxjs';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import * as pbi from 'powerbi-client';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PowerBIService {
  private powerbi: pbi.service.Service;
  private accessToken: string;

  constructor(
    private msalService: MsalService,
    private appInsightsService: ApplicationInsights,
  ) {
    this.powerbi = new pbi.service.Service(
      pbi.factories.hpmFactory,
      pbi.factories.wpmpFactory,
      pbi.factories.routerFactory
    );
  }

  getAccessToken(): Observable<string> {
    this.appInsightsService.trackTrace({ message: 'power-bi.service: return token subject.'});
    const resource = constants.PowerBIResource;
    const request = {
      scopes: [resource + '/.default']
    };
    return this.msalService.acquireTokenSilent(request).pipe<string>(map((tokenResponse) => {
      this.accessToken = tokenResponse.accessToken;
      return this.accessToken;
    })); // convert promise to observable
  }

  getPowerBIService(): pbi.service.Service {
    return this.powerbi;
  }
}

import { Inject, Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { GraphInstance } from '../common/graph/graph-instance';
import { GraphMap } from '../common/graph/graph-map';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { ProcessGraphMap, ProcessNode, ProcessEdge, ProcessGraphInstance, ProcessGraph } from '../model/graph.model';
import { InstanceFilters, Process, ProcessMiningRequest } from '../model/process.model';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { Graph } from '../common/graph/graph';
import { APP_CONFIG } from '../../common/constants';
import { AppConfig } from '../../model/app-config.model';

@Injectable()
export class GraphService {
  private baseUrlWithVersionBAMDashboard: string;

  constructor(
    @Inject(APP_CONFIG) appConfig: AppConfig,
    private httpClient: HttpClient,
    private appInsightsService: ApplicationInsights
  ) {
    this.baseUrlWithVersionBAMDashboard = appConfig.baseUrlWithVersionBAMDashboard;
  }

  getProcessInstanceGraph(processId: string, processInstanceFilters: InstanceFilters[]): Observable<GraphInstance> {
    const businessProcessUrl = this.baseUrlWithVersionBAMDashboard + 'Process/' + processId + '/instance';
    return this.httpClient.post<ProcessGraphInstance>(businessProcessUrl,
      processInstanceFilters,
    ).pipe(
      map((processInstance: ProcessGraphInstance) => {
        const graphInstance = new GraphInstance(this.appInsightsService);
        processInstance.nodes.forEach(processNode => {
          graphInstance.addNode(processNode);
        });
        processInstance.edges.forEach((processEdge: ProcessEdge) => {
          graphInstance.addEdge(processEdge);
        });
        graphInstance.setGraphProperties(processInstance.properties);
        graphInstance.setInstancePath(processInstance.instancePath);
        // generate alt text for accesibility.
        graphInstance.generateGraphDescription();
        graphInstance.generateCurrentPathDescription();
        return graphInstance;
      }),
    );
  }

  getProcessMapWithHealthInfo(processId: string, isFilter: boolean, filterDates: Array<string>): Observable<GraphMap> {
    let processHealthUrl = this.baseUrlWithVersionBAMDashboard + 'Process/' + processId + '/health';
    if (isFilter) {
      processHealthUrl += '/' + filterDates[0] + '/' + filterDates[1];
    }
    const processMapRequest: Observable<GraphMap> = this.httpClient.get<ProcessGraphMap>(processHealthUrl).pipe(
      map((processMap: ProcessGraphMap) => {
        const graphMap = new GraphMap(this.appInsightsService);
        processMap.nodes.forEach((processNode: ProcessNode) => {
          graphMap.addNode(processNode);
        });

        graphMap.setNodeGradientColor();

        processMap.edges.forEach((processEdge: ProcessEdge) => {
          graphMap.addEdge(processEdge);
        });
        graphMap.setGraphProperties(processMap.properties);
        graphMap.setGraphPaths(processMap.paths);

        graphMap.generateGraphModels();

        // generate accessible text.
        graphMap.generateGraphDescription();
        graphMap.setGraphMapCreatedDate(processMap.createdOn);
        return graphMap;
      }),
    );
    return processMapRequest;
  }

  onboardPowerBI(processId: string) {
    const onboardingUrl = this.baseUrlWithVersionBAMDashboard + 'Process/' + processId + '/report';
    return this.httpClient.post(onboardingUrl, {}, { observe: 'response' });
  }

  getProcessMiningGraph(businessProcessId: string, processMiningRequest?: ProcessMiningRequest): Observable<GraphMap> {
    let businessProcessUrl: string;
    if (processMiningRequest === null || typeof processMiningRequest === 'undefined') {
      businessProcessUrl = this.baseUrlWithVersionBAMDashboard + 'Process/' + businessProcessId + '/discovery';
    } else {
      businessProcessUrl = this.baseUrlWithVersionBAMDashboard + 'Process/' + businessProcessId + '/discovery/execute';
    }
    const processMiningMap$: Observable<GraphMap> =
      this.httpClient.post<ProcessGraphMap>(businessProcessUrl, processMiningRequest, {"headers":{'Content-Type':'application/json'}}).pipe(
        map((processGraphMap: ProcessGraphMap) => {
          // TODO: validate the received processGraphMap and raise error if missing data.

          const graphMap = new GraphMap(this.appInsightsService);
          processGraphMap.nodes.forEach((processNode: ProcessNode) => {
            graphMap.addNode(processNode);
          });

          processGraphMap.edges.forEach((processEdge: ProcessEdge) => {
            graphMap.addEdge(processEdge);
          });
          graphMap.setGraphProperties(processGraphMap.properties);
          graphMap.setGraphPaths(processGraphMap.paths);

          graphMap.generateGraphModels();

          // generate accessible text.
          graphMap.generateGraphDescription();
          graphMap.setGraphMapCreatedDate(processGraphMap.createdOn);
          return graphMap;
        })
      );

    return processMiningMap$;
  }

  getCSOFlowGraphs(nodeEvent: string): Observable<Graph> {
    const csoFlowGraphUrl = this.baseUrlWithVersionBAMDashboard + 'CSOGraph/' + nodeEvent;
    return this.httpClient.get<ProcessGraph>(csoFlowGraphUrl).pipe(
      catchError((err: HttpErrorResponse) => {
        if (err.status === 404) {
          // in case graph is not found, return valid null object.
          return of(null);
        } else {
          // for every other error, return the error.
          return throwError(err);
        }
      }),
      map((csoFlowGraph: ProcessGraph) => {
        if (csoFlowGraph !== null) {
          const flowGraph = new Graph(this.appInsightsService);

          csoFlowGraph.nodes.forEach((processNode: ProcessNode) => {
            flowGraph.addNode(processNode);
          });

          csoFlowGraph.edges.forEach((processEdge: ProcessEdge) => {
            flowGraph.addEdge(processEdge);
          });
          return flowGraph;
        } else {
          return null;
        }
      }),
    );
  }

  getProcessById(id: string): Observable<Process> {
    return this.httpClient.get<Process>(this.baseUrlWithVersionBAMDashboard + "Process/" + id).pipe(
      catchError((x) => this.handleError(x))
    );
  }

  saveProcessMiningAttributes(processId: string, processMiningRequest: ProcessMiningRequest) {
    return this.httpClient.put(this.baseUrlWithVersionBAMDashboard + "Process/" + processId + "/discovery/save",
      processMiningRequest, { observe: 'response' }).pipe(
        catchError((x) => this.handleError(x))
      );
  }

  handleError(error: HttpErrorResponse) {
    let errorMessage = '';
    let statusCode = 0;
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred.
      errorMessage = `An error occurred: ${error.error.message}`;

      this.appInsightsService.trackException(error);
    } else {
      // The backend returned an unsuccessful response code
      errorMessage = `Server returned code: ${error.status}, error message is: ${error.message}`;
      this.appInsightsService.trackException(error);
      statusCode = error.status;
    }
    console.log(errorMessage);
    return throwError({ errorMessage, statusCode });
  }
}

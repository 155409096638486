export {};
declare global {
   interface Date {
      addDays(days: number, useThis?: boolean): Date;
      getDatePart(): Date;
      clone(): Date;
      diffDate(date: Date): number;
      isWeekend(): boolean;
      isSameDate(date: Date): boolean;
   }
}


Date.prototype.addDays = function(days: number): Date {
   if (!days) {
      return this;
   }
   this.setUTCDate(this.getUTCDate() + days);
   return this;
};

Date.prototype.getDatePart = function(): Date {
   const reminder = this.getTime() % 86400000;
   this.setTime(this.getTime() - reminder);
   return this;
};

Date.prototype.clone = function(): Date {
   return new Date(this.getTime());
};

Date.prototype.diffDate = function(date: Date): number {
   return Math.floor((date.getTime() - this.getTime()) / 86400000);
};

Date.prototype.isWeekend = function(): boolean {
   return this.getDay() === 0 || this.getDay() === 6;
};

Date.prototype.isSameDate = function (date: Date): boolean {
   return date && this.getFullYear() === date.getFullYear() && this.getMonth() === date.getMonth() && this.getDate() === date.getDate();
};

<div class="d-flex flex-row mb-2">
  <div *ngIf="!isAlertLoaded" class="mx-auto">
    <span class="fa fa-spinner fa-spin fa-3x"></span>
  </div>
</div>
<div *ngIf="isAlertLoaded">
  <h5 class="display-5 ml-3 mt-3">Alert for {{rule.ruleName}}</h5>
  <div class="ml-auto mr-2">
    <!-- Alert Last Modified Date and User -->
    <div style="float:right;" *ngIf="!isNewAlert" tabindex="0">
      <h6 class="display-text">
        <span>
          Last modified by {{ rule.alert.updatedBy }} on
          {{ rule.alert.lastModified | date: 'MM-dd-yyyy HH:mm' }}
        </span>
        
      </h6>
    </div>
  </div>
  <div *ngIf="lastRunTime !== null && lastRunTime !== undefined" style="margin-top:1vw;margin-left:1vw;">
    <h6 class="display-text">
      <span>Last Run Time {{ lastRunTime | date: 'MM-dd-yyyy HH:mm'}}</span>
      <div *ngIf="jobStatus === 'Success'">
        <span class="success-text"> Success &#x2714;</span>
      </div>
      <div *ngIf="jobStatus === 'Running'">
        <span class="info-text"> Running </span>
        <div class="spinner-border spinner-border-sm text-primary"></div>
      </div>
      <div *ngIf="jobStatus === 'Failed'">
        <span class="danger-text"> Failed &#x274C;</span>
      </div>
    </h6>
  </div>
  <hr>
  <div class="d-flex flex-column mx-1 px-2 pb-2">
    <form class="text-black" [formGroup]="alertForm" (ngSubmit)="saveAlert()">
      <div class="d-flex flex-row input-group input-group-sm mb-2">
        <div class="input-group-prepend">
          <span class="input-group-text" id="label-alert-name" style="width: 11rem;">Title:</span>
        </div>
        <input type="text" class="form-control form-control-sm required" id="alert-name" formControlName="alertName"
          maxlength="80" title="Alert Name" aria-label="Alert Name" style="max-width: 26rem;">
      </div>
      <div class="d-flex flex-row input-group input-group-sm mb-2">
        <div class="input-group-prepend">
          <ng-template #popContent>
            <div>
              BAM Alert description can be templatized using result placeholder. Learn about templatizing BAM Alerts at
              <a class="link-text ml-2" href="{{templatizeBAMAlertWikiUrl}}" target="_blank"
                aria-label="Templatize BAM Alert Wiki Link">
                Templatize BAM Alert Wiki</a>
            </div>
          </ng-template>
          <ng-template #popTitle><b>Templatize Alert Description</b></ng-template>
          <span class="input-group-text" id="label-description" style="width: 11rem;">Description
            <span class="fa fa-info-circle ml-2 mr-1" [ngbPopover]="popContent" [popoverTitle]="popTitle"
              container="body">
            </span>
            :</span>
        </div>
        <textarea class="form-control form-control-sm required" formControlName="description" title="Description"
          aria-label="Description" rows="3" maxlength="5000" style="max-width: 26rem;"></textarea>
      </div>

      <div class="d-flex flex-row input-group input-group-sm mb-2">
        <div class="input-group-prepend">
          <span class="input-group-text" id="label-recur" style="width: 11rem;">Recur Every:</span>
        </div>
        <input type="number" min="1" step="1" class="form-control form-control-sm required"
          formControlName="frequencyValue" maxlength="80" style="max-width: 13rem;" title="Time Value"
          aria-label="Time Value">
        <select class="form-control form-control-sm required" formControlName="frequencyType" title="Time Unit"
          aria-label="Time Unit" style="max-width: 13rem;">
          <option value="Minute">Minute</option>
          <option value="Hour">Hour</option>
          <option value="Day">Day</option>
        </select>
        <div *ngIf="alertForm.errors?.isRecurranceError === true" class="col-auto" style="height:1.8rem;">
          <div class="alert alert-text p-1 ml-2" role="alert" style="height: 1.8rem;">
            <span>{{ alertForm.errors?.recurranceError }}</span>
          </div>
        </div>
      </div>
      <div class="d-flex flex-row input-group input-group-sm mb-2">
        <div class="input-group-prepend">
          <span class="input-group-text" id="label-start-time" style="width: 11rem;">Start Time:</span>
        </div>
        <input type="text" class="form-control form-control-sm" formControlName="startTime" maxlength="80"
          title="Start Time" aria-label="Start Time" [owlDateTime]="dtStart" [owlDateTimeTrigger]="dtStart"
          style="max-width: 13rem;">
        <owl-date-time #dtStart></owl-date-time>
        <h6 class="display-text pl-1 pt-1">
          <span *ngIf="this.rule.alert.startTime">{{ this.rule.alert.startTime }} UTC</span>
        </h6>
      </div>
      <div class="d-flex flex-row input-group input-group-sm mb-2">
        <div class="input-group-prepend">
          <span class="input-group-text" id="label-end-time" style="width: 11rem;">End Time:</span>
        </div>
        <owl-date-time #dtStart></owl-date-time>
        <input type="text" class="form-control form-control-sm" formControlName="endTime" maxlength="80"
          title="End Time" aria-label="End Time" [owlDateTime]="dtEnd" [owlDateTimeTrigger]="dtEnd"
          style="max-width: 13rem;">
        <owl-date-time #dtEnd></owl-date-time>
        <h6 class="display-text pl-1 pt-1">
          <span *ngIf="this.rule.alert.endTime">{{ this.rule.alert.endTime }} UTC</span>
        </h6>
      </div>
      <div class="d-flex flex-row">
        <div class="input-group input-group-sm mb-3" style="max-width: 12rem;">
          <div class="custom-control custom-switch">
            <input type="checkbox" class="custom-control-input" id="customSwitch1" formControlName="isEmailEnabled">
            <label class="custom-control-label" for="customSwitch1">Enable Email</label>
          </div>
        </div>
      </div>

      <div class="d-flex flex-row">
        <div class="input-group input-group-sm mb-2">
          <div class="input-group-prepend">
            <span class="input-group-text" for="customSwitch1" id="label-alert-email"
              style="width: 11rem; text-align:left;">Alert Email:<br />(separated by semi-colon)</span>
          </div>
          <textarea type="text" class="form-control form-control-sm required" for="customSwitch1"
            formControlName="alertEmail" maxlength="400" style="max-width: 26rem;" title="Alert Email"
            aria-label="Alert Email">
          </textarea>

          <!-- error message for email. -->
          <div *ngIf="alertForm.controls['alertEmail'].errors?.isInvalidEmail === true" style="height:1.8rem;">
            <div class="alert alert-text p-1 ml-2" role="alert" style="height: 1.8rem;">
              <span>{{ alertForm.controls['alertEmail'].errors?.invalidEmailError }}</span>
            </div>
          </div>
        </div>
      </div>


      <div class="d-flex flex-row">
        <div class="input-group input-group-sm mb-3" style="max-width: 12rem;">
          <div class="custom-control custom-switch">
            <input type="checkbox" class="custom-control-input" id="customSwitch2" formControlName="isIcMEnabled">
            <label class="custom-control-label" for="customSwitch2">Enable IcM</label>
          </div>
        </div>
      </div>

      <div class="d-flex flex-row input-group input-group-sm mb-2">
        <div class="input-group-prepend">
          <ng-template #icmPopContent>
            <div>
              To direct alerts to the appropriate IcM team, provide a valid IcM Team Public Id ("SERVICE\TEAM") or select from the list of previously used ids. To find your IcM Team Public Id, visit
              <a class="link-text ml-2" href="{{icmTeamPublicIdLookupUrl}}" target="_blank">
                IcM Team Public Id Lookup
              </a>
            </div>
          </ng-template>
          <ng-template #icmPopTitle><b>IcM Team Public Id</b></ng-template>
          <span class="input-group-text" id="icm-label-description" style="width: 11rem;">IcM Team Public Id:
            <span class="fa fa-info-circle ml-2 mr-1" [ngbPopover]="icmPopContent" [popoverTitle]="icmPopTitle"
              container="body">
            </span>
            :</span>
        </div>
        <input type="text" class="form-control form-control-sm required" id="icm-routingid" formControlName="icmRoutingId" maxlength="80" style="max-width: 26rem;" 
          title="IcM Team Public Id (type or select previously used id)" 
          placeholder="Type in IcM Team Public Id"
          [ngbTypeahead]="icmRoutingIdTeamGroupSearch" (focus)="onFocus($event)">
        <div *ngIf="alertForm.errors?.isIcmSelectError === true && alertForm.controls['icmRoutingId'].touched" style="height:1.8rem;">
          <div class="alert alert-text p-1 ml-2" role="alert" style="height: 1.8rem;">
            <span>{{ alertForm.errors?.IcmSelectError }}</span>
          </div>
        </div>
      </div>

      <!-- Default Severity -->
      <div class="d-flex flex-row input-group input-group-sm mb-2">
        <div class="input-group-prepend">
          <span class="input-group-text" id="label-severity" style="width: 11rem;">Default Severity:</span>
        </div>
        <select class="form-control form-control-sm required" formControlName="severity" style="max-width: 13rem;"
          title="Severity" aria-label="Severity">
          <option value="1">1</option>
          <option value="2">2</option>
          <option value="3">3</option>
          <option value="4">4</option>
        </select>
      </div>

      <!-- Enable Dynamic Severity -->
      <div class="d-flex flex-row">
        <div class="input-group input-group-sm mb-3" style="max-width: 24rem;">
          <div class="custom-control custom-switch">
            <input type="checkbox" class="custom-control-input" id="customSwitch3"
              formControlName="isDynamicIcmSeverityEnabled">
            <label class="custom-control-label" for="customSwitch3">Enable IcM Dynamic Severity</label>
          </div>
        </div>
      </div>
      <div class="d-flex flex-row input-group input-group-sm mb-2">
        <!-- Sev3 Threshold -->
        <div class="input-group-prepend">
          <span class="input-group-text" id="label-sev3threshold" style="width: 7rem;">Sev3 Threshold:</span>
        </div>
        <input type="number" min="1" class="form-control form-control-sm" formControlName="sev3Threshold" maxlength="80"
          style="max-width: 6rem;" title="Minimum ACV count required to raise sev 3 IcM" aria-label="Sev3 Threshold">
        <!-- Sev2 Threshold -->
        <div class="input-group-prepend">
          <span class="input-group-text" id="label-sev2threshold" style="width: 7rem;">Sev2 Threshold:</span>
        </div>
        <input type="number" min="1" class="form-control form-control-sm" formControlName="sev2Threshold" maxlength="80"
          style="max-width: 6rem;" title="Minimum ACV count required to raise sev 2 IcM" aria-label="Sev2 Threshold">
        <!-- Sev1 Threshold -->
        <div class="input-group-prepend">
          <span class="input-group-text" id="label-sev1threshold" style="width: 7rem;">Sev1 Threshold:</span>
        </div>
        <input type="number" min="1" class="form-control form-control-sm" formControlName="sev1Threshold" maxlength="80"
          style="max-width: 6rem;" title="Minimum ACV count required to raise sev 1 IcM" aria-label="Sev1 Threshold">
      </div>
      <div *ngIf="alertForm.errors?.isSevThresholdError === true && (!alertForm.controls['sev1Threshold'].pristine
       || !alertForm.controls['sev2Threshold'].pristine || !alertForm.controls['sev3Threshold'].pristine)"
        style="height:1.8rem;width: 42rem;">
        <div class="alert alert-text p-1 ml-2" role="alert" style="height: 1.8rem;">
          <span>{{ alertForm.errors?.sevThresholdError }}</span>
        </div>
      </div>

      <div class="form-group row">
        <div class="col-auto my-2">
          <button class="btn btn-sm primary-button mr-2" type="submit" [disabled]="!alertForm.valid">
            <span>Save Alert</span>
          </button>

          <button class="btn btn-sm danger-button mr-2" type="button" (click)="cancelClick()">
            <span>Cancel</span>
          </button>
        </div>
        <div *ngIf="isSaveInProgress" class="pt-2">
          <span class="text-info fa fa-spinner fa-spin fa-2x"></span>
        </div>
      </div>
    </form>
  </div>
</div>

import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { map, share, delay, catchError } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class CacheService {
    private cacheInprogress = new Map<string, Observable<any>>();
    private cacheStore = new Map<string, any>();

    public getCached<T>(url: string, callback: (url: string) => Observable<T>): Observable<T> {
        if (this.cacheStore.get(url)) {
            return of(this.cacheStore.get(url)).pipe(delay(1));
        } else if (this.cacheInprogress.get(url)) {
            return this.cacheInprogress.get(url);
        } else {
            this.cacheInprogress.set(url, callback(url)
                .pipe(
                    catchError(x => {
                        this.cacheInprogress.set(url, null);
                        return throwError(x);
                    }))
                .pipe<T>(
                    map((result: T) => this.cacheData<T>(url, result)
                    ))
                .pipe<T>(share()));
            return this.cacheInprogress.get(url);
        }
    }

    public getCachedWithPayload<T>(url: string, cacheKey: string, callback: (url: string) => Observable<T>): Observable<T> {
        if (this.cacheStore.get(cacheKey)) {
            return of(this.cacheStore.get(cacheKey)).pipe(delay(1));
        } else if (this.cacheInprogress.get(cacheKey)) {
            return this.cacheInprogress.get(cacheKey);
        } else {
            this.cacheInprogress.set(cacheKey, callback(url)
                .pipe(
                    catchError(x => {
                        this.cacheInprogress.set(cacheKey, null);
                        return throwError(x);
                    }))
                .pipe<T>(
                    map((result: T) => this.cacheData<T>(cacheKey, result)))
                .pipe<T>(share()));
            return this.cacheInprogress.get(cacheKey);
        }
    }

    public removeCached(url: string): void {
        if (this.cacheInprogress.get(url) != null) {
            this.cacheInprogress.set(url, null);
        }
        if (this.cacheStore.get(url) != null) {
            this.cacheStore.set(url, null);
        }
    }

    public clearCacheByRegex(regex: RegExp): void {
        const matchedKeys = new Array<string>();
        this.cacheStore.forEach((_: any, url: string) => {
            if(regex.test(url)) {
                matchedKeys.push(url);
            }
        });
        matchedKeys.forEach((url) => this.removeCached(url));
    }

    private cacheData<T>(url: string, body: T) {
        this.cacheInprogress.set(url, null);
        this.cacheStore.set(url, body);
        return this.cacheStore.get(url);
    }
}

import { EventHandler } from "./event-handler.model";

export class EventTypeSubscription {
  id: number;
  name: string;
  description: string;
  eventHandler: EventHandler;
  schema: string;
  contactAlias: string;
  ownerGroupAlias: string;
  ownerGroupId: string;
  teamName: string;
  serviceName: string;
  serviceId: string;
  updatedBy: string;
  updatedOn: Date;
  parentEventId: number;
  parentEventName: string;
  status: string;
  filters: string;
  enableDeliveryFailureEmail: boolean;
  minDeliveryFailureAlertInterval: number;
  minDeliveryFailureAlertIntervalUnit: string;
  enableDeliveryFailureIcM: boolean;
  icmRoutingId: string;
  severity: number;
  rowVersion: string;

  constructor();

  constructor(id: number,
    name: string,
    description: string,
    eventHandler: EventHandler,
    schema: string,
    contactAlias: string,
    ownerGroupAlias: string,
    ownerGroupId: string,
    teamName: string,
    serviceName: string,
    serviceId: string,
    updatedBy: string,
    updatedOn: Date,
    parentEventId: number,
    parentEventName: string,
    status: string,
    filters: string,
    enableDeliveryFailureEmail: boolean,
    minDeliveryFailureAlertInterval: number,
    minDeliveryFailureAlertIntervalUnit: string,
    enableDeliveryFailureIcM: boolean,
    icMRoutingId: string,
    severity: number,
    rowVersion: string
    );

  constructor(id?: number,
    name?: string,
    description?: string,
    eventHandler?: EventHandler,
    schema?: string,
    contactAlias?: string,
    ownerGroupAlias?: string,
    ownerGroupId?: string,
    teamName?: string,
    serviceName?: string,
    serviceId?: string,
    updatedBy?: string,
    updatedOn?: Date,
    parentEventId?: number,
    parentEventName?: string,
    status?: string,
    filters?: string,
    enableDeliveryFailureEmail?: boolean,
    minDeliveryFailureAlertInterval?: number,
    minDeliveryFailureAlertIntervalUnit?: string,
    enableDeliveryFailureIcM?: boolean,
    icmRoutingId?: string,
    severity?: number,
    rowVersion?: string
    ) {

    this.id = id;
    this.name = name;
    this.description = description;
    this.eventHandler = eventHandler;
    this.schema = schema;
    this.contactAlias = contactAlias;
    this.ownerGroupAlias = ownerGroupAlias;
    this.ownerGroupId = ownerGroupId;
    this.teamName = teamName;
    this.serviceName = serviceName;
    this.serviceId = serviceId;
    this.updatedBy = updatedBy;
    this.updatedOn = updatedOn;
    this.parentEventId = parentEventId;
    this.parentEventName = parentEventName;
    this.status = status;
    this.filters = filters;
    this.enableDeliveryFailureEmail = enableDeliveryFailureEmail;
    this.minDeliveryFailureAlertInterval = minDeliveryFailureAlertInterval;
    this.minDeliveryFailureAlertIntervalUnit = minDeliveryFailureAlertIntervalUnit;
    this.enableDeliveryFailureIcM = enableDeliveryFailureIcM;
    this.icmRoutingId = icmRoutingId;
    this.severity = severity;
    this.rowVersion = rowVersion;
  }
}

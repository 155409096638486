import { Inject, Injectable } from "@angular/core";
import { CanActivate, ActivatedRouteSnapshot } from "@angular/router";
import { APP_CONFIG } from "./common/constants";
import { AppConfig } from "./model/app-config.model";

@Injectable()
export class FeatureFlagGuard implements CanActivate {
  constructor(@Inject(APP_CONFIG) public appConfig: AppConfig) {}

  canActivate(route: ActivatedRouteSnapshot) {
    let activateRoute = false;
    // Get the name of the feature flag from the route data provided
    const featureFlag = route.data['featureFlag'] as string;
    // get dynamic routes configured as part of
    const dynamicScoreCardRoutes = this.appConfig.scoreCards
      .filter((config) => config.enable)
      .map((scenario) => scenario.route);
    if (featureFlag === 'monitoring-solution/add-dashboard' || featureFlag === 'monitoring-solution/update-dashboard') {
      activateRoute =
        this.appConfig.featureFlag.enableDashboardCatalog && this.appConfig.enableDashboardCatalog.enableAddNewSolution
          ? true
          : false;
    } else if (featureFlag === 'monitoring-solution/browse-catalog') {
      activateRoute =
        this.appConfig.featureFlag.enableDashboardCatalog &&
        this.appConfig.enableDashboardCatalog.enableBrowseAllSolutions
          ? true
          : false;
    } else if (featureFlag === 'quality-of-service') {
      activateRoute = this.appConfig.featureFlag.enableQoS ? true : false;
    } else if (featureFlag === 'quality-of-service/admin') {
      activateRoute =
        this.appConfig.featureFlag.enableQoS && this.appConfig.enableQoS.enableAdministration ? true : false;
    } else if (featureFlag === 'quality-of-service/view') {
      activateRoute = this.appConfig.featureFlag.enableQoS && this.appConfig.enableQoS.enableQoSView ? true : false;
    } else if (featureFlag === 'score-card') {
      activateRoute = this.appConfig.featureFlag.enableScoreCard ? true : false;
    } else if (featureFlag === 'score-card/scenario-health') {
      activateRoute =
        this.appConfig.featureFlag.enableScoreCard && this.appConfig.enableScoreCard.enableScenarioHealth
          ? true
          : false;
    } else if (dynamicScoreCardRoutes.findIndex((x) => x === featureFlag) !== -1) {
      activateRoute =
        this.appConfig.featureFlag.enableScoreCard && this.appConfig.enableScoreCard.enableScenarioHealthV2
          ? true
          : false;
    } else if (featureFlag === 'score-card/finance-scenario-health') {
      activateRoute =
        this.appConfig.featureFlag.enableScoreCard && this.appConfig.enableScoreCard.enableFinanceScenarioHealth
          ? true
          : false;
    } else if (featureFlag === 'score-card/crew-health') {
      activateRoute =
        this.appConfig.featureFlag.enableScoreCard && this.appConfig.enableScoreCard.enableCrewHealth ? true : false;
    } else if (featureFlag === 'score-card/monitoring-dashboard') {
      activateRoute =
        this.appConfig.featureFlag.enableScoreCard && this.appConfig.enableScoreCard.enableMonitoringDashboard
          ? true
          : false;
    } else if (featureFlag === 'icm') {
      activateRoute = this.appConfig.featureFlag.enableIcM ? true : false;
    } else if (featureFlag === 'icm/mdo') {
      activateRoute = this.appConfig.featureFlag.enableIcM ? true : false && this.appConfig.enableIcM.MDO ? true : false;
    } else if (featureFlag === 'icm/sce') {
      activateRoute = this.appConfig.featureFlag.enableIcM ? true : false && this.appConfig.enableIcM.SCE ? true : false;
    } else if (featureFlag === 'icm/cscp') {
      activateRoute = this.appConfig.featureFlag.enableIcM ? true : false && this.appConfig.enableIcM.CSCP ? true : false;
    } else if (featureFlag === 'bam-dashboard') {
      activateRoute = this.appConfig.enableBAM ? true : false;
    } else if (featureFlag === 'bam-dashboard/admin') {
      activateRoute =
        this.appConfig.featureFlag.enableBAM && this.appConfig.enableBAM.enableAdministration ? true : false;
    } else if (featureFlag === 'bam-dashboard/process-discovery') {
      activateRoute =
        this.appConfig.featureFlag.enableBAM && this.appConfig.enableBAM.enableProcessDiscovery ? true : false;
    } else if (featureFlag === 'bam-dashboard/process-flow') {
      activateRoute = this.appConfig.featureFlag.enableBAM && this.appConfig.enableBAM.enableProcessFlow ? true : false;
    } else if (featureFlag === 'bam-dashboard/process-table') {
      activateRoute =this.appConfig.featureFlag.enableBAM && this.appConfig.enableBAM.enableProcessTable ? true : false;
    } else if (featureFlag === 'bam-dashboard/process-health') {
      activateRoute =
        this.appConfig.featureFlag.enableBAM && this.appConfig.enableBAM.enableProcessHealth ? true : false;
    } else if (featureFlag === 'bam-dashboard/rule') {
      activateRoute = this.appConfig.featureFlag.enableBAM && this.appConfig.enableBAM.enableRuleConfig ? true : false;
    } else if (featureFlag === 'event-broker') {
      activateRoute =
        this.appConfig.featureFlag.enableEventBroker && this.appConfig.enableEventBroker.enableEventCatalog
          ? true
          : false;
    } else if (featureFlag === 'event-broker/event-catalog') {
      activateRoute =
        this.appConfig.featureFlag.enableEventBroker && this.appConfig.enableEventBroker.enableEventCatalog
          ? true
          : false;
    } else if (featureFlag === 'event-broker/event-activity') {
      activateRoute =
        this.appConfig.featureFlag.enableEventBroker && this.appConfig.enableEventBroker.enableEventActivity
          ? true
          : false;
    } else if (featureFlag === "control-tower") {
        activateRoute = this.appConfig.featureFlag.enableCT && this.appConfig.enableCT.enableListAllRules ? true : false;
      }
      
    if (!activateRoute) {
      console.error(`Route not enabled by feature flag. Feature Flag: ${featureFlag}.`);
    }

    return activateRoute;
  }
}

<div class="modal-header">
    <ng-container *ngIf="!isUpdate">
        <h4 class="modal-title">Add New Process</h4>
    </ng-container>
    <ng-container *ngIf="isUpdate">
        <h4 class="modal-title">Update Process</h4>
    </ng-container>
    <button type="button" class="close" aria-label="Close" (click)="cancel()" [disabled]="isLoading">
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<div class="modal-body">
    <div *ngIf="isProcessLoading" class="ml-auto">
        <span role="progressbar" class="info-text fa fa-spinner fa-spin fa-3x" aria-label="Loading"></span>
    </div>
    <ng-container *ngIf="!isProcessLoading">
        <form [formGroup]="processForm">
            <div class="input-group input-group-sm mb-2">
                <div class="input-group-prepend">
                    <label class="input-group-text" id="processNameLabel" style="width: 9rem;">
                        Process Name
                        <span class="danger-text ml-1" aria-label="Required Field">*</span>
                    </label>
                </div>
                <input type="text" formControlName="processName" class="form-control required" aria-label="Process Name"
                    aria-describedby="processNameLabel">
            </div>
            <div class="input-group input-group-sm mb-2">
                <div class="input-group-prepend">
                    <label class="input-group-text" id="processDescription" style="width: 9rem;">
                        Process Description
                    </label>
                </div>
                <textarea type="text" formControlName="description" class="form-control"
                    aria-label="Process Description" aria-describedby="processDescription">
            </textarea>
            </div>
        </form>
    </ng-container>
    <ng-container *ngIf="showError">
        <small class="danger-text"> {{ errorMessage }} </small>
    </ng-container>
</div>

<div class="modal-footer">
    <div *ngIf="isLoading" class="ml-2">
        <span role="progressbar" class="info-text fa fa-spinner fa-spin fa-2x" aria-label="Loading"></span>
    </div>
    <ng-container *ngIf="!isUpdate">
        <button type="button" class="btn primary-button" aria-label="Save" (click)="saveProcess()"
            [disabled]="isLoading || !processForm.valid">Save</button>
    </ng-container>
    <ng-container *ngIf="isUpdate">
        <button type="button" class="btn primary-button" aria-label="Update" (click)="updateProcess()"
            [disabled]="isLoading || !processForm.valid">Update</button>
    </ng-container>
    <button type="button" class="btn secondary-button" aria-label="Cancel" (click)="cancel()"
        [disabled]="isLoading">Cancel</button>
</div>
import { Inject, Injectable } from '@angular/core';
import { TemplateVariable } from '../model/template-variable.model';
import { Observable, of, throwError } from 'rxjs';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { map, catchError, mergeMap } from 'rxjs/operators';
import { APP_CONFIG } from '../../common/constants';
import { ODataResponse } from '../../model/common.model';
import { AppConfig } from '../../model/app-config.model';

@Injectable({
  providedIn: 'root'
})
export class TemplateVariableService {
  private bamDashboardAPIUrl: string;
  private readonly templateVariableRegex: RegExp;

  constructor(
    private httpClient: HttpClient,
    @Inject(APP_CONFIG) appConfig: AppConfig
  ) {
    this.bamDashboardAPIUrl = appConfig.baseUrlWithVersionBAMDashboard;
    this.templateVariableRegex = new RegExp(/\{\{([^{}]+?)\}\}/, 'g');
  }

  public getTemplateVariable(templateVariableId: number): Observable<TemplateVariable> {
    const url = this.bamDashboardAPIUrl + `TemplateVariable(${templateVariableId})`;
    return this.httpClient.get<TemplateVariable>(url);
  }

  public getTemplateVariableList(processId: number): Observable<Array<TemplateVariable>> {
    const url = this.bamDashboardAPIUrl + `TemplateVariable?processId=${processId}`;
    return this.httpClient.get<ODataResponse<Array<TemplateVariable>>>(url).pipe(
      map(response => response['value']),
      catchError((error: HttpErrorResponse) => {
        if (error.status === 404) {
          return of(new Array<TemplateVariable>());
        } else {
          throwError(error);
        }
      })
    );
  }

  public getTemplateVariableLookUpValue(processId: number): Observable<Map<string, TemplateVariable>> {
    return this.getTemplateVariableList(processId).pipe(
      map((templateVariableList: Array<TemplateVariable>) => {
        const valueLookUp = new Map<string, TemplateVariable>();
        templateVariableList.forEach((t: TemplateVariable) => {
          valueLookUp.set(t.name, t);
        });
        return valueLookUp;
      })
    );
  }

  public saveTemplateVariable(templateVariable: TemplateVariable): Observable<TemplateVariable> {
    const url = this.bamDashboardAPIUrl + 'TemplateVariable';
    return this.httpClient.post<TemplateVariable>(url, templateVariable);
  }

  public updateTemplateVariable(templateVariable: TemplateVariable): Observable<TemplateVariable> {
    const url = this.bamDashboardAPIUrl + `TemplateVariable(${templateVariable.id})`;
    return this.httpClient.put<TemplateVariable>(url, templateVariable).pipe(
      mergeMap(() => this.getTemplateVariable(templateVariable.id))
    );
  }

  public deleteTemplateVariable(templateVariableId: number): Observable<TemplateVariable> {
    const url = this.bamDashboardAPIUrl + `TemplateVariable(${templateVariableId})`;
    return this.httpClient.delete<TemplateVariable>(url);
  }

  public getParsedQuery(templatizedQuery: string, templateVariableLookup: Map<string, TemplateVariable>): string {
    templatizedQuery = templatizedQuery.replace(this.templateVariableRegex, (s) => {
      s = s.replace(/\{/g, '');
      s = s.replace(/\}/g, '');
      return templateVariableLookup.get(s)?.value;
    });
    return templatizedQuery;
  }

  public getTemplateVariableRegex(): RegExp {
    return this.templateVariableRegex;
  }
}

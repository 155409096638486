import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ListBusinessGoalComponent } from "./list-business-goal/list-business-goal.component";
import { RouterModule, Routes } from "@angular/router";
import { SharedModule } from "../common/shared.module";
import { AuthRoleGuard } from "../auth-role-guard";
import { FeatureFlagGuard } from "../feature-flag-guard";

const controlTowerRoutes: Routes = [
  {
    path: "control-tower",
    component: ListBusinessGoalComponent,
    canActivate: [AuthRoleGuard, FeatureFlagGuard],
    data: { featureFlag: "control-tower" },
  },
];
@NgModule({
  declarations: [ListBusinessGoalComponent],
  imports: [CommonModule, SharedModule, RouterModule.forChild(controlTowerRoutes)],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ControlTowerModule {}

import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { GraphMap } from '../common/graph/graph-map';

@Component({
  selector: 'app-path-tagging',
  templateUrl: './path-tagging.component.html',
  styleUrls: ['./path-tagging.component.scss', '../../../../src/style/common-styles.scss'],
})
export class PathTaggingComponent implements OnInit {
  @Input() processId: string;
  @Input() graphMap: GraphMap;

  constructor(
    private modalService: NgbActiveModal
  ) { }

  ngOnInit(): void {
  }

  handleClose() {
    this.modalService.close();
  }

}

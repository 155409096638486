<!-- title -->
<h5 class="display-5 mt-2 ml-2">Add New Operations to QoS</h5>
<hr />

<!-- form to add new service. -->
<form [formGroup]="telemetryInputForm" class="mx-2">
  <!-- telemetry source. -->
  <div class="input-group input-group-sm mb-2">
    <div class="input-group-prepend">
      <label class="input-group-text" id="telemetrySourceLabel" style="width: 11rem"
        >Telemetry Source
        <span class="danger-text ml-1" aria-label="Required Field">*</span>
        <span
          id="info_telemetrySource"
          class="fa fa-info-circle ml-2"
          ngbPopover="Telemetry source of service or application"
        >
        </span>
      </label>
    </div>
    <select
      class="custom-select"
      id="telemetrySourceOptionSelect"
      #telemetrySourceOptionSelectName
      formControlName="telemetrySource"
      aria-label="Telemetry Source"
      aria-labelledby="telemetrySourceLabel"
      aria-describedby="info_telemetrySource"
      style="max-width: 25rem"
    >
      <option disabled hidden>Select Telemetry Source</option>
      <option>Application Insights</option>
      <option>Log Analytics</option>
    </select>
  </div>
  <ng-container *ngIf="isTelemetryOnboardingSupported">
    <!-- Service name -->
    <div class="d-inline-flex">
      <div class="input-group input-group-sm mb-2">
        <div class="input-group-prepend">
          <label class="input-group-text" id="serviceNameLabel" style="width: 11rem"
            >Service Name
            <span class="danger-text ml-1" aria-label="Required Field">*</span>
            <span
              id="info_serviceName"
              class="fa fa-info-circle ml-2"
              ngbPopover="Service Name of application from Service Tree. It is required field to select."
            >
            </span>
          </label>
        </div>
        <select
          class="custom-select"
          id="serviceNameSelect"
          formControlName="serviceName"
          aria-label="Service Name"
          aria-labelledby="serviceNameLabel"
          aria-describedby="info_serviceName"
          style="min-width: 25rem"
        >
          <option disabled hidden>Loading...</option>
          <option disabled hidden>Select Service Name</option>
          <ng-container *ngFor="let serviceTreeService of serviceTreeServiceList">
            <option [ngValue]="serviceTreeService">
              {{ serviceTreeService.name }}
            </option>
          </ng-container>
        </select>
      </div>
      <div *ngIf="isServiceTreeServiceLoading" class="ml-2">
        <span
          role="progressbar"
          class="info-text fa fa-spinner fa-spin fa-2x"
          aria-label="Loading"
          aria-labelledby="serviceNameLabel"
        ></span>
      </div>
    </div>
    <!-- Service oid. -->
    <div class="input-group input-group-sm mb-2">
      <div class="input-group-prepend">
        <label class="input-group-text" id="ServiceOidLabel" style="width: 11rem"
          >Service Oid
          <span
            id="info_serviceOid"
            class="fa fa-info-circle ml-2"
            ngbPopover="Service Oid is an unique id for the selected service. It is readonly field."
          >
          </span>
        </label>
      </div>
      <input
        type="text"
        formControlName="serviceOid"
        class="form-control"
        aria-label="Service Oid"
        style="max-width: 25rem"
        aria-labelledby="ServiceOidLabel"
        aria-describedby="info_serviceOid"
        readonly
      />
    </div>
    <div *ngIf="isiKeyListLoading" class="ml-2">
      <span class="info-text" aria-label="Loading">Validating AI Instrumentation Keys...</span>
    </div>
    <ng-container *ngIf="hasValidiKeysConfigured">
      <!-- customers. -->
      <div class="input-group input-group-sm mb-2">
        <div class="input-group-prepend">
          <label class="input-group-text" id="customerLabel" style="width: 11rem"
            >Customers
            <span
              id="info_customer"
              class="fa fa-info-circle ml-2"
              ngbPopover="Customer names of the service or application. It is an optional field."
              container="body"
            >
            </span>
          </label>
        </div>
        <input
          type="text"
          formControlName="customers"
          class="form-control"
          aria-label="Customers"
          aria-describedby="info_customer"
          style="max-width: 25rem"
          aria-labelledby="customerLabel"
        />
      </div>
    </ng-container>
  </ng-container>
</form>
<div class="mx-2" *ngIf="isTelemetryOnboardingSupported === false" tabindex="0">
  UI Onboarding is not supported for selected telemetry source. Please check the wiki at
  <a class="link-text" href="{{ qosOnboardingWikiUrl }}" target="_blank" aria-label="QOS Onboarding Wiki Link"
    >QoS Onboarding Wiki
    <span class="fa fa-link" aria-hidden="true"></span>
  </a>
  to onboard to QoS.
</div>
<div class="mx-2" *ngIf="hasValidiKeysConfigured === false && isTelemetryOnboardingSupported" tabindex="0">
  Instrumentation Keys not configured for selected service.<br />
  Please configure instrumentation keys before adding operations at
  <button
    class="btn btn-sm info-button border border-secondary ml-2"
    type="button"
    (click)="manageIkey()"
    aria-label="Manage Ikey Button"
    style="width: 10rem"
  >
    Manage iKey
  </button>
</div>
<ng-container *ngIf="hasValidiKeysConfigured && isTelemetryOnboardingSupported">
  <button
    type="button"
    class="btn btn-sm btn-outline-dark ml-2 mt-2"
    (click)="addNewOperation('Request')"
    #AddRequestButton
    aria-label="Add Request Operation"
  >
    <div class="fa-stack" style="cursor: pointer; font-size: 0.75em" aria-hidden="true">
      <i class="text-success fa fa-circle fa-stack-2x"></i>
      <i class="fa fa-plus fa-stack-1x fa-inverse"></i>
    </div>
    Add Request
  </button>
  <button
    type="button"
    class="btn btn-sm btn-outline-dark ml-2 mt-2"
    (click)="addNewOperation('RemoteDependency')"
    aria-label="Add Remote Dependency Operation"
  >
    <div class="fa-stack" style="cursor: pointer; font-size: 0.75em" aria-hidden="true">
      <i class="text-success fa fa-circle fa-stack-2x"></i>
      <i class="fa fa-plus fa-stack-1x fa-inverse"></i>
    </div>
    Add Remote Dependency
  </button>
  <ng-container *ngIf="addOperation">
    <form [formGroup]="telemetryInputForm">
      <div formArrayName="serviceList">
        <table class="table table-bordered mt-3" style="width: 74rem; max-width: 100%">
          <thead class="thead-dark">
            <tr>
              <th scope="col" class="text-center" style="width: 2rem" id="h1">Index</th>
              <th scope="col" class="text-center" id="h2">
                Request Type
                <span class="ml-1" style="color: #f28383" aria-label="Required Field">*</span>
                <span id="info_requestType" class="fa fa-info-circle ml-2" ngbPopover="Request Type of the operation">
                </span>
              </th>
              <th scope="col" class="text-center" id="h3">
                Operation Name
                <span class="ml-1" style="color: #f28383" aria-label="Required Field">*</span>
                <span id="info_operationName" class="fa fa-info-circle ml-2" ngbPopover="Operation Name for the Qos">
                </span>
              </th>
              <th scope="col" class="text-center" style="width: 6rem" id="h4">
                Latency (ms)
                <span class="ml-1" style="color: #f28383" aria-label="Required Field">*</span>
                <span
                  id="info_latency"
                  class="fa fa-info-circle ml-2"
                  ngbPopover="Latency for operation in milliseconds"
                >
                </span>
              </th>
              <th scope="col" class="text-center" style="width: 5rem" id="h5">
                Critical
                <span class="ml-1" style="color: #f28383" aria-label="Required Field">*</span>
                <span
                  id="info_critical"
                  class="fa fa-info-circle ml-2"
                  ngbPopover="Operation is critical or not. By default checkbox is unchecked."
                >
                </span>
              </th>
              <th scope="col" class="text-center" style="width: 3rem" id="h6">
                Delete
                <span id="info_delete" class="fa fa-info-circle ml-2" ngbPopover="Remove the entry from table form">
                </span>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let serviceForm of this.getServiceListForm().controls; let serviceIndex = index">
              <ng-container formGroupName="{{ serviceIndex }}">
                <td class="text-center" headers="h1">
                  {{ serviceIndex + 1 }}
                </td>
                <td headers="h2">
                  <select
                    class="custom-select custom-select-sm"
                    formControlName="requestType"
                    aria-label="Request Type"
                    #formRowRequestType
                    aria-describedby="info_requestType"
                    style="max-width: 50rem"
                  >
                    <option>Request</option>
                    <option>Remote Dependency</option>
                  </select>
                </td>
                <td headers="h3">
                  <input
                    type="text"
                    formControlName="operationName"
                    class="form-control form-control-sm required"
                    aria-label="Operation Name"
                    aria-describedby="info_operationName"
                  />
                </td>
                <td headers="h4">
                  <input
                    type="number"
                    min="0"
                    formControlName="latencyThreshold"
                    class="form-control form-control-sm required"
                    aria-label="Latency"
                    aria-describedby="info_latency"
                  />
                </td>
                <td headers="h5" class="text-center">
                  <input
                    type="checkbox"
                    class="mt-2"
                    aria-label="Critical"
                    aria-describedby="info_critical"
                    formControlName="critical"
                  />
                </td>
                <td headers="h6" class="text-center">
                  <a
                    class="fa-stack"
                    style="cursor: pointer; font-size: 0.75em"
                    aria-label="Delete entry"
                    aria-describedby="info_delete"
                    [routerLink]="[]"
                    (click)="deleteOperation(serviceIndex)"
                  >
                    <i class="text-danger fa fa-circle fa-stack-2x"></i>
                    <i class="fa fa-minus fa-stack-1x fa-inverse"></i>
                  </a>
                </td>
              </ng-container>
            </tr>
          </tbody>
        </table>
        <div class="mt-3 ml-3">
          <div *ngFor="let serviceForm of this.getServiceListForm().controls; let id = index" class="alert-text">
            <div
              *ngIf="
                !telemetryInputForm.controls['serviceList'].controls[id].pristine &&
                telemetryInputForm.controls['serviceList'].controls[id].invalid
              "
            >
              <div
                *ngIf="
                  telemetryInputForm.controls['serviceList'].controls[id].get('operationName').hasError('required') &&
                  !telemetryInputForm.controls['serviceList'].controls[id].get('operationName').pristine
                "
                aria-live="assertive"
              >
                <small> Row {{ id + 1 }}: Operation Name is Required Field </small>
              </div>
              <div
                *ngIf="
                  telemetryInputForm.controls['serviceList'].controls[id]
                    .get('latencyThreshold')
                    .hasError('required') &&
                  !telemetryInputForm.controls['serviceList'].controls[id].get('latencyThreshold').pristine
                "
                aria-live="assertive"
              >
                <small> Row {{ id + 1 }}: Latency Field is Required Field</small>
              </div>
              <div
                *ngIf="
                  telemetryInputForm.controls['serviceList'].controls[id].get('latencyThreshold').hasError('pattern') &&
                  !telemetryInputForm.controls['serviceList'].controls[id].get('latencyThreshold').pristine
                "
                aria-live="assertive"
              >
                <small> Row {{ id + 1 }}: Latency Field value should be numeric and greater than 0.</small>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
    <div class="d-flex flex-row">
      <div class="col-auto my-3">
        <button
          type="button"
          class="btn btn-sm primary-button"
          (click)="saveOperations()"
          aria-label="Save New Operations"
          [disabled]="!telemetryInputForm.valid || isSaveInProgress"
        >
          Save
        </button>
        <button
          type="button"
          class="btn btn-sm secondary-button ml-2"
          (click)="cancel()"
          aria-label="Cancel"
          [disabled]="isSaveInProgress"
        >
          Cancel
        </button>
        <i *ngIf="isSaveInProgress" class="ml-2 mt-1">
          <span role="progressbar" class="info-text fa fa-spinner fa-spin fa-2x" aria-label="Loading"></span>
        </i>
      </div>
    </div>
  </ng-container>
</ng-container>

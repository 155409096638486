import { Component, Input, Output, EventEmitter, SimpleChanges, ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'app-table-edit-display',
  templateUrl: './table-edit-display.component.html',
  styleUrls: ['./table-edit-display.component.scss']
})
export class TableEditDisplayComponent {
  @Input() showModal: boolean = false; 
  @Input() rowData: any; 
  @Input() formFields: any[] = []; 
  @Output() close = new EventEmitter<void>(); 
  @Output() update = new EventEmitter<any>(); 

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.showModal) {
      this.cdr.detectChanges(); 
    }
  }

  closeModal() {
    this.showModal = false; 
    this.close.emit(); 
    this.cdr.detectChanges(); 
    
  }

  handleUpdate() {
    const updatedData = {
      ...this.rowData  
    };
    this.update.emit(updatedData);
    this.closeModal(); 
  }
}

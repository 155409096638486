import { Component, OnInit, OnDestroy, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { ServiceInput, QosRoutingState, IKeyMapping, IKeyServiceMapping } from '../model/qos.model';
import { UntypedFormControl, Validators, ValidatorFn, AbstractControl } from '@angular/forms';
import { filter, mergeMap } from 'rxjs/operators';
import { ServiceTreeService } from '../../bam-dashboard/model/process.model';
import { Subscription } from 'rxjs';
import { QoSService } from '../service/qos.service';
import { HttpErrorResponse } from '@angular/common/http';
import { showError } from '../common/utils';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgbModalSuccessComponent } from '../../modal/ngb-modal-success.component';
import { QoSRouterPage } from '../common/constants';
import { NgbModalConfirmComponent } from '../../modal/ngb-modal-confirm.component';

@Component({
  selector: 'app-manage-ikeys',
  templateUrl: './manage-ikeys.component.html',
  styleUrls: ['./manage-ikeys.component.css']
})
export class ManageIkeysComponent implements OnInit, OnDestroy, AfterViewInit {
  private serviceInput: ServiceInput;
  private previousUrl: string;
  teamgroupName: string;
  teamGroupId: string;

  serviceName = new UntypedFormControl('');
  serviceOid = new UntypedFormControl('');
  iKey = new UntypedFormControl('', [
    Validators.required,
    Validators.pattern(/{{0,1}([0-9a-fA-F]){8}-([0-9a-fA-F]){4}-([0-9a-fA-F]){4}-([0-9a-fA-F]){4}-([0-9a-fA-F]){12}}{0,1}/),
    this.validateDuplicateKeys()
  ]);

  serviceTreeServiceList: Array<ServiceTreeService>;
  isServiceTreeServiceLoading: boolean;

  iKeyList: Array<string>;
  iKeysAdded: Array<string>;
  isiKeyListLoading: boolean;
  isSaveInProgress: boolean;
  isDeleteInProgress: boolean;
  isServiceSelected: boolean;

  subscriptions: Subscription;
  saveIkeySubscription: Subscription;

  @ViewChild('ServiceNameSelectField') ServiceNameSelectFieldElement: ElementRef;

  constructor(
    private router: Router,
    private qosService: QoSService,
    private modalService: NgbModal
  ) {
    this.subscriptions = new Subscription();

    const state = this.router.getCurrentNavigation().extras.state as QosRoutingState;
    this.serviceInput = state.serviceInput;
    this.teamgroupName = state.teamGroupName;
    this.previousUrl = state.previousUrl;

    this.serviceTreeServiceList = new Array<ServiceTreeService>();
    this.isServiceTreeServiceLoading = false;
    this.isServiceSelected = false;
    this.getServiceNames(this.serviceInput.teamGroupOid, state);

    this.isiKeyListLoading = false;
    this.iKeysAdded = new Array<string>();
    this.iKeyList = new Array<string>();
    this.getIkeyList();

    this.isSaveInProgress = false;
    this.isDeleteInProgress = false;
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  ngAfterViewInit() {
    this.focusElement();
  }

  focusElement() {
    this.ServiceNameSelectFieldElement.nativeElement.focus();
  }

  getServiceNames(teamGroupId: string, state: QosRoutingState): void {
    this.isServiceTreeServiceLoading = true;
    this.serviceName.setValue('Loading...');
    const serviceTreeSubscription = this.qosService.getServiceTreeServicesByTeamGroup(teamGroupId).subscribe(
      (serviceList: ServiceTreeService[]) => {
        this.serviceTreeServiceList = serviceList;
        this.isServiceTreeServiceLoading = false;
        if (state.previousUrl === QoSRouterPage.AddOperation) {
          const selectedService = this.serviceTreeServiceList.find(x => x.id === state.selectedServiceId);
          this.serviceName.setValue(selectedService);
          this.serviceOid.setValue(state.selectedServiceId);
          this.isServiceSelected = true;
        } else {
          this.serviceName.setValue('Select Service Name');
        }
      },
      (error: HttpErrorResponse) => {
        this.isServiceTreeServiceLoading = false;
        this.serviceName.setValue('Select Service Name');
        showError(this.modalService, error, error.error);
      }
    );

    this.subscriptions.add(serviceTreeSubscription);
  }

  getIkeyList(): void {
    const valueChanges = this.serviceName.valueChanges.pipe(
      filter(x => !['Select Service Name', 'Loading...'].includes(x)),
      mergeMap((selectedServiceName: ServiceTreeService) => {
        this.isServiceTreeServiceLoading = true;
        this.isiKeyListLoading = true;
        this.isServiceSelected = true;
        this.serviceOid.setValue(selectedServiceName.id);
        return this.qosService.getIkeyListByServiceId(selectedServiceName.id);
      })
    ).subscribe(
      (iKeyList: Array<string>) => {
        this.isServiceTreeServiceLoading = false;
        this.isiKeyListLoading = false;
        this.iKeyList = iKeyList;
      },
    );

    this.subscriptions.add(valueChanges);
  }

  addIkey(): void {
    this.iKeysAdded.push(this.iKey.value);
    this.iKey.setValue('');
  }

  saveIkeyList(): void {
    this.isSaveInProgress = true;
    const ikeyMapping = new IKeyMapping();
    ikeyMapping.teamGroupOid = this.serviceInput.teamGroupOid;
    ikeyMapping.serviceOid = this.serviceOid.value;

    // Concat newly added ikeys to main list and reset the newly added array.
    this.iKeyList = this.iKeyList.concat(this.iKeysAdded);
    this.iKeysAdded.length = 0;
    ikeyMapping.iKeys = this.iKeyList;

    this.saveIkeySubscription = this.qosService.saveIkeyList(ikeyMapping).subscribe(
      () => {
        this.isSaveInProgress = false;
        const saveConfirmationModal = this.modalService.open(NgbModalSuccessComponent);
        saveConfirmationModal.componentInstance.message = 'Instrumentation Keys Added Successfully.';

        if (saveConfirmationModal) {
          saveConfirmationModal.result
            .then(() => {
              this.navigate();
            });
        }
      },
      (error: HttpErrorResponse) => {
        this.isSaveInProgress = false;
        console.error(error);
        showError(this.modalService, error, error.error);
      }
    );
    this.subscriptions.add(this.saveIkeySubscription);
  }

  deleteIKey(key: string): void {
    // if ikey was just added, do not send a request to backend. Just delete it from the list
    if (this.iKeysAdded.length > 0 && this.iKeysAdded.includes(key)) {
      this.iKeysAdded.splice(this.iKeysAdded.indexOf(key), 1);
      return;
    }

    // if the ikey already exists, confirm and delete
    const modal = this.modalService.open(NgbModalConfirmComponent);
    modal.componentInstance.message = "Are you sure you want to delete?";
    modal.result.then(() => {
      this.isDeleteInProgress = true;
      const deleteIkey = new IKeyServiceMapping();
      deleteIkey.serviceOid = this.serviceOid.value;
      deleteIkey.iKey = key;
      this.qosService.deleteIKeyServiceMapping(deleteIkey).subscribe(
        () => {
          this.isDeleteInProgress = false;
          // add loader till delete is finished, then popup with delete finished
          const deleteConfirmationModal = this.modalService.open(NgbModalSuccessComponent);
          deleteConfirmationModal.componentInstance.message = 'IKey successfully deleted';
        },
        (error: HttpErrorResponse) => {
          this.isDeleteInProgress = false;
          showError(this.modalService, error, error.error);
        },
        () => {
          // delete ikey from list
          this.iKeyList.splice(this.iKeyList.indexOf(key), 1);
        }
      );
    });
  }

  cancel(): void {
    this.navigate();
  }

  navigate(): void {
    if (this.previousUrl === QoSRouterPage.Admin) {
      this.router.navigate(['quality-of-service/admin']);
    } else if (this.previousUrl === QoSRouterPage.AddOperation) {
      const state: QosRoutingState = {
        previousUrl: QoSRouterPage.ManageIKey,
        teamGroupName: this.teamgroupName,
        selectedServiceId: this.serviceOid.value,
        serviceInput: this.serviceInput,
      };
      this.router.navigate(['quality-of-service/add-component'], { state: state });
    }
  }

  validateDuplicateKeys(): ValidatorFn {
    return (iKeyControl: AbstractControl): { [key: string]: any } | null => {
      const ikeyValue = iKeyControl.value;
      if ((this.iKeyList && this.iKeyList.includes(ikeyValue)) || (this.iKeysAdded && this.iKeysAdded.includes(ikeyValue))) {
        return {
          duplicateError: true
        };
      } else {
        return null;
      }
    };
  }

}

import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from '@angular/core';
import { EventType } from "../model/event-type.model";
import { EventTypeSubscription } from "../model/event-type-subscription.model";
import { EventTypeSubscriptionAPI } from "../model/event-type-subscription-api.model";
import { Observable, of } from 'rxjs';
import { combineAll, defaultIfEmpty, mergeMap } from 'rxjs/operators';
import { map } from 'rxjs/operators';
import { APP_CONFIG } from "../../common/constants";
import { AppConfig } from '../../model/app-config.model';
import { EventTypeMasterData } from "../model/event-type-master-data.model";
import { EventTypeSubscriptionMasterData } from "../model/event-type-subscription-master-data.model";
import { EventHandler, EventHandlerArg } from "../model/event-handler.model";
import { CacheService } from '../../service/cache.service';

@Injectable({
  providedIn: 'root'
})

export class EventCatalogService {
  private baseUrlWithVersion: string;

  private eventAPI: EventAPI;
  private subscriptionAPI: SubscriptionAPI;

  constructor(private httpClient: HttpClient,
              private cacheService: CacheService,
              @Inject(APP_CONFIG) appConfig: AppConfig) {
    this.baseUrlWithVersion = appConfig.baseUrlEventManagementAPI;

    // eventually inject this
    this.eventAPI = new EventAPI(this.httpClient, this.baseUrlWithVersion, this.cacheService);
    this.subscriptionAPI = new SubscriptionAPI(this.httpClient, this.baseUrlWithVersion, this.cacheService, this.eventAPI);
  }

  // Master Data
  getEventTypeMasterData(): Observable<EventTypeMasterData> {
    return of(MasterData.getEventTypeMasterData());
  }

  getEventTypeSubscriptionMasterData(): Observable<EventTypeSubscriptionMasterData> {
    return of(MasterData.getEventTypeSubscriptionMasterData());
  }

  // Event API
  getEventCatalog(): Observable<EventType[]> {
    return this.eventAPI.getEventCatalog();
  }

  createEventType(newEventType: EventType): Observable<EventType> {
    return this.eventAPI.createEventType(newEventType);
  }

  updateEventType(updatedEventType: EventType): Observable<EventType> {
    return this.eventAPI.updateEventType(updatedEventType);
  }

  getEventTypeById(id: number): Observable<EventType> {
    return this.eventAPI.getEventTypeById(id);
  }

  getEventTypeByName(name: string): Observable<EventType> {
    return this.eventAPI.getEventTypeByName(name);
  }


  // Subscription API
  getSubscriptionListByEventTypeId(id: number): Observable<EventTypeSubscription[]> {
    return this.subscriptionAPI.getSubscriptionListByEventTypeId(id);
  }

  getSubscriptionListByEventTypeName(name: string): Observable<EventTypeSubscription[]> {
    return this.subscriptionAPI.getSubscriptionListByEventTypeName(name);
  }

  createEventTypeSubscription(newEventTypeSub: EventTypeSubscription, parentEvent: EventType): Observable<EventTypeSubscription> {
    return this.subscriptionAPI.createEventTypeSubscription(newEventTypeSub, parentEvent);
  }

  updateEventTypeSubscription(updatedEventTypeSub: EventTypeSubscription): Observable<EventTypeSubscription> {
    return this.subscriptionAPI.updateEventTypeSubscription(updatedEventTypeSub);
  }

  getEventTypeSubscriptionById(id: number): Observable<EventTypeSubscription> {
    return this.subscriptionAPI.getEventTypeSubscriptionById(id);
  }

  getEventTypeSubscriptionByName(name: string): Observable<EventTypeSubscription> {
    return this.subscriptionAPI.getEventTypeSubscriptionByName(name);
  }

  deleteEventTypeSubscription(id: number): Observable<any> {
    return this.subscriptionAPI.deleteEventTypeSubscription(id);
  }

  getEventTypeSubscriptionStatus(id: number): Observable<string> {
    return this.subscriptionAPI.getEventTypeSubscriptionStatus(id);
  }

  getEventHandlerValidationStatus(
    eventHandlerProperties: EventHandler, eventHandlerType: string
    , eventType: string, schemaType: string, environment: string): Observable<any> {
    const eventHandlerJson: any = {
      type: eventHandlerType.replace(/\s/g, '')
    };
    eventHandlerProperties.requiredArgs.forEach((a: EventHandlerArg) => eventHandlerJson[a.name] = a.value);
    return this.subscriptionAPI.getEventHandlerValidationStatus(JSON.stringify(eventHandlerJson), eventType, schemaType, environment);
  }
}


//// MasterData ////

class MasterData {
  static getEventTypeMasterData(): EventTypeMasterData {
    const masterData = new EventTypeMasterData();
    masterData.environment = ['PPE','PROD'];
    masterData.schema = ['CloudEventSchemaV1_0', 'EventGridSchema'];
    return masterData;
  }

  static getEventTypeSubscriptionMasterData(): EventTypeSubscriptionMasterData {
    const masterData = new EventTypeSubscriptionMasterData();

    masterData.eventHandler = [
      new EventHandler("WebHook", [
          new EventHandlerArg("endpointUrl", "Endpoint Url", "string", false),
          new EventHandlerArg("azureActiveDirectoryApplicationIdOrUri", "AAD App Id","string", true)
        ],
        [
          new EventHandlerArg("maxEventsPerBatch", "Max Events Per Batch","int", true),
          new EventHandlerArg("preferredBatchSizeInKilobytes", "Batch Size (kB)","int", true),
        ]
      ),
      new EventHandler("EventHub", [
          new EventHandlerArg("resourceId", "Resource Id","string", false)
        ],
        []
      ),
       new EventHandler("Azure Functions", [
          new EventHandlerArg("resourceId", "Resource Id","string", false),
        ],
        [
          new EventHandlerArg("maxEventsPerBatch", "Max Events Per Batch","int", false),
          new EventHandlerArg("batchSize", "Batch Size (kB)","int", false),
        ]
      ),
      new EventHandler("Service Bus Queue", [
        new EventHandlerArg("resourceId", "Resource Id","string", false),
        ], []
      ),
      new EventHandler("Service Bus Topic", [
        new EventHandlerArg("resourceId", "Resource Id","string", false),
        ], []
      ),
      new EventHandler("Hybrid Connection", [
        new EventHandlerArg("resourceId", "Resource Id","string", false),
        ], []
      ),
      new EventHandler("Storage Queue", [
        new EventHandlerArg("resourceId", "Resource Id","string", false),
        new EventHandlerArg("queueName", "Queue Name","string", false),
        ], []
      ),/*
      new EventHandler("Existing Subscription", [
        new EventHandlerArg("resourceId", "Resource Id","string"),
        new EventHandlerArg("queueName", "Queue Name","string"),
        ], []
      ),
      new EventHandler("BAM Validation Engine", [], []),*/
    ];

    return masterData;
  }
}


//// EventAPI ////

class EventAPI {
  constructor(private httpClient: HttpClient, private baseUrlWithVersion: string, private cacheService: CacheService) {}

  getEventCatalog(queryString: string = ''): Observable<EventType[]> {
    const eventList = this.cacheService.getCached<any>(`${this.baseUrlWithVersion}EventType${queryString}`,
      (url) => this.httpClient.get<any[]>(url));

    return eventList.pipe(
      map((el: any) => el.value.map((e: EventType) => e))         // map from OData response
    );
  }

  createEventType(newEventType: EventType): Observable<EventType> {
    const result = this.httpClient.post<EventType>(`${this.baseUrlWithVersion}EventType`, newEventType);
    this.clearCache();
    return result;
  }

  updateEventType(updatedEventType: EventType): Observable<EventType> {
    const result = this.httpClient.put<EventType>(`${this.baseUrlWithVersion}EventType`, updatedEventType);
    this.clearCache();
    return result;
  }

  getEventTypeById(id: number): Observable<EventType> {
    const eventType = this.cacheService.getCached<any>(`${this.baseUrlWithVersion}EventType(${id})`,
      (url) => this.httpClient.get(url));

    return eventType.pipe(
      map((e: EventType) => e),
    );
  }

  getEventTypeByName(name: string): Observable<EventType> {
    return this.getEventCatalog(`?$filter=Name eq '${name}'`).pipe(
      map((sl: EventType[]) => sl.find(e => true))          // return first value or undefined, assumes Name is a unique key in this context
    );
  }

  /// private methods ///

  private clearCache() {
    const regex = new RegExp(`${this.baseUrlWithVersion}EventType*`);
    this.cacheService.clearCacheByRegex(regex);
  }
}


//// SubscriptionAPI ////

class SubscriptionAPI {
  constructor(private httpClient: HttpClient,
              private baseUrlWithVersion: string,
              private cacheService: CacheService,
              private eventAPI: EventAPI) {

  }

  getSubscriptionListByEventTypeId(id: number): Observable<EventTypeSubscription[]> {
    // TODO: update API to support OData filter or by name endpoint
    return this.getSubscriptions().pipe(
      map((sl: EventTypeSubscription[]) => sl.filter(s => s.parentEventId === id))
    );
  }

  getSubscriptionListByEventTypeName(name: string): Observable<EventTypeSubscription[]> {
    return this.getSubscriptions(`?$filter=EventType eq '${name}'`);
  }

  createEventTypeSubscription(newEventTypeSub: EventTypeSubscription, parentEvent: EventType): Observable<EventTypeSubscription> {
    const request = this.getCreateSubRequestForAPI(newEventTypeSub, parentEvent);
    const res = this.httpClient.post<EventTypeSubscription>(`${this.baseUrlWithVersion}SubscriptionDetails`, request);
    this.clearCache();
    return res;
  }

  updateEventTypeSubscription(updatedEventTypeSub: EventTypeSubscription): Observable<EventTypeSubscription> {
    const request = this.getUpdateSubRequestForAPI(updatedEventTypeSub);
    const res = this.httpClient.put<EventTypeSubscription>(`${this.baseUrlWithVersion}SubscriptionDetails`, request);
    this.clearCache();
    return res;
  }

  getEventTypeSubscriptionById(id: number): Observable<EventTypeSubscription> {
    const sub = this.cacheService.getCached<any>(`${this.baseUrlWithVersion}SubscriptionDetails(${id})`,
      (url) => this.httpClient.get(url));

    return sub.pipe(
      mergeMap((s: any) => this.convertFromAPIModel(s))
    );
  }

  getEventTypeSubscriptionByName(name: string): Observable<EventTypeSubscription> {
    return this.getSubscriptions(`?$filter=Name eq '${name}'`).pipe(
      map((sl: EventTypeSubscription[]) => sl.find(e => true))       // return first value or undef, assumes Name is a unique key
    );
  }

  getEventTypeSubscriptionStatus(id: number): Observable<string> {
    // this is not cached
    return this.httpClient.get<string>(`${this.baseUrlWithVersion}SubscriptionDetails(${id})?$select=status`);
  }

  deleteEventTypeSubscription(id: number): Observable<any> {
    this.clearCache();
    const res = this.httpClient.delete(`${this.baseUrlWithVersion}SubscriptionDetails(${id})`);
    return res;
  }

  getEventHandlerValidationStatus(
    eventHandlerProperties: string, eventType: string, schemaType: string, environment: string): Observable<any> {
    const url = `${this.baseUrlWithVersion}EventHandlerValidationStatus?eventHandlerPropertiesJson=${eventHandlerProperties}` +
     `&eventType=${eventType}&schemaType=${schemaType}&environment=${environment}`;
    return this.httpClient.get(url);
  }


  // private methods //

  private getSubscriptions(queryString: string = ''): Observable<EventTypeSubscription[]> {
    const subList = this.cacheService.getCached<any>(`${this.baseUrlWithVersion}SubscriptionDetails${queryString}`,
      (url) => this.httpClient.get(url));

    return subList.pipe(
      mergeMap((sl: any) => (sl.value as EventTypeSubscriptionAPI[]).map(
        (s: EventTypeSubscriptionAPI) => this.convertFromAPIModel(s)
      )),
      combineAll(),
      defaultIfEmpty([])
    );
  }

  private clearCache() {
    const regex = new RegExp(`${this.baseUrlWithVersion}SubscriptionDetails*`);
    this.cacheService.clearCacheByRegex(regex);
  }

  private convertFromAPIModel(apiSub: EventTypeSubscriptionAPI): Observable<EventTypeSubscription> {
    // get data from parent event
    const s = this.eventAPI.getEventTypeByName(apiSub.eventType).pipe(map((parentEvent: EventType) => {
      if (parentEvent === undefined) {
        return undefined;
      }
      // set up event handler
      let ehType: string;
      if (apiSub.eventHandlerType === "ServiceBusQueue") {
        ehType = "Service Bus Queue";
      } else if (apiSub.eventHandlerType === 'ServiceBusTopic') {
        ehType = "Service Bus Topic";
      } else {
        ehType = apiSub.eventHandlerType;
      }
      let ehProps: any;
      if (apiSub.eventHandlerType === "ServiceBusQueue"
      || apiSub.eventHandlerType === "ServiceBusTopic"
      || apiSub.eventHandlerType === "EventHub") {
        ehProps = JSON.parse(apiSub.eventHandlerProperties).Destination.Properties;
      } else {
        ehProps = JSON.parse(apiSub.eventHandlerProperties).Properties;
      }
      const ehPropNames = Object.keys(ehProps);
      let eventHandler = MasterData.getEventTypeSubscriptionMasterData().eventHandler.find(eh => eh.ehType === ehType);
      if (eventHandler !== undefined) {
        // iterate on args in UI model and copy over value from API model via case-insensitive lookup
        eventHandler.requiredArgs.forEach(a => {
          const apiPropName = ehPropNames.find(k => k.toLowerCase() === a.name.toLowerCase());
          a.value = ehProps[apiPropName];
        });
      } else {
        eventHandler = new EventHandler(ehType + " (unrecognized type)", [], []);
      }

      // set up filter
      let filters = JSON.stringify(
        JSON.parse(apiSub.filters).AdvancedFilters
      );
      if (filters === '[]' || filters === null || filters === undefined) {
        filters = '';
      }

      // create the UI model
      const uiSub = new EventTypeSubscription(
        apiSub.id,
        apiSub.name,
        apiSub.description,
        eventHandler,
        parentEvent.schemaType,
        apiSub.contactAlias,
        apiSub.ownerGroupAlias,
        apiSub.ownerGroupId,
        apiSub.teamName,
        apiSub.serviceName,
        apiSub.serviceId,
        apiSub.updatedBy,
        apiSub.updatedOn,
        parentEvent.id,
        apiSub.eventType,
        apiSub.status,
        filters,
        apiSub.enableDeliveryFailureEmail,
        apiSub.minDeliveryFailureAlertIntervalHours,
        "Hour",
        apiSub.enableDeliveryFailureIcM,
        apiSub.icmRoutingId,
        apiSub.severity,
        apiSub.rowVersion
      );

      return uiSub;
    }));

    return s;
  }

  private getCreateSubRequestForAPI(sub: EventTypeSubscription, parentEvent: EventType): any {
    const ehProps: any = {
      type: sub.eventHandler.ehType.replace(/\s/g, '')
    };
    sub.eventHandler.requiredArgs.forEach((a: EventHandlerArg) => ehProps[a.name] = a.value);

    const subRequest = {
      subscriptionName: sub.name,
      orgName: 'SupplyChain',
      teamName: sub.teamName,
      serviceName: sub.serviceName,
      serviceId: sub.serviceId,
      description: sub.description,
      environment: parentEvent.environment,
      schemaType: sub.schema,
      filters: this.createEventGridFilter(sub),
      //deadLetter: sub.deadLetter,
      //retryPolicy: sub.retryPolicy,
      eventHandlerProperties: ehProps,
      contactAlias: sub.contactAlias,
      ownerGroupAlias: sub.ownerGroupAlias,
      ownerGroupId: sub.ownerGroupId,
      enableDeliveryFailureEmail: sub.enableDeliveryFailureEmail,
      minDeliveryFailureAlertIntervalHours: sub.minDeliveryFailureAlertInterval,
      enableDeliveryFailureIcM: sub.enableDeliveryFailureIcM,
      icmRoutingId: sub.icmRoutingId,
      severity: sub.severity
    };

    return subRequest;
  }

  private getUpdateSubRequestForAPI(sub: EventTypeSubscription) {
    const ehProps: any = {
      type: sub.eventHandler.ehType.replace(/\s/g, '')
    };
    sub.eventHandler.requiredArgs.forEach((a: EventHandlerArg) => ehProps[a.name] = a.value);

    // same as create request, but excludes schemaType and environment and adds id
    const subRequest = {
      id: sub.id,
      subscriptionName: sub.name,
      orgName: 'SupplyChain',
      teamName: sub.teamName,
      serviceName: sub.serviceName,
      serviceId: sub.serviceId,
      description: sub.description,
      schemaType: sub.schema,
      filters: this.createEventGridFilter(sub),
      //deadLetter: sub.deadLetter,
      //retryPolicy: sub.retryPolicy,
      eventHandlerProperties: ehProps,
      contactAlias: sub.contactAlias,
      ownerGroupAlias: sub.ownerGroupAlias,
      ownerGroupId: sub.ownerGroupId,
      enableDeliveryFailureEmail: sub.enableDeliveryFailureEmail,
      minDeliveryFailureAlertIntervalHours: sub.minDeliveryFailureAlertInterval,
      enableDeliveryFailureIcM: sub.enableDeliveryFailureIcM,
      icmRoutingId: sub.icmRoutingId,
      severity: sub.severity,
      rowVersion: sub.rowVersion
    };

    return subRequest;
  }

  private createEventGridFilter(sub: EventTypeSubscription): any {
    let advFilterObj;

    if (sub.filters !== '') {
      try {
        advFilterObj = JSON.parse(sub.filters);
      } catch (error) {
        throw new Error('Could not parse filters due to invalid JSON format');    // validator should catch this
      }
    } else {
      advFilterObj = [];
    }

    const filters = {
      includedEventTypes: [sub.parentEventName],
      advancedFilters: advFilterObj
      //subjectBeginsWith:
      //subjectEndsWith:
      //isSubjectCaseSensitive:
      //enableAdvancedFilteringOnArrays:
    };
    return filters;
  }
}


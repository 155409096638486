import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { EventTypeMasterData } from '../model/event-type-master-data.model';
import { EventType } from '../model/event-type.model';
import { EventCatalogService } from '../service/event-catalog.service';
import { UserDataService } from '../../service/user-data.service';
import { switchMap } from 'rxjs/operators';
import { GraphDataService } from '../../service/graph-data.service';
import { ProblemDetailsHttpErrorResponse } from '../../common/model/problem-details-http-error-response.model';
import { AppConfig } from '../../model/app-config.model';
import { APP_CONFIG } from '../../common/constants';

@Component({
  selector: 'app-save-event',
  templateUrl: './save-event.component.html',
  styleUrls: ['./save-event.component.scss'],
})
export class SaveEventComponent implements OnInit, OnDestroy {

  @Input() eventType: EventType;

  isUpdate: boolean;

  eventForm = new UntypedFormGroup({
    eventName: new UntypedFormControl('', [Validators.required, Validators.pattern("^[A-Z]+[A-Za-z0-9.]*$")]),
    description: new UntypedFormControl('', [Validators.required]),
    schema: new UntypedFormControl('', [Validators.required]),
    contact: new UntypedFormControl('', [Validators.required, Validators.email]),
    ownerGroupAlias: new UntypedFormControl('', [Validators.required, Validators.email]),
    team: new UntypedFormControl('', [Validators.required]),
    service: new UntypedFormControl('', [Validators.required]),
    serviceId: new UntypedFormControl('', [Validators.required,
      Validators.pattern("^[A-Fa-f0-9]{8}-[A-Fa-f0-9]{4}-[A-Fa-f0-9]{4}-[A-Fa-f0-9]{4}-[A-Fa-f0-9]{12}$")]),
  });

  masterData: EventTypeMasterData;
  subscription: Subscription;
  isLoadingMasterData: boolean;
  isSaving: boolean;
  showError: boolean;
  errorMessage: string;

  constructor(
    private activeModal: NgbActiveModal,
    private eventCatalogService: EventCatalogService,
    private userDataService: UserDataService,
    private graphDataService: GraphDataService,
    @Inject(APP_CONFIG) private appConfig: AppConfig,
  ) {
    this.subscription = new Subscription();
    this.isLoadingMasterData = false;
    this.showError = false;
    this.errorMessage = "";
    this.isUpdate = false;
  }

  ngOnInit(): void {
    // get master data for dropdowns
    this.isLoadingMasterData = true;
    const masterDataSubscription = this.eventCatalogService.getEventTypeMasterData()
    .subscribe(
      (masterData: EventTypeMasterData) => this.masterData = masterData,
      (errResponse: ProblemDetailsHttpErrorResponse) => {
        console.error('Error occurred while fetching event type master data', errResponse);
        this.setError(errResponse.error.title);
        this.isSaving = false;
      }
    ).add(() => this.isLoadingMasterData = false);
    this.subscription.add(masterDataSubscription);

    // load existing event type configuration if needed
    if (this.eventType !== undefined) {
      this.isUpdate = true;

      // disable controls for update
      this.eventForm.controls.eventName.disable();
      this.eventForm.controls.schema.disable();

      // load values into form group
      this.eventForm.controls.eventName.setValue(this.eventType.name);
      this.eventForm.controls.description.setValue(this.eventType.description);
      this.eventForm.controls.schema.setValue(this.eventType.schemaType);
      this.eventForm.controls.contact.setValue(this.eventType.contactAlias);
      this.eventForm.controls.ownerGroupAlias.setValue(this.eventType.ownerGroupAlias);
      this.eventForm.controls.team.setValue(this.eventType.teamName);
      this.eventForm.controls.service.setValue(this.eventType.serviceName);
      this.eventForm.controls.serviceId.setValue(this.eventType.serviceId);
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  cancel(): void {
    this.activeModal.dismiss();
  }

  saveEventType(): void {
    this.isSaving = true;

    const savedEventType = new EventType();

    if (this.isUpdate) {
      savedEventType.id = this.eventType.id;
    }
    savedEventType.name = this.eventForm.controls.eventName.value as string;
    savedEventType.description = this.eventForm.controls.description.value as string;
    savedEventType.schemaType = this.eventForm.controls.schema.value as string;
    savedEventType.contactAlias = this.eventForm.controls.contact.value as string;
    savedEventType.ownerGroupAlias = this.eventForm.controls.ownerGroupAlias.value as string;
    savedEventType.teamName = this.eventForm.controls.team.value as string;
    savedEventType.serviceName = this.eventForm.controls.service.value as string;
    savedEventType.serviceId = this.eventForm.controls.serviceId.value as string;
    savedEventType.environment = this.appConfig.environment === "PROD" ? "PROD" : "PPE";
    savedEventType.updatedBy = this.userDataService.userName;
    savedEventType.updatedOn = new Date();

    // get group id for alias then submit to save
    const groupId$ = this.graphDataService.getGroupId(savedEventType.ownerGroupAlias);
    const saveEventTypeSubscription = groupId$
    .pipe(
      switchMap((groupId: string) => {
        savedEventType.ownerGroupId = groupId;
        if (this.isUpdate) {
          return this.eventCatalogService.updateEventType(savedEventType);
        } else {
          return this.eventCatalogService.createEventType(savedEventType);
        }
      })
    )
    .subscribe(
      () => {
        this.activeModal.close(savedEventType.name);
      },
      (errResponse: ProblemDetailsHttpErrorResponse) => {
        console.error('Error occurred while saving event type', errResponse);
        this.setError(errResponse.error.title);
        this.isSaving = false;
      }
    );

    this.subscription.add(saveEventTypeSubscription);
  }

  setError(msg: string) {
    this.showError = true;
    this.errorMessage = msg;
  }

  clearError() {
    this.showError = false;
    this.errorMessage = '';
  }

  shouldShowValidationError(formControl: AbstractControl): boolean {
    return formControl.invalid && formControl.touched && formControl.dirty;
  }
}

import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { ReplaySubject, Subject, Subscription, partition, of, forkJoin } from 'rxjs';
import { mergeMap, switchMap } from 'rxjs/operators';
import { EventType } from '../model/event-type.model';
import { EventCatalogService } from '../service/event-catalog.service';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgbModalSuccessComponent } from '../../modal/ngb-modal-success.component';
import { NgbModalErrorComponent } from '../../modal/ngb-modal-error.component';
import { APP_CONFIG } from '../../common/constants';
import { TableOptions, TableInputV2, RowEditEvent } from '../../bam-dashboard/model/common.model';
import { AppConfig } from '../../model/app-config.model';
import { EventTypeSubscription } from '../model/event-type-subscription.model';
import { SaveEventComponent } from '../save-event/save-event.component';
import { SaveEventModal } from '../model/save-event-modal-model';
import { SaveSubscriptionComponent } from '../save-subscription/save-subscription.component';
import { SaveSubscriptionModal } from '../model/save-subscription-modal-model';
import moment from 'moment';
import { ProblemDetailsHttpErrorResponse } from '../../common/model/problem-details-http-error-response.model';
import { ProblemDetails } from '../../common/model/problem-details.model';

class Table {
  isLoaded: boolean;
  loadTable: boolean;
  hidden: boolean;

  // display table components.
  columns: Array<string>;
  options: TableOptions;
  data: ReplaySubject<TableInputV2>;
  search: Subject<string>;

  constructor() {
    this.isLoaded = false;
    this.loadTable = false;
    this.hidden = false;

    this.columns = [];
    this.options = {
      addEditColumn: false,
      editColumns: [],
      enableSearch: false,
      searchColumns: [],
      columnWidths: [],
      centeredColumns: [],
      wrapTextColumns: [],
      addNavColumn: false,
      navColumns: []
    };
    this.data = new ReplaySubject<TableInputV2>(1);
    this.search = new Subject<string>();
  }

  complete(): void {
    this.data.complete();
    this.search.complete();
  }
}

@Component({
  selector: 'app-event-details',
  templateUrl: './event-details.component.html',
  styleUrls: ['./event-details.component.css'],
})
export class EventDetailsComponent implements OnInit, OnDestroy {

  /*
  Description:
  - event details page - arrive here from main catalog page to see more information about the event
*/

  // current EventType
  eventType: EventType;
  isEventTypeLoading: boolean;

  // tables
  detailsTable: Table;
  pubsTable: Table;
  subsTable: Table;
  testsTable: Table;

  // hide dev features
  isProd: boolean;

  // Subscription
  private subscriptions: Subscription;

  constructor(
    private router: Router,
    private eventCatalogService: EventCatalogService,
    private appInsightsService: ApplicationInsights,
    private modalService: NgbModal,
    private route: ActivatedRoute,
    @Inject(APP_CONFIG) appConfig: AppConfig,
  ) {
    this.isProd = appConfig.environment === 'PROD' || appConfig.environment === 'UAT';
    this.subscriptions = new Subscription();

    // Details table (use basic bootstrap table instead of display-table-v2, don't need to set options here)
    this.detailsTable = new Table();

    // Publishers table
    this.pubsTable = new Table();
    this.pubsTable.columns = ["Name", "Sample Event", "Payload Destinations (past 30d)", "Last Sent Date"];
    this.pubsTable.options.columnWidths = [0.8, 2, 4, 0.8];
    this.pubsTable.options.wrapTextColumns = ['Payload Destinations (past 30d)'];

    // Subscribers table
    this.subsTable = new Table();
    this.subsTable.columns = ["Name", "Description", "Event Handler", "Updated By", "Updated On", "Status"];
    this.subsTable.options.columnWidths = [0.8, 0.8, 0.4, 0.4, 0.4, 0.25];
    this.subsTable.options.addNavColumn = true;
    this.subsTable.options.navColumns = ['Name'];

    // Test Events table
    this.testsTable = new Table();
    this.testsTable.columns = ["Sent Date", "Sent Time", "Received Date", "Received Time", "Retries", "Json", "Payload"];
    this.testsTable.options.columnWidths = [0.4, 0.4, 0.4, 0.4, 0.2, 0.8, 0.8];
    this.testsTable.options.wrapTextColumns = ['Json', 'Payload'];

  }

  ngOnInit(): void {
    // read eventType id from route and fetch it
    this.isEventTypeLoading = true;
    this.eventType = new EventType();

    let eventTypeId: string;
    let openSubscribeModal = false;

    // parse route for event type id and then get it from API
    const eventId$ = this.route.paramMap
      .pipe(
        switchMap((params: ParamMap) => {
          // handle action
          openSubscribeModal = params.get('action') === 'subscribe';

          // handle event id
          eventTypeId = params.get('id');
          if (isNaN(Number(eventTypeId))) {
            return this.eventCatalogService.getEventTypeByName(eventTypeId);
          } else {
            return this.eventCatalogService.getEventTypeById(+eventTypeId);
          }
        }),
      );

    // redirect if returned event type is undefined
    const [emptyEventType$, validEventType$] = partition(eventId$, (eventType) => eventType === undefined);
    const emptyEventTypeSub = emptyEventType$.subscribe(() => {
      const errorModal = this.modalService.open(NgbModalErrorComponent);
      errorModal.componentInstance.message = `Event with id '${eventTypeId}' does not exist. Redirecting to Event Catalog.`;
      this.router.navigateByUrl('event-broker/event-catalog')
    });

    // get subscription list for event type and subscribe
    const validEventTypeSub = validEventType$.pipe(
      mergeMap((eventType: EventType) =>
        forkJoin([of(eventType), this.eventCatalogService.getSubscriptionListByEventTypeName(eventType.name)])
      )
    ).subscribe(
        ([eventType, subList]) => {
          this.eventType = eventType;
          this.isEventTypeLoading = false;

          // populate the page sections
          this.loadDetails(eventType);
          this.loadSubscribers(subList);
          this.loadPublishers();
          this.loadTestEvents();

          // execute action
          if (openSubscribeModal) {
            this.createSubscription();
          }
        },
        (errResponse: ProblemDetailsHttpErrorResponse) => {
          this.appInsightsService.trackException({ exception: errResponse} ,
            { message: `EventDetails Component: Error fetching eventType with id ${eventTypeId} from catalog`});
          const pd: ProblemDetails = errResponse.error;
          if (pd.status !== 404) {
            const errorModal = this.modalService.open(NgbModalErrorComponent);
            errorModal.componentInstance.message = "Could not load event details: " + pd.title;
          } else {
            const errorModal = this.modalService.open(NgbModalErrorComponent);
            errorModal.componentInstance.message = `Could not find event with id '${eventTypeId}'`;
          }
        }
    );
    this.subscriptions.add(emptyEventTypeSub);
    this.subscriptions.add(validEventTypeSub);
  }

  ngOnDestroy(): void {
    // all child subscriptions will be unsubscribed to avoid memory leaks
    this.subscriptions.unsubscribe();
    this.detailsTable.complete();
    this.pubsTable.complete();
    this.subsTable.complete();
    this.testsTable.complete();
  }

  loadDetails(eventType: EventType): void {
    const tableInput = new TableInputV2();
    tableInput.rows = new Array<Array<string>>();

    tableInput.rows.push(["Event Name", eventType.name]);
    tableInput.rows.push(["Event Id", String(eventType.id)]);
    tableInput.rows.push(["Description", eventType.description]);
    tableInput.rows.push(["Schema", eventType.schemaType]);
    tableInput.rows.push(["Contact Alias", eventType.contactAlias]);
    tableInput.rows.push(["Admin SG", eventType.ownerGroupAlias]);
    tableInput.rows.push(["Team", eventType.teamName]);
    tableInput.rows.push(["Service", eventType.serviceName]);
    tableInput.rows.push(["Service GUID", eventType.serviceId]);
    tableInput.rows.push(["Updated By", eventType.updatedBy]);
    tableInput.rows.push(["Updated On", String(moment.utc(eventType.updatedOn).local().format('MM-DD-YYYY HH:mm'))]);
    tableInput.rows.push(["Publish To Telemetry", eventType.publishToTelemetry ? "Yes" : "No"]);

    this.detailsTable.data.next(tableInput);
    this.detailsTable.isLoaded = true;
  }

  loadSubscribers(eventTypeSubscriptionList: Array<EventTypeSubscription>): void {
          const tableInput = new TableInputV2();
          tableInput.columns = this.subsTable.columns;
          tableInput.rows = new Array<Array<string>>();
          eventTypeSubscriptionList.forEach((sub) => {
            tableInput.rows.push([
              sub.name,
              sub.description,
              sub.eventHandler.ehType,
              sub.updatedBy,
              String(moment.utc(sub.updatedOn).local().format('MM-DD-YYYY HH:mm')),
              sub.status
            ]);
          });

          this.subsTable.data.next(tableInput);
          this.subsTable.isLoaded = true;
  }

  loadPublishers(): void {
    const tableInput = new TableInputV2();
    tableInput.columns = this.pubsTable.columns;
    tableInput.rows = new Array<Array<string>>();
    this.pubsTable.data.next(tableInput);
    this.pubsTable.isLoaded = true;
  }

  loadTestEvents(): void {
    const tableInput = new TableInputV2();
    tableInput.columns = this.testsTable.columns;
    tableInput.rows = new Array<Array<string>>();
    this.testsTable.data.next(tableInput);
    this.testsTable.isLoaded = true;
  }

  editEventDetails(): void {
    const editEventModal = this.modalService.open(SaveEventComponent);
    (editEventModal.componentInstance as SaveEventModal).eventType = this.eventType;
    if (editEventModal) {
      editEventModal.result
        .then((updatedEventTypeName: string) => {
          this.router.navigateByUrl('event-broker/event/'+updatedEventTypeName);
        })
        .catch(() => { });
    }
  }

  createSubscription(): void {
    // open subscription modal
    const editEventSubModal = this.modalService.open(SaveSubscriptionComponent);
    (editEventSubModal.componentInstance as SaveSubscriptionModal).parentEventTypeName = this.eventType.name;
    (editEventSubModal.componentInstance as SaveSubscriptionModal).parentEventTypeId = this.eventType.id;
     editEventSubModal.result
       .then(
       (newSubName: string) => {
         // display successful save message
         const successModal = this.modalService.open(NgbModalSuccessComponent);
         successModal.componentInstance.message = "Subscription creation is in progress. " +
                 "Please refresh after a few minutes to see updated subscription status.";

         // redirect to subscription page
         successModal.result.finally(() => {
          this.router.navigateByUrl('event-broker/event/'+this.eventType.name.toLowerCase()+'/subscription/'+newSubName.toLowerCase());
         });
       })
       .catch(() => { });

    return;
  }

  viewSubscriptionDetails(input: RowEditEvent): void {
    this.router.navigateByUrl('event-broker/event/' + this.eventType.name.toLowerCase() + '/subscription/' + input.value.toLowerCase());
  }

  sendTestEvent(): void {
    return;
  }
}

export class Dashboard {
  id: number;
  name: string;
  type: string;
  purpose: string;
  url: string;
  dataClassification: string;
  accessMethod: string;
  owners: string;
  dataSource: string;
  dataSourceValue: string;
  serviceTree: string;
  alerting: any;
  securityGroup: string;
  environment: string;

  constructor(name: string, type: string, purpose: string, url: string, dataClassification: string, accessMethod: string,
    owners: string, dataSource: string, dataSourceValue: string, serviceTree: string, alerting: any,
    securityGroup: string, environment: string) {

    this.name = name;
    this.type = type;
    this.purpose = purpose;
    this.url = url;
    this.dataClassification = dataClassification;
    this.accessMethod = accessMethod;
    this.owners = owners;
    this.dataSource = dataSource;
    this.dataSourceValue = dataSourceValue;
    this.serviceTree = serviceTree;
    this.alerting = alerting;
    this.securityGroup = securityGroup;
    this.environment = environment;
  }
}

import { Component, OnInit, EventEmitter, Output, Input, OnDestroy, Inject } from '@angular/core';
import { RuleConfig } from '../model/rule.model';
import { constants } from '../common/constants';
import { MasterDataService } from '../service/master-data.service';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { HttpErrorResponse } from '@angular/common/http';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgbModalErrorComponent } from '../../modal/ngb-modal-error.component';
import { UntypedFormGroup, UntypedFormControl, Validators, AbstractControl, ValidatorFn, ValidationErrors } from '@angular/forms';
import { Subscription } from 'rxjs';
import { TemplateVariableService } from '../service/template-variable.service';
import { QueryDisplayModalComponent } from '../query-display-modal/query-display-modal.component';
import { TemplateVariable } from '../model/template-variable.model';
import { APP_CONFIG } from '../../common/constants';
import { AppConfig } from '../../model/app-config.model';
import { DataSource } from '../model/data-source.model';

@Component({
  selector: 'app-direct-query',
  templateUrl: './direct-query.component.html',
  styleUrls: ['./direct-query.component.css']
})
export class DirectQueryComponent implements OnInit, OnDestroy {

  /*
    Description:
    - direct-query component is a child component of parent rule component. It is one type of rule.
    - It contains rule form with all the fields related to direct query feature.
    - This component handles all the responsibility of fields value changes, building a new form,
      validation of all the fields and sending data to parent component named "rule".
  */

  // Subscriptions
  subscriptions: Subscription;

  // Form Group
  directQueryForm = new UntypedFormGroup({
    dataSourceId: new UntypedFormControl('', [Validators.required]),
    templatizedRuleQuery: new UntypedFormControl('', [Validators.required]),
    endEventName: new UntypedFormControl(''),
    removeDuplicates: new UntypedFormControl(''),
    lookbackPeriod: new UntypedFormControl(''),
    lookbackPeriodUnit: new UntypedFormControl(''),
    uniqueKeyFields: new UntypedFormControl('', [this.uniqueKeyFieldValidator]),
    eventTimestampField: new UntypedFormControl(''),
    description: new UntypedFormControl(''),
    ruleName: new UntypedFormControl('', [Validators.required, this.ruleNameValidator()]),
  }, { validators: [this.lookbackPeriodValidator, this.aiNameValidator(), this.validateTemplateVariable()] });

  // Data Source Related
  dataSourceList: Array<DataSource>;

  // RuleConfig
  ruleConfig: RuleConfig;
  ruleNameList: Array<string>;
  isNewRule: boolean;
  currrentRuleName: string;

  // template variable.
  templateVariableValueLookup: Map<string, TemplateVariable>;
  templateVariableRegex: RegExp;
  templatizedRulesWikiUrl: string;

  // loaders.
  isLoading: boolean;

  // Inputs
  @Input() set ruleConfigSet(value: RuleConfig) {
    if (typeof value !== 'undefined') {
      this.ruleConfig = value;
      this.currrentRuleName = this.ruleConfig.ruleName;
      this.isLoading = true;
      const templateVariableSubscription =
        this.templateVariableService.getTemplateVariableLookUpValue(+this.ruleConfig.mappingId)
          .subscribe((lookUpValues: Map<string, TemplateVariable>) => {
            this.templateVariableValueLookup = lookUpValues;
            this.directQueryForm.patchValue(this.ruleConfig, { emitEvent: false });
            // This code is for the first time when someone opens "Add New Rule"
            this.lookbackControlEnablement(this.ruleConfig.removeDuplicates);
            this.isLoading = false;
          });
      this.subscriptions.add(templateVariableSubscription);
      // set flag in case of new rule
      if (this.ruleConfig.ruleName.length === 0) {
        this.isNewRule = true;
      } else {
        this.isNewRule = false;
      }
    }
  }

  @Input() isValid: boolean;

  @Input() set ruleNames(value: Array<string>) {
    if (typeof value !== 'undefined') {
      this.ruleNameList = value;
    }
  }

  @Input() groupId: string;

  @Output() isValidChange = new EventEmitter<boolean>();

  constructor(
    private masterDataService: MasterDataService,
    private appInsightsService: ApplicationInsights,
    private modalService: NgbModal,
    private templateVariableService: TemplateVariableService,
    @Inject(APP_CONFIG) appConfig: AppConfig,
  ) {
    this.templatizedRulesWikiUrl = appConfig.templatizedRulesWikiUrl;
    this.isValid = false;

    this.subscriptions = new Subscription();
    this.isLoading = false;
  }

  ngOnInit(): void {
    this.getDataSources();
    this.templateVariableRegex = this.templateVariableService.getTemplateVariableRegex();
    this.onFormStatusChange();
    this.onFormValueChange();
  }

  ngOnDestroy(): void {
    // all child subscriptions will be unsubscribed to avoid memory leaks
    this.subscriptions.unsubscribe();
  }

  private onFormStatusChange() {
    const formStatusChangeSubscription = this.directQueryForm.statusChanges.subscribe(() => {
      this.isValidChange.emit(this.directQueryForm.valid);
      this.isValid = this.directQueryForm.valid;
    });

    this.subscriptions.add(formStatusChangeSubscription);
  }

  private onFormValueChange() {
    const dataSourceIdSubscription = this.directQueryForm.get('dataSourceId').valueChanges.subscribe((response) => {
      this.ruleConfig.dataSourceId = response;
    });
    const templatizedRuleQuerySubscription = this.directQueryForm.get('templatizedRuleQuery').valueChanges.subscribe((response) => {
      this.ruleConfig.templatizedRuleQuery = response;
    });
    const endEventNameSubscription = this.directQueryForm.get('endEventName').valueChanges.subscribe((response) => {
      this.ruleConfig.endEventName = response;
    });

    const removeDuplicatesSubscription = this.directQueryForm.get('removeDuplicates').valueChanges.subscribe((response) => {
      this.lookbackControlEnablement(response);
      this.ruleConfig.removeDuplicates = response;
    });

    const lookbackPeriodSubscription = this.directQueryForm.get('lookbackPeriod').valueChanges.subscribe((response) => {
      this.ruleConfig.lookbackPeriod = response;
    });
    const lookbackPeriodUnitSubscription = this.directQueryForm.get('lookbackPeriodUnit').valueChanges.subscribe((response) => {
      this.ruleConfig.lookbackPeriodUnit = response;
    });

    const uniqueKeyFieldsSubscription = this.directQueryForm.get('uniqueKeyFields').valueChanges.subscribe((response) => {
      const uniqueKeyFieldsList = response.split(',')
        .map((x: string) => x.trim())
        .filter((x: string) => x !== '');
      this.ruleConfig.uniqueKeyFields = uniqueKeyFieldsList.join(',');
    });

    const eventTimestampFieldSubscription = this.directQueryForm.get('eventTimestampField').valueChanges.subscribe((response) => {
      this.ruleConfig.eventTimestampField = response;
    });

    const descriptionSubscription = this.directQueryForm.get('description').valueChanges.subscribe((response) => {
      this.ruleConfig.description = response;
    });

    const ruleNameSubscription = this.directQueryForm.get('ruleName').valueChanges.subscribe((response) => {
      this.ruleConfig.ruleName = response;
    });

    this.subscriptions.add(dataSourceIdSubscription);
    this.subscriptions.add(templatizedRuleQuerySubscription);
    this.subscriptions.add(endEventNameSubscription);
    this.subscriptions.add(removeDuplicatesSubscription);
    this.subscriptions.add(lookbackPeriodSubscription);
    this.subscriptions.add(lookbackPeriodUnitSubscription);
    this.subscriptions.add(uniqueKeyFieldsSubscription);
    this.subscriptions.add(eventTimestampFieldSubscription);
    this.subscriptions.add(descriptionSubscription);
    this.subscriptions.add(ruleNameSubscription);
  }

  private lookbackControlEnablement(response: any) {
    if (response === true) {
      this.directQueryForm.get('lookbackPeriod').enable({ emitEvent: false });
      this.directQueryForm.get('lookbackPeriodUnit').enable({ emitEvent: false });
      this.directQueryForm.get('uniqueKeyFields').setValidators([Validators.required, this.uniqueKeyFieldValidator]);
    } else {
      this.directQueryForm.get('lookbackPeriod').disable({ emitEvent: false });
      this.directQueryForm.get('lookbackPeriodUnit').disable({ emitEvent: false });
      this.directQueryForm.get('uniqueKeyFields').setValidators([this.uniqueKeyFieldValidator]);
    }
    this.directQueryForm.get('uniqueKeyFields').updateValueAndValidity({ emitEvent: false });
  }

  getDataSources() {
    this.dataSourceList = new Array<DataSource>();
    const getDataSourceListSubscription = this.masterDataService.getNonImportedDataSources(this.groupId).subscribe(
      (response: DataSource[]) => {
        this.dataSourceList = response;
      },
      (err: HttpErrorResponse) => {
        this.appInsightsService.trackException(err, { componentName: 'Direct Query Component: Error while fetching data sources'});
        const e: HttpErrorResponse = err;
        const errorModal = this.modalService.open(NgbModalErrorComponent);
        errorModal.componentInstance.message = "Something went wrong, please try again later";
      }
    );
    this.subscriptions.add(getDataSourceListSubscription);
  }

  viewExpandedQuery() {
    const templatizedQuery = this.directQueryForm.controls.templatizedRuleQuery.value;
    if (templatizedQuery) {
      const ruleQuery = this.templateVariableService.getParsedQuery(templatizedQuery, this.templateVariableValueLookup);
      const queryModal = this.modalService.open(QueryDisplayModalComponent);
      queryModal.componentInstance.query = ruleQuery;
    }
  }

  aiNameValidator(): ValidatorFn {
    return (group: AbstractControl): { [key: string]: any } | null => {
      const dataSourceId = group.get('dataSourceId').value;
      const ruleQuery = this.templateVariableService.getParsedQuery(
        group.get('templatizedRuleQuery').value, this.templateVariableValueLookup);
      let queryErrors = group.get('templatizedRuleQuery')?.errors;
      if (this.dataSourceList !== undefined) {
        const dataSource = this.dataSourceList.find(x => x.id === dataSourceId);
        if (dataSource != null && ruleQuery != null) {
          if (dataSource.type === constants.AI && !ruleQuery.startsWith("app('", 0)) {
            if (!queryErrors) {
              queryErrors = {};
            }
            queryErrors['isAiNameError'] = true;
            queryErrors['AiNameError'] = "Please include Application Insights name in the query. ex: app('AI_Name')";
            group.get('templatizedRuleQuery').setErrors(queryErrors);
            group.get('templatizedRuleQuery').markAsTouched();

          } else {
            // clear only the errors added by ai validator.
            if (queryErrors) {
              delete queryErrors['isAiNameError'];
              delete queryErrors['AiNameError'];
              if (Object.keys(queryErrors).length === 0) {
                group.get('templatizedRuleQuery').setErrors(null);
              } else {
                group.get('templatizedRuleQuery').setErrors(queryErrors);
              }
            }
          }
        }
      }
      return null;
    };
  }

  lookbackPeriodValidator(group: AbstractControl): { [key: string]: any } | null {
    const removeDuplicates = group.get('removeDuplicates').value;
    const lookbackPeriod = group.get('lookbackPeriod').value;
    const lookbackPeriodUnit = group.get('lookbackPeriodUnit').value;

    if (removeDuplicates &&
      (lookbackPeriod === undefined || lookbackPeriodUnit === undefined || lookbackPeriodUnit === null || lookbackPeriod <= 0)
    ) {
      group.get('lookbackPeriod').markAsTouched();
      return { isLookbackPeriodError: true, lookbackPeriodError: "Please select valid Lookback period" };
    } else {
      return null;
    }
  }

  // Validate uniqueness of rule name
  ruleNameValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const ruleName: string = control.value;
      if (ruleName.length > 0 && this.ruleNameList !== undefined) {
        if (this.currrentRuleName !== ruleName && this.ruleNameList.includes(ruleName.toLowerCase())) {
          return { isInvalidRuleNameError: true, invalidRuleNameError: "Rule Name already exists" }
        }
      }
      return null;
    };
  }

  uniqueKeyFieldValidator(control: AbstractControl): { [key: string]: any } | null {
    const fieldList: string = control.value;
    if (fieldList) {
      let fieldArray: Array<string> = fieldList.split(',');
      fieldArray = fieldArray.map(x => x.trim());
      fieldArray = fieldArray.filter(x => x !== '');

      // test for empty field name.
      if (fieldArray.length === 0) {
        return { isInvalidUniqueKeyFields: true, invalidUniqueKeyFieldsError: "Please enter valid unique key fields" };
      } else {
        return null;
      }
    } else {
      return null;
    }
  }

  validateTemplateVariable(): ValidatorFn {
    return (formControl: AbstractControl): ValidationErrors | null => {
      const formGroup = formControl as UntypedFormGroup;
      const queryValue = formGroup.controls.templatizedRuleQuery.value as string;
      const dataSourceId = formGroup.controls.dataSourceId.value as number;

      // regex different from the ones used to extract template variable values.
      // this regex also matches empty spaces inside two curly braces.
      const regex = new RegExp(/\{\{([^{}]*?)\}\}/, 'g');
      const match = queryValue.match(regex);
      if (match == null) {
        return null;
      }

      const dataSourceLanguage = this.dataSourceList.find(x => x.id === dataSourceId)?.type;

      let templateVariableErrorMessage = '';
      const invalidTemplateVariables = new Array<string>();
      const invalidDatasource = new Array<string>();
      match.forEach((matchedValue: string) => {
        matchedValue = matchedValue.replace(/\{/g, '');
        matchedValue = matchedValue.replace(/\}/g, '');
        matchedValue = matchedValue.trim();
        // check for empty template variable.
        if (!(matchedValue || templateVariableErrorMessage)) {
          templateVariableErrorMessage = 'Empty template variable used in query.';
        }

        // check for invalid template variable.
        if (!this.templateVariableValueLookup.has(matchedValue)) {
          if (matchedValue) {
            invalidTemplateVariables.push(matchedValue);
          }
        } else {
          // check for data source mismatch.
          const templateVariable = this.templateVariableValueLookup.get(matchedValue);
          if (templateVariable.languageType !== constants.CONSTANT) {
            if ((dataSourceLanguage === constants.KUSTO || dataSourceLanguage === constants.AI)
              && templateVariable.languageType !== constants.KUSTO) {
              invalidDatasource.push(matchedValue);
            }
            if (dataSourceLanguage === constants.SQL && templateVariable.languageType !== constants.SQL) {
              invalidDatasource.push(matchedValue);
            }
          }
        }
      });

      if (invalidTemplateVariables.length > 0) {
        templateVariableErrorMessage += ` Following template variables do not exist: ${invalidTemplateVariables.join(',')}.`;
      }
      if (invalidDatasource.length > 0) {
        templateVariableErrorMessage += ` Following template variables not compatible with selected data source: ${invalidDatasource.join(',')}.`;
      }

      let queryErrors = formGroup.controls.templatizedRuleQuery.errors;

      if (templateVariableErrorMessage) {
        if (!queryErrors) {
          queryErrors = {};
        }
        queryErrors['inValidTemplateVariable'] = true;
        queryErrors['templateVariableErrorMessage'] = templateVariableErrorMessage;
        formGroup.controls.templatizedRuleQuery.setErrors(queryErrors);
        formGroup.controls.templatizedRuleQuery.markAsTouched();
      } else {
        // clear only the errors added by template variable validator.
        if (queryErrors) {
          delete queryErrors['inValidTemplateVariable'];
          delete queryErrors['templateVariableErrorMessage'];
          if (Object.keys(queryErrors).length === 0) {
            formGroup.controls.templatizedRuleQuery.setErrors(null);
          } else {
            formGroup.controls.templatizedRuleQuery.setErrors(queryErrors);
          }
        }
      }
      return null;
    };
  }
}

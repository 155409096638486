import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { APP_CONFIG } from "../../common/constants";
import { AppConfig } from "../../model/app-config.model";
import { CacheService } from "../../service/cache.service";
import { ProcessPath } from "../model/graph.model";
import { PathTags } from "../model/path-tags.model";
import { Process } from "../model/process.model";

@Injectable()
export class ProcessService {
  private baseBAMUrl: string;

  constructor(
    @Inject(APP_CONFIG) appConfig: AppConfig,
    private httpClient: HttpClient,
    private cacheService: CacheService
  ) {
    this.baseBAMUrl = appConfig.baseUrlWithVersionBAMDashboard;
  }

  public addProcess(
    processName: string,
    teamGroupId: string,
    description: string
  ): Observable<any> {
    const newProcess = {
      name: processName,
      teamGroupId,
      description,
    };
    const url = this.baseBAMUrl + "Process";
    return this.httpClient.post(url, newProcess);
  }

  public updateProcess(process: Process): Observable<any> {
    const url = this.baseBAMUrl + "Process/" + process.id;
    return this.httpClient.put(url, process);
  }

  public getProcessTagMap(processId: string): Observable<Map<string, string>> {
    const tagUrl = this.baseBAMUrl + "Process/" + processId + "/pathtags";
    return this.cacheService.getCached<any>(tagUrl,
      () => this.httpClient.get<Array<PathTags>>(tagUrl).pipe(
      map((data) => {
        const pathCategoryMap = new Map<string, string>();
        data.forEach((path: PathTags) => {
          pathCategoryMap.set(path.pathSequence, path.tags);
        });
        return pathCategoryMap;
      })
    ));
  }

  public updateProcessTags(
    processId: string,
    pathTags: Array<PathTags>
  ): Observable<any> {
    const url = this.baseBAMUrl + "Process/" + processId + "/pathtags";
    this.cacheService.removeCached(url);
    return this.httpClient.post(url, pathTags);
  }

  public getPathIdToSequenceMap(paths: Array<ProcessPath>): Map<string, string> {
    const pathIdToSequenceMap = new Map<string, string>();
    for (const path of paths) {
      const nodePathConcat = path.nodeEventPath.slice(1, path.nodeEventPath.length - 1).join('->');
      pathIdToSequenceMap.set(path.id, nodePathConcat);
    }
    return pathIdToSequenceMap;
  }
}

<div class="pt-2">
  <h5 class="display-5 mt-3 ml-3">Process Onboarding</h5>
  <hr />

  <!-- Adding tabs for process table -->
  <ul class="nav nav-tabs process-table-nav">
    <li class="nav-item">
      <a class="nav-link link-text" id="tab1" [class.active]="selectedTab === 'tab1'" (click)="selectTab('tab1')">Process Alerts and Reports</a>
    </li>
    <li class="nav-item">
      <a class="nav-link link-text" id="tab2" [class.active]="selectedTab === 'tab2'" (click)="selectTab('tab2')">New Process To Onboard</a>
    </li>
    <li class="nav-item">
      <a class="nav-link link-text" id="tab3" [class.active]="selectedTab === 'tab3'" (click)="selectTab('tab3')">Active projects</a>
    </li>
    <li class="nav-item">
      <a class="nav-link link-text" id="tab4" [class.active]="selectedTab === 'tab4'" (click)="selectTab('tab4')">CSCP Processes Forcast</a>
    </li>
  </ul>

  <!-- Add button for adding new process -->
  <div class="mb-3 mt-2">
    <button class="btn btn-primary" (click)="openAddModal()">Add new process to the table</button>
  </div>

  <div class="tab-content">
    <!-- Tab 1 Content -->
    <div class="tab-pane" [class.active]="selectedTab === 'tab1'">
      <div class="table-container">
        <table class="table table-bordered table-striped" aria-label="Process table">
          <thead class="thead-dark">
            <tr tabindex="0">
              <th id="h1" class="text-center" scope="col" style="width:5%;font-weight:bold;padding-top:1px;padding-bottom:5px;">SCOR Functions</th>
              <th id="h2" class="text-center" scope="col" style="width:5%;font-weight:bold;padding-top:1px;padding-bottom:5px;">Service Name</th>
              <th id="h3" class="text-center" scope="col" style="width:10%;font-weight:bold;padding-top:1px;padding-bottom:5px;">All Rules For That Service</th>
              <th id="h4" class="text-center" scope="col" style="width:10%;font-weight:bold;padding-top:1px;padding-bottom:5px;">Description</th>
              <th id="h5" class="text-center" scope="col" style="width:7%;font-weight:bold;padding-bottom: 5px;">Org</th>
              <th id="h6" class="text-center" scope="col" style="width:5%;font-weight:bold;padding-top:1px;padding-bottom:5px;">Team Name</th>
              <th id="h6" class="text-center" scope="col" style="width:5%;font-weight:bold;padding-top:1px;padding-bottom:5px;">Percentage</th>
              <th id="h6" class="text-center" scope="col" style="width:5%;font-weight:bold;padding-top:1px;padding-bottom:5px;">Type</th>
              <th id="h6" class="text-center" scope="col" style="width:5%;font-weight:bold;padding-top:1px;padding-bottom:5px;">Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let alert of processes">
              <td class="description-cell" [title]="alert.scorFunctions">{{ alert.scorFunctions }}</td>
              <td class="description-cell" [title]="alert.serviceName">{{ alert.serviceName }}</td>
              <td class="description-cell" [title]="alert.allRulesForThatService">{{ alert.allRulesForThatService }}</td>
              <td class="description-cell" [title]="alert.description">{{ alert.description }}</td>
              <td>{{ alert.org }}</td>
              <td>{{ alert.teamName }}</td>
              <td>{{ alert.percentage }}</td>
              <td>{{ alert.type }}</td>
              <td headers="h9" class="text-center" style="font-weight:bold;">
                <a class="tab-click" role='button' [routerLink]="[]" aria-label="Edit Details" (click)="openModal(alert, tab1Fields)">
                  <span class="fa fa-edit"></span>
                </a>
                <a class="tab-click ml-2" role="button" [routerLink]="[]" aria-label="Delete Process" (click)="deleteProcess(alert)">
                  <span class="fa fa-trash"></span>
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <!-- Tab 2 Content -->
    <div class="tab-pane" [class.active]="selectedTab === 'tab2'">
      <div class="table-container">
        <table class="table table-bordered table-striped" aria-label="Process table">
          <thead class="thead-dark">
            <tr tabindex="0">
              <th id="h1" class="text-center" scope="col" style="width:5%;font-weight:bold;padding-top:1px;padding-bottom:5px;">Scor Function</th>
              <th id="h2" class="text-center" scope="col" style="width:5%;font-weight:bold;padding-top:1px;padding-bottom:5px;">Service Name</th>
              <th id="h3" class="text-center" scope="col" style="width:5%;font-weight:bold;padding-top:1px;padding-bottom:5px;">Forcasted New Process</th>
              <th id="h4" class="text-center" scope="col" style="width:5%;font-weight:bold;padding-top:1px;padding-bottom:5px;">Process Description</th>
              <th id="h5" class="text-center" scope="col" style="width:7%;font-weight:bold;padding-bottom: 5px;">Org</th>
              <th id="h6" class="text-center" scope="col" style="width:5%;font-weight:bold;padding-top:1px;padding-bottom:5px;">Team Name</th>
              <th id="h7" class="text-center" scope="col" style="width:5%;font-weight:bold;padding-top:1px;padding-bottom:5px;">Type</th>
              <th id="h8" class="text-center" scope="col" style="width:5%;font-weight:bold;padding-top:1px;padding-bottom:5px;">Count</th>
              <th id="h9" class="text-center" scope="col" style="width:5%;font-weight:bold;padding-top:1px;padding-bottom:5px;">Scor Status</th>
              <th id="h10" class="text-center" scope="col" style="width:5%;font-weight:bold;padding-top:1px;padding-bottom:5px;">Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let process of newProcesses">
              <td>{{ process.scorFunction }}</td>
              <td>{{ process.serviceName }}</td>
              <td class="description-cell" title="{{ process.newProcessNames }}">{{ process.newProcessNames }}</td>
              <td class="description-cell" title="{{ process.processDescription }}">{{ process.processDescription }}</td>
              <td>{{ process.org }}</td>
              <td>{{ process.teamName }}</td>
              <td>{{ process.type }}</td>
              <td>{{ process.count }}</td>
              <td>{{ process.status }}</td>
              <td headers="h9" class="text-center" style="font-weight:bold;">
                <a class="tab-click" role='button' [routerLink]="[]" aria-label="Edit Details" (click)="openModal(process, tab2Fields)">
                  <span class="fa fa-edit"></span>
                </a>
                <a class="tab-click ml-2" role="button" [routerLink]="[]" aria-label="Delete Process" (click)="deleteProcess(process)">
                  <span class="fa fa-trash"></span>
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <!-- Tab 3 Content -->
    <div class="tab-pane" [class.active]="selectedTab === 'tab3'">
      <div class="table-container">
        <table class="table table-bordered table-striped" aria-label="Process table">
          <thead class="thead-dark">
            <tr tabindex="0">
              <th id="h1" class="text-center" scope="col" style="width:5%;font-weight:bold;padding-top:1px;padding-bottom:5px;">Project Names</th>
              <th id="h2" class="text-center" scope="col" style="width:5%;font-weight:bold;padding-top:1px;padding-bottom:5px;">Project Description</th>
              <th id="h3" class="text-center" scope="col" style="width:5%;font-weight:bold;padding-top:1px;padding-bottom:5px;">Process Monitoring</th>
              <th id="h4" class="text-center" scope="col" style="width:5%;font-weight:bold;padding-top:1px;padding-bottom:5px;">Process Description</th>
              <th id="h5" class="text-center" scope="col" style="width:7%;font-weight:bold;padding-top:1px;padding-bottom:5px;">Team Name</th>
              <th id="h6" class="text-center" scope="col" style="width:7%;font-weight:bold;padding-top:1px;padding-bottom:5px;">Process Count</th>
              <th id="h7" class="text-center" scope="col" style="width:5%;font-weight:bold;padding-top:1px;padding-bottom:5px;">Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let process of activeProjects">
              <td class="description-cell" title="{{ process.projectNames }}">{{ process.projectNames }}</td>
              <td class="description-cell" title="{{ process.projectDescription }}">{{ process.projectDescription }}</td>
              <td class="description-cell" title="{{ process.processMonitoring }}">{{ process.processMonitoring }}</td>
              <td class="description-cell" title="{{ process.processMonitoring }}">{{ process.processMonitoring }}</td>
              <td>{{ process.teamName }}</td>
              <td>{{ process.processCount }}</td>
              <td headers="h9" class="text-center" style="font-weight:bold;">
                <a class="tab-click" role='button' [routerLink]="[]" aria-label="Edit Details" (click)="openModal(process, tab3Fields)">
                  <span class="fa fa-edit"></span>
                </a>
                <a class="tab-click ml-2" role="button" [routerLink]="[]" aria-label="Delete Process" (click)="deleteProcess(process)">
                  <span class="fa fa-trash"></span>
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <!-- Tab 4 Content -->
    <div class="tab-pane" [class.active]="selectedTab === 'tab4'">
      <div class="table-container">
        <table class="table table-bordered table-striped" aria-label="CSCP Processes Forecast table">
          <thead class="thead-dark">
            <tr tabindex="0">
              <th id="h1" class="text-center" scope="col" style="width:5%;font-weight:bold;padding-top:1px;padding-bottom:5px;">SCOR Function</th>
              <th id="h2" class="text-center" scope="col" style="width:5%;font-weight:bold;padding-top:1px;padding-bottom:5px;">Service Name</th>
              <th id="h3" class="text-center" scope="col" style="width:5%;font-weight:bold;padding-top:1px;padding-bottom:5px;">New Process Name</th>
              <th id="h4" class="text-center" scope="col" style="width:5%;font-weight:bold;padding-top:1px;padding-bottom:5px;">Process Description</th>
              <th id="h5" class="text-center" scope="col" style="width:7%;font-weight:bold;padding-top:1px;padding-bottom:5px;">Org</th>
              <th id="h6" class="text-center" scope="col" style="width:5%;font-weight:bold;padding-top:1px;padding-bottom:5px;">Type</th>
              <th id="h7" class="text-center" scope="col" style="width:5%;font-weight:bold;padding-top:1px;padding-bottom:5px;">Count</th>
              <th id="h8" class="text-center" scope="col" style="width:5%;font-weight:bold;padding-top:1px;padding-bottom:5px;">Status</th>
              <th id="h9" class="text-center" scope="col" style="width:5%;font-weight:bold;padding-top:1px;padding-bottom:5px;">Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let process of cscpprocesses">
              <td>{{ process.scorFunction }}</td>
              <td class="description-cell" title="{{ process.serviceName }}">{{ process.serviceName }}</td>
              <td class="description-cell" title="{{ process.newProcessNames }}">{{ process.newProcessNames }}</td>
              <td class="description-cell" title="{{ process.processDescription }}">{{ process.processDescription }}</td>
              <td>{{ process.org }}</td>
              <td>{{ process.type }}</td>
              <td>{{ process.count }}</td>
              <td>{{ process.status }}</td>
              <td headers="h9" class="text-center" style="font-weight:bold;">
                <a class="tab-click" role="button" [routerLink]="[]" aria-label="Edit Details" (click)="openModal(process, tab4Fields)">
                  <span class="fa fa-edit"></span>
                </a>
                <a class="tab-click ml-2" role="button" [routerLink]="[]" aria-label="Delete Process" (click)="deleteProcess(process)">
                  <span class="fa fa-trash"></span>
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  <app-table-edit-display [showModal]="showModal" [rowData]="selectedRow" [formFields]="selectedFields" (close)="closeModal()" (update)="updateRow($event)"></app-table-edit-display>
</div>